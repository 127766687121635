/* eslint-disable camelcase */
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import type { RefObject, UIEvent, ChangeEvent, SyntheticEvent } from 'react'
import type {
  MRT_Column,
  MRT_Icons,
  MRT_Localization,
  MRT_TableInstance,
  MRT_TableState,
  MRT_Virtualizer,
} from 'material-react-table'
import MaterialReactTable from 'material-react-table'
import type {
  ColumnPinningState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table'
import type { AsyncThunk } from '@reduxjs/toolkit'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import type {
  IconPropTypes,
  IpColumnsFilter,
  IpDataGridBodyRowTypes,
  IpDataGridPropTypes,
  IpDataGridScrollOptionsTypes,
} from '@infopulse-design-system/shared/types/IpTable.types'
import type { IpSelectOptionTypes } from '@infopulse-design-system/shared/types/IpSelect.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import {
  primaryFontFamily,
  tableFontSize,
  tableRowHeight,
} from '@infopulse-design-system/shared/theme/typography'
import type { SelectChangeEvent, Theme } from '@mui/material'
import { Box } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpTable'
import { TopToolbar } from './IpTableTopToolbar'
import { useAppDispatch } from '../../../../redux/store'
import useResponsive from '../../../../hooks/useResponsive'
import { IpPagination } from '../IpPagination'
import { IpText } from '../IpText'
import { IpSelect } from '../IpSelect'
import '../../../../theme/components/MuiTableWrapper.scss'

export type IpTablePropTypesReact = IpDataGridPropTypes<
  SortingState,
  PaginationState,
  AsyncThunk<void, any, Record<string, unknown>>,
  SyntheticEvent,
  MRT_Localization
>

export const IpTableTestId = 'IpTableTestId'
export const IpTablePaginationTestId = 'IpTablePaginationTestId'

const cellFontStyles = {
  fontFamily: primaryFontFamily,
  fontSize: tableFontSize,
}

const getMuiTableHeadCellProps = ({
  column,
}: {
  column: MRT_Column<IpDataGridBodyRowTypes>
}) => {
  return {
    sx: {
      ...cellFontStyles,
      fontWeight: 600,
      paddingBottom: '1rem',
      paddingTop: '1rem',
    },
    className: column.getIsPinned() ? 'pinned-column' : '',
  }
}

const muiTableHeadRowProps = {
  sx: { height: tableRowHeight },
}

const muiTableBodyCellProps = {
  sx: cellFontStyles,
}

const getMuiTableContainerProps = ({
  tableContainerRef,
  tableColor = 'primary',
  isMobileResolution,
  maxHeight,
  scrollOptions,
  infiniteScrollAsyncAction,
  fetchMoreOnBottomReached,
}: {
  tableContainerRef: RefObject<HTMLDivElement>
  tableColor: IpTablePropTypesReact['tableColor']
  isMobileResolution?: boolean
  maxHeight?: string
  scrollOptions?: IpDataGridScrollOptionsTypes
  infiniteScrollAsyncAction?: AsyncThunk<void, any, Record<string, unknown>>
  fetchMoreOnBottomReached: (
    containerRefElement?: HTMLDivElement | null
  ) => void
}) => {
  const scrollStyles = scrollOptions
    ? {
        '&::-webkit-scrollbar': {
          width: scrollOptions?.width,
          height: scrollOptions?.width,
        },
        '&::-webkit-scrollbar-track': {
          background: scrollOptions?.background,
        },
        '&::-webkit-scrollbar-thumb': {
          background: scrollOptions?.color,
          borderRadius: scrollOptions?.borderRadius,
          '&:hover': {
            background: scrollOptions?.color,
          },
        },
      }
    : {}
  return {
    ref: tableContainerRef,
    sx: (theme: Theme) => ({
      maxHeight: isMobileResolution
        ? styles.table_max_height_mobile
        : maxHeight,
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette[tableColor].main,
        '&:hover': {
          background: theme.palette[tableColor].main,
        },
      },
      ...scrollStyles,
    }),
    onScroll: (event: UIEvent<HTMLDivElement>) =>
      !!infiniteScrollAsyncAction &&
      fetchMoreOnBottomReached(event.target as HTMLDivElement),
  }
}

const getIcons = (SortIconComponent: any): Partial<MRT_Icons> => ({
  ArrowDownwardIcon: (iconProps: IconPropTypes) => (
    <SortIconComponent {...iconProps} />
  ),
})

const defaultPaginationOptions: IpSelectOptionTypes[] = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
]

/**
 * `IpTable` is a powerful component that showcases sets of data in a structured manner. They offer extensive
 * customization options, allowing users to tailor the appearance and functionality to their specific
 * needs. Here are some of key features and capabilities provided by our table component:
 *
 * Pagination: Users can navigate through large datasets by dividing them into manageable pages, enabling
 * easy access to specific sections of the data.
 *
 * Sorting: Users can sort table columns in ascending or descending order, allowing them to organize
 * the data based on different criteria.
 *
 * Filtering: Multiple filtering options are available, including text-based filtering, range filtering,
 * and multi-select filtering. These options enable users to refine the data displayed in the table based
 * on specific conditions.
 *
 * Infinite Scroll: Instead of traditional pagination, infinite scroll dynamically loads more data as
 * users scroll down the table, providing a seamless and uninterrupted browsing experience.
 *
 * Search: Users can search for specific values or keywords within the table, making it easier to locate
 * and retrieve relevant information.
 *
 * Backend Support: Our component is easy to integrate with server systems, which ensures efficient data
 * retrieval and synchronization between interface and backend.
 *
 * Localization: The table component supports localization, allowing users to present and interact with
 * the table in different languages or regions.
 *
 * With these extensive features and capabilities, our table component empowers users to efficiently
 * manage, analyze, and interact with complex data sets, facilitating better decision-making and data
 * exploration.
 *
 * To create a basic table, you only need to provide two essential pieces of information: the `headConfig`
 * and the `data`. The `headConfig` specifies the configuration for the table headers, while the `data` array
 * contains the actual data to be displayed.
 *
 * One crucial point to note is that the `accessorKey` in the `headConfig` should match the field name in the
 * objects of the `data` array. This ensures that the table can correctly associate the headers with the
 * corresponding data.
 *
 * By providing the `headConfig` and `data`, you can effortlessly generate a simple table that effectively
 * presents your data in a structured and organized manner.
 */
export function IpTable(props: IpTablePropTypesReact) {
  const {
    classes,
    dataRowsCount = 1,
    data,
    defaultColumnSize,
    enableBottomToolbar = false,
    enableColumnActions = false,
    enableColumnFilters = false,
    enableColumnResizing = false,
    enableHiding = false,
    enablePagination = false,
    enablePinning = false,
    enableSearch = false,
    enableSorting = false,
    enableStickyFooter = false,
    enableStickyHeader = false,
    enableTopToolbar = false,
    error,
    headConfig,
    infiniteScrollAsyncAction,
    infiniteScrollFetchSize = 10,
    isLoading,
    localization,
    manualPagination = false,
    manualSearchAndFiltering = false,
    manualSorting = false,
    maxHeight = '100%',
    maxPinningItem = 2,
    onColumnFiltersChange,
    onRowClick,
    onGlobalSearchChange,
    onSortingChange,
    onPaginationChange,
    paginationOptions = defaultPaginationOptions,
    scrollOptions,
    scrollToTop = true,
    startSearchCount = 2,
    SortIconComponent = ArrowDownwardIcon,
    tableColor = 'primary',
    totalRowCount = 0,
  } = props

  const dispatch = useAppDispatch()

  const defaultPaginationPageSize = Number(paginationOptions[0].value)

  const virtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableCellElement>>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableInstanceRef =
    useRef<MRT_TableInstance<IpDataGridBodyRowTypes> | null>(null)

  const [searchValue, setSearchValue] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [pinning, setPinning] = useState<ColumnPinningState>({})
  const [fetchedItemsCount, setFetchedItemsCount] = useState(0)
  const [columnFilters, setColumnFilters] = useState<IpColumnsFilter>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: defaultPaginationPageSize,
  })

  const muiTableBodyRowProps = ({ row }: { row: unknown }) => ({
    onClick: (e: SyntheticEvent) => onRowClick && onRowClick(e, row),
    sx: { cursor: 'pointer', height: tableRowHeight },
  })

  const { isMobileResolution } = useResponsive()

  const { pageSize, pageIndex } = pagination
  const dataLength = manualPagination ? dataRowsCount : data.length
  const pageCount = Math.ceil(dataLength / pageSize)
  const onPaginationChangeHandler = (_: ChangeEvent<unknown>, page: number) => {
    if (page !== pageIndex) {
      setPagination((prev) => ({
        pageIndex: page,
        pageSize: prev.pageSize,
      }))
    }
  }

  const onPageSizeChange = (e: SelectChangeEvent<string>): void => {
    const size = +e.target.value
    if (size !== pageSize) {
      setPagination({
        pageIndex: 1,
        pageSize: size,
      })
    }
  }

  const CustomTablePagination = () => {
    return (
      <Box
        className="IpTable-pagination-wrapper"
        data-testid={IpTablePaginationTestId}
      >
        <IpText variant="body4" classes="IpTable-pagination-text">
          Set
        </IpText>
        <IpSelect
          variant="standard"
          classes="IpTable-pagination-select"
          defaultValue={defaultPaginationPageSize}
          value={pageSize}
          options={paginationOptions}
          onChange={onPageSizeChange}
        />
        <IpText variant="body4" classes="IpTable-pagination-text">
          Items per page
        </IpText>
        <IpPagination
          count={pageCount}
          page={pageIndex}
          variant="text"
          shape="rounded"
          onChange={onPaginationChangeHandler}
        />
      </Box>
    )
  }

  const getMuiTablePaginationProps = () => {
    return {
      ActionsComponent: CustomTablePagination,
      onRowsPerPageChange: onPageSizeChange,
      count: dataLength,
    }
  }

  const onColumnPinningChange = (e: any) => {
    const currentAction = e()

    const getPinItemLength = (state: ColumnPinningState) => {
      const leftCount = state.left?.length || 0
      const rightCount = state.right?.length || 0
      return leftCount + rightCount
    }

    const isUserWantToUnpin = getPinItemLength(currentAction) === 0
    const isPossibleToPin = maxPinningItem > getPinItemLength(pinning)

    if (isMobileResolution) {
      setPinning(currentAction)
    } else if (isPossibleToPin || isUserWantToUnpin) {
      setPinning(e)
    } else {
      const activeSide = currentAction.left.length ? 'left' : 'right'
      const inactiveSide = activeSide === 'left' ? 'right' : 'left'

      const withoutLastValue = pinning?.[activeSide]?.filter(
        (item) => item !== pinning?.[activeSide]?.[maxPinningItem - 1]
      )

      setPinning({
        [activeSide]: currentAction?.[activeSide].concat(withoutLastValue),
        [inactiveSide]: [],
      })
    }
  }

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        const isScrollPositionOnBottom =
          scrollHeight - scrollTop - clientHeight < 50
        const isServerHasMoreData = data.length < totalRowCount

        if (error) return

        if (
          isScrollPositionOnBottom &&
          !isLoading &&
          isServerHasMoreData &&
          infiniteScrollAsyncAction
        ) {
          dispatch(
            infiniteScrollAsyncAction({
              size: fetchedItemsCount + infiniteScrollFetchSize,
              sorting,
              searchValue,
              columnFilters,
            })
          )
          setFetchedItemsCount(fetchedItemsCount + infiniteScrollFetchSize)
        }
      }
    },
    [isLoading, totalRowCount]
  )

  const customClasses = formatClassName(
    generateClasses('table', 'react'),
    `IpTable-color-${tableColor}`,
    classes
  )

  const muiTableContainerProps = getMuiTableContainerProps({
    tableContainerRef,
    tableColor,
    isMobileResolution,
    maxHeight,
    scrollOptions,
    infiniteScrollAsyncAction,
    fetchMoreOnBottomReached,
  })

  const tableIcons = getIcons(SortIconComponent)

  const tableState: Partial<MRT_TableState<IpDataGridBodyRowTypes>> = {
    sorting,
    columnFilters,
    columnPinning: pinning,
    isLoading,
    showProgressBars: isLoading,
    pagination: {
      ...pagination,
      pageIndex: 0,
    },
  }

  const renderToolbar = ({
    table,
  }: {
    table: MRT_TableInstance<IpDataGridBodyRowTypes>
  }) => (
    <TopToolbar
      table={table}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      headConfig={headConfig}
      startSearchCount={startSearchCount}
      tableColor={tableColor}
      enableSearch={enableSearch}
    />
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  useEffect(() => {
    if (scrollToTop && tableContainerRef.current?.scrollTo) {
      tableContainerRef.current.scrollTo({ top: 0 })
    }
  }, [sorting, searchValue, columnFilters, pageIndex])

  useEffect(() => {
    if (scrollToTop && tableInstanceRef.current) {
      const tablePaperEl = tableInstanceRef.current.refs.tablePaperRef.current
      tablePaperEl.scrollIntoView()
    }
    if (onPaginationChange) {
      onPaginationChange({ pageIndex, pageSize })
    }
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (onColumnFiltersChange) onColumnFiltersChange(columnFilters)
  }, [columnFilters])

  useEffect(() => {
    if (onGlobalSearchChange) onGlobalSearchChange(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (onSortingChange) onSortingChange(sorting)
  }, [sorting])

  const paginatedData = useMemo(() => {
    const begin = (pageIndex - 1) * pageSize
    const end = begin + pageSize
    return data.slice(begin, end)
  }, [data, pageIndex, pageSize])

  const paginationData = manualPagination
    ? data
    : (paginatedData as IpTablePropTypesReact['data'])

  return (
    <div data-testid={IpTableTestId} className={customClasses}>
      <MaterialReactTable
        columns={headConfig}
        columnResizeMode="onEnd"
        data={enablePagination ? paginationData : data}
        defaultColumn={defaultColumnSize}
        enableBottomToolbar={enableBottomToolbar}
        enableColumnActions={enableColumnActions}
        enableColumnFilters={enableColumnFilters}
        enableColumnResizing={enableColumnResizing}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableGlobalFilter={enableSearch}
        enableHiding={enableHiding}
        enablePagination={enablePagination}
        enablePinning={enablePinning}
        enableRowVirtualization={!!infiniteScrollAsyncAction}
        enableSorting={enableSorting}
        enableStickyFooter={enableStickyFooter}
        enableStickyHeader={enableStickyHeader}
        enableTopToolbar={enableTopToolbar}
        globalFilterFn="contains"
        localization={localization}
        manualFiltering={manualSearchAndFiltering}
        manualSorting={manualSorting}
        muiTableBodyCellProps={muiTableBodyCellProps}
        muiTableBodyRowProps={muiTableBodyRowProps}
        muiTableContainerProps={muiTableContainerProps}
        muiTableHeadCellProps={getMuiTableHeadCellProps}
        muiTableHeadRowProps={muiTableHeadRowProps}
        // @ts-ignore
        muiTablePaginationProps={getMuiTablePaginationProps}
        onColumnPinningChange={onColumnPinningChange}
        onSortingChange={setSorting}
        renderTopToolbar={renderToolbar}
        state={tableState}
        icons={tableIcons}
        tableInstanceRef={tableInstanceRef}
        virtualizerInstanceRef={virtualizerInstanceRef}
      />
    </div>
  )
}
