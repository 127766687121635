import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpDateTimePicker'

const isMobile = '@media (max-width: 600px)'

export default (theme: Theme) => {
  return {
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: {
          '& > div': {
            width: '100%',
            maxHeight: '100%',
          },
          '.MuiPickersToolbar-root': {
            [isMobile]: {
              padding: 0,
            },
          },
          '& .PrivatePickersYear-yearButton.Mui-disabled': {
            display: 'none',
          },
          '& .MuiPickersFadeTransitionGroup-root': {
            color: theme.palette.primary.dark,
          },
          '& .MuiDayPicker-header > .MuiTypography-root': {
            color: theme.palette.text.primary,
            '&:first-of-type, &:last-of-type': {
              color: theme.palette.error.main,
            },
          },
          '& .MuiDayPicker-weekContainer > .MuiPickersDay-root:not(.Mui-selected)':
            {
              color: theme.palette.grey[600],
            },
          '& .MuiButtonBase-root.IpHoliday:not(.Mui-selected)': {
            color: `${theme.palette.error.main}`,
          },
          '& .MuiButtonBase-root.MuiPickersDay-root': {
            borderRadius: styles.date_time_picker_button_border_radius,
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root.Mui-disabled .MuiSvgIcon-root':
            {
              color: theme.palette.grey[900],
            },
          '& .MuiClockPicker-root': {
            width: styles.date_time_picker_clock_width,
            maxWidth: '100%',
            height: styles.date_time_picker_clock_height,
            marginTop: styles.date_time_picker_clock_margin_top,
            overflowX: 'initial',
            [isMobile]: {
              width: styles.date_time_picker_clock_width_mobile,
            },
            '& .MuiPickersArrowSwitcher-root': {
              '& .MuiPickersArrowSwitcher-spacer': {
                width:
                  styles.date_time_picker_clock_arrow_switcher_spacer_width,
              },
              '& .MuiButtonBase-root:not(.Mui-disabled) > .MuiSvgIcon-root': {
                color: theme.palette.primary.dark,
              },
            },
            '& .MuiClock-root': {
              '& .MuiClock-clock': {
                scale: styles.date_time_picker_clock_scale,
                backgroundColor: `${theme.palette.grey[500]}40`,
                [isMobile]: {
                  scale: styles.date_time_picker_clock_scale_mobile,
                },
                '& .MuiClock-pin, & .MuiClockPointer-root': {
                  backgroundColor: theme.palette.primary.dark,
                },
                '& .MuiClockPointer-thumb': {
                  border: `${styles.date_time_picker_clock_thumb_border_size} solid ${theme.palette.primary.dark}`,
                  backgroundColor: theme.palette.primary.dark,
                },
                '& .MuiClockNumber-root:not(.Mui-selected)': {
                  color: theme.palette.text.primary,
                },
              },
              '& .MuiButtonBase-root': {
                border: `${styles.date_time_picker_clock_button_border_radius} solid ${theme.palette.primary.dark}`,
                width: styles.date_time_picker_clock_button_size,
                height: styles.date_time_picker_clock_button_size,
                bottom: styles.date_time_picker_clock_button_bottom,
                '& .MuiTypography-root': {
                  fontSize: styles.date_time_picker_button_font_size,
                },
                '&.MuiClock-pmButton': {
                  right: styles.date_time_picker_button_position,
                },
                '&.MuiClock-amButton': {
                  left: styles.date_time_picker_button_position,
                },
              },
            },
          },
        },
      },
    },
  }
}
