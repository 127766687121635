import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { MAX_MEDIA_LENGTH, MIN_MEDIA_LENGTH } from '../../../../../constants'
import useFilters from '../../../../../hooks/useFilters'
import { updateFilters } from '../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../redux/store'
import { IpSlider } from '../../../../ipDesignSystemComponents/atoms/IpSlider'
import { DateTime } from './DateTime'

import './index.scss'

export const convertSliderValueToTime = (value: number): string => {
  const hours = Math.floor(value / 60)
  const minutes = Math.floor(value % 60)
  return `${hours.toString()}:${minutes.toString().padStart(2, '0')}`
}

const sliderValueToDayjs = (number: number): Dayjs => {
  return dayjs(`2023-01-01T${convertSliderValueToTime(number)}:00`)
}

export const FilterMediaLength = () => {
  const dispatch = useAppDispatch()
  const { filterType, reset } = useFilters()
  const [sliderValue, setSliderValue] = useState<number[]>([
    MIN_MEDIA_LENGTH,
    MAX_MEDIA_LENGTH,
  ])
  const [value, setValue] = useState<number[] | undefined>()

  const onChangeFrom = (newValue: Dayjs | null) => {
    if (newValue) {
      const number = newValue.hour() * 60 + newValue.minute()
      setSliderValue((sliderState) => [number, sliderState[1]])
      dispatch(
        updateFilters({
          filterType,
          type: 'media_length_start',
          value: number,
        })
      )
    }
  }

  const onChangeTo = (newValue: Dayjs | null) => {
    if (newValue) {
      const number = newValue.hour() * 60 + newValue.minute()
      setSliderValue((sliderState) => [sliderState[0], number])
      dispatch(
        updateFilters({
          filterType,
          type: 'media_length_end',
          value: number,
        })
      )
    }
  }

  const onSliderChange = (_: Event, newValue: number | number[]) => {
    const newSliderValue = newValue as number[]
    setSliderValue(newSliderValue)
  }

  const onSliderChangeCommitted = (
    _: Event | SyntheticEvent<Element, Event>,
    values: number | number[]
  ) => {
    if (typeof values !== 'number') {
      setValue(values)
    }
  }

  useEffect(() => {
    if (value) {
      const [start, end] = value
      dispatch(
        updateFilters({
          filterType,
          type: 'media_length_start',
          value: start,
        })
      )
      dispatch(
        updateFilters({
          filterType,
          type: 'media_length_end',
          value: end,
        })
      )
    }
  }, [value])

  useEffect(() => {
    setSliderValue([MIN_MEDIA_LENGTH, MAX_MEDIA_LENGTH])
  }, [reset.media_length, reset[filterType]])

  return (
    <div className="filter-range">
      <div className="filter-range-inputs">
        <DateTime
          label="From:"
          value={sliderValueToDayjs(sliderValue[0])}
          onChange={onChangeFrom}
        />
        <div className="filter-range-divider"></div>
        <DateTime
          label="To:"
          value={sliderValueToDayjs(sliderValue[1])}
          onChange={onChangeTo}
        />
      </div>
      <IpSlider
        max={MAX_MEDIA_LENGTH}
        size="small"
        onChange={onSliderChange}
        onChangeCommitted={onSliderChangeCommitted}
        value={sliderValue}
      />
    </div>
  )
}
