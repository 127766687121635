import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetUserTags } from '../../api'
import type { UserTagsCustomPageTypes } from '../../api/apiTypes'
import { updateUserTags } from '../slices/profileSlice'

export const fetchUserTags = createAsyncThunk<void, UserTagsCustomPageTypes>(
  'userTags/fetch',
  // @ts-ignore
  async (page, thunkAPI) => {
    try {
      const data = await apiGetUserTags(page)
      thunkAPI.dispatch(updateUserTags(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
    }
  }
)
