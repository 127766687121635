import type { RootState } from '../store'
import type { MediaData } from '../types/media'

export const mediaDataSelector = (state: RootState) => state.mediaData

export const currentMediaDataSelector = (
  state: RootState,
  mediaId: string
): MediaData | undefined => state.mediaData.data[mediaId]

export const MediaDataIsLoadingSelector = (state: RootState) =>
  state.mediaData.isLoading

export const mediaListSelector = (state: RootState) => state.mediaList
