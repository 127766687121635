/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { fetchBugs } from '../thunk/bugsThunk'
import type { GetBugsResponse } from '../../api/apiTypes'
import type { ApiData } from '../types'

export type BugsState = ApiData<GetBugsResponse>

export const initialBagsState: BugsState = {
  data: { data: [], total: 0 },
  isLoading: false,
  error: '',
}

export const BugsSlice = createSlice({
  name: 'bugs',
  initialState: initialBagsState,
  reducers: {
    setBugs: (state, { payload }: PayloadAction<GetBugsResponse>) => {
      state.data.data = payload.data
      state.data.total = payload.total
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBugs.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchBugs.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchBugs.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const { setBugs } = BugsSlice.actions

export default BugsSlice.reducer
