import CloseIcon from '@mui/icons-material/Close'
import { Popover } from '@mui/material'
import type { Dispatch } from 'react'
import React from 'react'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { usePersonaData } from '../../../../hooks/usePersonaData'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpLink } from '../../../ipDesignSystemComponents/atoms/IpLink'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { MemoAvatar } from '../../ipDSMemo'
import { ROUTER_PATHS } from '../../Router'
import { PersonaMediaList } from '../PersonaMediaList'

import './index.scss'

export type PersonaInfoIconState = {
  anchor: HTMLElement
  id: string
} | null

type Props = {
  personaInfoIconState: PersonaInfoIconState
  setPersonaInfoIconState: Dispatch<PersonaInfoIconState>
}

export const PersonaInfoPopup = ({
  personaInfoIconState,
  setPersonaInfoIconState,
}: Props) => {
  const {
    isPersonaDataLoading,
    personaDataError,
    name,
    photo_url,
    title,
    media,
    categories_id,
    resetError,
  } = usePersonaData(personaInfoIconState?.id ?? '')
  const dictionaryData = useDictionaryData({ categories_id })

  const onCloseHandler = () => {
    setPersonaInfoIconState(null)
    resetError()
  }

  return (
    <Popover
      className="persona-info-popup"
      open={!!personaInfoIconState}
      anchorEl={personaInfoIconState?.anchor}
      onClose={onCloseHandler}
      onBackdropClick={onCloseHandler}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className="persona-info-popup_content">
        {isPersonaDataLoading && <IpProgressBar />}
        <span
          style={{
            color: 'red',
            fontSize: '0.75rem',
            position: 'absolute',
            top: '5px',
            left: '10px',
          }}
        >
          {personaDataError && 'Error on data loading'}
        </span>
        <IpIcon
          classes="persona-info-popup_close"
          color="primary"
          onClick={onCloseHandler}
        >
          <CloseIcon />
        </IpIcon>
        <div className="persona-info">
          <MemoAvatar
            sizes="85px"
            photo={photo_url}
            classes="persona-info_avatar"
          />
          <ul>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Full name:
              </IpText>
              {name}
            </IpText>
            {dictionaryData.categories && (
              <IpText variant="body2" classes="medium" component="li">
                <IpText
                  variant="body2"
                  classes="semi-bold"
                  component="span"
                  color="primary"
                >
                  Categories:
                </IpText>
                {dictionaryData.categories}
              </IpText>
            )}
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Title:
              </IpText>
              {title}
            </IpText>
            <li>
              <div className="persona-media">
                <PersonaMediaList {...media} />
              </div>
            </li>
          </ul>
        </div>
        {!personaDataError && (
          <IpLink
            classes="persona-info-popup_link semi-bold fs14"
            target="_blank"
            to={`/${ROUTER_PATHS.persona}/${personaInfoIconState?.id}`}
            onClick={() => setTimeout(onCloseHandler, 100)}
          >
            See more
          </IpLink>
        )}
      </div>
    </Popover>
  )
}
