import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiAddToBasket, apiGetBasket } from '../../api'
import type { AddBasketParams } from '../../api/apiTypes'
import type { FiltersState } from '../types/filters'
import { setDataIntoBasket, moveToBasket } from '../slices/reportBasketSlice'

type FetchParams = FiltersState & { continuationToken?: string }
export const fetchReportBasket = createAsyncThunk<void, FetchParams>(
  'reportBasket/fetch',
  async (params, thunkAPI) => {
    try {
      const data = await apiGetBasket(params)
      thunkAPI.dispatch(setDataIntoBasket(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)

export const addToReportBasket = createAsyncThunk<void, AddBasketParams>(
  'reportBasket/add',
  async (params, thunkAPI) => {
    try {
      const data = await apiAddToBasket(params)
      thunkAPI.dispatch(moveToBasket(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)
