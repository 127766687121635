import {
  currentMediaDataSelector,
  MediaDataIsLoadingSelector,
  profileSelector,
  transcriptionsDataSelector,
} from '../redux/selectors'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { fetchMediaData } from '../redux/thunk/mediaDataThunk'
import {
  fetchTranscriptionsBugs,
  fetchTranscriptionsData,
} from '../redux/thunk/transcriptionsDataThunk'
import useMainPageActiveTab from './useMainPageActiveTab'

export const useFetchStartPreviewData = (id: string) => {
  const dispatch = useAppDispatch()
  const mediaDataIsLoading = useAppSelector(MediaDataIsLoadingSelector)
  const { data: transcriptionsData, isLoading: transcriptionsDataIsLoading } =
    useAppSelector(transcriptionsDataSelector)
  const { mainActiveTab, isMainBugsTab, isMainResultsTab } =
    useMainPageActiveTab()

  const currentMediaData = useAppSelector((state) =>
    currentMediaDataSelector(state, id)
  )

  const { isLoading: isProfileLoading } = useAppSelector(profileSelector)

  const currentTrData = transcriptionsData[mainActiveTab][id] // hook?

  const startPreviewDataIsLoading =
    mediaDataIsLoading || transcriptionsDataIsLoading || isProfileLoading

  const isStartPreviewDataUploaded = Boolean(currentMediaData && currentTrData)

  const fetchStartPreviewData = () => {
    if (!currentTrData) {
      if (isMainBugsTab) {
        dispatch(fetchTranscriptionsBugs(id))
      }
      if (isMainResultsTab) {
        dispatch(fetchTranscriptionsData(id))
      }
    }
    if (!currentMediaData) {
      dispatch(fetchMediaData(id as string))
    }
  }

  return {
    fetchStartPreviewData,
    startPreviewDataIsLoading,
    isStartPreviewDataUploaded,
  }
}
