import styles from '@infopulse-design-system/shared/theme/components/IpDialog/IpDialogFooter'

export default () => {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: styles.dialog_footer_padding,
          '& > :not(:first-of-type)': {
            marginLeft: styles.dialog_footer_space_between,
          },
        },
      },
    },
  }
}
