const generateData = (year = 0, mouth = 0) => {
  return new Date(
    new Date().getFullYear() - year,
    new Date().getMonth() - mouth,
    new Date().getDate()
  )
}
export const getDate = (dateName: string) => {
  switch (dateName) {
    case '1D':
      return new Date(Date.now() - 24 * 60 * 60 * 1000)
    case '1W':
      return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    case '1M':
      return generateData(0, 1)
    case '6M':
      return generateData(0, 6)
    case '1Y':
      return generateData(1, 0)
    case '5Y':
      return generateData(5, 0)
    case 'All':
      return generateData(30, 0)
    default:
      return new Date()
  }
}
