import { IP_NOTIFICATION_AUTO_HIDE_DURATION } from '@infopulse-design-system/shared/constants/global.config.constants'
import type { IpNotificationSeverityTypes } from '@infopulse-design-system/shared/types/IpNotification.types'
import type { SnackbarKey } from 'notistack'
import React from 'react'
import { IpNotification } from '../components/ipDesignSystemComponents/atoms/IpNotification'
import { useNotification } from './useNotification'

export const useHoveredNotification = () => {
  const { enqueueNotification, closeNotification } = useNotification()

  /**
   * @param message - string
   * @param severity - "error" | "success" | "info" | "warning"
   * @param persist - false
   * @param autoHideDurationOnMouseLeave - 1000
   * @param autoHideDuration -  2500
   */
  const notification = (
    message: string,
    severity: IpNotificationSeverityTypes,
    persist = false,
    autoHideDurationOnMouseLeave = 1000,
    autoHideDuration = IP_NOTIFICATION_AUTO_HIDE_DURATION
  ) => {
    let key: SnackbarKey
    let stopWithDelay: ReturnType<typeof setTimeout>

    const clear = (timeout: number) =>
      setTimeout(() => {
        closeNotification(key)
      }, timeout)

    stopWithDelay = clear(autoHideDuration)

    key = enqueueNotification(
      <IpNotification
        onMouseEnter={() => {
          if (persist) {
            clearTimeout(stopWithDelay)
          }
        }}
        onMouseLeave={() => {
          if (persist) {
            stopWithDelay = clear(autoHideDurationOnMouseLeave)
          }
        }}
        severity={severity}
        variant="filled"
        message={message}
      />,
      { persist, autoHideDuration }
    )
  }

  return { notification }
}
