/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { fetchMediaData } from '../thunk/mediaDataThunk'
import type { ApiData } from '../types'
import type { MediaData } from '../types/media'

export type MediaDataState = ApiData<Record<MediaData['id'], MediaData>>

export const initialMediaDataState: MediaDataState = {
  data: {},
  isLoading: false,
  error: '',
}

export const MediaDataSlice = createSlice({
  name: 'mediaData',
  initialState: initialMediaDataState,
  reducers: {
    setMediaData: (
      state,
      { payload: { id, data } }: PayloadAction<{ id: string; data: MediaData }>
    ) => {
      state.data[id] = data
    },
    resetMediaData: (state) => {
      state.data = {}
    },
    updateOnlyMediaData: (
      state,
      { payload: { id, data } }: PayloadAction<{ id: string; data: MediaData }>
    ) => {
      state.data = {
        [id]: data,
      }
    },
    setUpdatedMediaData: (state, { payload }: PayloadAction<MediaData>) => {
      state.data[payload.id] = { ...state.data[payload.id], ...payload } // todo update only some fields
    },
    updateSpeakersInMediaData: (
      state,
      { payload }: PayloadAction<MediaData>
    ) => {
      state.data[payload.id].speakers = payload.speakers
    },
    // todo: rename to updateUserTagsInMediaData
    updateUserTagsInMedia: (
      state,
      {
        payload: { mediaId, tags },
      }: PayloadAction<{ tags: string[]; mediaId: string }>
    ) => {
      state.data[mediaId].userTags = tags
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchMediaData.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchMediaData.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  resetMediaData,
  setMediaData,
  updateOnlyMediaData,
  setUpdatedMediaData,
  updateSpeakersInMediaData,
  updateUserTagsInMedia,
} = MediaDataSlice.actions

export default MediaDataSlice.reducer
