import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import React from 'react'
import { FiltersPanel } from '../../components/custom/FiltersPanel'
import {
  MainTabButtons,
  MediaExportMenu,
  ResultPanel,
} from '../../components/custom/main'
import { PreviewPanel } from '../../components/custom/PreviewPanel'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import { useActiveMedia } from '../../hooks/useActiveMedia'
import { useFetchStartPreviewData } from '../../hooks/useFetchStartPreviewData'
import useResponsivePerform from '../../hooks/useResponsivePerform'
import { useSimilarSpeakerList } from '../../hooks/useSimilarSpeakerList'
import './index.scss'

function Main() {
  const { mediaId = '' } = useActiveMedia()
  const { isSimilarSpeakerPage } = useSimilarSpeakerList()
  const { isMore: isDetailsShown } = useResponsivePerform(breakpoints.values.lg)
  const { isStartPreviewDataUploaded } = useFetchStartPreviewData(mediaId)

  return (
    <div className="page main">
      {!isSimilarSpeakerPage && <FiltersPanel classes="main-filter" />}
      <div className="main-result">
        <div className="main-result-header">
          <IpText
            color="primary"
            variant="h2"
            classes="main-subtitle main-subtitle_result semi-bold"
          >
            {isSimilarSpeakerPage ? (
              'Similar speakers media list'
            ) : (
              <>
                Search
                <MediaExportMenu />
              </>
            )}
          </IpText>
          {!isSimilarSpeakerPage && <MainTabButtons />}
        </div>
        <ResultPanel />
      </div>
      {isDetailsShown && (
        <div className="main-preview">
          <IpText
            color="primary"
            variant="h2"
            classes="main-subtitle semi-bold"
          >
            Details
          </IpText>
          {mediaId && (
            <>
              {!isStartPreviewDataUploaded ? <>Loading...</> : <PreviewPanel />}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Main
