import React from 'react'

export const Vk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM17.515 16H16.206C15.71 16 15.553 15.509 14.688 14.5C13.938 13.625 13.55 13.5 13.359 13.5C13.091 13.5 13 13.566 13 13.967V15.453C13 15.786 12.914 16 12.075 16C10.69 16 9.125 15.062 7.938 13.437C6.3 11.196 6 9.247 6 8.887C6 8.687 6.063 8.5 6.445 8.5H7.766C8.097 8.5 8.224 8.647 8.351 9.034C8.938 11 9.813 12.5 10.258 12.5C10.423 12.5 10.5 12.42 10.5 11.98V10.168C10.449 9.234 10 9.154 10 8.82C10 8.659 10.106 8.5 10.31 8.5H12.634C12.914 8.5 13 8.66 13 9.007V11.6C13 11.893 13.224 12 13.312 12C13.477 12 13.632 12.008 13.937 11.687C14.877 10.579 15.693 8.9 15.693 8.9C15.783 8.7 15.935 8.5 16.266 8.5H17.575C17.868 8.5 17.992 8.631 18 8.813C18.003 8.876 17.992 8.946 17.969 9.021C17.804 9.821 16.112 12.197 16.125 12.197C16.053 12.32 16.005 12.408 16 12.501C15.995 12.589 16.031 12.681 16.125 12.811C16.264 13.011 16.568 13.379 16.875 13.751C17.43 14.422 17.875 15.126 17.972 15.414C17.993 15.492 18.002 15.563 18 15.626C17.993 15.872 17.818 16 17.515 16Z"
      fill="#06037D"
    />
  </svg>
)
