import { ExpandMore } from '@mui/icons-material'
import { Paper, Popover, Popper } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useLanguage } from '../../../../../../../hooks/useLanguage'
import useResponsive from '../../../../../../../hooks/useResponsive'
import { dictionariesSelector } from '../../../../../../../redux/selectors'
import { useAppSelector } from '../../../../../../../redux/store'
import type { NarrativeSubcategory } from '../../../../../../../redux/types/dictionaries'
import { IpCheckbox } from '../../../../../../ipDesignSystemComponents/atoms/IpCheckbox'
import { IpChip } from '../../../../../../ipDesignSystemComponents/atoms/IpChip'
import { IpIcon } from '../../../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextEllipsis } from '../../../../../../ipDesignSystemComponents/core/IpTextEllipsis'

type AnchorSubElType = {
  anchor: HTMLLIElement
  subcategories: NarrativeSubcategory[]
  index: number
} | null

type NarrativeListProps = {
  currentList: NarrativeSubcategory[]
  onItemAdd: (item: NarrativeSubcategory) => void
  onItemRemove: (item: NarrativeSubcategory) => void
}

export const NarrativeList = (props: NarrativeListProps) => {
  const { suffix } = useLanguage()
  const { currentList, onItemAdd, onItemRemove } = props
  const { isMobileResolution } = useResponsive()
  const menuRef = useRef<HTMLSpanElement | null>(null)
  const { narratives } = useAppSelector(dictionariesSelector).data
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)
  const [anchorSubEl, setAnchorSubEl] = useState<AnchorSubElType>(null)

  const onClickAway = () => {
    setAnchorEl(null)
    setAnchorSubEl(null)
  }

  const menuList = narratives.map((item, index) => {
    return (
      <li
        key={item.id}
        className="narrative-item"
        onClick={(event) => {
          if (anchorSubEl?.anchor === event.currentTarget) {
            setAnchorSubEl(null)
          } else {
            setAnchorSubEl({
              anchor: event.currentTarget,
              subcategories: item.subcategories,
              index,
            })
          }
        }}
      >
        <span>{item[`name${suffix}`]}</span>
        <IpIcon
          fontSize="20px"
          classes={index === anchorSubEl?.index ? 'open' : ''}
          color="primary"
        >
          <ExpandMore />
        </IpIcon>
      </li>
    )
  })

  const menuSubList = anchorSubEl?.subcategories?.map((el) => {
    const isItemApplied = currentList.some(({ id }) => id === el.id)
    return (
      <li key={el.id} className="narrative-sub-item">
        <IpCheckbox
          checked={isItemApplied}
          label={el[`name${suffix}`]}
          onChange={() => (isItemApplied ? onItemRemove(el) : onItemAdd(el))}
        />
      </li>
    )
  })

  return (
    <div className="select-list narrative-list">
      <IpText
        variant="subtitle3"
        color="primary"
        classes="select-list-title"
        ref={menuRef}
      >
        Narratives:
      </IpText>
      {currentList.map((item) => (
        <IpChip
          key={item.id}
          classes="select-list-chip"
          label={
            <IpTextEllipsis
              placement="top-start"
              isTextOverflow={true}
              classes="select-list-chip-tooltip"
            >
              {item[`name${suffix}`]}
            </IpTextEllipsis>
          }
          size="extraSmall"
          variant="outlined"
          color="primary"
        />
      ))}
      <IpChip
        classes="select-list-add-btn"
        onClick={() => {
          setAnchorEl(menuRef?.current ?? null)
        }}
        label="+"
        size="extraSmall"
        variant="outlined"
        color="primary"
      />
      <Popover
        className="narrative-popover"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClickAway}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ul>{menuList}</ul>
        <Popper
          disablePortal
          className="narrative-popover-sub"
          anchorEl={anchorSubEl?.anchor}
          open={Boolean(anchorSubEl?.anchor)}
          placement={isMobileResolution ? 'auto' : 'right-start'}
        >
          <Paper>
            <ul>{menuSubList}</ul>
          </Paper>
        </Popper>
      </Popover>
    </div>
  )
}
