import React, { memo } from 'react'
import useFilters from '../../../../hooks/useFilters'
import { useIsInitialFilters } from '../../../../hooks/useIsInitialFilters'
import { resetFilters } from '../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../redux/store'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'

export const ResetFilters = memo(function ResetFilters() {
  const dispatch = useAppDispatch()
  const { getIsInitialFilters } = useIsInitialFilters()
  const { filterType } = useFilters()

  return (
    <IpButton
      classes="filters-reset"
      variant="contained"
      onClick={() => {
        dispatch(resetFilters({ filterType }))
      }}
      disabled={getIsInitialFilters()}
    >
      Reset all
    </IpButton>
  )
})
