/* eslint-disable prefer-regex-literals */
import { scrollToCurrentElWithTimeOut } from '../../../utils'

export const isValidInputValue = (value = '') =>
  /^[^<>"'%#[\]\\/{}*()|?!@&]*$/.test(value)

export const scrollToCurrentEl = () => {
  scrollToCurrentElWithTimeOut('.text-found-current', false)
}

export const removeFoundCurrentClass = () => {
  document
    .querySelector('.text-found-current')
    ?.classList.remove('text-found-current')
}

export const addFoundCurrentClass = (currentIndex = 1) => {
  document
    .querySelectorAll('.text-found')
    [currentIndex - 1]?.classList.add('text-found-current')
}

export const cleanUp = (
  targetSelector: string,
  nodeList?: NodeListOf<Element>
) => {
  removeFoundCurrentClass()
  const elements = nodeList || document.querySelectorAll(targetSelector)
  if (elements.length) {
    for (let i = 0; i < elements.length; i += 1) {
      const res = elements[i].innerHTML
        .replace(/<i class="text-found">/g, '')
        .replace(new RegExp('</i>', 'g'), '')
      elements[i].innerHTML = res
    }
  }
}

export const highlightFoundText = (
  pattern: string,
  targetSelector: string,
  nodeList?: NodeListOf<Element>
) => {
  if (!pattern) return
  const elements = nodeList || document.querySelectorAll(targetSelector)
  for (let i = 0; i < elements.length; i += 1) {
    const reg = new RegExp(pattern, 'gi')
    const innerText = elements[i].textContent
    const matchArr = innerText ? innerText.match(reg) : null

    if (matchArr && matchArr.length > 0) {
      const res = innerText!.replace(
        reg,
        `<i class="text-found">${matchArr[0]}</i>`
      )
      elements[i].innerHTML = res
    }
  }
}
