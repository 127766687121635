import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import { ExpandMore } from '@mui/icons-material'
import React, { useRef } from 'react'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { usePersonaMediasData } from '../../../../hooks/usePersonaMediasData'
import { formatSecondsToTime } from '../../../../utils'
import { IpCard } from '../../../ipDesignSystemComponents/atoms/IpCard'
import { IpCardActions } from '../../../ipDesignSystemComponents/atoms/IpCard/IpCardActions'
import { IpCardContent } from '../../../ipDesignSystemComponents/atoms/IpCard/IpCardContent'
import { IpCardHeader } from '../../../ipDesignSystemComponents/atoms/IpCard/IpCardHeader'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpLink } from '../../../ipDesignSystemComponents/atoms/IpLink'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpCollapse } from '../../../ipDesignSystemComponents/core/IpCollapse'
import { ROUTER_PATHS } from '../../Router'

import './index.scss'

type Props = {
  personaId: string
  index: number
  open: boolean
  onExpandClick: () => void
}

export const PersonaMediaCard = ({
  personaId,
  index,
  open,
  onExpandClick,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const {
    id: mediaId,
    title = '',
    episode_title = '',
    saved_file_link = '',
    transcriptions,
  } = usePersonaMediasData(personaId).medias[index]

  const {
    start = 0,
    text = '',
    program_id,
    author_id,
  } = transcriptions[0] || {}

  const dictionaryData = useDictionaryData({
    program_id,
    author_id,
  })

  const expandIconClasses = formatClassName(
    'expand-icon',
    open ? 'expanded' : ''
  )

  const onLoadedMetadata = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = start
    }
  }

  return (
    <IpCard
      key={`persona-tv-${personaId}`}
      classes={`persona-tv_card ${open ? 'open' : ''}`}
    >
      <video
        ref={videoRef}
        onLoadedMetadata={onLoadedMetadata}
        controls
        src={saved_file_link}
        className="persona-tv_card-media"
      >
        {' '}
        Your browser does not support the video tag.
      </video>

      <IpCardContent classes="persona-tv_card-top-content">
        <IpText variant="body2">
          <IpText variant="body2" component="span" color="primary">
            {formatSecondsToTime(start)}
          </IpText>
          {' - '}
          {text}
        </IpText>
      </IpCardContent>
      <IpCardActions>
        <IpLink
          to={`/${ROUTER_PATHS.details}/${mediaId}`}
          target="_blank"
          classes="fs14 mb6"
        >
          Open media details
        </IpLink>
        <IpIcon
          classes={expandIconClasses}
          onClick={onExpandClick}
          color="primary"
        >
          <ExpandMore />
        </IpIcon>
      </IpCardActions>
      <IpCollapse inside={open} classes="collapsed-content">
        <IpCardHeader title={title} subheader={episode_title} />
        <IpCardContent>
          {program_id && (
            <IpText variant="body2">
              <IpText
                variant="body2"
                component="span"
                classes=""
                color="primary"
              >
                Program:{' '}
              </IpText>
              {dictionaryData.program}
            </IpText>
          )}
          {author_id && (
            <IpText variant="body2">
              <IpText
                variant="body2"
                component="span"
                classes=""
                color="primary"
              >
                Author:{' '}
              </IpText>
              {dictionaryData.author}
            </IpText>
          )}
        </IpCardContent>
      </IpCollapse>
    </IpCard>
  )
}
