import React from 'react'
import type { ElementType, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import ButtonGroup from '@mui/material/ButtonGroup'
import type { IpButtonGroupPropTypes } from '@infopulse-design-system/shared/types/IpButtonGroup.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpAvatar/styles.scss'

export type IpButtonGroupPropTypesReact = IpButtonGroupPropTypes & {
  children: ReactNode
  component?: ElementType
}

const IpButtonGroupStyled = styled(ButtonGroup)(
  ({ offset }: IpButtonGroupPropTypesReact) => {
    const halfOffset = `calc(${offset} / 2)`
    return (
      offset && {
        '&:not(.MuiButtonGroup-vertical) > :not(:first-of-type)': {
          marginLeft: halfOffset,
        },
        '&:not(.MuiButtonGroup-vertical) > :not(:last-of-type)': {
          marginRight: halfOffset,
        },
        '&.IpButtonGroup--offset .MuiButton-text': {
          margin: 0,
        },
        '&.IpButtonGroup--offset:not(.MuiButtonGroup-vertical) .MuiButton-text':
          {
            paddingLeft: halfOffset,
            paddingRight: halfOffset,
          },
        '&.IpButtonGroup--offset.MuiButtonGroup-vertical .MuiButton-text': {
          paddingTop: halfOffset,
          paddingBottom: halfOffset,
        },
        '&.IpButtonGroup--offset.MuiButtonGroup-vertical .MuiButton-outlined, &.IpButtonGroup--offset.MuiButtonGroup-vertical .MuiButton-contained':
          {
            marginTop: halfOffset,
            marginBottom: halfOffset,
          },
      }
    )
  }
)

/**
 * `IpButtonGroup` component can be used to group related `IpButton`'s.
 * They need to be immediate children.
 */
export function IpButtonGroup(props: IpButtonGroupPropTypesReact) {
  const {
    children,
    classes,
    color,
    component = 'span',
    disableElevation,
    fullWidth,
    offset,
    orientation,
    size,
    variant,
  } = props

  const customClasses = formatClassName(
    generateClasses('button-group', 'react'),
    classes,
    offset ? 'IpButtonGroup--offset' : ''
  )

  return (
    <IpButtonGroupStyled
      className={customClasses}
      color={color}
      component={component}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      offset={offset}
      orientation={orientation}
      size={size}
      variant={variant}
    >
      {children}
    </IpButtonGroupStyled>
  )
}
