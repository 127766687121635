import '@infopulse-design-system/shared/theme/components/IpSelect/styles.scss'
import type { IpSelectPropTypes } from '@infopulse-design-system/shared/types/IpSelect.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { InputLabel } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'
import type { ElementType, ReactNode, SyntheticEvent } from 'react'
import React, { forwardRef } from 'react'
import { IpFormControl } from '../../core/IpFormControl'
import { IpMenuItem } from '../../core/IpMenuItem'

export type IpSelectPropTypesReact = IpSelectPropTypes<
  SelectChangeEvent,
  SyntheticEvent
> & {
  IconComponent?: ElementType
  children?: ReactNode
  isOptionIcon?: boolean
}

const MenuProps = { disablePortal: true }

/**
 * The `IpSelect` component is specifically designed for efficiently gathering user-provided
 * information from a list of available options. It offers a user-friendly interface that allows
 * users to make selections with ease. By using the `IpSelect` component, you can streamline the
 * process of collecting user input and ensuring a smooth and intuitive experience for your users.
 */
export const IpSelect = forwardRef(function IpSelect(
  props: IpSelectPropTypesReact,
  ref
) {
  const {
    autoWidth,
    classes,
    children,
    defaultOpen,
    defaultValue,
    disabled,
    focused,
    error,
    IconComponent,
    id,
    label,
    labelId,
    multiple,
    native,
    onChange,
    onClose,
    open,
    options,
    readOnly,
    required,
    size = 'medium',
    value: inputValue = '',
    variant,
    isOptionIcon = true,
  } = props

  const customClasses = formatClassName(
    generateClasses('select', 'react'),
    generateClasses('input', 'react'),
    `IpSelect--${size}`,
    classes
  )

  return (
    <IpFormControl
      classes={customClasses}
      disabled={disabled}
      error={error}
      focused={focused}
      required={required}
    >
      <InputLabel className={`IpSelect-label--${size}`} id={labelId}>
        {label}
      </InputLabel>
      <Select
        autoWidth={autoWidth}
        className={`IpSelect-select--${size}`}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        IconComponent={IconComponent}
        id={id}
        label={label}
        labelId={labelId}
        multiple={multiple}
        native={native}
        onChange={onChange}
        onClose={onClose}
        open={open}
        readOnly={readOnly}
        ref={ref}
        value={inputValue}
        variant={variant}
        MenuProps={MenuProps} // todo: DS update
      >
        {!children &&
          options.map(({ value, title }) => {
            const icon =
              isOptionIcon &&
              (!multiple ? (
                inputValue === value ? (
                  <RadioButtonCheckedIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              ) : inputValue?.includes(value) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              ))

            return native ? (
              <option key={value} value={value}>
                {title}
              </option>
            ) : (
              <IpMenuItem key={value} value={value}>
                {icon}
                {title}
              </IpMenuItem>
            )
          })}
        {children}
      </Select>
    </IpFormControl>
  )
})
