import React from 'react'

export const Wiki = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_1244_3412)">
      <path
        d="M12 0C5.37281 0 0 5.37281 0 12C0 18.6272 5.37281 24 12 24C18.6272 24 24 18.6272 24 12C24 5.37281 18.6272 0 12 0ZM19.075 9.2079C18.3927 10.7498 16.3058 15.6335 14.9131 18.8167C14.9112 18.8182 14.5483 18.816 14.5473 18.8155L12.3511 13.6426C11.4807 15.3497 10.5163 17.1243 9.69158 18.8131C9.68667 18.8216 9.2926 18.817 9.29211 18.8116C8.03135 15.8687 6.72417 12.9453 5.45678 10.0046C5.16338 9.28573 4.13414 8.12956 3.42973 8.13618C3.42973 8.05271 3.4258 7.86635 3.42482 7.75366L7.76618 7.75317L7.76274 8.12906C7.25303 8.15263 6.37209 8.47795 6.60043 9.0407C7.21228 10.3619 9.37878 15.4801 9.96436 16.7794C10.3729 15.9797 11.5139 13.8481 11.9838 12.947C11.6155 12.1913 10.3984 9.3697 10.0336 8.65915C9.75836 8.19585 9.0677 8.13913 8.5359 8.13103C8.5359 8.01244 8.54204 7.92111 8.53983 7.75832L12.356 7.77011V8.11679C11.8394 8.13103 11.3503 8.32327 11.5721 8.81727C12.0854 9.88284 12.3852 10.6415 12.8564 11.627C13.0069 11.3383 13.7783 9.75566 14.1461 8.92039C14.3683 8.36501 14.0364 8.15705 13.1071 8.13201C13.1193 8.04068 13.1112 7.85727 13.1193 7.76986L16.4153 7.77305L16.4167 8.11703C15.811 8.1406 15.184 8.46322 14.8567 8.96409L13.2696 12.2556C13.4437 12.6909 14.9696 16.0809 15.1302 16.4568L18.4114 8.88307C18.1779 8.26999 17.4332 8.13299 17.142 8.12636C17.144 8.02864 17.1445 7.87961 17.145 7.75513L20.5703 7.76495L20.5752 7.78214L20.5695 8.1244C19.818 8.14723 19.3532 8.54916 19.075 9.2079Z"
        fill="#06037D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1244_3412">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
