import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import { PickersDay } from '@mui/x-date-pickers'
import type { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay'
import { isWeekend } from 'date-fns'
import React from 'react'
import {
  DATE_FORMAT,
  INITIAL_DATE_FROM,
  INITIAL_DATE_TO,
} from '../../../../../constants'
import useFilters from '../../../../../hooks/useFilters'
import { updateFilters } from '../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../redux/store'
import type { DateFilters } from '../../../../../redux/types/filters'
import { getLocalDate, parseDateString } from '../../../../../utils/data'
import { IpDateTimePicker } from '../../../../ipDesignSystemComponents/atoms/IpDateTimePicker'
import { FilterPeriodsOfTime } from './FilterPeriodsOfTime'
import { FilterWeekDays } from './FilterWeekDays'
import './index.scss'

export type FilterPeriodProps = {
  classes?: string
  variant: DateFilters
}

const setNoneNullValue = (value: Date | null, prev: string): string =>
  value ? getLocalDate(value) : prev

export const FilterPeriod = ({ classes, variant }: FilterPeriodProps) => {
  const dispatch = useAppDispatch()

  const prefixTo = `${variant}_to` as const
  const prefixFrom = `${variant}_from` as const
  const { filters, filterType, isBasketType, isMainBugsType } = useFilters()
  const disableWeekdays = isBasketType || isMainBugsType
  const { weekdays = [] } = filters
  const dateTo = filters[prefixTo]
  const dateFrom = filters[prefixFrom]

  const handleFromChange = (newValue: Date) => {
    dispatch(
      updateFilters({
        filterType,
        type: prefixFrom,
        value: setNoneNullValue(newValue, INITIAL_DATE_FROM),
      })
    )
  }

  const handleToChange = (newValue: Date) => {
    dispatch(
      updateFilters({
        filterType,
        type: prefixTo,
        value: setNoneNullValue(newValue, INITIAL_DATE_TO),
      })
    )
  }

  const customDayRenderer = (
    date: Date,
    selectedDates: Date[] | null,
    pickersDayProps: PickersDayProps<any>
  ) => {
    if (weekdays?.includes(date.getDay().toString())) {
      return <PickersDay className="markedDays" {...pickersDayProps} />
    }
    if (isWeekend(date)) {
      return <PickersDay className="IpHoliday" {...pickersDayProps} />
    }
    return <PickersDay {...pickersDayProps} />
  }

  const customClasses = formatClassName('filter-period', classes)

  return (
    <div className={customClasses}>
      <div className="filter-period-inputs">
        <IpDateTimePicker
          size="small"
          variant="standard"
          classes="data-picker"
          onChange={handleFromChange}
          value={parseDateString(dateFrom)}
          views={['year', 'month', 'day']}
          disableFuture
          label="Start Date"
          placeholder={INITIAL_DATE_FROM}
          inputFormat={DATE_FORMAT}
          type="date"
          renderDay={customDayRenderer}
        />
        <IpDateTimePicker
          size="small"
          variant="standard"
          classes="data-picker"
          onChange={handleToChange}
          value={parseDateString(dateTo)}
          views={['year', 'month', 'day']}
          disableFuture
          label="End Date"
          placeholder={INITIAL_DATE_TO}
          inputFormat={DATE_FORMAT}
          type="date"
          renderDay={customDayRenderer}
        />
      </div>
      <FilterWeekDays disableWeekdays={disableWeekdays} />
      <FilterPeriodsOfTime variant={variant} disableDays={disableWeekdays} />
    </div>
  )
}
