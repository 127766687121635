import React from 'react'
import type { ReactNode } from 'react'
import ListItemText from '@mui/material/ListItemText'
import type { IpListItemTextPropTypes } from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpList/IpListItemText/styles.scss'

export type IpListItemTextPropTypesReact = IpListItemTextPropTypes<ReactNode>

export function IpListItemText(props: IpListItemTextPropTypesReact) {
  const { id, color, classes, inset, text } = props
  const customClasses = formatClassName(
    generateClasses('list-item-text', 'react'),
    classes
  )

  const component = typeof text === 'string' ? 'p' : 'div'

  return (
    <ListItemText
      className={customClasses}
      color={color}
      id={id}
      inset={inset}
      primaryTypographyProps={{ component }}
    >
      {text}
    </ListItemText>
  )
}
