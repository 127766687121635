import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  apiGetTranscriptionBugsByMediaId,
  apiGetTranscriptionsData,
} from '../../api'
import type {
  TranscriptionsDataResponse,
  TranscriptionsWithBugsResponse,
} from '../../api/apiTypes'
import {
  setCorrectTranscriptions,
  setIncorrectTranscriptions,
} from '../slices/transcriptionsDataSlice'

export const fetchTranscriptionsData = createAsyncThunk<
  TranscriptionsDataResponse,
  string
>('transcriptionsData/fetch', async (id, thunkAPI) => {
  try {
    const res = await apiGetTranscriptionsData(id)
    thunkAPI.dispatch(
      setCorrectTranscriptions({ id, data: res.transcriptions })
    )
    return res
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export const fetchTranscriptionsBugs = createAsyncThunk<
  TranscriptionsWithBugsResponse,
  string
>('transcriptionsWithBugsData/fetch', async (id, thunkAPI) => {
  try {
    const bugs = await apiGetTranscriptionBugsByMediaId(id)
    thunkAPI.dispatch(
      setIncorrectTranscriptions({ id, data: bugs.transcriptions })
    )
    return bugs
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})
