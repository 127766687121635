import PhotoIcon from '@mui/icons-material/Photo'
import React from 'react'
import type { IpIconPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  onClick?: IpIconPropTypesReact['onClick']
  placement: IpTooltipPropTypesReact['placement']
  fontSize?: string
}

export const DownloadImage = ({ placement, onClick, fontSize }: Props) => {
  return (
    <IpTooltip
      arrow
      title={onClick ? 'Download image' : ''}
      placement={placement}
      content={
        <IpIcon
          fontSize={fontSize}
          disabled={!onClick}
          onClick={onClick}
          color="primary"
        >
          <PhotoIcon />
        </IpIcon>
      }
    />
  )
}
