import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import type { Dispatch, MouseEvent } from 'react'
import React, { memo } from 'react'
import { UnknownSpeakerData } from '../../../../../../constants'
import { useLanguage } from '../../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../../redux/selectors'
import { useAppSelector } from '../../../../../../redux/store'
import { DICTIONARY_ADDITION } from '../../../../../../redux/types/dictionaries'
import {
  formatSecondsToTime,
  getNameFromDictionaryByLang,
} from '../../../../../../utils'
import { IpText } from '../../../../../ipDesignSystemComponents/atoms/IpText'
import type { PersonaInfoIconState } from '../../../../persona'
import { PersonaInfoIcon } from '../../../../persona'

type Props = {
  active: boolean
  time: number
  text: string
  personaId?: string | null
  speakerId: string
  setPersonaInfoIconState: Dispatch<PersonaInfoIconState>
}

export const ProcessingTr = memo(function ProcessingTr(props: Props) {
  const { time, text, active, personaId, speakerId, setPersonaInfoIconState } =
    props
  const isUnknownSpeaker = personaId === UnknownSpeakerData.id
  const { suffix } = useLanguage()
  const { data: dictionary } = useAppSelector(dictionariesSelector)

  const personaName = isUnknownSpeaker
    ? UnknownSpeakerData.name
    : getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY_ADDITION.personas,
        id: personaId,
      })

  const onPersonaIconClick = (event: MouseEvent<HTMLElement>) => {
    setPersonaInfoIconState({
      anchor: event.currentTarget,
      id: personaId ?? '',
    })
  }

  const customClasses = formatClassName(
    active ? 'transcription_active' : '',
    'transcription_details'
  )

  return (
    <IpText variant="body1" classes={customClasses}>
      <span className="transcription_time">{formatSecondsToTime(time)}</span>
      {personaId ? (
        <span className="transcription_persona">
          {' - '}
          {personaName}
          {!isUnknownSpeaker && (
            <PersonaInfoIcon onClick={onPersonaIconClick} />
          )}
        </span>
      ) : (
        <span className="transcription-icon_anchor">
          <span className="transcription_speaker">{speakerId}</span>
        </span>
      )}
      <span className="transcription_text">{text}</span>
    </IpText>
  )
})
