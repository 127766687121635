/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  INFINITE_SCROLL_PORTION_SIZE,
  INITIAL_DATE_FROM,
  INITIAL_DATE_TO,
} from '../../constants'
import { updateStringArrValues } from '../../utils'
import { DICTIONARY } from '../types/dictionaries'
import type {
  DateFilters,
  FiltersState,
  FilterStringArrayDataKeys,
  FilterStringDataKeys,
  FilterType,
  FilterTypeData,
  PayloadUpdateFilterArrActionType,
  PayloadUpdateFiltersActionType,
  ResetFilterData,
  StartEndFilters,
} from '../types/filters'

export type InitialFiltersState = Record<FilterType, FiltersState> & {
  reset: ResetFilterData
}

export const initialFiltersState: InitialFiltersState = {
  personLibrary: {
    [DICTIONARY.persona_category]: [],
    networks: [],
    title: '',
    search: '',
    skip: 0,
    limit: INFINITE_SCROLL_PORTION_SIZE,
  },
  mainResults: {
    authors: [],
    date_from: INITIAL_DATE_FROM,
    date_to: INITIAL_DATE_TO,
    limit: INFINITE_SCROLL_PORTION_SIZE,
    media_type: [],
    personas: [],
    programs: [],
    search: '',
    search_model: '',
    skip: 0,
    sources: [],
    signs_of_crimes: [],
    telegram_channels: [],
    user_tags: [],
    weekdays: [],
  },
  mainBugs: {
    date_from: INITIAL_DATE_FROM,
    date_to: INITIAL_DATE_TO,
    limit: INFINITE_SCROLL_PORTION_SIZE,
    skip: 0,
  },
  dashboard: {
    authors: [],
    date_from: INITIAL_DATE_FROM,
    date_to: INITIAL_DATE_TO,
    limit: INFINITE_SCROLL_PORTION_SIZE,
    media_type: [],
    personas: [],
    programs: [],
    skip: 0,
    sources: [],
    signs_of_crimes: [],
    weekdays: [],
    chartLabelType: 'source_id',
    interval: 'day',
    chartType: 'linear',
  },
  basket: {
    limit: INFINITE_SCROLL_PORTION_SIZE,
    personas: [],
    program_title: '',
    programs: [],
    search: '',
    skip: 0,
    sources: [],
    submit_date_from: INITIAL_DATE_FROM,
    submit_date_to: INITIAL_DATE_TO,
    user_tags: [],
  },
  reset: {},
}

export const FiltersSlice = createSlice({
  name: 'filters',
  initialState: initialFiltersState,
  reducers: {
    updateFilters: (
      state,
      {
        payload: { type, value, filterType },
      }: PayloadAction<PayloadUpdateFiltersActionType>
    ) => {
      ;(state as any)[filterType][type] = value
      if (type !== 'skip') {
        state[filterType].skip = 0
      }
    },
    updateFilterArr: (
      state,
      {
        payload: { type, value, filterType },
      }: PayloadAction<PayloadUpdateFilterArrActionType>
    ) => {
      const currentFilterData: string[] = (state as any)[filterType][type] || []
      const newData = updateStringArrValues(currentFilterData, value)
      ;(state as any)[filterType][type] = newData
      state[filterType].skip = 0
    },
    resetFilters: (
      state,
      { payload: { filterType } }: PayloadAction<FilterTypeData>
    ) => {
      state[filterType] = initialFiltersState[filterType]
      state.reset[filterType] = new Date()
    },
    resetStringFilters: (
      state,
      {
        payload: { filterType, type },
      }: PayloadAction<FilterTypeData & { type: FilterStringDataKeys }>
    ) => {
      state[filterType][type] = ''
      state.reset[type] = new Date()
      state[filterType].skip = 0
    },
    resetFilterDate: (
      state,
      {
        payload: { filterType, resetType },
      }: PayloadAction<FilterTypeData & { resetType: DateFilters }>
    ) => {
      state[filterType][`${resetType}_to`] =
        initialFiltersState[filterType][`${resetType}_to`]
      state[filterType][`${resetType}_from`] =
        initialFiltersState[filterType][`${resetType}_from`]
      state[filterType].skip = 0
      state.reset[resetType] = new Date()

      if (filterType === 'mainResults') {
        state[filterType].weekdays = initialFiltersState[filterType].weekdays
      }
    },
    resetFilterStartEnd: (
      state,
      {
        payload: { filterType, resetType },
      }: PayloadAction<FilterTypeData & { resetType: StartEndFilters }>
    ) => {
      state[filterType][`${resetType}_start`] = undefined
      state[filterType][`${resetType}_end`] = undefined
      state[filterType].skip = 0
      state.reset[resetType] = new Date()
    },
    resetFilterArr: (
      state,
      {
        payload: { filterType, type },
      }: PayloadAction<{ type: FilterStringArrayDataKeys } & FilterTypeData>
    ) => {
      state[filterType][type] = initialFiltersState[filterType][type]
      state[filterType].skip = 0
    },
  },
})

export const {
  updateFilters,
  updateFilterArr,
  resetFilters,
  resetStringFilters,
  resetFilterDate,
  resetFilterStartEnd,
  resetFilterArr,
} = FiltersSlice.actions

export default FiltersSlice.reducer
