import styles from '@infopulse-design-system/shared/theme/components/IpBadge'

export default {
  MuiBadge: {
    styleOverrides: {
      root: {
        '& .MuiBadge-badge:not(.MuiBadge-dot)': {
          minWidth: styles.badge_min_width,
          height: styles.badge_height,
          fontWeight: styles.badge_font_weight,
          fontSize: styles.badge_font_size,
          lineHeight: styles.badge_line_height,
          padding: styles.badge_standard_padding,
        },
        '&.IpBadge--centered .MuiBadge-badge': {
          top: '50%',
          zIndex: 2,
        },
        '& .MuiAvatar-root + .MuiBadge-badge': {
          width: '20%',
          height: '20%',
          borderRadius: '50%',
        },
        '& .MuiAvatar-circular': {
          '& + .MuiBadge-anchorOriginTopRight': {
            top: '10%',
            right: '20%',
          },
          '& + .MuiBadge-anchorOriginTopLeft': {
            top: '10%',
            left: '20%',
          },
          '& + .MuiBadge-anchorOriginBottomRight': {
            bottom: '10%',
            right: '20%',
          },
          '& + .MuiBadge-anchorOriginBottomLeft': {
            bottom: '10%',
            left: '20%',
          },
        },
      },
    },
  },
}
