import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import React from 'react'
import { apiSetUserQueryType } from '../../../../api'
import { QueryTypes } from '../../../../constants/enums'
import useFilters from '../../../../hooks/useFilters'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { usePermission } from '../../../../hooks/usePermission'
import { profileSelector } from '../../../../redux/selectors'
import { updateFilters } from '../../../../redux/slices/filtersSlice'
import type { QueryType } from '../../../../redux/slices/profileSlice'
import { updateQueryType } from '../../../../redux/slices/profileSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { fetchMediaList } from '../../../../redux/thunk/mediaListThunk'
import { IpSwitch } from '../../../ipDesignSystemComponents/atoms/IpSwitch'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'

import './index.scss'

export const QueryTypeSwitcher = () => {
  const dispatch = useAppDispatch()
  const { queryType } = useAppSelector(profileSelector).data
  const { notification } = useHoveredNotification()
  const { filterType, filters, isMainType } = useFilters()
  const { isMainBugsTab } = useMainPageActiveTab()
  const { isAnonymous } = usePermission()
  const disabled = isMainType && isMainBugsTab

  const setQueryType = async (type: QueryType) => {
    try {
      dispatch(updateQueryType(type))
      if (filters.skip) {
        dispatch(
          updateFilters({
            filterType,
            type: 'skip',
            value: 0,
          })
        )
      } else if (isMainType) {
        dispatch(fetchMediaList({ ...filters, queryType: type }))
      }
      if (!isAnonymous) {
        await apiSetUserQueryType({ queryType: type })
      }
    } catch (e) {
      dispatch(updateQueryType(queryType!))
      notification(`Can't switch query type. Error: ${e}`, 'error', true)
    }
  }
  const handleSearchTypeChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setQueryType(QueryTypes.semantic)
    } else {
      setQueryType(QueryTypes.full)
    }
  }

  const customClasses = formatClassName(
    'query-type-switcher',
    disabled ? 'disabled' : ''
  )

  return (
    <div className={customClasses}>
      <IpText
        color="primary"
        classes={queryType === QueryTypes.full ? 'bold' : ''}
        onClick={() => setQueryType(QueryTypes.full)}
      >
        Full Text
      </IpText>
      <IpSwitch
        disabled={disabled}
        size="small"
        checked={queryType === QueryTypes.semantic}
        onChange={handleSearchTypeChange}
      />
      <IpText
        color="primary"
        classes={queryType === QueryTypes.semantic ? 'bold' : ''}
        onClick={() => setQueryType(QueryTypes.semantic)}
      >
        Semantic
      </IpText>
    </div>
  )
}
