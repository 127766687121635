import type {
  CreateBugParams,
  GetBugParams,
  GetBugsResponse,
  TranscriptionsWithBugsResponse,
} from './apiTypes'
import { api } from './axiosInstance'
import { mapBugParams } from './mappers'

export const apiCreateBug = async (params: CreateBugParams) => {
  try {
    const response = await api.post('bugs/create', params)
    return response
  } catch (error) {
    console.error('Error during creating bug', error)
    throw error
  }
}

export const apiGetBugs = async (
  params: GetBugParams
): Promise<GetBugsResponse> => {
  try {
    const mappedParams = mapBugParams(params)
    const response = await api.post('bugs', mappedParams)
    return response.data
  } catch (error) {
    console.error('Error during getting bugs', error)
    throw error
  }
}

export const apiGetTranscriptionBugsByMediaId = async (
  id: string
): Promise<TranscriptionsWithBugsResponse> => {
  try {
    const response = await api.get(`bugs/all/${id}`)
    return response.data
  } catch (error) {
    console.error('Error during get transcriptions with bags', error)
    throw error
  }
}
