import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import { useEffect } from 'react'
import { INFINITE_SCROLL_PORTION_SIZE } from '../constants'
import {
  resetActiveMediaByType,
  resetActivePersonaId,
} from '../redux/slices/appStateSlice'
import { useAppDispatch } from '../redux/store'
import useMainPageActiveTab from './useMainPageActiveTab'
import { usePageName } from './usePageName'

const CARD_HEIGHT = 172
const CARD_HEIGHT_MOB = 207

type Props = {
  refNode?: HTMLDivElement | null
  activeId?: string
  data: Array<any & { id: string }>
}

export const useResultListScrollTo = ({ refNode, activeId, data }: Props) => {
  const dispatch = useAppDispatch()
  const { mainActiveTab } = useMainPageActiveTab()
  const { isMainPage, isPersonLibraryPage } = usePageName()

  useEffect(() => {
    if (!refNode) return

    const activeElIndex = activeId
      ? data.findIndex((el) => el.id === activeId)
      : -1

    const isActiveEl = activeElIndex > -1
    const isFirstPortion = data.length <= INFINITE_SCROLL_PORTION_SIZE

    if (!isActiveEl && activeId) {
      if (isMainPage) {
        dispatch(resetActiveMediaByType(mainActiveTab))
      }
      if (isPersonLibraryPage) {
        dispatch(resetActivePersonaId())
      }
    }

    if (isFirstPortion && !isActiveEl) {
      refNode.scrollTo(0, 0)
      return
    }

    if (isActiveEl) {
      const height =
        window.innerWidth > breakpoints.values.sm
          ? CARD_HEIGHT
          : CARD_HEIGHT_MOB
      const activeElTop = activeElIndex * height
      if (activeElTop + height > refNode.getBoundingClientRect().height) {
        refNode.scrollTo(0, activeElTop)
      }
    }
  }, [data, refNode])
}
