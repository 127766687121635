import { useEffect, useState } from 'react'
import { useActiveMedia } from '../../../../hooks/useActiveMedia'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { usePageName } from '../../../../hooks/usePageName'
import {
  mediaDataSelector,
  mediaListSelector,
  transcriptionsDataSelector,
} from '../../../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { fetchMediaData } from '../../../../redux/thunk/mediaDataThunk'
import { fetchTranscriptionsData } from '../../../../redux/thunk/transcriptionsDataThunk'

export const useTelegramGroup = () => {
  const {
    data: { data: list },
  } = useAppSelector(mediaListSelector)
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { mediaId = '', mediaIndex = 0 } = useActiveMedia()
  const { data: mediaData } = useAppSelector(mediaDataSelector)
  const { data: trsData } = useAppSelector(transcriptionsDataSelector)
  const { isMainPage } = usePageName()
  const { isMainResultsTab } = useMainPageActiveTab()
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const needData = mediaId && list
  const mediaByList = needData ? list[mediaIndex] : null
  const telegramGroupList = mediaByList?.media_group
  const showTelegramNav =
    isMainResultsTab &&
    isMainPage &&
    telegramGroupList &&
    telegramGroupList?.length > 1

  const telegramId = showTelegramNav ? telegramGroupList[index]?.media_id : ''
  const isMediaByTelegramData = mediaData[telegramId]
  const isTrByTelegramData = trsData.correct[telegramId]

  const fetchData = async () => {
    if (!telegramId) return
    setLoading(true)
    if (!isMediaByTelegramData) {
      try {
        await dispatch(fetchMediaData(telegramId))
      } catch (e: any) {
        notification(e.message, 'error', true)
      }
    }

    if (!isTrByTelegramData) {
      try {
        await dispatch(fetchTranscriptionsData(telegramId))
      } catch (e: any) {
        notification(e.message, 'error', true)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [telegramId])

  useEffect(() => {
    setIndex(0)
  }, [mediaId])

  return {
    telegramGroupListLength: telegramGroupList?.length || 1,
    index,
    setIndex,
    nativeMediaId: mediaId,
    telegramId,
    showTelegramNav,
    loading,
  }
}
