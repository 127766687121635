import type { RootState } from '../store'
import { DICTIONARY_CUSTOM } from '../types/dictionaries'

export const dictionariesSelector = (state: RootState) => state.dictionaries

export const dictionariesLanguageSelector = (state: RootState) =>
  state.dictionaries.data[DICTIONARY_CUSTOM.languages]

export const dictionariesFootnotesSelector = (state: RootState) =>
  state.dictionaries.data[DICTIONARY_CUSTOM.footnotes]

export const footnoteSelector = (state: RootState, index: number) =>
  state.dictionaries.data[DICTIONARY_CUSTOM.footnotes][index]
