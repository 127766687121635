import type { Dispatch, SetStateAction } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { ActiveTrData } from '..'
import type { Transcription } from '../../../../redux/types/media'
import { scrollToCurrentElWithTimeOut, throttle } from '../../../../utils'

import cssData from './index.scss'

const MIN_WIDTH = parseInt(cssData.video_min_width, 10)
const VIDEO_DOTS_HEIGHT = parseInt(cssData.dot_height, 10)
const PREVIEW_TIME = 0.7 // time to show the next transcription a little bit earlier for comfort view

type Props = {
  onClick: Dispatch<SetStateAction<ActiveTrData>>
  isActive: boolean
  left: number
  index: number
}

const VideoButton = ({ onClick, isActive, left, index }: Props) => {
  const onClickHandler = useCallback(() => {
    onClick({
      tIndex: index,
      setManualTime: true,
      needScroll: true,
    })
  }, [])

  return (
    <button
      onClick={onClickHandler}
      className={`video-period ${isActive ? 'active' : ''}`}
      style={{
        left: `calc(${left}px )`,
      }}
    ></button>
  )
}

export type VideoPlayerProps = {
  url?: string
  activeTrData: ActiveTrData
  activeTr?: Transcription
  setActiveTrData: Dispatch<SetStateAction<ActiveTrData>>
  currentTrData?: {
    start?: number
    end?: number
    id: string
  }[]
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    url = '',
    activeTrData,
    setActiveTrData,
    currentTrData,
    activeTr,
  } = props
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const videoElement = videoRef.current
  const [isInPiP, setIsInPiP] = useState(false)

  const [positions, setPositions] = useState<[number, number][]>([])
  const [videoDuration, setVideoDuration] = useState(0)
  const isPeriodsBlock =
    !!videoElement?.duration && !!positions.length && !!currentTrData

  const onLoadedMetadata = () => {
    if (videoElement) {
      setVideoDuration(videoElement.duration)
    }
  }

  useEffect(() => {
    if (activeTrData.needScroll) {
      scrollToCurrentElWithTimeOut('.transcription_active', true)
    }

    if (videoRef.current?.duration && activeTrData.setManualTime) {
      const newTime = activeTr?.start ?? 0

      videoRef.current.currentTime = +newTime

      if (videoRef.current.currentTime !== newTime) {
        try {
          videoRef.current.load()
          videoRef.current.currentTime = Number(newTime)
          console.log('video manually reloaded')
        } catch (e) {
          console.error('error', e)
        }
      }
    }

    if (videoElement && activeTrData.pause) {
      videoElement.pause()
    }
  }, [activeTrData, videoRef.current?.duration])

  useEffect(() => {
    if (videoElement && videoDuration && currentTrData) {
      const data: [number, number][] = currentTrData.map(
        ({ start = 0, end = 0 }) => {
          return [start / videoDuration, end / videoDuration]
        }
      )
      setPositions(data)
    }
  }, [videoDuration])

  const periodsData = positions.map(([number]: [number, number], i: number) => {
    const currentVideoPlayerWidth = isInPiP
      ? MIN_WIDTH
      : videoElement?.getBoundingClientRect()?.width ?? MIN_WIDTH
    let left = currentVideoPlayerWidth * number

    if (currentVideoPlayerWidth - left < VIDEO_DOTS_HEIGHT / 2) {
      left -= VIDEO_DOTS_HEIGHT / 2
    }

    return (
      <VideoButton
        key={i}
        index={i}
        onClick={setActiveTrData}
        isActive={activeTrData.tIndex === i}
        left={left}
      />
    )
  })

  const onTimeUpdate = throttle(() => {
    const { currentTime } = videoElement || {}
    if (videoElement && typeof currentTime === 'number' && currentTrData) {
      const selectedTranscription: VideoPlayerProps['currentTrData'] = []
      let firstElIndex: number | undefined

      currentTrData.forEach((el, index) => {
        const { start = 0, end = 0 } = el
        if (currentTime >= start - PREVIEW_TIME && currentTime < end) {
          selectedTranscription.push(el)
          if (!firstElIndex) firstElIndex = index
        }
      })

      if (
        selectedTranscription.length > 1 &&
        activeTr?.id &&
        selectedTranscription.find(({ id }) => id === activeTr?.id)
      ) {
        return
      }

      if (firstElIndex && firstElIndex !== activeTrData.tIndex) {
        setActiveTrData({
          tIndex: firstElIndex,
          needScroll: true,
        })
      }
    }
  })

  const handleExitPiP = () => {
    if (document.pictureInPictureElement) {
      ;(document.pictureInPictureElement as HTMLVideoElement).pause()
      document.exitPictureInPicture()
    }
    // @ts-ignore
    if (videoElement && videoElement.mozExitPictureInPicture) {
      // @ts-ignore
      videoElement.mozExitPictureInPicture()
    }
    setIsInPiP(false)
  }

  const handleEnterPiP = () => {
    setIsInPiP(true)
  }

  const handleLeavePiP = () => {
    setIsInPiP(false)
  }

  const handleListeners = (
    listenerType: 'addEventListener' | 'removeEventListener'
  ) => {
    if (videoElement) {
      videoElement[listenerType]('enterpictureinpicture', handleEnterPiP)
      videoElement[listenerType]('leavepictureinpicture', handleLeavePiP)
    }
  }

  useEffect(() => {
    handleExitPiP()
    handleListeners('removeEventListener')
    handleListeners('addEventListener')
    return () => {
      if (videoElement) {
        handleListeners('removeEventListener')
      }
      handleExitPiP()
    }
  }, [url, videoElement])

  if (!url) {
    console.warn('The url for video is empty')

    return <></>
  }

  return (
    <div className="video-wrapper">
      <video
        src={url}
        ref={videoRef}
        controls
        width={isInPiP ? cssData.video_min_width : '100%'}
        height={isInPiP ? '50px' : 'auto'}
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
        preload="auto"
      >
        Your browser does not support the video tag.
      </video>
      <div
        style={{
          display: isPeriodsBlock ? 'block' : 'none',
        }}
        className="video-periods"
      >
        {periodsData}
      </div>
    </div>
  )
}
