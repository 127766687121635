import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import type { FC, ReactNode } from 'react'
import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WeekFullDays } from '../../../../../constants/enums'
import { useDictionaryData } from '../../../../../hooks/useDictionaryData'
import useFilters from '../../../../../hooks/useFilters'
import { useLanguage } from '../../../../../hooks/useLanguage'
import useMainPageActiveTab from '../../../../../hooks/useMainPageActiveTab'
import useResponsivePerform from '../../../../../hooks/useResponsivePerform'
import { useSimilarSpeakerList } from '../../../../../hooks/useSimilarSpeakerList'
import {
  mediaDataSelector,
  mediaListSelector,
  mediaWithBugsSelector,
  transcriptionsDataSelector,
} from '../../../../../redux/selectors'
import { setActiveMedia } from '../../../../../redux/slices/appStateSlice'
import { updateFilterArr } from '../../../../../redux/slices/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { fetchMediaData } from '../../../../../redux/thunk/mediaDataThunk'
import {
  fetchTranscriptionsBugs,
  fetchTranscriptionsData,
} from '../../../../../redux/thunk/transcriptionsDataThunk'
import type { FilterStringArrayDataKeys } from '../../../../../redux/types/filters'
import { formatSecondsToTime } from '../../../../../utils'
import { IpChip } from '../../../../ipDesignSystemComponents/atoms/IpChip'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../../ipDesignSystemComponents/atoms/IpText'
import { IpTooltip } from '../../../../ipDesignSystemComponents/atoms/IpTooltip'
import { ROUTER_PATHS } from '../../../Router'

import './index.scss'

type SearchResultItemProps = {
  index: number
  isActive: boolean
}

type ItemContentMemoProps = {
  index: number
}

const Tooltip = ({
  children,
  hide,
}: {
  children: ReactNode
  hide?: boolean
}) => (
  <IpTooltip
    arrow
    title={hide ? '' : 'Add filter'}
    placement="top"
    content={children}
  />
)

const ItemContentMemo = memo(function ItemContent({
  index,
}: ItemContentMemoProps) {
  const dispatch = useAppDispatch()
  const { suffix } = useLanguage()
  const { isMainBugsTab } = useMainPageActiveTab()
  const { filters, filterType } = useFilters()
  const { storeData: similarSpeakerMediaData, isSimilarSpeakerParams } =
    useSimilarSpeakerList()
  const bugItem = useAppSelector(mediaWithBugsSelector).data.data[index]
  const mediaData = useAppSelector(mediaListSelector)
  const mediaItem = (
    isSimilarSpeakerParams ? similarSpeakerMediaData : mediaData
  ).data.data[index]

  const {
    times,
    media_type_id,
    source_id,
    author_id,
    air_date,
    weekday,
    source_media_type,
    duration,
  } = isMainBugsTab ? bugItem : mediaItem

  const title = isMainBugsTab
    ? bugItem.title
    : mediaItem[`title${suffix}` as const]

  const episode_title = isMainBugsTab
    ? bugItem.episode_title
    : mediaItem[`episode_title${suffix}` as const]

  const program_id = isMainBugsTab
    ? bugItem.bug.program_id
    : mediaItem.transcription.program_id
  const persona_id = isMainBugsTab
    ? bugItem.bug.persona_id
    : mediaItem.transcription.persona_id
  const start =
    (isMainBugsTab ? bugItem.bug.start : mediaItem.transcription.start) || 0
  const text = isMainBugsTab
    ? bugItem.bug.text
    : mediaItem.transcription[`text${suffix}` as const]

  const speaker = isMainBugsTab
    ? bugItem.bug.speaker
    : mediaItem.transcription.speaker

  const telegram_channel_id = isMainBugsTab
    ? undefined
    : mediaItem.telegram_channel_id

  const dictionaryData = useDictionaryData({
    media_type_id,
    source_id,
    program_id,
    author_id,
    persona_id,
    telegram_channel_id,
  })

  const startTime = formatSecondsToTime(start)
  const transcriptionPersona = persona_id ? dictionaryData.persona : speaker
  const titleText = episode_title || title || ''
  const isMediaTypeFilter = filters.media_type?.includes(media_type_id)
  const isSourceFilter = filters.sources?.includes(source_id)
  const isProgramsFilter = filters.programs?.includes(program_id)

  const onClickHandler = (
    e: React.SyntheticEvent<Element, Event>,
    value: string,
    type: FilterStringArrayDataKeys,
    isValue?: boolean
  ) => {
    if (value && !isValue) {
      dispatch(
        updateFilterArr({
          filterType,
          type,
          value,
        })
      )
      e.stopPropagation()
    }
  }

  return (
    <>
      <div className="top-line">
        <div className="top-line_chip-wrapper">
          <Tooltip hide={isMediaTypeFilter}>
            <IpChip
              label={dictionaryData.mediaType}
              classes="top-line_chip"
              color="primary"
              size="extraSmall"
              onClick={(e) =>
                onClickHandler(
                  e,
                  media_type_id,
                  'media_type',
                  isMediaTypeFilter
                )
              }
            />
          </Tooltip>
          {dictionaryData.source && (
            <Tooltip hide={isSourceFilter}>
              <IpChip
                label={dictionaryData.source}
                classes="top-line_chip"
                color="primary"
                size="extraSmall"
                onClick={(e) => {
                  onClickHandler(e, source_id, 'sources', isSourceFilter)
                }}
              />
            </Tooltip>
          )}
          <IpText component="span" classes="top-line_date" color="primary">
            {WeekFullDays[weekday]}, {air_date}
          </IpText>
        </div>
        <div className="top-line_additional">
          {times && source_media_type && (
            <>
              {filters.search && (
                <IpText>
                  {times} {times === 1 ? 'time' : 'times'}
                </IpText>
              )}
              {!!duration && (
                <>
                  <IpIcon
                    classes="top-line_type-icon"
                    color="primary"
                    fontSize="24px"
                  >
                    {source_media_type && <PlayCircleOutlineIcon />}
                  </IpIcon>
                  <IpText>{formatSecondsToTime(duration)}</IpText>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="main-info">
        <div className="program-info">
          <Tooltip hide={isProgramsFilter}>
            <IpText
              classes="program-name"
              onClick={(e) => {
                onClickHandler(e, program_id, 'programs', isProgramsFilter)
              }}
            >
              {dictionaryData.program}
            </IpText>
          </Tooltip>
          {dictionaryData.telegramChanel && (
            <IpText classes="program-name">
              {dictionaryData.telegramChanel}
            </IpText>
          )}
          {media_type_id === '1' ? (
            <IpText classes="author-info">
              <span>Program:</span> {dictionaryData.program}
            </IpText>
          ) : (
            author_id && (
              <IpText classes="author-info">
                <span>Author:</span> {dictionaryData.author}
              </IpText>
            )
          )}
        </div>
        {!!titleText && titleText !== 'null' && (
          <IpText classes="program-title">{titleText}</IpText>
        )}
        {text ? (
          <IpText key={start} classes="transcription">
            <span className="transcription_time">{startTime}</span>
            {' - '}
            {transcriptionPersona && (
              <>
                <span className="transcription_persona">
                  {transcriptionPersona}
                </span>
                {' - '}
              </>
            )}
            {text}
          </IpText>
        ) : (
          ''
        )}
      </div>
    </>
  )
})

export const SearchResultItem: FC<SearchResultItemProps> = ({
  index,
  isActive,
}) => {
  const { isLess: isLessLg } = useResponsivePerform(breakpoints.values.lg)
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { storeData, isSimilarSpeakerParams } = useSimilarSpeakerList()

  const { data: mediaData } = useAppSelector(mediaDataSelector)
  const { data: transcriptionsData } = useAppSelector(
    transcriptionsDataSelector
  )
  const { isMainBugsTab, mainActiveTab } = useMainPageActiveTab()

  const bugItem = useAppSelector(mediaWithBugsSelector).data.data[index]
  const mediaItem = (
    isSimilarSpeakerParams ? storeData : useAppSelector(mediaListSelector)
  ).data.data[index]

  const onClickHandler = () => {
    const { id } = isMainBugsTab ? bugItem : mediaItem
    const currentMediaData = mediaData[id]
    const currentTrData = transcriptionsData[mainActiveTab][id]

    if (!currentMediaData) {
      dispatch(fetchMediaData(id))
    }
    if (!currentTrData && isMainBugsTab) {
      dispatch(fetchTranscriptionsBugs(id))
    }
    if (!currentTrData && !isMainBugsTab) {
      dispatch(fetchTranscriptionsData(id))
    }

    dispatch(
      setActiveMedia({
        mainActiveTab,
        mediaIndex: index,
        mediaId: id,
      })
    )

    if (isLessLg) {
      navigate(`${ROUTER_PATHS.details}/${id}${search}`)
    }
  }

  return (
    <div
      className={`search-result-item ${isActive ? 'active' : ''}`}
      onClick={onClickHandler}
    >
      <ItemContentMemo index={index} />
    </div>
  )
}
