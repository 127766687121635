import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import { Popover } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IpCheckbox } from '../../ipDesignSystemComponents/atoms/IpCheckbox'
import { IpChip } from '../../ipDesignSystemComponents/atoms/IpChip'
import { IpText } from '../../ipDesignSystemComponents/atoms/IpText'
import { IpTextEllipsis } from '../../ipDesignSystemComponents/core/IpTextEllipsis'
import { SelectedListInput } from './SelectedListInput'

import './index.scss'

type SelectedListProps = {
  classes?: string
  dropdownListClasses?: string
  title: string
  currentList: string[]
  list: string[]
  onItemAdd: (item: string) => void
  onItemRemove: (item: string) => void
  onNewItemAdd: (item: string) => void
  disableSearch?: boolean
  color?: 'primary' | 'secondary'
}

export const SelectedList = (props: SelectedListProps) => {
  const {
    classes = '',
    dropdownListClasses,
    title = '',
    currentList,
    list,
    onItemAdd,
    onItemRemove,
    onNewItemAdd,
    disableSearch,
    color = 'secondary',
  } = props

  const [filteredList, setFilteredList] = useState<string[]>(list)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    setFilteredList(list)
  }, [list])

  const isPopoverOpen = Boolean(anchorEl)

  const addHandleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setFilteredList(list)
  }

  const customClasses = formatClassName('select-list', classes)
  const customListClasses = formatClassName(
    'select-list-items',
    dropdownListClasses
  )

  return (
    <div className={customClasses}>
      {title && (
        <IpText variant="subtitle3" color={color} classes="select-list-title">
          {title}
        </IpText>
      )}
      {currentList.map((item) => (
        <IpChip
          key={item}
          classes="select-list-chip"
          label={
            <IpTextEllipsis
              placement="top-start"
              isTextOverflow={true}
              classes="select-list-chip-tooltip"
            >
              {item}
            </IpTextEllipsis>
          }
          size="extraSmall"
          variant="outlined"
          color={color}
        />
      ))}
      <IpChip
        classes="select-list-add-btn"
        onClick={addHandleClick}
        label="+"
        size="extraSmall"
        variant="outlined"
        color={color}
      />
      <Popover
        className="select-list-popover"
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={handlePopoverClose}
        onBackdropClick={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!disableSearch && (
          <SelectedListInput
            onItemAdd={onNewItemAdd}
            setFilteredValues={setFilteredList}
            list={list}
          />
        )}
        <ul className={customListClasses}>
          {filteredList.map((item, i) => {
            const isItemApplied = currentList.includes(item)
            return (
              <IpCheckbox
                key={item ?? i}
                color={color}
                classes="select-list-item"
                checked={isItemApplied}
                label={item}
                labelPlacement="left"
                onChange={() =>
                  isItemApplied ? onItemRemove(item) : onItemAdd(item)
                }
              />
            )
          })}
        </ul>
      </Popover>
    </div>
  )
}
