import type { DictionariesPayloadData } from '../redux/types/dictionaries'
import { api } from './axiosInstance'

export const apiGetDictionaries =
  async (): Promise<DictionariesPayloadData> => {
    try {
      const response = await api.get('dictionary')
      return response.data
    } catch (error) {
      console.error('Error fetching dictionaries:', error)
      throw error
    }
  }
