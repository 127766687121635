import React from 'react'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'

type Props = {
  content?: string | null
}
export const Annotation = ({ content }: Props) => {
  return content ? (
    <div>
      <IpText variant="subtitle3" color="primary">
        Annotation:
      </IpText>
      <IpText variant="body1">{content}</IpText>
    </div>
  ) : (
    <></>
  )
}
