import GetAppIcon from '@mui/icons-material/GetApp'
import React from 'react'
import { apiExportBasket } from '../../../../../api'
import { EXPORT_FILE_EXT } from '../../../../../constants'
import { useExportFile } from '../../../../../hooks/useExportFile'
import useFilters from '../../../../../hooks/useFilters'
import { IpButton } from '../../../../ipDesignSystemComponents/atoms/IpButton'

type Props = {
  activeItems: string[]
}

function ExportBasketBtn({ activeItems }: Props) {
  const { filters } = useFilters()
  const { handleExport } = useExportFile()

  return (
    <IpButton
      startIcon={<GetAppIcon />}
      onClick={() =>
        handleExport({
          api: apiExportBasket,
          params: {
            report_ids: activeItems,
            ...filters,
          },
          fileName: 'basket',
          fileExt: EXPORT_FILE_EXT.excel,
        })
      }
      classes="basket-actions-panel-btn"
    >
      Export to excel
    </IpButton>
  )
}

export default ExportBasketBtn
