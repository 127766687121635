import type { FC } from 'react'

type ConditionalWrapType = {
  condition: boolean
  wrap: FC
  children: any
}

export function ConditionalWrap({
  condition,
  wrap,
  children,
}: ConditionalWrapType) {
  return condition ? wrap(children) : children
}
