import React, { forwardRef, useEffect, useRef, useState } from 'react'
import type {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  ElementType,
  ForwardedRef,
  KeyboardEvent,
} from 'react'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { MenuList, ListItemAvatar, ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material'
import styles from '@infopulse-design-system/shared/theme/components/IpMenuList'
import type { NavLinkProps } from 'react-router-dom'
import type { IpMenuListPropTypes } from '@infopulse-design-system/shared/types/IpMenuList.types'
import type { IpMenuButtonBaseTypes } from '@infopulse-design-system/shared/types/IpMenuBase.types'
import { IpMenuItem } from '../IpMenuItem'
import { IpCollapse } from '../IpCollapse'
import type { IpMenuItemPropTypesReact } from '../IpMenuItem/index.types'
import '@infopulse-design-system/shared/theme/components/IpMenuList/styles.scss'

export const IpMenuListClass = 'IpMenuList'
export const IpMenuListTestId = 'IpMenuListTestId'

const IpComponentClasses = formatClassName(
  generateClasses('menu-list', 'react'),
  IpMenuListClass
)

export type IpMenuListPropTypesReact = IpMenuListPropTypes<
  ReactNode,
  Event | SyntheticEvent,
  KeyboardEvent<HTMLElement>,
  ElementType,
  NavLinkProps['to'],
  IpMenuItemPropTypesReact
>

export type IpMenuListButtonsConfigPropTypesReact = IpMenuButtonBaseTypes<
  ReactNode,
  Event | SyntheticEvent,
  KeyboardEvent<HTMLElement>,
  ElementType,
  NavLinkProps['to'],
  IpMenuItemPropTypesReact
>

type IpMenuStyledPropTypes = {
  activecolor?: IpMenuListPropTypesReact['activeColor']
  bg?: IpMenuListPropTypesReact['bgColor']
  bgactive?: IpMenuListPropTypesReact['bgActiveColor']
  color?: IpMenuListPropTypesReact['color']
  maxwidth?: IpMenuListPropTypesReact['maxWidth']
  minwidth?: IpMenuListPropTypesReact['minWidth']
}

type IpSubMenuContentPropTypes = {
  buttonsConfig: IpMenuListButtonsConfigPropTypesReact[]
  dense: boolean
  divider: boolean
  endIcon: ReactNode
  onClose: (e: Event | SyntheticEvent | KeyboardEvent<HTMLElement>) => void
  open: boolean
  transitionDuration: number
}

type IpMenuContentItemPropTypes = {
  openedElem: HTMLElement | null
  setOpenedElem: Dispatch<SetStateAction<HTMLElement | null>>
  transitionDuration: number
  endIcon: ReactNode
  dense: boolean
  divider: boolean
} & Omit<IpMenuListButtonsConfigPropTypesReact, 'divider'> & {
    onKeyDown: (
      event: KeyboardEvent<HTMLElement>,
      index: number,
      state: boolean | undefined
    ) => void
    isFocused: boolean
    index: number
  }

export function onMenuItemKeyDownHelper({
  event,
  index,
  isOpen,
  itemsLength,
  dispatchFocus,
  dispatchElem,
  onClose,
}: {
  event: KeyboardEvent<HTMLElement>
  index: number
  isOpen: boolean | undefined
  itemsLength: number
  dispatchFocus: React.Dispatch<React.SetStateAction<number | null>>
  dispatchElem: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  onClose?: IpSubMenuContentPropTypes['onClose']
}) {
  switch (event.key) {
    case 'ArrowUp':
      if (index === 0) dispatchFocus(itemsLength - 1)
      else dispatchFocus(index - 1)
      break
    case 'ArrowDown':
      if (index === itemsLength - 1) dispatchFocus(0)
      else dispatchFocus(index + 1)
      break
    case 'ArrowLeft':
      dispatchElem(null)
      if (!isOpen && onClose) {
        onClose(event)
      } else {
        event.preventDefault()
      }
      break
    case 'ArrowRight':
      event.currentTarget.click()
      break
    default:
  }
}

const StyledMenu = styled(MenuList)(
  ({
    activecolor,
    bgactive,
    bg,
    color,
    minwidth: minWidth,
    maxwidth: maxWidth,
  }: IpMenuStyledPropTypes) => ({
    [`&.${IpMenuListClass} .MuiMenuItem-root`]: {
      color,
      backgroundColor: bg,
      minWidth,
      maxWidth,
      '&:hover, &.Mui-focusVisible': {
        color: activecolor,
        backgroundColor: bgactive,
      },
    },
  })
)

const SubMenuContent = (props: IpSubMenuContentPropTypes) => {
  const {
    buttonsConfig,
    dense,
    divider,
    endIcon,
    onClose,
    open,
    transitionDuration,
  } = props

  const [openedElem, setOpenedElem] = useState<HTMLElement | null>(null)
  const [focusedIndex, setFocusedIndex] = useState<null | number>(0)

  const onKeyDownHandler = (
    event: KeyboardEvent<HTMLElement>,
    index: number,
    isOpen: boolean | undefined
  ) => {
    onMenuItemKeyDownHelper({
      event,
      index,
      isOpen,
      itemsLength: buttonsConfig.length,
      dispatchFocus: setFocusedIndex,
      dispatchElem: setOpenedElem,
      onClose,
    })
  }

  return (
    <IpCollapse inside={open} timeout={transitionDuration}>
      <StyledMenu dense={dense}>
        {buttonsConfig.map((item, index) => (
          // eslint-disable-next-line no-use-before-define
          <MenuContentItem
            dense={dense}
            endIcon={endIcon}
            key={index}
            openedElem={openedElem}
            setOpenedElem={setOpenedElem}
            transitionDuration={transitionDuration}
            divider={item.divider ?? divider}
            isFocused={focusedIndex === index}
            index={index}
            onKeyDown={onKeyDownHandler}
            {...item}
          />
        ))}
      </StyledMenu>
    </IpCollapse>
  )
}

function MenuContentItem(props: IpMenuContentItemPropTypes) {
  const {
    classes,
    dense,
    disabled,
    divider,
    endIcon,
    href,
    icon,
    index,
    inset,
    isFocused,
    isLinkSafe,
    onClick,
    onKeyDown,
    open,
    openedElem,
    setOpenedElem,
    subMenuContent = [],
    target,
    text,
    transitionDuration,
    ...overrideProps
  } = props

  const buttonRef = useRef(null)

  useEffect(() => {
    if (open) {
      setOpenedElem(buttonRef.current)
    }
  }, [])

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    if (event.currentTarget !== openedElem) {
      setOpenedElem(buttonRef.current)
    } else {
      setOpenedElem(null)
    }
    if (onClick) onClick(event)
    event.stopPropagation()
  }

  const isOpen = !!openedElem && openedElem === buttonRef.current

  const customClasses = formatClassName(classes, isOpen ? 'open' : '')

  const isSubMenuContent = subMenuContent.length > 0

  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    onKeyDown(event, index, isOpen)
  }

  const onCloseHandler = (
    event: Event | SyntheticEvent | KeyboardEvent<HTMLElement>
  ) => {
    openedElem?.click()
    openedElem?.focus()
    event.preventDefault()
  }

  return (
    <>
      <IpMenuItem
        autoFocus={isFocused}
        classes={customClasses}
        disabled={disabled}
        divider={divider}
        href={href}
        isLinkSafe={isLinkSafe}
        onClick={clickHandler}
        onKeyDown={onKeyDownHandler}
        ref={buttonRef}
        target={target}
        {...overrideProps}
      >
        {icon && <ListItemAvatar>{icon}</ListItemAvatar>}
        {text && <ListItemText inset={inset}>{text}</ListItemText>}
        {isSubMenuContent && endIcon && (
          <div className="IpList-endIcon">{endIcon}</div>
        )}
      </IpMenuItem>
      {isSubMenuContent && (
        <li className="IpMenuItem-subMenu">
          <SubMenuContent
            buttonsConfig={subMenuContent}
            dense={dense}
            divider={divider}
            endIcon={endIcon}
            onClose={onCloseHandler}
            open={isOpen}
            transitionDuration={transitionDuration}
          />
        </li>
      )}
    </>
  )
}

export const IpMenuList = forwardRef(function IpMenuList(
  props: IpMenuListPropTypesReact,
  ref: ForwardedRef<HTMLUListElement | null>
) {
  const {
    activeColor,
    bgActiveColor,
    bgColor,
    buttonsConfig,
    classes,
    color,
    dense = false,
    divider = false,
    endIcon = <ExpandMore color="primary" />,
    minWidth = styles.menu_list_min_width,
    maxWidth,
    transitionDuration = 400,
  } = props

  const [openedElem, setOpenedElem] = useState<HTMLElement | null>(null)
  const customClasses = formatClassName(IpComponentClasses, classes)

  const [focusedIndex, setFocusedIndex] = useState<null | number>(0)

  const onKeyDownHandler = (
    event: KeyboardEvent<HTMLElement>,
    index: number,
    isOpen: boolean | undefined
  ) => {
    onMenuItemKeyDownHelper({
      event,
      index,
      isOpen,
      itemsLength: buttonsConfig.length,
      dispatchFocus: setFocusedIndex,
      dispatchElem: setOpenedElem,
    })
  }

  return (
    <StyledMenu
      // @ts-ignore
      activecolor={activeColor}
      bgactive={bgActiveColor}
      bg={bgColor}
      color={color}
      dense={dense}
      minwidth={minWidth}
      maxwidth={maxWidth}
      className={customClasses}
      ref={ref}
      data-testid={IpMenuListTestId}
    >
      {buttonsConfig.map((item, index) => (
        <MenuContentItem
          dense={dense}
          divider={item.divider ?? divider}
          endIcon={endIcon}
          index={index}
          isFocused={focusedIndex === index}
          key={index}
          onKeyDown={onKeyDownHandler}
          openedElem={openedElem}
          setOpenedElem={setOpenedElem}
          transitionDuration={transitionDuration}
          {...item}
        />
      ))}
    </StyledMenu>
  )
})
