export default {
  MuiCard: {
    styleOverrides: {
      root: {
        position: 'relative',
        borderRadius: 0,
      },
    },
  },
}
