/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CreateProcessRes, ProcessRes } from '../types/mediaProcessing'

export type MediaProcessingState = {
  data: Record<string, ProcessRes>
}

export const initialBagsState: MediaProcessingState = {
  data: {},
}

export const MediaProcessingSlice = createSlice({
  name: 'mediaProcessing',
  initialState: initialBagsState,
  reducers: {
    setProcessingEl: (state, { payload }: PayloadAction<CreateProcessRes>) => {
      state.data[payload.id] = payload
    },
    updateProcessingEl: (state, { payload }: PayloadAction<ProcessRes>) => {
      state.data[payload.id] = payload
    },
    updateProcessingList: (state, { payload }: PayloadAction<ProcessRes[]>) => {
      payload.forEach((item) => {
        state.data[item.id] = item
      })
    },
    removeProcessingEl: (state, { payload }: PayloadAction<string>) => {
      delete state.data[payload]
    },
  },
})

export const {
  setProcessingEl,
  updateProcessingEl,
  removeProcessingEl,
  updateProcessingList,
} = MediaProcessingSlice.actions

export default MediaProcessingSlice.reducer
