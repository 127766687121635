import type {
  ExportBasketParams,
  ExportPersonasParams,
  ExportTranscriptionsParams,
  GetMediaParams,
} from './apiTypes'
import { api } from './axiosInstance'
import { mapFiltersToSearchParams } from './mappers'

export const apiExportMediaData = async ({
  export_count,
  fileType,
  ...filters
}: GetMediaParams & { fileType: string; export_count: number }) => {
  try {
    const params = mapFiltersToSearchParams(filters)
    const { data } = await api.post(
      `media/export/${fileType}`,
      { ...params, export_count, limit: 0 },
      {
        responseType: 'blob',
      }
    )
    return data
  } catch (error) {
    console.error('Error during exporting media data', error)
    throw error
  }
}

export const apiExportTranscriptionsData = async (
  params: ExportTranscriptionsParams
) => {
  try {
    const { data } = await api.get(
      `media/export/${params.fileType}/${params.id}`,
      {
        responseType: 'blob',
      }
    )
    return data
  } catch (error) {
    console.error('Error during exporting transcription data', error)
    throw error
  }
}

export const apiExportPersonasData = async (filters: ExportPersonasParams) => {
  try {
    const { data } = await api.post('persona/export', filters, {
      responseType: 'blob',
    })
    return data
  } catch (error) {
    console.error('Error during exporting personas data', error)
    throw error
  }
}

export const apiExportBasket = async (params: ExportBasketParams) => {
  try {
    const { data } = await api.post('basket/export', params, {
      responseType: 'blob',
    })
    return data
  } catch (error) {
    console.error('Error during exporting data from basket', error)
    throw error
  }
}
