import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpCard/IpCardContent'

export default (theme: Theme) => {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[900],
          font: styles.card_content_font,
          '&.MuiCardContent-root': {
            padding: styles.card_content_padding,
          },
        },
      },
    },
  }
}
