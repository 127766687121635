import { useMsal } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'
import React, { useEffect, useRef, useState } from 'react'
import { apiGetClientId } from './api'
import { api } from './api/axiosInstance'
import { API_HEADERS } from './api/constants'
import { AppRouter } from './components/custom/Router'
import { PERMISSIONS, QueryTypes } from './constants/enums'
import { useMainBugs } from './hooks/dataLoaders/useMainBugs'
import { useMainResults } from './hooks/dataLoaders/useMainResults'
import { usePersonLibrary } from './hooks/dataLoaders/usePersonLibrary'
import useFilters from './hooks/useFilters'
import { useLanguage } from './hooks/useLanguage'
import { usePageName } from './hooks/usePageName'
import { usePermission } from './hooks/usePermission'
import { useUserTags } from './hooks/useUserTags'
import { profileSelector } from './redux/selectors'
import {
  resetActiveMedia,
  resetActivePersonaId,
} from './redux/slices/appStateSlice'
import { setRoles, setUserInfo } from './redux/slices/profileSlice'
import { useAppDispatch, useAppSelector } from './redux/store'
import { fetchBugs } from './redux/thunk/bugsThunk'
import {
  fetchDictionaries,
  fetchPersonas,
} from './redux/thunk/dictionariesThank'
import {
  getMinRefreshTokenTimeDiff,
  refreshToken,
} from './services/msalService'

const anonymousDefaultProfile = {
  roles: [],
  name: 'Anonymous',
  queryType: QueryTypes.full,
}

function App() {
  const { instance, accounts } = useMsal()
  const dispatch = useAppDispatch()
  const { lang, locale, suffix } = useLanguage()
  const { mainBugsFilters } = useFilters()
  const { getUserTags, isBasketTags } = useUserTags()
  const { getIsPermission, isAnonymous } = usePermission()
  const profile = useAppSelector(profileSelector)
  const { isDetailsPage, isPersonaPage } = usePageName()
  const [expTokenTime, setExpTokenTime] = useState<number | undefined>()
  const refreshTokenTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
  api.defaults.headers['Accept-Language'] = lang
  api.defaults.headers.Language = lang

  useMainResults()
  useMainBugs()
  usePersonLibrary()

  const updateApiHeadersByRefreshToken = async () => {
    const authResult = await refreshToken(accounts[0])
    if (authResult) {
      const { expiresOn, accessToken } = authResult
      api.defaults.headers.common[
        API_HEADERS.AUTHORIZATION
      ] = `Bearer ${accessToken}`
      setExpTokenTime(getMinRefreshTokenTimeDiff(expiresOn))

      return accessToken
    }
    return null
  }

  const fetchData = async () => {
    try {
      const accessToken = await updateApiHeadersByRefreshToken()

      const decoded: any = accessToken
        ? jwt_decode(accessToken)
        : anonymousDefaultProfile

      api.defaults.headers.common[API_HEADERS.X_API_CLIENT_ID] =
        await apiGetClientId()

      if (isAnonymous) {
        dispatch(
          setUserInfo({ name: decoded.name, queryType: decoded.queryType })
        )
        dispatch(setRoles(decoded.roles))
      }

      dispatch(fetchDictionaries())

      dispatch(fetchPersonas({ locale, suffix }))

      if (accessToken) {
        dispatch(fetchBugs(mainBugsFilters))
      }
      if (!isDetailsPage) {
        dispatch(resetActiveMedia())
      }
      if (!isPersonaPage) {
        dispatch(resetActivePersonaId())
      }
    } catch (e: any) {
      window.console.error(e.message || e)
    }
  }

  const clearRefreshTokenTimer = () => {
    if (refreshTokenTimer.current) {
      clearTimeout(refreshTokenTimer.current)
    }
  }

  useEffect(() => {
    if (expTokenTime) {
      clearRefreshTokenTimer()
      refreshTokenTimer.current = setTimeout(async () => {
        updateApiHeadersByRefreshToken()

        console.log('Update accessToken by expTokenTime')
      }, expTokenTime)
    }

    return () => clearRefreshTokenTimer()
  }, [expTokenTime])

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const originalRequest = error.config
          const accessToken = await updateApiHeadersByRefreshToken()
          if (!accessToken) {
            return Promise.reject(error)
          }

          console.log('Update accessToken by api.interceptors.response')

          originalRequest.headers[
            API_HEADERS.AUTHORIZATION
          ] = `Bearer ${accessToken}`
          return api(originalRequest)
        }
        return Promise.reject(error)
      }
    )

    fetchData()

    return () => {
      api.interceptors.response.eject(interceptor)
    }
  }, [instance, accounts])

  useEffect(() => {
    if (getIsPermission(PERMISSIONS.user_tags)) {
      getUserTags()
    }
  }, [isBasketTags, profile.data.name])

  return <AppRouter />
}

export default App
