import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetMediaData } from '../../api'
import { setMediaData, updateOnlyMediaData } from '../slices/mediaDataSlice'
import type { MediaData } from '../types/media'

export const fetchMediaData = createAsyncThunk<MediaData, string>(
  'mediaData/fetch',
  async (id, thunkAPI) => {
    try {
      const data = await apiGetMediaData(id)
      thunkAPI.dispatch(setMediaData({ id, data }))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)

export const updateMediaData = createAsyncThunk<MediaData, string>(
  'mediaData/update/lang',
  async (id, thunkAPI) => {
    try {
      const data = await apiGetMediaData(id)
      thunkAPI.dispatch(updateOnlyMediaData({ id, data }))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)
