import type {
  FilterTypes,
  OrderByFields,
  OrderDirection,
} from '../../constants/enums'
import type { DictionaryCommonName } from './dictionaries'

export enum DATE_FILTERS {
  date = 'date',
  submit_date = 'submit_date',
  air_date = 'air_date',
}

export enum START_END_FILTERS {
  media_length = 'media_length',
  percentage = 'percentage',
  similar_embeddings_num = 'similar_embeddings_num',
  media_num = 'media_num',
}

export enum SEARCH_STRING_FILTERS {
  search = 'search',
  program_title = 'program_title',
  title = 'title',
}

export type SearchStringFilters = keyof typeof SEARCH_STRING_FILTERS

export type FilterStringDataKeys =
  | SearchStringFilters
  | 'search_model'
  | 'chartLabelType'
  | 'interval'
  | 'chartType'

export type FilterStringArrayDataKeysCustom = 'user_tags' | 'weekdays'

export type FilterStringArrayDataKeys =
  | DictionaryCommonName
  | FilterStringArrayDataKeysCustom

export type DateFilters = keyof typeof DATE_FILTERS
export type FilterDateDataKeys = `${DateFilters}_from` | `${DateFilters}_to`

export type FilterNumberDataKeys = 'skip' | 'limit'

export type StartEndFilters = keyof typeof START_END_FILTERS
export type FilterStartEndDataKeys =
  | `${StartEndFilters}_start`
  | `${StartEndFilters}_end`

type StringData = Partial<Record<FilterStringDataKeys, string>>

type StringArrayData = Partial<Record<FilterStringArrayDataKeys, string[]>>

type DateData = Partial<Record<FilterDateDataKeys, string>>

type NumberData = Record<FilterNumberDataKeys, number> &
  Partial<Record<FilterStartEndDataKeys, number>>

export type SortData = {
  order_by?: keyof typeof OrderByFields
  order_direction?: keyof typeof OrderDirection
}

export type FiltersState = StringData &
  StringArrayData &
  DateData &
  NumberData &
  SortData

export type FilterType = keyof typeof FilterTypes

export type FilterTypeData = { filterType: FilterType }

export type PayloadUpdateFiltersActionType = FilterTypeData &
  (
    | {
        type: 'order_by'
        value: SortData['order_by']
      }
    | {
        type: 'order_direction'
        value: SortData['order_direction']
      }
    | {
        type: FilterNumberDataKeys
        value: NumberData[FilterNumberDataKeys]
      }
    | {
        type: FilterStartEndDataKeys
        value: NumberData[FilterStartEndDataKeys]
      }
    | {
        type: FilterDateDataKeys
        value: DateData[FilterDateDataKeys]
      }
    | {
        type: FilterStringDataKeys
        value: StringData[FilterStringDataKeys]
      }
    | {
        type: FilterStringArrayDataKeys
        value: StringArrayData[FilterStringArrayDataKeys]
      }
  )

export type PayloadUpdateFilterArrActionType = {
  type: FilterStringArrayDataKeys
  value: string
  filterType: FilterType
}

export type CustomStateFullResetFIlters = StartEndFilters | DateFilters

export type StateFullResetFIlters =
  | FilterType
  | FilterStringDataKeys
  | CustomStateFullResetFIlters

export type ResetFilterData = Partial<Record<StateFullResetFIlters, Date>>
