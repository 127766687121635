import React from 'react'
import type { ReactNode } from 'react'
import { TabPanel } from '@mui/lab'
import type { IpTabPanelPropTypes } from '@infopulse-design-system/shared/types/IpTabs.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpTabs/IpTabPanel/styles.scss'

export const IpTabPanelTestId = 'IpTabPanelTestId'

type IpTabPanelPropTypesReact = IpTabPanelPropTypes & {
  children: ReactNode
}

const getCustomClasses = (value: string, classes?: string): string =>
  formatClassName(
    generateClasses('tab-panel', 'react'),
    classes,
    `${value}-tab-container`
  )

export function IpTabPanel({
  children,
  classes,
  value,
}: IpTabPanelPropTypesReact) {
  const customClasses = getCustomClasses(value, classes)

  return (
    <TabPanel
      className={customClasses}
      data-testid={IpTabPanelTestId}
      value={value}
    >
      {children}
    </TabPanel>
  )
}
