import { styled } from '@mui/material/styles'
import type { TooltipProps } from '@mui/material/Tooltip'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import React, { memo } from 'react'
import { dictionariesFootnotesSelector } from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import type { FootnoteConfigType } from '../../../../../redux/types/media'

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'calc(100vw - 32px)',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
    fontSize: 11,
  },
})

interface TooltipReplacementProps {
  text: string
  highlights?: string[]
  footnotes?: FootnoteConfigType[]
}

const getReplacements = (
  text: string,
  footnotes?: TooltipReplacementProps['footnotes'],
  highlights?: string[]
) => {
  const arr: {
    offset: number
    len: number
    type: 'footnote' | 'highlight' | 'both'
    id?: string
  }[] = []

  if (highlights) {
    let str = text
    let length = 0
    highlights?.forEach((highlight) => {
      const search = highlight.match(/{{(.*?)}}/)![1]
      const len = search.length
      const offset = str.indexOf(search) + length
      str = str.replace(search, '')
      length += len
      arr.push({ offset, len, type: 'highlight' })
    })
  }

  if (footnotes) {
    footnotes.forEach(({ offset, len, id }) => {
      const index = arr.findIndex((item) => {
        const [s, e] = [item.offset, item.offset + item.len] // highlight
        const [start, end] = [offset, offset + len] // footnote
        return (
          !(start >= s && end >= s) || !(start <= e && end <= e) // not inside
        )
      })
      if (index === -1) {
        arr.push({ offset, len, type: 'footnote', id })
      } else {
        arr[index] = {
          offset: Math.max(offset, arr[index].offset),
          len: Math.max(arr[index].offset + arr[index].len, offset + len),
          type: 'both',
          id,
        }
      }
    })
  }

  const replacements = arr.sort((a, b) => a.offset - b.offset)

  return replacements
}

export const TrFootnote = memo(function MemoTrFootnote({
  text,
  footnotes,
  highlights,
}: TooltipReplacementProps) {
  const dictionaryF = useAppSelector(dictionariesFootnotesSelector)
  const renderedText: React.ReactNode[] = []
  let currentPosition = 0

  const replacements = getReplacements(text, footnotes, highlights)

  replacements.forEach((replacement) => {
    const { offset, len, type } = replacement

    const getFootnote = () => {
      const footnote = dictionaryF?.find((f) => f.id === replacement?.id)

      const title = footnote
        ? `In this sentence phrase ${footnote.text} ${footnote.transliteration} ${footnote.meaning_en}. ${footnote.origin_en}.`
        : ''

      return (
        <NoMaxWidthTooltip
          title={title}
          placement="top"
          sx={{ tooltip: { minWidth: '800px' } }}
        >
          <em style={{ backgroundColor: '#adb5bd' }}>
            {text.slice(offset, offset + len)}
          </em>
        </NoMaxWidthTooltip>
      )
    }

    if (offset > currentPosition) {
      const textBeforeReplacement = text.slice(currentPosition, offset)
      renderedText.push(textBeforeReplacement)
    }

    if (type === 'highlight') {
      renderedText.push(
        <i className="text-found">{text.slice(offset, offset + len)}</i>
      )
    } else if (type === 'footnote') {
      renderedText.push(getFootnote())
    } else {
      renderedText.push(<i className="text-found">{getFootnote()}</i>)
    }

    currentPosition = offset + len
  })

  const remainingText = text.slice(currentPosition)
  renderedText.push(remainingText)

  return (
    <span className="transcription_text">
      {renderedText.map((item, i) => (
        <React.Fragment key={i}>{item}</React.Fragment>
      ))}
    </span>
  )
})
