import type { AccountInfo } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'
import { loginRequest, msalConfig } from '../../components/custom/Auth/config'

const msalInstance = new PublicClientApplication(msalConfig)

export const getMinRefreshTokenTimeDiff = (expiresOn: Date | null): number => {
  const timeDiff = expiresOn ? expiresOn.getTime() - Date.now() : 1
  const timeout = timeDiff >= 1 ? timeDiff : 1
  return timeout
}

export const refreshToken = async (account?: AccountInfo) => {
  try {
    const authResult = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    })
    return authResult
  } catch (e: any) {
    window.console.error(`Error on get token: ${e.message}`)
    return null
  }
}

export default msalInstance
