import React, { useState } from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import { apiExportTranscriptionsData } from '../../../../api'
import type { ExportTranscriptionsParams } from '../../../../api/apiTypes'
import { EXPORT_FILE_EXT } from '../../../../constants'
import { EXPORT_FILE_FORMATS, PERMISSIONS } from '../../../../constants/enums'
import { useExportFile } from '../../../../hooks/useExportFile'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { usePageName } from '../../../../hooks/usePageName'
import { useAppDispatch } from '../../../../redux/store'
import { fetchMediaData } from '../../../../redux/thunk/mediaDataThunk'
import {
  fetchTranscriptionsBugs,
  fetchTranscriptionsData,
} from '../../../../redux/thunk/transcriptionsDataThunk'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { CopyMediaLink, ExportToWord, FullScreenButton } from '../../icons'
import { RefreshButton } from '../../icons/RefreshButton'
import PermissionsGate from '../../PermissionsGate'
import './index.scss'
import { LinkToUrl } from './LinkToUrl'

type Props = {
  id: string
  path: string
  placement: IpTooltipPropTypesReact['placement']
  currentUrl?: string
  openInNewTab?: boolean
}

export const PreviewAdditionMenu = ({
  id,
  path,
  placement,
  currentUrl,
  openInNewTab,
}: Props) => {
  const { handleExport } = useExportFile()
  const { isMainPage } = usePageName()
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { isMainResultsTab, isMainBugsTab } = useMainPageActiveTab()
  const [loading, setLoading] = useState(false)
  const [isDocxLoading, setIsDocxLoading] = React.useState(false)

  const exportHandler = async (
    fileType: ExportTranscriptionsParams['fileType']
  ) => {
    setIsDocxLoading(true)
    await handleExport({
      api: apiExportTranscriptionsData,
      params: {
        id,
        fileType,
      },
      fileName: 'transcriptions',
      fileExt: EXPORT_FILE_EXT[fileType],
    })
    setIsDocxLoading(false)
  }

  const to = generatePath(`/${path}/:id${search}`, { id })

  const refreshMedia = async () => {
    const resM: any = await dispatch(fetchMediaData(id))

    let resT: any

    if (isMainBugsTab) {
      resT = await dispatch(fetchTranscriptionsBugs(id))
    }
    if (isMainResultsTab) {
      resT = await dispatch(fetchTranscriptionsData(id))
    }

    if (resT.error || resM.error) {
      return {
        mediaError: resM.error.message,
        transcriptionError: resT.error.message,
      }
    }
    return undefined
  }

  const onClick = async () => {
    if (loading) return

    setLoading(true)

    const errors = await refreshMedia()

    if (errors) {
      const mediaError = errors.mediaError
        ? `Get media error: ${errors.mediaError}. `
        : ''
      const transcriptionError = errors.transcriptionError
        ? `Get transcriptions error: ${errors.transcriptionError}.`
        : ''
      const errorMessage = `Refresh error. ${mediaError}${transcriptionError} Media id: ${id}.`

      notification(errorMessage, 'error', true)
    } else {
      notification('Media data is updated', 'success')
    }

    setLoading(false)
  }

  return (
    <div className="preview_addition-menu">
      {isMainResultsTab && isMainPage && (
        <FullScreenButton
          placement={placement}
          to={to}
          openInNewTab={openInNewTab}
        />
      )}
      <CopyMediaLink placement={placement} id={id} path={path} />
      <RefreshButton
        placement={placement}
        onClick={onClick}
        loading={loading}
      />
      <PermissionsGate
        permissionKey={PERMISSIONS.export_reports_and_transcriptions}
      >
        <ExportToWord
          loading={isDocxLoading}
          placement={placement}
          onClick={() => exportHandler(EXPORT_FILE_FORMATS.DOCX)}
        />
      </PermissionsGate>
      {/* <ExportToExcel
        placement={placement}
        onClick={() => exportHandler(EXPORT_FILE_FORMATS.EXCEL)}
      /> */}
      {currentUrl && (
        <LinkToUrl placement={placement} currentUrl={currentUrl} />
      )}
    </div>
  )
}
