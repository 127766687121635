import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  id: string
  path: string
  placement: IpTooltipPropTypesReact['placement']
}

export const CopyMediaLink = ({ id, path, placement }: Props) => {
  const copyLinkToBuffer = () => {
    const link = `${process.env.REACT_APP_HOST}${path}/${id}`
    navigator.clipboard.writeText(link)
  }

  return (
    <IpTooltip
      arrow
      title="Copy link to buffer"
      placement={placement}
      content={
        <IpIcon
          onClick={copyLinkToBuffer}
          color="primary"
          classes="copy-media-link"
        >
          <ContentCopyIcon />
        </IpIcon>
      }
    />
  )
}
