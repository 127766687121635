import SyncIcon from '@mui/icons-material/Sync'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

import './index.scss'

type Props = {
  placement: IpTooltipPropTypesReact['placement']
  onClick: () => Promise<void>
  loading: boolean
}

export const RefreshButton = ({ placement, onClick, loading }: Props) => {
  return (
    <IpTooltip
      arrow
      title="Refresh media"
      placement={placement}
      content={
        <IpIcon
          color="primary"
          classes={`refresh-button ${loading ? 'animation' : ''}`}
          onClick={onClick}
        >
          <SyncIcon />
        </IpIcon>
      }
    />
  )
}
