import React from 'react'
import { Unstable_DateField as DateField } from '@mui/x-date-pickers/DateField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'

type Props = {
  label?: string
  value: Dayjs | null
  onChange: (newValue: Dayjs | null) => void
}

export const DateTime = ({ label, value, onChange }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateField
        className="date-time"
        variant="standard"
        label={label}
        value={value}
        onChange={onChange}
        format="HH:mm"
      />
    </LocalizationProvider>
  )
}
