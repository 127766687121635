import React from 'react'
import type { ReactNode, ElementType } from 'react'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import type { IpAvatarPropTypes } from '@infopulse-design-system/shared/types/IpAvatar.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import styles from '@infopulse-design-system/shared/theme/components/IpAvatar'
import { getRandomColor } from '../../../../utils'
import { IpBadge } from '../IpBadge'
import '@infopulse-design-system/shared/theme/components/IpAvatar/styles.scss'

export const IpAvatarTestId = 'IpAvatarTestId'

export type IpAvatarPropTypesReact = IpAvatarPropTypes<
  ElementType,
  { children?: ReactNode }
>

type IpAvatarItemPropTypesReact = IpAvatarPropTypesReact['avatarsData'][0]

const IpAvatarItemStyled = styled(Avatar)(
  ({ color }: IpAvatarItemPropTypesReact) => ({
    backgroundColor: color,
  })
)

const IpAvatarStyled = styled(AvatarGroup)(
  ({ sizes, space }: { sizes?: string; space?: string }) => ({
    '& .MuiAvatarGroup-avatar.MuiAvatar-root, & .MuiAvatar-root': {
      width: sizes,
      height: sizes,
      marginLeft: '0px',
    },
    '& .MuiAvatarGroup-avatar:not(:last-child)': {
      marginLeft: space || styles.avatar_margin_left,
    },
    '& .MuiAvatarGroup-avatar.MuiAvatar-root': {
      backgroundColor: getRandomColor(),
    },
  })
)

/**
 * `IpAvatar` is a simple and flexible element that helps you to display user profile pictures
 * or icons effortlessly. By simply configuring the avatarsData, the component generates
 * the avatar elements seamlessly. If no specific color is provided, a random color will
 * be assigned automatically. Additionally, the `IpAvatar` component offers various
 * customization options, such as the ability to add badges or set a maximum limit for
 * visible avatars. When the limit is reached, the remaining avatars will be represented
 * as a '+n' item, indicating the number of additional avatars hidden. With its flexible
 * features and easy configuration, the `IpAvatar` component provides an efficient and
 * visually appealing way to display user avatars.
 */
export function IpAvatar(props: IpAvatarPropTypesReact) {
  const {
    avatarsData,
    badgeHorizontalPosition,
    badgeVerticalPosition,
    classes,
    component,
    max,
    sizes,
    space,
    total,
    variant,
  } = props

  const simpleAvatar = (
    avatar: IpAvatarItemPropTypesReact,
    customClasses = formatClassName(
      generateClasses('avatar', 'react'),
      `${avatar.classes}`
    )
  ) => (
    <IpAvatarItemStyled
      alt={avatar.alt ?? avatar.name}
      color={avatar.color ? avatar.color : getRandomColor()}
      className={customClasses}
      component={avatar.component}
      src={avatar.src ?? '/'}
      srcSet={avatar.srcSet}
      variant={variant}
    >
      {avatar.children
        ? avatar.children
        : avatar.name && avatar.name.split(' ').length >= 2
        ? `${avatar.name.split(' ')[0][0]}${avatar.name.split(' ')[1][0]}`
        : avatar.name && `${avatar.name.split(' ')[0][0]}`}
    </IpAvatarItemStyled>
  )

  const generateAvatar = (avatar: IpAvatarItemPropTypesReact) => {
    return avatar.status ? (
      <IpBadge
        horizontalPosition={badgeHorizontalPosition}
        verticalPosition={badgeVerticalPosition}
        variant="dot"
        component="div"
        color={avatar.status}
      >
        {simpleAvatar(avatar)}
      </IpBadge>
    ) : (
      simpleAvatar(avatar)
    )
  }

  const customClasses = formatClassName(
    generateClasses('avatar-group', 'react'),
    classes,
    avatarsData.length === 1 ? 'IpAvatarGroup--only-child' : ''
  )

  return (
    <IpAvatarStyled
      data-testid={IpAvatarTestId}
      className={customClasses}
      component={component}
      max={max}
      sizes={sizes}
      space={space}
      total={total}
      variant={variant}
    >
      {avatarsData.map((avatar, index) => (
        <div key={index}>{generateAvatar(avatar)}</div>
      ))}
    </IpAvatarStyled>
  )
}
