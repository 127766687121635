import type { PermissionList, Role } from '../slices/profileSlice'

export enum DICTIONARY {
  authors = 'authors',
  citizenship = 'citizenship',
  media_type = 'media_type',
  networks = 'networks',
  persona_category = 'persona_category',
  programs = 'programs',
  signs_of_crimes = 'signs_of_crimes', // todo: make custom + update in filters
  sources = 'sources',
  sanctions = 'sanctions',
}

export enum DICTIONARY_ADDITION {
  personas = 'personas',
}

export enum DICTIONARY_CUSTOM {
  models = 'models',
  narratives = 'narratives',
  languages = 'content_languages',
  permissions = 'permissions',
  footnotes = 'footnotes',
  telegram_channels = 'telegram_channels',
}

export type DictionaryCustomName = keyof typeof DICTIONARY_CUSTOM

export type DictionaryName = keyof typeof DICTIONARY

export type DictionaryAdditionName = keyof typeof DICTIONARY_ADDITION

// dictionary with common structure on names: have name and name_en
export type DictionaryCommonName =
  | DictionaryName
  | DICTIONARY_ADDITION.personas
  | DICTIONARY_CUSTOM.telegram_channels

export type DictionaryContent = {
  id: string
  name: string
} & Record<`name${string}`, string> // is it possible null ? maybe bug in DB

export type DictionaryPersonaContent = DictionaryContent & {
  // title: string // todo: do we need title?
}

export type TelegramData = {
  about: string
  id: string
  participants_count: number
  username: string
} & Record<`name${string}`, string>

export type DictionaryFootnotesStringKeys =
  | 'meaning_en'
  | 'origin_en'
  | 'text'
  | 'id'
  | 'transliteration'

export type FootnoteData = {
  last_scan_ts: number
  is_deleted?: boolean
} & Record<DictionaryFootnotesStringKeys, string>

export type FootnoteCreateParams = Pick<
  FootnoteData,
  'meaning_en' | 'origin_en' | 'text' | 'transliteration'
>

export type FootnoteUpdateParams = Partial<
  Pick<FootnoteData, 'meaning_en' | 'origin_en' | 'text' | 'transliteration'>
>

export type NarrativeSubcategory = DictionaryContent & {
  description?: string
}

type NarrativesPayloadContent = {
  classes_id: string
  data: (DictionaryContent & {
    description?: string
    subcategories: NarrativeSubcategory[]
  })[]
}

export type ModelsPayloadContent = {
  data: (DictionaryContent & {
    monitoring: number
    classes_id?: string[]
  })[]
}

export enum LANGUAGE {
  attribute_suffix = 'attribute_suffix',
  id = 'id',
  name = 'name',
  short_name = 'short_name',
  locale = 'locale',
}

export type Language = Record<keyof typeof LANGUAGE, string>
export type LanguageSuffix = Language['attribute_suffix']

export type PermissionsPayloadContent = {
  data: {
    id: PermissionList
    roles: Role[]
  }[]
}

export type LanguagesPayloadContent = {
  data: Language[]
}

export type DictionaryCommonPayloadContent = {
  data: Array<DictionaryContent>
}

export type DictionaryFootnotesPayloadContent = {
  data: Array<FootnoteData>
}

export type DictionaryTelegramPayloadContent = {
  data: Array<TelegramData>
}

export type DictionariesPayloadData = Record<
  DictionaryName,
  DictionaryCommonPayloadContent
> & {
  [DICTIONARY_CUSTOM.narratives]: NarrativesPayloadContent
  [DICTIONARY_CUSTOM.models]: ModelsPayloadContent
  [DICTIONARY_CUSTOM.languages]: LanguagesPayloadContent
  [DICTIONARY_CUSTOM.permissions]: PermissionsPayloadContent
  [DICTIONARY_CUSTOM.telegram_channels]: DictionaryTelegramPayloadContent
  [DICTIONARY_CUSTOM.footnotes]: DictionaryFootnotesPayloadContent
}

export type DictionariesData = Record<DictionaryName, DictionaryContent[]> & {
  [DICTIONARY_ADDITION.personas]: DictionaryPersonaContent[]
  [DICTIONARY_CUSTOM.footnotes]: FootnoteData[]
  [DICTIONARY_CUSTOM.telegram_channels]: TelegramData[]
  [DICTIONARY_CUSTOM.narratives]: NarrativesPayloadContent['data']
  [DICTIONARY_CUSTOM.models]: ModelsPayloadContent['data']
  [DICTIONARY_CUSTOM.languages]: LanguagesPayloadContent['data']
}
