import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { MenuItem } from '@mui/material'
import React from 'react'
import { OrderDirection } from '../../../../constants/enums'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { MEDIA_SORT_MENU_NAME_CONFIG } from '../constants/sort-menu-config'
import type { SortMenuConfigItem } from '../types/sort-menu-config.type'

type Props = {
  orderBy: SortMenuConfigItem
  isDisabled: boolean
  isSelected: boolean
  isAscending: boolean
  onMenuItemChange: (
    orderBy: SortMenuConfigItem,
    orderDirection: keyof typeof OrderDirection
  ) => void
}
export const SortMenuItem = ({
  orderBy,
  isDisabled,
  isSelected,
  isAscending,
  onMenuItemChange,
}: Props) => {
  const onClick = (orderDirection: keyof typeof OrderDirection) => {
    onMenuItemChange(orderBy, orderDirection)
  }
  const name = MEDIA_SORT_MENU_NAME_CONFIG[orderBy]

  return (
    <MenuItem disabled={isDisabled} selected={isSelected}>
      <IpText color="primary">
        {name}
        <span>
          <IpIcon
            fontSize="18px"
            color={isSelected && isAscending ? 'primary' : undefined}
            onClick={() => onClick(OrderDirection.asc)}
          >
            <ArrowUpwardIcon />
          </IpIcon>
          <IpIcon
            fontSize="18px"
            color={isSelected && !isAscending ? 'primary' : undefined}
            onClick={() => onClick(OrderDirection.desc)}
          >
            <ArrowDownwardIcon />
          </IpIcon>
        </span>
      </IpText>
    </MenuItem>
  )
}
