import React, { useCallback } from 'react'
import { WEEK_SHORT_DAYS } from '../../../../../../constants'
import useFilters from '../../../../../../hooks/useFilters'
import { updateFilterArr } from '../../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../../redux/store'
import { MemoIpButton } from '../../../../ipDSMemo'

type WeekDayButtonPropTypes = {
  name: string
  index: number
}

const WeekDayButton = ({ name, index }: WeekDayButtonPropTypes) => {
  const dispatch = useAppDispatch()
  const { filterType, filters } = useFilters()
  const { weekdays = [] } = filters
  const variant = weekdays.includes(index.toString()) ? 'contained' : 'outlined'

  const onClickButtonHandler = useCallback(() => {
    dispatch(
      updateFilterArr({
        filterType,
        type: 'weekdays',
        value: index.toString(),
      })
    )
  }, [filterType, index])

  return (
    <MemoIpButton variant={variant} onClick={onClickButtonHandler}>
      {name}
    </MemoIpButton>
  )
}

type FilterWeekDaysPropTypes = {
  disableWeekdays?: boolean
}

export const FilterWeekDays = ({
  disableWeekdays,
}: FilterWeekDaysPropTypes) => {
  return disableWeekdays ? (
    <></>
  ) : (
    <div className="filter-period-btns">
      {WEEK_SHORT_DAYS.map((name, index) => {
        return <WeekDayButton key={name} name={name} index={index} />
      })}
    </div>
  )
}
