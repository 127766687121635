import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import React, { useEffect, useRef, useState } from 'react'
import { MainPageTabTypes, PERMISSIONS } from '../../../constants/enums'
import { useLanguage } from '../../../hooks/useLanguage'
import useMainPageActiveTab from '../../../hooks/useMainPageActiveTab'
import { usePageName } from '../../../hooks/usePageName'
import { useResizeObserverTopBottom } from '../../../hooks/useResizeObserverTopBottom'
import useResponsivePerform from '../../../hooks/useResponsivePerform'
import { useSimilarSpeakerList } from '../../../hooks/useSimilarSpeakerList'
import { useWindowInnerHeight } from '../../../hooks/useWindowInnerHeight'
import {
  currentMediaDataSelector,
  mediaListSelector,
  transcriptionsDataSelector,
} from '../../../redux/selectors'
import type { TranscriptionsDataState } from '../../../redux/slices/transcriptionsDataSlice'
import { useAppSelector } from '../../../redux/store'
import type { MainPageActiveTabs } from '../../../redux/types/appState'
import type {
  Bug,
  MediaData,
  MediaListItem,
  Transcription,
} from '../../../redux/types/media'
import PermissionsGate from '../PermissionsGate'
import { QuickCognigySearch } from '../QuickSearch/QuickCognigySearch'
import { QuickSearchCustom } from '../QuickSearch/QuickSearchCustom'
import { ROUTER_PATHS } from '../Router'
import { Annotation } from './Annotation'
import { useCurrentTrData } from './hooks/useCurrentTrData'
import { useTelegramGroup } from './hooks/useTelegramGroup'
import './index.scss'
import { MediaInfo } from './MediaInfo'
import { NextSpeakerButton } from './NextSpeakerButton'
import { PreviewAdditionMenu } from './PreviewAdditionMenu'
import { PreviewUserTags } from './PreviewUserTags'
import { TelegramNav } from './TelegramNav.tsx'
import { Title } from './Title'
import { TranscriptionList } from './TranscriptionList'
import { VideoPlayer } from './VideoPlayer'

const SCROLL_MARGIN_BOTTOM = 10

export type ActiveTrData = {
  tIndex: number
  setManualTime?: boolean
  needScroll?: boolean
  pause?: boolean
}

const getStartTIndex = (
  state: MediaListItem[],
  id: string,
  data: TranscriptionsDataState['data'],
  mainActiveTab: MainPageActiveTabs
) => {
  if (mainActiveTab === MainPageTabTypes.bugs) return 0

  const trId = state?.find((el) => el.id === id)?.transcription?.id
  const index = data[MainPageTabTypes.results][id]?.findIndex(
    (el) => el.id === trId
  )
  return index === -1 ? 0 : index
}

export type HighlightsData = Record<string, string[]>

export const PreviewPanel = () => {
  const { suffix } = useLanguage()
  const block1Ref = useRef<HTMLDivElement | null>(null)
  const { top, bottom } = useResizeObserverTopBottom(block1Ref.current)
  const { windowInnerHeight } = useWindowInnerHeight()
  const { isMore } = useResponsivePerform(breakpoints.values.md)
  const { isDetailsPage, isMainPage } = usePageName()
  const { mainActiveTab, isMainResultsTab } = useMainPageActiveTab()
  const {
    telegramGroupListLength,
    index,
    setIndex,
    nativeMediaId,
    telegramId,
    showTelegramNav,
    loading: telegramChanelLoading,
  } = useTelegramGroup()
  const mediaId = isMainResultsTab ? telegramId || nativeMediaId : nativeMediaId

  const isQuickCognigySearch =
    (isMainPage || isDetailsPage) && isMainResultsTab && mediaId
  const currentMediaData = useAppSelector((state) =>
    currentMediaDataSelector(state, mediaId)
  )
  const { data: trsData } = useAppSelector(transcriptionsDataSelector)
  const { storeData: similarSpeakerMediaList, isSimilarSpeakerPage } =
    useSimilarSpeakerList()
  const mediaList = useAppSelector(mediaListSelector)
  const mediaState = (
    isSimilarSpeakerPage ? similarSpeakerMediaList : mediaList
  ).data.data
  const [trScrollHeight, setTrScrollHeight] = useState(0)
  const [activeTrData, setActiveTrData] = useState<ActiveTrData>({
    tIndex: getStartTIndex(mediaState, mediaId, trsData, mainActiveTab),
    setManualTime: true,
    needScroll: true,
  })
  const [highlightsData, setHighlightsData] = useState<HighlightsData>({})

  const {
    isCurrentTrData,
    currentTrData,
    transcription: currentTr,
  } = useCurrentTrData(mediaId, activeTrData.tIndex)

  useEffect(() => {
    setActiveTrData({
      tIndex: getStartTIndex(mediaState, mediaId, trsData, mainActiveTab),
      setManualTime: true,
      needScroll: true,
    })
  }, [mediaId])

  useEffect(() => {
    if (!block1Ref.current) return

    if (!isDetailsPage && bottom) {
      setTrScrollHeight(windowInnerHeight - bottom)
    }
    if (isDetailsPage && isMore && top) {
      setTrScrollHeight(windowInnerHeight - top)
    }
    if (isDetailsPage && !isMore && bottom) {
      setTrScrollHeight(windowInnerHeight - bottom)
    }
  }, [windowInnerHeight, top, bottom, isMore])

  if (telegramChanelLoading) {
    return <>Loading...</>
  }

  if (!(mediaId && currentMediaData)) {
    return <></>
  }

  const mediaInfo = (
    <div className="media-info">
      <Title
        title={
          currentMediaData[`episode_title${suffix}` as const] ??
          currentMediaData[`title${suffix}` as const] ??
          ''
        }
      />
      <PermissionsGate permissionKey={PERMISSIONS.user_tags}>
        <PreviewUserTags
          mediaId={mediaId}
          userTags={currentMediaData.userTags}
        />
      </PermissionsGate>

      <Annotation
        content={
          (currentMediaData as MediaData)[`annotation${suffix}` as const]
        }
      />
    </div>
  )

  const block1Styles =
    isDetailsPage && isMore
      ? {
          style: { height: `${trScrollHeight - SCROLL_MARGIN_BOTTOM}px` },
        }
      : {}

  return (
    <>
      {showTelegramNav && (
        <TelegramNav
          index={index}
          setIndex={setIndex}
          length={telegramGroupListLength}
        />
      )}
      {isMainResultsTab && (
        <NextSpeakerButton
          activeTrData={activeTrData}
          setActiveTrData={setActiveTrData}
          currentTrData={currentTrData}
        />
      )}
      {isQuickCognigySearch ? (
        <QuickCognigySearch
          id={mediaId}
          highlightsData={highlightsData}
          setHighlightsData={setHighlightsData}
        />
      ) : (
        <QuickSearchCustom id={mediaId} targetSelector=".transcription_text" />
      )}
      <div className="top-line">
        <PreviewAdditionMenu
          id={mediaId}
          path={ROUTER_PATHS.details}
          currentUrl={currentMediaData?.page_url}
          placement={isDetailsPage && isMore ? 'bottom' : 'left'}
          openInNewTab={Boolean(telegramId && index > 0)}
        />
        <MediaInfo
          airDate={currentMediaData.air_date}
          programId={currentMediaData.program_id?.toString()}
          authorId={currentMediaData.author_id}
          sourceId={currentMediaData.source_id}
          telegram_channel_id={currentMediaData.telegram_channel_id}
        />
      </div>
      <div className="content-wrapper">
        <div className="block-1" ref={block1Ref} {...block1Styles}>
          <VideoPlayer
            activeTr={currentTr}
            activeTrData={activeTrData}
            setActiveTrData={setActiveTrData}
            url={currentMediaData?.saved_file_link}
            currentTrData={currentTrData}
          />
          {isDetailsPage && isMore && mediaInfo}
        </div>
        <div
          className="block-2"
          style={{ height: `${trScrollHeight - SCROLL_MARGIN_BOTTOM}px` }}
        >
          {(!isDetailsPage || (isDetailsPage && !isMore)) && mediaInfo}
          {isCurrentTrData && (
            <TranscriptionList
              activeTrData={activeTrData}
              setActiveTrData={setActiveTrData}
              highlightsData={highlightsData}
              currentTrData={currentTrData as (Transcription | Bug)[]}
              transcription={currentTr as Transcription | Bug}
              currentMediaData={currentMediaData}
            />
          )}
        </div>
      </div>
    </>
  )
}
