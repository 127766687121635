import type { Theme } from '@mui/material'
import list from '@infopulse-design-system/shared/theme/components/IpList'
import listItem from '@infopulse-design-system/shared/theme/components/IpList/IpListItem'
import icon from '@infopulse-design-system/shared/theme/components/IpList/IpListItemIcon'
import menuList from '@infopulse-design-system/shared/theme/components/IpMenuList'
import menuHorizontal from '@infopulse-design-system/shared/theme/components/IpMenuHorizontal'

export default (theme: Theme) => {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          boxShadow: 'none',
          '& .MuiList-root, & .MuiCollapse-root': {
            boxShadow: 'none',
            width: '100%',
          },
          '& .MuiList-root > .MuiListItemButton-root:not(.IpListItem-disablePadding)':
            {
              paddingLeft: list.list_second_level_padding_left,
            },
          '& .MuiList-root .MuiList-root > .MuiListItemButton-root:not(.IpListItem-disablePadding)':
            {
              paddingLeft: list.list_third_level_padding_left,
            },
          '&.MuiList-root > li > .MuiDivider-inset': {
            marginLeft: `calc(${icon.list_item_icon_min_width} + ${listItem.list_item_padding_left_right})`,
          },
          '& .IpList-endIcon': {
            display: 'flex',
            transitionDuration: '150ms',
          },
          '&.IpMenuHorizontal, &.IpMenuVerticalList, &.IpMenuList': {
            '& .IpList-endIcon': {
              transitionDuration: menuList.menu_list_icon_transition_duration,
            },
            '& .MuiMenuItem-root.Mui-focusVisible': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&.IpMenuHorizontal': {
            display: 'flex',
            width: 'inherit',
            '& .MuiDivider-root': {
              borderColor: menuHorizontal.menu_horizontal_divider_border_color,
            },

            '& > .MuiMenuItem-root': {
              position: 'relative',
              alignContent: 'center',
              minHeight: menuHorizontal.menu_horizontal_item_min_height,
              padding: `${menuHorizontal.menu_horizontal_item_padding_vertical} ${menuHorizontal.menu_horizontal_item_padding_horizontal}`,
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.default,
              '&:first-child:not(style)': {
                marginLeft: 0,
              },
              '&:last-child': {
                marginRight: 0,
              },
              '&.open > .IpList-endIcon': {
                transform: 'rotate(180deg)',
              },
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '& > .MuiListItemText-root > .MuiTypography-root': {
                font: menuHorizontal.menu_horizontal_font,
                color: 'inherit',
              },
              '& > .MuiListItemAvatar-root': {
                minWidth: 0,
              },
              '& > .MuiListItemAvatar-root + .MuiListItemText-root': {
                margin: menuHorizontal.menu_horizontal_item_text_margin,
              },
              '& > .MuiListItemText-root + .IpList-endIcon': {
                margin: menuHorizontal.menu_horizontal_end_icon_margin,
              },
            },

            '& [data-popper-placement="top-start"]': {
              paddingBottom:
                menuHorizontal.menu_horizontal_submenu_indent_vertical,
            },
            '& [data-popper-placement="bottom-start"]': {
              paddingTop:
                menuHorizontal.menu_horizontal_submenu_indent_vertical,
            },
          },
          '&.IpMenuVerticalList, &.IpMenuList': {
            backgroundColor: theme.palette.background.default,
            '&.MuiList-dense .MuiMenuItem-root:not(.IpMenuItem-subMenu)': {
              paddingTop: `calc(${menuList.menu_list_item_padding_vertical} / 2)`,
              paddingBottom: `calc(${menuList.menu_list_item_padding_vertical} / 2)`,
              minHeight: `calc(${menuList.menu_list_item_min_height} - ${menuList.menu_list_item_padding_vertical} * 2 )`,
            },
            '& .MuiMenuItem-root': {
              width: '100%',
              minHeight: menuList.menu_list_item_min_height,
              padding: `${menuList.menu_list_item_padding_vertical} ${menuList.menu_list_item_padding_horizontal}`,
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.default,
              borderBottomColor: menuList.menu_list_divider_border_color,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '&.open > .IpList-endIcon': {
                transform: 'rotate(-90deg)',
              },
              '& > .MuiListItemAvatar-root': {
                minWidth: menuList.menu_list_item_icon_min_width,
                '& > *': {
                  margin: menuList.menu_list_item_avatar_margin,
                },
              },
              '& .MuiListItemText-root': {
                '&.MuiListItemText-inset': {
                  paddingLeft: menuList.menu_list_item_icon_min_width,
                },
                '& > .MuiTypography-root': {
                  font: menuList.menu_list_font,
                  color: 'inherit',
                },
              },
            },
            '& .MuiCollapse-wrapper .MuiMenuItem-root:not(.IpMenuItem-subMenu)':
              {
                paddingLeft: menuList.menu_list_second_level_padding_left,
              },
            '& .MuiCollapse-wrapper .MuiCollapse-wrapper .MuiMenuItem-root:not(.IpMenuItem-subMenu)':
              {
                paddingLeft: menuList.menu_list_third_level_padding_left,
              },
            '& .IpMenuItem-subMenu': {
              padding: 0,
              margin: 0,
              minHeight: 0,
              backgroundColor: 'transparent',
              '& > .MuiTouchRipple-root': {
                color: 'transparent',
              },
              '& .MuiList-root': {
                width: '100%',
              },
            },
          },
          '&.IpMenuVerticalList': {
            display: 'block',
            overflowY: 'auto',
            width: 'fit-content',
            '& .MuiMenuItem-root.open > .IpList-endIcon': {
              transform: 'rotate(-90deg)',
            },
          },
          '&.IpMenuList': {
            '.IpTableFilter-text-wrapper, .IpTableFilter-range-wrapper, .IpTableFilter-checkbox-wrapper':
              {
                paddingLeft: `${menuList.menu_list_item_padding_vertical} !important`,
              },
            '.IpTableFilter-checkbox-wrapper': {
              padding: '0',
              '.MuiListItemText-root >.MuiTypography-root, .MuiFormControlLabel-root':
                {
                  height: '100%',
                  width: '100%',
                },
            },
            '.IpTableFilter-range-header': {
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            },
            '.IpTableFilter-text-wrapper': {
              paddingLeft: `${menuList.menu_list_item_padding_vertical} !important`,
            },
            '& .MuiMenuItem-root.open > .IpList-endIcon': {
              transform: 'rotate(180deg)',
            },
          },
        },
      },
    },
  }
}
