import type { RootState } from '../store'

export const profileSelector = (state: RootState) => state.profile

export const isAuthSelector = (state: RootState) =>
  Boolean(state.profile.data.name)

export const userTagsSelector = (state: RootState) =>
  state.profile.data.userTags

export const permissionsSelector = (state: RootState) =>
  state.profile.data.permissions

export const rolesSelector = (state: RootState) => state.profile.data.roles

export const profileNameSelector = (state: RootState) => state.profile.data.name

export const queryTypeSelector = (state: RootState) =>
  state.profile.data.queryType
