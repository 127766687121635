import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpAccordion'
import shadows from '@infopulse-design-system/shared/theme/shadows'

export default (theme: Theme) => {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            boxShadow: shadows.accordion_box_shadow,
            '&:not(.MuiAccordion-root:last-of-type)': {
              borderBottom: `1px solid ${theme.palette.grey[500]}`,
            },
            '& .MuiButtonBase-root.Mui-focusVisible': {
              backgroundColor: `${theme.palette.grey[500]}50`,
            },
          },
          '&.MuiAccordion-root.Mui-expanded ': {
            margin: styles.accordion_expanded_margin,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[500],
          },
          '& .MuiButtonBase-root.Mui-disabled': {
            opacity: 1,
            '& .MuiAccordionSummary-content .MuiTypography-root:first-of-type':
              { color: theme.palette.grey[900] },
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '&::before, &.Mui-expanded::before': {
            opacity: 0,
          },
          '& .MuiAccordionSummary-root': {
            minHeight: styles.accordion_summary_min_height,
          },
          '& .MuiAccordionSummary-content .MuiTypography-root': {
            fontSize: styles.accordion_summary_font_size,
          },
          '& .MuiAccordionSummary-content .MuiTypography-root:first-of-type': {
            width: styles.accordion_summary_width,
            color: theme.palette.text.primary,
          },
          '& .MuiAccordionSummary-content .MuiTypography-root:last-of-type': {
            color: theme.palette.grey[900],
          },
          '& .MuiAccordionDetails-root .MuiTypography-root': {
            fontSize: styles.accordion_details_font_size,
            color: theme.palette.text.primary,
          },
        },
      },
    },
  }
}
