import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { usePersonaList } from '../../../../hooks/usePersonaList'
import { isActivePersonaByIdSelector } from '../../../../redux/selectors'
import { setActivePersonaId } from '../../../../redux/slices/appStateSlice'
import { resetPersonaDataError } from '../../../../redux/slices/personaDataSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IpChip } from '../../../ipDesignSystemComponents/atoms/IpChip'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { MemoAvatar } from '../../ipDSMemo'
import { ROUTER_PATHS } from '../../Router'

import './index.scss'

type PersonaResultItemProps = {
  index: number
  isLessLg: boolean
}

type PersonaItemContentMemoProps = {
  index: number
}

const PersonaItemContentMemo = memo(function PersonaItemContent({
  index,
}: PersonaItemContentMemoProps) {
  const {
    name,
    title,
    photo_url,
    categories_id,
    sanctions_list_id,
    create_date,
  } = usePersonaList(index)

  const dictionaryData = useDictionaryData({ categories_id, sanctions_list_id })

  return (
    <>
      <div className="top-line">
        <div className="top-line_chip-wrapper">
          <IpChip
            label={name}
            classes="top-line_chip"
            color="primary"
            size="extraSmall"
          />
        </div>
      </div>
      <div className="persona-info">
        <MemoAvatar
          sizes="78px"
          photo={photo_url}
          classes="persona-info_avatar"
        />
        <ul>
          <IpText variant="body2" classes="medium" component="li">
            <span className="primary-main semi-bold">Title:</span>
            {title}
          </IpText>
          {dictionaryData?.categories && (
            <IpText variant="body2" classes="medium" component="li">
              <span className="primary-main semi-bold">Categories:</span>
              {dictionaryData?.categories}
            </IpText>
          )}
          {dictionaryData.sanctions && (
            <IpText variant="body2" classes="medium" component="li">
              <span className="primary-main semi-bold">Sanctions list:</span>
              {dictionaryData.sanctions}
            </IpText>
          )}
          <IpText variant="body2" classes="medium" component="li">
            <span className="primary-main semi-bold">Create date:</span>
            {create_date}
          </IpText>
        </ul>
      </div>
    </>
  )
})

export const PersonaResultItem = ({
  index,
  isLessLg,
}: PersonaResultItemProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isPersonaListData: isPersonaData, id } = usePersonaList(index)

  if (!isPersonaData) return <></>

  const isActive = useAppSelector((state) =>
    isActivePersonaByIdSelector(state, id)
  )

  const onClickHandler = () => {
    if (!isActive) {
      dispatch(resetPersonaDataError())
      dispatch(setActivePersonaId([id, index]))
    }

    if (isLessLg) {
      navigate(`/${ROUTER_PATHS.persona}/${id}`)
    }
  }

  return (
    <div
      className={`search-result-item person-result-item ${
        isActive ? 'active' : ''
      } `}
      onClick={onClickHandler}
    >
      <PersonaItemContentMemo index={index} />
    </div>
  )
}
