import React from 'react'
import type { ReactNode, MouseEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import type { IpIconPropTypes } from '@infopulse-design-system/shared/types/IpIcon.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import styles from '@infopulse-design-system/shared/theme/components/IpIcon'
import '@infopulse-design-system/shared/theme/components/IpIcon/styles.scss'

export const IpIconTestId = 'IpIconTestId'

export type IpIconPropTypesReact = IpIconPropTypes<MouseEvent<HTMLElement>> & {
  children: ReactNode
}

type IpIconStyledPropTypesReact = IpIconPropTypesReact & {
  disableRipple: boolean
  minwidth?: string
  minheight?: string
  component: 'button' | 'span'
}

const IpIconStyled = styled(IconButton)(
  ({
    fontSize,
    height,
    minheight,
    minwidth,
    width,
  }: IpIconStyledPropTypesReact) => {
    const fontSizeValue = fontSize || styles.icon_size
    return {
      '&.MuiButtonBase-root': {
        padding: 0,
        width: width || fontSizeValue,
        height: height || fontSizeValue,
        minWidth: minwidth || fontSizeValue,
        minHeight: minheight || fontSizeValue,
        fontSize: fontSizeValue,
      },
      '& .MuiSvgIcon-root': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    }
  }
)

/**
 * `IpIcon` component acts as a wrapper for MUI icons, providing the ability to customize their
 * appearance to align with your requirements. It offers a convenient way to define and style
 * icons within your application. With the `IpIcon` component, you have the flexibility to specify
 * properties like size, color, onClick handler, and others, enabling you to seamlessly integrate
 * and enhance the icons within your user interface.
 */
export function IpIcon(props: IpIconPropTypesReact) {
  const {
    ariaLabel,
    children,
    classes,
    color,
    disabled,
    fontSize,
    height,
    minHeight,
    minWidth,
    onClick,
    width,
  } = props

  const customClasses = formatClassName(
    generateClasses('icon-button', 'react'),
    classes
  )

  return (
    <IpIconStyled
      aria-label={ariaLabel}
      component={onClick ? 'button' : 'span'}
      className={customClasses}
      color={color}
      data-testid={IpIconTestId}
      disableRipple={!onClick}
      disabled={disabled}
      fontSize={fontSize}
      height={height}
      minheight={minHeight}
      minwidth={minWidth}
      onClick={onClick}
      role={onClick ? 'button' : 'img'}
      width={width}
    >
      {children}
    </IpIconStyled>
  )
}
