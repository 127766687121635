import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import * as yup from 'yup'
import { apiSetNewPerson } from '../../../../../api'
import type { AddNewPersonParamsType } from '../../../../../api/apiTypes'
import { useHoveredNotification } from '../../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../redux/selectors'
import { addPerson } from '../../../../../redux/slices/dictionariesSlice'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { fetchPersonas } from '../../../../../redux/thunk/dictionariesThank'
import type { IpButtonPropTypesReact } from '../../../../ipDesignSystemComponents/atoms/IpButton/index.types'
import type { IpFormPropTypesReact } from '../../../../ipDesignSystemComponents/atoms/IpForm'
import { IpForm } from '../../../../ipDesignSystemComponents/atoms/IpForm'
import './index.scss'

const submitButton: IpButtonPropTypesReact = {
  variant: 'contained',
  children: 'Add',
  size: 'small',
}

type AddSpeakerFormProps = {
  onSubmit: (personId: string) => Promise<void>
  setIsUpdateLoading: Dispatch<SetStateAction<boolean>>
  onError: () => void
}

export const AddSpeakerForm = ({
  onSubmit,
  setIsUpdateLoading: setIsUpdatingSpeaker,
  onError,
}: AddSpeakerFormProps) => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { suffix, locale } = useLanguage()
  const dictionary = useAppSelector(dictionariesSelector)

  const submitHandler = async (params: AddNewPersonParamsType) => {
    setIsUpdatingSpeaker(true)
    try {
      const data = await apiSetNewPerson(params)
      await dispatch(addPerson({ data, locale, suffix }))
      notification('Speaker was added', 'success')
      onSubmit(data?.id)
    } catch (e: any) {
      notification(
        `Error on speaker creation. Errors: ${e.message}; ${e.response?.data?.message}`,
        'error',
        true
      )
      dispatch(fetchPersonas({ locale, suffix }))
      onError()
    }
  }

  const formData: IpFormPropTypesReact['formElements'] = [
    {
      name: 'name',
      defaultValue: '',
      elementType: 'input',
      elementProps: {
        placeholder: 'Add name of Speaker',
        fullWidth: true,
        variant: 'standard',
        autoFocus: true,
      },
      yupValidation: yup
        .string()
        .required('No name provided.')
        .max(150, 'Is more then 150 symbols.'),
    },
    {
      name: 'title',
      defaultValue: '',
      elementType: 'input',
      elementProps: {
        placeholder: 'Add Title of Speaker',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup
        .string()
        .required('No title provided.')
        .max(1500, 'Is more then 1500 symbols.'),
    },
    {
      name: 'categories_id',
      defaultValue: [],
      elementType: 'select',
      elementProps: {
        multiple: true,
        label: 'Add categories',
        variant: 'standard',
        options:
          dictionary.data.persona_category?.map((item) => ({
            value: item.id,
            title: item[`name${suffix}`] ?? '',
          })) || [],
      },
    },
  ]

  return (
    <IpForm
      formElements={formData}
      submitHandler={submitHandler}
      classes="speaker-form"
      submitElementProps={submitButton}
    />
  )
}
