import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { useSearchFilter } from '../../../../../hooks/useSearchFilter'
import type { SEARCH_STRING_FILTERS } from '../../../../../redux/types/filters'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpTextField } from '../../../../ipDesignSystemComponents/atoms/IpTextField'

const endAdornment = (
  <IpIcon
    classes="filter-search-icon"
    ariaLabel="Filter search icon"
    color="primary"
    fontSize="18px"
  >
    <SearchIcon />
  </IpIcon>
)

export type FilterSearchProps = {
  variant: SEARCH_STRING_FILTERS.program_title | SEARCH_STRING_FILTERS.title
}

export const FilterSearch = ({ variant }: FilterSearchProps) => {
  const { error, value, onChange } = useSearchFilter(variant)

  return (
    <div>
      <IpTextField
        size="small"
        classes="filter-search-input"
        value={value}
        variant="standard"
        onChange={onChange}
        error={!!error}
        helperText={error}
        endAdornment={endAdornment}
      />
    </div>
  )
}
