/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { MediaListResponse } from '../../api/apiTypes'
import { fetchMediaList, fetchMediaListNext } from '../thunk/mediaListThunk'
import type { ApiData } from '../types'

export type MediaListState = ApiData<MediaListResponse>

export const initialMediaListState: MediaListState = {
  data: { data: [], hasMoreResults: false },
  isLoading: false,
  error: '',
}

export const MediaListSlice = createSlice({
  name: 'mediaList',
  initialState: initialMediaListState,
  reducers: {
    setMediaList: (state, { payload }: PayloadAction<MediaListResponse>) => {
      state.data = payload
    },
    setMediaListNext: (
      state,
      { payload }: PayloadAction<MediaListResponse>
    ) => {
      state.data.data = [...state.data.data, ...payload.data]
      state.data.hasMoreResults = payload.hasMoreResults
      state.data.totalMedias = payload.totalMedias
    },
    updatePersonaInMediaListTranscription: (
      state,
      { payload }: PayloadAction<{ id: string; persona_id: string }>
    ) => {
      const searchData = state.data.data.find((el) => el.id === payload.id)
      if (searchData) {
        searchData.transcription.persona_id = payload.persona_id
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchMediaList.fulfilled, (state) => {
      state.isLoading = false
      state.error = ''
    })
    builder.addCase(fetchMediaList.rejected, (state, action) => {
      if (action.payload !== 'canceled') {
        state.isLoading = false
        state.error = action.payload as string
      }
    })
    builder.addCase(fetchMediaListNext.pending, (state) => {
      state.isLoading = true
      state.error = ''
    })
    builder.addCase(fetchMediaListNext.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchMediaListNext.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  setMediaList,
  setMediaListNext,
  updatePersonaInMediaListTranscription,
} = MediaListSlice.actions

export default MediaListSlice.reducer
