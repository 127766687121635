import type { Theme } from '@mui/material'
import '@infopulse-design-system/shared/theme/colors.scss'
import styles from '@infopulse-design-system/shared/theme/components/IpTable'

export default (theme: Theme) => {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            color: theme.palette.text.primary,
          },
          '.MuiTableRow-root': {
            height: 'auto',
          },
          '.MuiTableCell-root': {
            color: theme.palette.text.primary,
            '.MuiTableSortLabel-iconDirectionAsc, .MuiTableSortLabel-iconDirectionDesc':
              {
                fill: theme.palette.grey[900],
              },
            '&.pinned-column': {
              '&::after': {
                content: '""',
                width: styles.table_pin_icon_size,
                height: styles.table_pin_icon_size,
                position: 'absolute',
                right: styles.table_pin_icon_right,
                top: styles.table_pin_icon_top,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              },
            },
          },
        },
      },
    },
  }
}
