import type { IpTypographyVariantTypes } from '@infopulse-design-system/shared/types/IpTypography.types'
import type { Theme } from '@mui/material'

const TypographyVariants: IpTypographyVariantTypes[] = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'subtitle3',
  'body1',
  'body2',
  'body3',
  'body4',
  'button',
]

export default (theme: Theme) => {
  const getFontStyles = () => {
    return TypographyVariants.reduce((acc, variant) => {
      // @ts-ignore
      if (theme.typography[variant]) {
        const { fontFamily, fontSize, fontWeight, lineHeight } =
          // @ts-ignore
          theme.typography[variant]
        return {
          ...acc,
          [`& .MuiTypography-${variant}`]: {
            fontFamily,
            fontSize,
            fontWeight,
            lineHeight,
          },
        }
      }
      return { ...acc }
    }, {})
  }
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          ...getFontStyles(),
        },
      },
    },
  }
}
