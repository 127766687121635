import { useEffect, useState } from 'react'
import { personaDataSelector, profileSelector } from '../redux/selectors'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { fetchPersonaMediasData } from '../redux/thunk/personaDataThunk'
import type { PersonaItemData } from '../redux/types/personas'
import { useHoveredNotification } from './useHoveredNotification'

type MediasState = {
  isMediasLoading: boolean
  mediasError: string
  uploaded: boolean
}

export const usePersonaMediasData = (id: string) => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { name } = useAppSelector(profileSelector).data
  const { data } = useAppSelector(personaDataSelector)
  const personaData: PersonaItemData | undefined = data[id]
  const medias = personaData?.medias || []
  const isMedias = !!personaData?.medias

  const startMediasState: MediasState = {
    isMediasLoading: false,
    mediasError: '',
    uploaded: isMedias,
  }

  const [mediasState, setMediasState] = useState<MediasState>(startMediasState)
  const { isMediasLoading, mediasError, uploaded } = mediasState

  useEffect(() => {
    setMediasState(startMediasState)
  }, [id])

  useEffect(() => {
    if (
      name &&
      id &&
      personaData &&
      !isMedias &&
      !isMediasLoading &&
      !mediasError
    ) {
      const fetchData = async () => {
        setMediasState((state) => ({ ...state, isMediasLoading: true }))
        const res: any = await dispatch(fetchPersonaMediasData(id))
        if (res.error) {
          const errorMessage = `Error: ${res.payload}. Error message:  ${res?.error?.message}`
          notification(errorMessage, 'error', true)
          setMediasState({
            mediasError: errorMessage,
            isMediasLoading: false,
            uploaded: true,
          })
        } else {
          setMediasState({
            mediasError: '',
            isMediasLoading: false,
            uploaded: true,
          })
        }
      }
      fetchData()
    }
  }, [name, id, personaData, mediasState])

  return {
    isMedias,
    isMediasLoading,
    mediasError,
    uploaded,
    medias,
  }
}
