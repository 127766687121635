import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PreviewPanel } from '../../components/custom/PreviewPanel'
import { IpButton } from '../../components/ipDesignSystemComponents/atoms/IpButton'
import { IpProgressBar } from '../../components/ipDesignSystemComponents/atoms/IpProgressBar'
import { MainPageTabTypes } from '../../constants/enums'
import { useActiveMedia } from '../../hooks/useActiveMedia'
import { useFetchStartPreviewData } from '../../hooks/useFetchStartPreviewData'
import useMainPageActiveTab from '../../hooks/useMainPageActiveTab'
import { useSimilarSpeakerList } from '../../hooks/useSimilarSpeakerList'
import {
  currentMediaDataSelector,
  mediaListSelector,
  profileSelector,
} from '../../redux/selectors'
import { mediaWithBugsSelector } from '../../redux/selectors/bugs'
import {
  setActiveMedia,
  setMainActiveTab,
} from '../../redux/slices/appStateSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'

function Details() {
  const { id = '' } = useParams()
  const { search } = useLocation()
  const { resultsCount: similarSpeakerListCount } = useSimilarSpeakerList()
  const { mediaId = '' } = useActiveMedia()
  const dispatch = useAppDispatch()
  const { name } = useAppSelector(profileSelector).data
  const mediaList = useAppSelector(mediaListSelector).data.data
  const bugs = useAppSelector(mediaWithBugsSelector).data.data
  const currentMediaData = useAppSelector((state) =>
    currentMediaDataSelector(state, id)
  )
  const { mainActiveTab, isMainBugsTab } = useMainPageActiveTab()
  const {
    fetchStartPreviewData,
    startPreviewDataIsLoading,
    isStartPreviewDataUploaded,
  } = useFetchStartPreviewData(id)

  const shouldGoToSimilarSpeakerList = Boolean(
    search && similarSpeakerListCount
  )

  const findMediaItemIndexInMediaList = () => {
    const list = isMainBugsTab ? bugs : mediaList
    return list?.findIndex((el) => el.id === id)
  }

  const currentIndex = findMediaItemIndexInMediaList()
  const isCurrentIndex = currentIndex >= 0

  const onClick = () => {
    if (!isCurrentIndex && isMainBugsTab) {
      dispatch(setMainActiveTab(MainPageTabTypes.results))
    }
  }

  useEffect(() => {
    if (!name) return

    fetchStartPreviewData()
  }, [name, currentMediaData])

  useEffect(() => {
    if (mediaId !== id)
      dispatch(
        setActiveMedia({
          mainActiveTab,
          mediaIndex: currentIndex,
          mediaId: id,
        })
      )
  }, [mediaId])

  const buttonText =
    isCurrentIndex || shouldGoToSimilarSpeakerList ? 'Go back' : 'Go home'

  return (
    <div className="page details-preview">
      <IpButton
        disabled={startPreviewDataIsLoading}
        variant="text"
        classes="btn_go-back"
        to={`/${shouldGoToSimilarSpeakerList ? search : ''}`}
        onClick={onClick}
      >
        <ArrowBackIcon /> {buttonText}
      </IpButton>
      {startPreviewDataIsLoading && <IpProgressBar />}
      {isStartPreviewDataUploaded && <PreviewPanel />}
    </div>
  )
}

export default Details
