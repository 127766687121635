import type { ExportTranscriptionsParams } from '../../../../api'
import { EXPORT_FILE_FORMATS } from '../../../../constants/enums'
import { formatSecondsToTime } from '../../../../utils'

export function getDuration(
  amount: number,
  type: ExportTranscriptionsParams['fileType']
): string {
  let newTime = 0

  if (type === EXPORT_FILE_FORMATS.EXCEL) {
    if (amount <= 1000) {
      newTime = 20
    } else if (amount <= 2000) {
      newTime = 30
    } else if (amount <= 5000) {
      newTime = 60
    } else if (amount <= 10000) {
      newTime = 120
    } else {
      newTime = Math.ceil(amount / 5000) * 45
    }
  } else {
    newTime = Math.ceil((amount / 100) * 60)
  }

  return formatSecondsToTime(newTime)
}

export function getSize(
  amount: number,
  type: ExportTranscriptionsParams['fileType']
) {
  let size = 0

  if (type === EXPORT_FILE_FORMATS.EXCEL) {
    size = amount / 1000
  } else {
    size = amount * 0.02
  }

  return size
}
