import type { Theme } from '@mui/material'
import buttonStyles from '@infopulse-design-system/shared/theme/components/IpButton'

export default (theme: Theme) => {
  return {
    MuiButtonGroup: {
      variants: [
        {
          props: { size: 'extraSmall' },
        },
      ],
      styleOverrides: {
        root: {
          '&.MuiButtonGroup-outlined, &.MuiButtonGroup-contained': {
            boxShadow: 'none',
          },
          '&.MuiButtonGroup-root .MuiButton-text': {
            borderRightColor: 'inherit',
            borderBottomColor: 'inherit',
            borderRadius: '0px',
          },
          '&.MuiButtonGroup-outlined.MuiButtonGroup-root:not(.IpButtonGroup--offset) > .MuiButton-root':
            {
              marginTop: 0,
              marginLeft: 0,
            },
          '&.MuiButtonGroup-outlined:not(.IpButtonGroup--offset):not(.MuiButtonGroup-vertical) > :not(:first-of-type)':
            { borderLeft: '0px' },
          '&.MuiButtonGroup-outlined:not(.IpButtonGroup--offset):not(.MuiButtonGroup-vertical) > :not(:first-of-type) .button-content':
            {
              paddingLeft: '0.5px',
              paddingRight: '0.5px',
            },
          '&.MuiButtonGroup-outlined:not(.IpButtonGroup--offset).MuiButtonGroup-vertical > :not(:first-of-type)':
            { borderTop: '0px' },
          '&.MuiButtonGroup-contained:not(.IpButtonGroup--offset) > *': {
            borderRight: '0px',
            borderBottom: '0px',
          },
          '&.IpButtonGroup--offset .MuiButton-root': {
            margin: 0,
            borderRightColor: 'inherit',
            borderBottomColor: 'inherit',
          },
          '&.IpButtonGroup--offset .MuiButton-outlined, &.IpButtonGroup--offset .MuiButton-contained':
            {
              borderRightColor: 'inherit',
              borderRadius: buttonStyles.button_border_radius,
            },
          '&.IpButtonGroup--offset.MuiButtonGroup-contained .MuiButton-root': {
            borderRight: 'none',
            borderBottom: 'none',
          },
          '& .MuiButton-root.MuiButton-outlinedPrimary': {
            borderColor: theme.palette.primary.main,
            '&:hover': {
              borderColor: theme.palette.primary.dark,
            },
          },
          '& .MuiButton-root.MuiButton-outlinedSecondary': {
            borderColor: theme.palette.secondary.main,
            '&:hover': {
              borderColor: theme.palette.secondary.dark,
            },
          },
          '& .MuiButton-root.MuiButton-outlinedSuccess': {
            borderColor: theme.palette.success.main,
          },
          '& .MuiButton-root.MuiButton-outlinedWarning': {
            borderColor: theme.palette.warning.main,
          },
          '& .MuiButton-root.MuiButton-outlinedError': {
            borderColor: theme.palette.error.main,
          },
          '& .MuiButton-root.MuiButton-outlinedInfo': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  }
}
