import type { DictionaryCommonName } from '../../../redux/types/dictionaries'
import {
  DICTIONARY,
  DICTIONARY_ADDITION,
  DICTIONARY_CUSTOM,
} from '../../../redux/types/dictionaries'
import type { FilterType } from '../../../redux/types/filters'
import {
  SEARCH_STRING_FILTERS,
  START_END_FILTERS,
} from '../../../redux/types/filters'
import type { FilterPeriodProps } from './FiltersList/FilterPeriod'
import type { FilterSearchProps } from './FiltersList/FilterSearch'

type FilterVariantTypes =
  | 'period'
  | 'search-checkbox'
  | 'checkbox'
  | 'range'
  | 'search'
  | 'sings-of-crimes'
  | 'percentage-range'
  | 'speaker-score-range'
  | 'media_num-range'

export type FilterValueType = {
  title: string
  type: FilterVariantTypes
}

export type FilterKeyType =
  | DictionaryCommonName
  | FilterPeriodProps['variant']
  | FilterSearchProps['variant']
  | 'media_length'
  | 'user_tags'
  | 'percentage'
  | 'speaker_score'
  | 'media_num'

export type ConfigItemType = [FilterKeyType, FilterValueType]

const mainFiltersConfig: ConfigItemType[] = [
  ['date', { title: 'Date and time', type: 'period' }],
  [DICTIONARY.media_type, { title: 'Media type', type: 'checkbox' }],
  [DICTIONARY.sources, { title: 'Sources', type: 'search-checkbox' }],
  [DICTIONARY.programs, { title: 'Programs', type: 'search-checkbox' }],
  [DICTIONARY.authors, { title: 'Authors', type: 'search-checkbox' }],
  [
    DICTIONARY_ADDITION.personas,
    { title: 'Speakers', type: 'search-checkbox' },
  ],
  [
    DICTIONARY_CUSTOM.telegram_channels,
    { title: 'Telegram channels', type: 'search-checkbox' },
  ],
  [
    DICTIONARY.signs_of_crimes,
    { title: 'Sings of crimes', type: 'sings-of-crimes' },
  ],
  ['user_tags', { title: 'User Tags', type: 'search-checkbox' }],
  ['media_length', { title: 'Media length', type: 'range' }],
  ['percentage', { title: 'Identified speakers %', type: 'percentage-range' }],
  [
    'speaker_score',
    { title: 'Unknown speaker score', type: 'speaker-score-range' },
  ],
]

const dashboardFiltersConfig: ConfigItemType[] = [
  ['date', { title: 'Date and time', type: 'period' }],
  [DICTIONARY.media_type, { title: 'Media type', type: 'checkbox' }],
  [DICTIONARY.sources, { title: 'Sources', type: 'search-checkbox' }],
  [DICTIONARY.programs, { title: 'Programs', type: 'search-checkbox' }],
  [DICTIONARY.authors, { title: 'Authors', type: 'search-checkbox' }],
  [
    DICTIONARY_ADDITION.personas,
    { title: 'Speakers', type: 'search-checkbox' },
  ],
  [
    DICTIONARY.signs_of_crimes,
    { title: 'Sings of crimes', type: 'sings-of-crimes' },
  ],
  ['user_tags', { title: 'User Tags', type: 'search-checkbox' }],
  ['media_length', { title: 'Media length', type: 'range' }],
  ['percentage', { title: 'Identified speakers %', type: 'percentage-range' }],
  [
    'speaker_score',
    { title: 'Unknown speaker score', type: 'speaker-score-range' },
  ],
]

const basketFiltersConfig: ConfigItemType[] = [
  ['submit_date', { title: 'Submit date', type: 'period' }],
  // air_date is also commented in redux filter basket initial data and in mapFiltersToBasketParams
  // ['air_date', { title: 'Air date', type: 'period' }],
  [DICTIONARY.sources, { title: 'Sources', type: 'search-checkbox' }],
  [DICTIONARY.programs, { title: 'Programs', type: 'search-checkbox' }],
  [
    SEARCH_STRING_FILTERS.program_title,
    { title: 'Program title', type: 'search' },
  ],
  [
    DICTIONARY_ADDITION.personas,
    { title: 'Speakers', type: 'search-checkbox' },
  ],
  ['user_tags', { title: 'User Tags', type: 'search-checkbox' }],
]

const personLibraryFiltersConfig: ConfigItemType[] = [
  [
    DICTIONARY.persona_category,
    { title: 'Categories', type: 'search-checkbox' },
  ],
  [SEARCH_STRING_FILTERS.title, { title: 'Title', type: 'search' }],
  [DICTIONARY.networks, { title: 'Social network', type: 'checkbox' }],
  [
    START_END_FILTERS.media_num,
    { title: 'Available in media score', type: 'media_num-range' },
  ],
]

export const filterConfigObject: Record<FilterType, ConfigItemType[]> = {
  mainResults: mainFiltersConfig,
  mainBugs: mainFiltersConfig,
  basket: basketFiltersConfig,
  personLibrary: personLibraryFiltersConfig,
  dashboard: dashboardFiltersConfig,
}
