import type { Theme } from '@mui/material'

export default (theme: Theme) => {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: theme.palette.grey[500],
          ':after': {
            background: `linear-gradient( 90deg, transparent, ${theme.palette.grey[600]}, transparent )`,
            opacity: '50%',
          },
        },
      },
    },
  }
}
