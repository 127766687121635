import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetSearchData } from '../../api'
import type { GetSearchParams } from '../../api/apiTypes'
import { setSearch } from '../slices/searchSlice'

export const fetchSearchData = createAsyncThunk<void, GetSearchParams>(
  'search/fetch',
  // @ts-ignore
  async (params, thunkAPI) => {
    try {
      const data = await apiGetSearchData(params)
      thunkAPI.dispatch(setSearch(data))
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue('ERROR')
    }
  }
)
