import { RESPONSE_ERROR_REASON } from '../constants/enums'
import type { QueryType } from '../redux/slices/profileSlice'
import type { LanguageSuffix } from '../redux/types/dictionaries'
import type { MediaData, Transcription } from '../redux/types/media'
import type {
  GetMediaParams,
  SetSpeakerParams,
  TranscriptionsAnnotationParams,
  TranscriptionsDataResponse,
  UpdateMediaUserTagsParams,
} from './apiTypes'
import { api } from './axiosInstance'
import { mapFiltersToSearchParams } from './mappers'

let GetMediaAbortController: AbortController | undefined

export const apiGetMedia = async (filters: GetMediaParams) => {
  if (GetMediaAbortController) {
    GetMediaAbortController.abort()
  }

  const controller = new AbortController()
  const { signal } = controller
  GetMediaAbortController = controller

  const params = mapFiltersToSearchParams(filters)

  try {
    const response = await api.post('media/filter', params, {
      signal,
    })
    return response.data
  } catch (error: any) {
    if (error.message !== RESPONSE_ERROR_REASON.CANCELED) {
      console.error('Error fetching global search data:', error)
    }

    throw error
  }
}

export const apiSetSpeakerData = async (params: SetSpeakerParams) => {
  try {
    const response = await api.post('media/speaker', params)
    return response.data
  } catch (error) {
    // todo: Handle error if needed
    console.error('Error sending speakers data:', error)
    throw error
  }
}

export const apiAddMediaUserTag = async (params: UpdateMediaUserTagsParams) => {
  try {
    const response = await api.post('media/user-tags', params)
    return { tags: response.data.tags, mediaId: response.data.media_id }
  } catch (error) {
    console.error('Error during add media user tag', error)
    throw error
  }
}

export const apiRemoveMediaUserTag = async (
  params: UpdateMediaUserTagsParams
) => {
  try {
    const response = await api.delete('media/user-tags', { data: params })
    return { tags: response.data.tags, mediaId: response.data.media_id }
  } catch (error) {
    console.error('Error during remove media user tag', error)
    throw error
  }
}

export const apiGetMediaData = async (id: string): Promise<MediaData> => {
  try {
    const response = await api.get(`media/${id}`)
    return response.data
  } catch (error) {
    console.error('Error during get media', error)
    throw error
  }
}

// Temporary sorting in the frontend side should be in the backend side in future
function compareObjectsByStartAndEnd(a: Transcription, b: Transcription) {
  if (a.start === b.start) {
    return Number(a.end) - Number(b.end)
  }
  return Number(a.start) - Number(b.start)
}

export type HighlightParams = {
  id: string
  search: string
  queryType: QueryType
  search_model?: string
  highlightPreTag: string
  highlightPostTag: string
}

export type HighlightTrsRes = Transcription & {
  '@search.highlights'?: Record<`text${LanguageSuffix}`, Array<string>>
}

export const apiGetHighlightTranscriptionsData = async (
  data: HighlightParams
): Promise<HighlightTrsRes[]> => {
  try {
    const params = {
      media_id: [data.id],
      skip: 0,
      limit: 0,
      search: data.search,
      queryType: data.queryType,
      search_model: data.search_model,
      highlightPreTag: data.highlightPreTag,
      highlightPostTag: data.highlightPostTag,
    }
    const response = await api.post(
      'media/transcription/search-highlight',
      params
    )
    if (response.data?.transcriptions) {
      const sortedTranscriptions = response.data?.transcriptions?.sort(
        compareObjectsByStartAndEnd
      )
      response.data.transcriptions = sortedTranscriptions
    }
    return response.data
  } catch (error) {
    console.error(
      'Error during get search-highlight media transcription',
      error
    )
    throw error
  }
}

export const apiGetTranscriptionsData = async (
  id: string
): Promise<TranscriptionsDataResponse> => {
  try {
    const response = await api.get(`media/transcriptions/${id}`)
    if (response.data?.transcriptions) {
      const sortedTranscriptions = response.data?.transcriptions?.sort(
        compareObjectsByStartAndEnd
      )
      response.data.transcriptions = sortedTranscriptions
    }
    return response.data
  } catch (error) {
    console.error('Error during get media transcription', error)
    throw error
  }
}

export const apiTranscriptionsAnnotation = async (
  params: TranscriptionsAnnotationParams
) => {
  try {
    const response = await api.post('media/transcription/annotation', params)
    return response.data
  } catch (error) {
    console.error('Error during annotation', error)
    throw error
  }
}
