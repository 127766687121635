import type { ReactNode } from 'react'
import React from 'react'
import type { CircularProgressProps } from '@mui/material'
import { CircularProgress, LinearProgress } from '@mui/material'
import type { IpProgressBarPropTypes } from '@infopulse-design-system/shared/types/IpProgressBar.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpProgressBar/styles.scss'
import { styled } from '@mui/material/styles'
import styles from '@infopulse-design-system/shared/theme/components/IpProgressBar'

export const IpProgressBarTestId = 'IpProgressBarTestId'

export type IpProgressBarPropTypesReact = IpProgressBarPropTypes & {
  children?: ReactNode
}

const IpProgressBarStyled = styled('div')(
  ({
    type,
    fontSize,
  }: {
    type: IpProgressBarPropTypesReact['type']
    fontSize: IpProgressBarPropTypesReact['fontSize']
  }) => {
    return type === 'circular'
      ? {
          position: 'relative',
          display: 'inline-flex',
          '& .IpProgressBar-label-root': {
            fontSize,
          },
        }
      : {
          display: 'flex',
          alignItems: 'center',
          '& .MuiLinearProgress-root': {
            margin: styles.progress_bar_linear_label_margin,
          },
          '& .IpProgressBar-label-root': {
            fontSize,
          },
        }
  }
)

const IpLinearProgressStyled = styled(LinearProgress)(
  ({ size }: { size?: IpProgressBarPropTypesReact['size'] }) => {
    return {
      height: size,
    }
  }
)

/**
 * `IpProgressBar` component is a useful tool that shows how far along a task is or whether it lets
 * you know when something is still loading. It keeps users informed about what's happening in
 * real-time, like when an app is loading, a form is being submitted, or updates are being saved.
 * By showing the progress visually, users can see how much is done and how much is left, making their
 * experience smoother and keeping them in the know. Users have the flexibility to choose from
 * circular, linear, or even provide a custom progress bar according to their preferences.
 */
export function IpProgressBar(props: IpProgressBarPropTypesReact) {
  const {
    ariaLabel,
    classes,
    children,
    color,
    label,
    fontSize,
    size,
    type = 'circular',
    value,
    valueBuffer,
    variant = 'indeterminate',
  } = props

  const customClasses = formatClassName(
    generateClasses('progress-bar', 'react'),
    classes
  )

  const sharedProps = {
    'aria-label': ariaLabel,
    color,
    value,
    variant,
    size,
  }

  const component =
    type === 'circular' ? (
      <CircularProgress {...(sharedProps as CircularProgressProps)} />
    ) : (
      <IpLinearProgressStyled valueBuffer={valueBuffer} {...sharedProps} />
    )

  return (
    <div data-testid={IpProgressBarTestId} className={customClasses}>
      {children ||
        (label ? (
          <IpProgressBarStyled type={type} fontSize={fontSize}>
            {component}
            <div className="IpProgressBar-label-root">{`${value}%`}</div>
          </IpProgressBarStyled>
        ) : (
          component
        ))}
    </div>
  )
}
