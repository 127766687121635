import { useHoveredNotification } from './useHoveredNotification'

type HandleExportPropsType<P> = {
  api: (params: P) => Promise<any>
  params: P
  fileName: string
  fileExt: string
}

export function useExportFile() {
  const { notification } = useHoveredNotification()

  const handleExport = async <P>({
    api,
    params,
    fileName,
    fileExt,
  }: HandleExportPropsType<P>) => {
    try {
      const blob: any = await api(params)
      const anchor = document.createElement('a')
      const objectUrl = window.URL.createObjectURL(blob)
      anchor.href = objectUrl
      anchor.download = `${fileName}.${fileExt}`
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(objectUrl)
    } catch (e: any) {
      notification(`Error: ${e.message}`, 'error', true)
    }
  }
  return {
    handleExport,
  }
}
