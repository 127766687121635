import React from 'react'
import type { ReactNode } from 'react'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { Drawer } from '@mui/material'
import type { IpDrawerPropTypes } from '@infopulse-design-system/shared/types/IpDrawer.types'

export type IpDrawerPropTypesReact = IpDrawerPropTypes & {
  children?: ReactNode
}

export const IpDrawerTestId = 'IpDrawerTestId'

/**
 * `IpDrawer` serves as a convenient access point to different destinations and app features,
 * allowing users to switch between sections or perform specific actions. They can be permanently
 * visible on the screen or hidden behind a navigation menu icon, depending on the design and user
 * interaction patterns. They are typically anchored to either left or right edge of the screen
 * and are commonly used in tablet and desktop interfaces.
 */
export const IpDrawer = (props: IpDrawerPropTypesReact) => {
  const {
    anchor,
    children,
    classes,
    elevation,
    hideBackdrop,
    ModalProps,
    onClose,
    open,
    PaperProps,
    SlideProps,
    transitionDuration,
    variant,
  } = props

  const customClasses = formatClassName(
    generateClasses('drawer', 'react'),
    classes
  )

  return (
    <Drawer
      anchor={anchor}
      className={customClasses}
      data-testid={IpDrawerTestId}
      elevation={elevation}
      hideBackdrop={hideBackdrop}
      ModalProps={ModalProps}
      onClose={onClose}
      open={open}
      PaperProps={PaperProps}
      SlideProps={SlideProps}
      transitionDuration={transitionDuration}
      variant={variant}
    >
      {children}
    </Drawer>
  )
}
