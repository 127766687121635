import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { apiExportProcessData } from '../../../../../api/mediaProcessing'
import { EXPORT_FILE_EXT } from '../../../../../constants'
import { EXPORT_FILE_FORMATS } from '../../../../../constants/enums'
import { useExportFile } from '../../../../../hooks/useExportFile'
import { useHoveredNotification } from '../../../../../hooks/useHoveredNotification'
import { useAppDispatch } from '../../../../../redux/store'
import {
  deleteMediaProcessingById,
  updateMediaProcessingThunk,
} from '../../../../../redux/thunk/mediaProcessingThunk'
import type { IpTooltipPropTypesReact } from '../../../../ipDesignSystemComponents/atoms/IpTooltip'
import { ExportToWord } from '../../../icons/ExportToWord'
import { RefreshButton } from '../../../icons/RefreshButton'
import { RemoveMedia } from '../../../icons/RemoveMedia'
import { ROUTER_PATHS } from '../../../Router'

type Props = {
  placement: IpTooltipPropTypesReact['placement']
  isSuccess: boolean
}

export const ProcessingAdditionMenu = ({ placement, isSuccess }: Props) => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { handleExport } = useExportFile()
  const { notification } = useHoveredNotification()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    if (loading) return
    setLoading(true)
    const res = await dispatch(updateMediaProcessingThunk(id))
    if (res.type.includes('rejected')) {
      // @ts-ignore
      notification(res?.error?.message ?? 'rejected', 'error', true)
    }
    setLoading(false)
  }
  const [isDocxLoading, setIsDocxLoading] = React.useState(false)

  const exportHandler = async (fileType: EXPORT_FILE_FORMATS) => {
    setIsDocxLoading(true)
    await handleExport({
      api: apiExportProcessData,
      params: {
        id,
        fileType,
      },
      fileName: 'media-processing',
      fileExt: EXPORT_FILE_EXT[fileType],
    })
    setIsDocxLoading(false)
  }

  return (
    <div className="preview_addition-menu">
      {!isSuccess && (
        <RefreshButton
          placement={placement}
          onClick={onClick}
          loading={loading}
        />
      )}
      <RemoveMedia
        placement={placement}
        onClick={() => {
          dispatch(deleteMediaProcessingById(id))
          navigate(`/${ROUTER_PATHS.media_processing}`)
        }}
      />
      {isSuccess && (
        <ExportToWord
          loading={isDocxLoading}
          placement={placement}
          onClick={() => exportHandler(EXPORT_FILE_FORMATS.DOCX)}
        />
      )}
    </div>
  )
}
