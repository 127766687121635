import type { FC } from 'react'
import React from 'react'
// import { WeekFullDays } from '@infopulse-design-system/shared/constants/enums'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { reportBasketSelector } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux/store'
import { formatSecondsToTime } from '../../../../utils'
import type { IpCardPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpCard'
import { IpCard } from '../../../ipDesignSystemComponents/atoms/IpCard'
import { IpChip } from '../../../ipDesignSystemComponents/atoms/IpChip'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'

type SearchResultItemProps = {
  index: number
  isActive: boolean
  isBug?: boolean
  onClick: IpCardPropTypesReact['onClick']
}

export const BasketItem: FC<SearchResultItemProps> = ({
  index,
  isActive,
  onClick,
}) => {
  const basketItem = useAppSelector(reportBasketSelector).data.data[index]

  const {
    user_tags,
    time_code,
    media_type_id,
    source_id,
    author_id,
    title,
    air_date,
    program_id,
    text,
    persona_id,
    // weekday,
  } = basketItem

  const startTime = formatSecondsToTime(Number(time_code))
  const dictionaryData = useDictionaryData({
    media_type_id,
    source_id,
    program_id,
    author_id,
    persona_id,
  })

  const transcriptionPersona = dictionaryData.persona
  return (
    <IpCard
      classes={`search-result-item ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <div className="top-line">
        <div className="top-line_chip-wrapper">
          <IpChip
            label={dictionaryData.mediaType}
            classes="top-line_chip"
            color="primary"
            size="extraSmall"
          />
          <IpChip
            label={dictionaryData.source}
            classes="top-line_chip"
            color="primary"
            size="extraSmall"
          />
          <IpText component="span" classes="top-line_date" color="primary">
            {/* {WeekFullDays[weekday]}, {air_date} */}
            {air_date}
          </IpText>
        </div>
        <div className="top-line_additional">
          {user_tags?.length ? (
            <>
              <IpText
                variant="body2"
                color="primary"
                classes="semi-bold user-tags-title"
              >
                User Tags:{' '}
              </IpText>
              {user_tags.map((item) => (
                <IpChip
                  key={item}
                  label={item}
                  size="extraSmall"
                  variant="outlined"
                  color="secondary"
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="main-info">
        <div className="program-info">
          <IpText classes="program-name">{dictionaryData.program}</IpText>
          {dictionaryData.author && (
            <IpText classes="author-info">
              <span>Author:</span> {dictionaryData.author}
            </IpText>
          )}
        </div>
        <IpText classes="program-title">{title}</IpText>
        {text ? (
          <IpText key={time_code} classes="transcription">
            <span className="transcription_time">{startTime}</span>
            {' - '}
            {transcriptionPersona && (
              <>
                <span className="transcription_persona">
                  {transcriptionPersona}
                </span>
                {' - '}
              </>
            )}
            {text}
          </IpText>
        ) : (
          ''
        )}
      </div>
    </IpCard>
  )
}
