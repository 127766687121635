import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpProgressBar'

export default (theme: Theme) => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: styles.progress_bar_linear_border_radius,
          backgroundColor: theme.palette.background.default,
          border: 'none',
          position: 'relative',
          '& .MuiLinearProgress-bar': {
            borderRadius: styles.progress_bar_linear_border_radius,
          },
          '&:before': {
            position: 'absolute',
            content: "''",
            width: `calc(100% - ${styles.progress_bar_linear_border_width}*2)`,
            height: `calc(100% - ${styles.progress_bar_linear_border_width}*2)`,
            borderRadius: styles.progress_bar_linear_border_radius,
            borderWidth: styles.progress_bar_linear_border_width,
            borderStyle: 'solid',
          },
          '&.MuiLinearProgress-colorPrimary': {
            '&:before': {
              borderColor: theme.palette.primary.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.primary.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.primary.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.primary.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.primary.main}`,
            },
          },
          '&.MuiLinearProgress-colorSecondary': {
            '&:before': {
              borderColor: theme.palette.secondary.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.secondary.light} 0%, ${theme.palette.secondary.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.secondary.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.secondary.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.secondary.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.secondary.main}`,
            },
          },
          '&.MuiLinearProgress-colorError': {
            '&:before': {
              borderColor: theme.palette.error.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.error.light} 0%, ${theme.palette.error.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.error.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.error.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.error.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.error.main}`,
            },
          },
          '&.MuiLinearProgress-colorInfo': {
            '&:before': {
              borderColor: theme.palette.info.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.info.light} 0%, ${theme.palette.info.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.info.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.info.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.info.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.info.main}`,
            },
          },
          '&.MuiLinearProgress-colorSuccess': {
            '&:before': {
              borderColor: theme.palette.success.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.success.light} 0%, ${theme.palette.success.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.success.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.success.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.success.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.success.main}`,
            },
          },
          '&.MuiLinearProgress-colorWarning': {
            '&:before': {
              borderColor: theme.palette.warning.main,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `radial-gradient(${theme.palette.warning.light} 0%, ${theme.palette.warning.light} 16%, transparent 42%)`,
            },
            '&:not(.MuiLinearProgress-buffer) .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.warning.dark,
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: theme.palette.warning.dark,
            },
            '& .MuiLinearProgress-bar2Buffer': {
              borderBottom: `${styles.progress_bar_linear_border_width} solid ${theme.palette.warning.main}`,
              borderTop: `${styles.progress_bar_linear_border_width} solid ${theme.palette.warning.main}`,
            },
          },
          '& ~ .IpProgressBar-label-root': {
            minWidth: styles.progress_bar_min_width,
            color: theme.palette.text.primary,
            font: styles.progress_bar_label_font,
          },
        },
      },
    },
  }
}
