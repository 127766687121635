import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpList/IpListSubheader'

export default (theme: Theme) => {
  return {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          font: styles.list_subheader_font,
          padding: styles.list_subheader_padding,
        },
      },
    },
  }
}
