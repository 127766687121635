import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetUserData } from '../../api'
import { setUserInfo } from '../slices/profileSlice'

export const fetchUserData = createAsyncThunk<void>(
  'profile/fetch',
  // @ts-ignore
  async (params, thunkAPI) => {
    try {
      const data = await apiGetUserData()
      thunkAPI.dispatch(setUserInfo(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)
