import type { SelectChangeEvent } from '@mui/material'
import React from 'react'
import { useLanguage } from '../../../../hooks/useLanguage'
import { setLanguage } from '../../../../redux/slices/settingsSlice'
import { useAppDispatch } from '../../../../redux/store'
import type { IpSelectPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpSelect'
import { IpSelect } from '../../../ipDesignSystemComponents/atoms/IpSelect'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import './index.scss'

export const LanguageSwitcher = () => {
  const dispatch = useAppDispatch()
  const { langList, lang } = useLanguage()

  const languageOptions: IpSelectPropTypesReact['options'] = langList?.map(
    (item) => ({
      value: item.id,
      title: item.short_name ?? item.name,
    })
  )

  const handleLanguage = async (e: SelectChangeEvent) => {
    const langData = langList?.find((item) => item.id === e.target.value)
    if (langData) {
      await dispatch(
        setLanguage({
          language: langData.id,
          locale: langData.locale,
          attribute_suffix: langData.attribute_suffix,
        })
      )
    }
  }

  return (
    <div className="language-switcher">
      {!!languageOptions.length && (
        <>
          <IpText color="primary">Content:</IpText>
          <IpSelect
            options={languageOptions}
            variant="standard"
            value={lang}
            onChange={handleLanguage}
            isOptionIcon={false}
          />
        </>
      )}
    </div>
  )
}
