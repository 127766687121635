import { Telegram, Tv, YouTube } from '@mui/icons-material'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import React from 'react'
import type { PersonaNetworkDataType } from '../../../../redux/types/personas'
import { IpLink } from '../../../ipDesignSystemComponents/atoms/IpLink'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { Ru, Vk, Wiki } from '../../icons'

type Props = PersonaNetworkDataType

export const PersonaMediaList = ({
  facebook_page,
  site_url,
  telegram_channel,
  vk_page,
  wiki_url,
  youtube_channel,
}: Props) => {
  return (
    <div className="persona-media_list">
      <IpTooltip
        arrow
        title={site_url}
        placement="top"
        content={
          <IpLink
            href={site_url}
            target="_blank"
            classes={!site_url ? 'disabled' : ''}
          >
            <Tv />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={wiki_url}
        placement="top"
        content={
          <IpLink
            href={wiki_url}
            target="_blank"
            classes={!wiki_url ? 'disabled' : ''}
          >
            <Wiki />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={facebook_page}
        placement="top"
        content={
          <IpLink
            href={facebook_page}
            target="_blank"
            classes={!facebook_page ? 'disabled' : ''}
          >
            <FacebookRoundedIcon />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={youtube_channel}
        placement="top"
        content={
          <IpLink
            href={youtube_channel}
            target="_blank"
            classes={!youtube_channel ? 'disabled' : ''}
          >
            <YouTube />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={''}
        placement="top"
        content={
          <IpLink href={'#'} classes={'disabled'}>
            <Ru />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={telegram_channel}
        placement="top"
        content={
          <IpLink
            href={telegram_channel}
            target="_blank"
            classes={!telegram_channel ? 'disabled' : ''}
          >
            <Telegram />
          </IpLink>
        }
      />
      <IpTooltip
        arrow
        title={vk_page}
        placement="top"
        content={
          <IpLink
            href={vk_page}
            target="_blank"
            classes={!vk_page ? 'disabled' : ''}
          >
            <Vk />
          </IpLink>
        }
      />
    </div>
  )
}
