import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetPersonaList } from '../../api'
import type {
  GetPersonaListParams,
  PersonaListResponse,
} from '../../api/apiTypes'
import { setPersonaList, setPersonaListNext } from '../slices/personaListSlice'

export const fetchPersonaList = createAsyncThunk<
  PersonaListResponse,
  GetPersonaListParams
>('personaList/fetch', async (params, thunkAPI) => {
  try {
    const data = await apiGetPersonaList(params)
    thunkAPI.dispatch(setPersonaList(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
  }
})

export const fetchPersonaListNext = createAsyncThunk<
  PersonaListResponse,
  GetPersonaListParams
>('personaList/fetch/next', async (params, thunkAPI) => {
  try {
    const data = await apiGetPersonaList(params)
    thunkAPI.dispatch(setPersonaListNext(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
  }
})
