import type { Theme } from '@mui/material'
import { primaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'
import tableStyles from '@infopulse-design-system/shared/theme/components/IpTable'

export default (theme: Theme) => {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: primaryFontFamily,
          minWidth: inputStyles.input_min_width,
          '& .MuiInputBase-root': {
            borderRadius: inputStyles.input_border_radius,
            '& .MuiInputBase-input': {
              padding: 0,
            },
            color: theme.palette.text.primary,
            paddingRight: inputStyles.input_padding_horizontal,
            paddingLeft: inputStyles.input_padding_horizontal,
            paddingTop: 0,
            paddingBottom: 0,
            '&:not(.MuiInputBase-multiline)': {
              height: inputStyles.input_height,
              lineHeight: inputStyles.input_height,
            },
          },
          '& .MuiInputBase-sizeSmall': {
            '&:not(.MuiInputBase-multiline)': {
              height: inputStyles.input_small_height,
              lineHeight: inputStyles.input_small_height,
            },
          },
          '& .MuiInput-root, & .MuiInputBase-root': {
            fontSize: inputStyles.input_label_font_size,
            marginTop: '0px',
          },
          '& .MuiInputBase-multiline': {
            paddingLeft: inputStyles.input_padding_horizontal,
            paddingRight: inputStyles.input_padding_horizontal,
            paddingTop: inputStyles.input_padding_vertical,
            paddingBottom: inputStyles.input_padding_vertical,
          },
          '& .MuiInput-underline': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: inputStyles.input_padding_vertical,
            paddingBottom: `calc(${inputStyles.input_padding_vertical} / 3)`,
          },
          '& .MuiInput-underline.MuiInputBase-multiline': {
            paddingTop: inputStyles.input_padding_vertical,
            paddingBottom: `calc(${inputStyles.input_padding_vertical} / 3)`,
          },
          '& .MuiInputLabel-root:not(.Mui-error)': {
            color: theme.palette.grey['600'],
          },
          '& .MuiInputLabel-root.Mui-disabled': {
            color: theme.palette.grey['600'],
          },
          '&.MuiTextField-startAdornment .MuiInputLabel-root': {
            transform: 'translate(0px, -9px) scale(0.75)',
            left: inputStyles.input_label_left,
            lineHeight: '1.4375em',
          },
          '& .MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root:not(.Mui-error)':
            {
              color: theme.palette.primary.main,
            },
          '&:hover .MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root:not(.Mui-error)':
            {
              color: theme.palette.primary.dark,
            },
          '&:hover .MuiFormLabel-colorSecondary.MuiFormLabel-filled.MuiInputLabel-root:not(.Mui-error)':
            {
              color: theme.palette.secondary.dark,
            },
          '&:hover .MuiFormLabel-colorPrimary.MuiInputLabel-root.Mui-focused': {
            color: theme.palette.primary.dark,
          },
          '& .MuiFormLabel-colorSecondary.MuiFormLabel-filled.MuiInputLabel-root':
            {
              color: theme.palette.secondary.main,
            },
          '& .MuiFormLabel-colorSuccess.MuiFormLabel-filled.MuiInputLabel-root':
            {
              color: theme.palette.success.main,
            },
          '& .MuiFormLabel-colorError.MuiFormLabel-filled.MuiInputLabel-root, & .Mui-error.MuiFormLabel-filled.MuiInputLabel-root':
            {
              color: theme.palette.error.main,
            },

          '&:hover .MuiFormLabel-colorSecondary.MuiInputLabel-root.Mui-focused':
            {
              color: theme.palette.secondary.dark,
            },
          '&:hover .MuiFormLabel-colorSuccess.MuiInputLabel-root.Mui-focused': {
            color: theme.palette.success.main,
          },
          '&:hover .MuiFormLabel-colorError.MuiInputLabel-root.Mui-focused, &:hover .Mui-error.MuiInputLabel-root.Mui-focused':
            {
              color: theme.palette.error.main,
            },
          '& .MuiInputBase-colorPrimary:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.primary.dark,
            },
          '& .MuiInputBase-colorSecondary:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.secondary.dark,
            },
          '& .MuiInputBase-colorSuccess:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.success.main,
            },
          '& .MuiInputBase-colorError:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline, & .Mui-error:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.error.main,
            },
          '& .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '& .MuiInputBase-colorSecondary .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },
          '& .MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.success.main,
          },
          '& .MuiInputBase-root.MuiInput-root.Mui-disabled:before': {
            borderBottom: `1px solid ${theme.palette.grey['500']}`,
          },

          '& .MuiInputBase-colorPrimary.MuiInput-root:hover:not(.Mui-disabled):before':
            {
              borderBottom: `1px solid ${theme.palette.primary.dark}`,
            },
          '& .MuiInputBase-colorPrimary.MuiInput-root:hover:not(.Mui-disabled):after':
            {
              borderBottom: `1px solid ${theme.palette.primary.dark}`,
            },
          '& .MuiInputBase-colorPrimary::before': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },
          '& .MuiInputBase-colorPrimary::after': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },

          '& .MuiInputBase-colorSecondary.MuiInput-root:hover:not(.Mui-disabled):before':
            {
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
            },
          '& .MuiInputBase-colorSecondary.MuiInput-root:hover:not(.Mui-disabled):after':
            {
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
            },
          '& .MuiInputBase-colorSecondary::before': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          },
          '& .MuiInputBase-colorSecondary::after': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          },

          '& .MuiInputBase-colorSuccess.MuiInput-root:hover:not(.Mui-disabled):before':
            {
              borderBottom: `1px solid ${theme.palette.success.main}`,
            },
          '& .MuiInputBase-colorSuccess.MuiInput-root:hover:not(.Mui-disabled):after':
            {
              borderBottom: `1px solid ${theme.palette.success.main}`,
            },
          '& .MuiInputBase-colorSuccess::before': {
            borderBottom: `1px solid ${theme.palette.success.main}`,
          },
          '& .MuiInputBase-colorSuccess::after': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          },

          '& .MuiInputBase-colorError.MuiInput-root:hover:not(.Mui-disabled):before, & .Mui-error.MuiInput-root:hover:not(.Mui-disabled):before':
            {
              borderBottom: `1px solid ${theme.palette.error.main}`,
            },
          '& .MuiInputBase-colorError.MuiInput-root:hover:not(.Mui-disabled):after, & .Mui-error.MuiInput-root:hover:not(.Mui-disabled):after':
            {
              borderBottom: `1px solid ${theme.palette.error.main}`,
            },
          '& .MuiInputBase-colorError::before, & .Mui-error::before': {
            borderBottom: `1px solid ${theme.palette.error.main}`,
          },
          '& .MuiInputBase-colorError::after, & .Mui-error::after': {
            borderBottom: `1px solid ${theme.palette.error.main}`,
          },

          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderWidth: '1px',
            },
          '& .MuiInputAdornment-root': {
            height: '100%',
            '& div': {
              display: 'flex',
            },
          },
          '&.IpTableFilter-text': {
            width: '100%',
            minWidth: tableStyles.table_filter_text_min_width,
            marginTop: 0,
          },
          '&.IpTableFilter-range-input': {
            minWidth: tableStyles.table_filter_range_input_width,
            width: '75px',
            marginTop: 0,
          },
        },
      },
    },
  }
}
