import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTER_PATHS } from '../components/custom/Router'

export type PageNameType = typeof ROUTER_PATHS[keyof typeof ROUTER_PATHS]

const getPageName = (pathname: string): PageNameType => {
  const pathnameArr = pathname.split('/')

  if (pathnameArr.includes(ROUTER_PATHS.basket)) {
    return ROUTER_PATHS.basket
  }
  if (pathnameArr.includes(ROUTER_PATHS.details)) {
    return ROUTER_PATHS.details
  }
  if (pathnameArr.includes(ROUTER_PATHS.person_library)) {
    return ROUTER_PATHS.person_library
  }
  if (pathnameArr.includes(ROUTER_PATHS.persona)) {
    return ROUTER_PATHS.persona
  }
  if (pathnameArr.includes(ROUTER_PATHS.media_processing)) {
    return ROUTER_PATHS.media_processing
  }
  if (pathnameArr.includes(ROUTER_PATHS.dashboard)) {
    return ROUTER_PATHS.dashboard
  }
  if (pathnameArr.includes(ROUTER_PATHS.admin_panel)) {
    return ROUTER_PATHS.admin_panel
  }
  if (pathnameArr.includes(ROUTER_PATHS.about_us)) {
    return ROUTER_PATHS.about_us
  }
  if (pathnameArr.includes(ROUTER_PATHS.terms_of_use)) {
    return ROUTER_PATHS.terms_of_use
  }

  return ROUTER_PATHS.root
}

export const usePageName = () => {
  const { pathname } = useLocation()
  const [pageName, setPageName] = useState<PageNameType>(
    getPageName(window.location.pathname)
  )

  useEffect(() => {
    setPageName(getPageName(pathname))
  }, [pathname])

  const isAdminPage = pageName === ROUTER_PATHS.admin_panel
  const isBasketPage = pageName === ROUTER_PATHS.basket
  const isDetailsPage = pageName === ROUTER_PATHS.details
  const isMainPage = pageName === ROUTER_PATHS.root
  const isMediaProcessingPage = pageName === ROUTER_PATHS.media_processing
  const isPersonLibraryPage = pageName === ROUTER_PATHS.person_library
  const isPersonaPage = pageName === ROUTER_PATHS.persona
  const isDashboardPage = pageName === ROUTER_PATHS.dashboard
  const isAboutUsPage = pageName === ROUTER_PATHS.about_us
  const isTermsOfUsePage = pageName === ROUTER_PATHS.terms_of_use

  return {
    pageName,
    isAdminPage,
    isBasketPage,
    isDetailsPage,
    isMainPage,
    isMediaProcessingPage,
    isPersonLibraryPage,
    isPersonaPage,
    isDashboardPage,
    isAboutUsPage,
    isTermsOfUsePage,
  }
}
