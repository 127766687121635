import styles from '@infopulse-design-system/shared/theme/components/IpRadioGroup'
import '@infopulse-design-system/shared/theme/components/IpRadioGroup/styles.scss'
import type { IpRadioGroupPropTypes } from '@infopulse-design-system/shared/types/IpRadioGroup.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ChangeEvent, ReactNode } from 'react'
import React, { forwardRef } from 'react'

export type IpRadioGroupPropTypesReact = IpRadioGroupPropTypes<
  ChangeEvent<HTMLInputElement>,
  ReactNode
>

export const IpRadioGroupTestId = 'IpRadioGroupTestId'

const IpRadioStyled = styled(FormControlLabel)(
  ({ size }: { size: string | undefined }) => ({
    '&': { fontSize: size || styles.radio_size },
  })
)

const IpRadioGroupStyled = styled(RadioGroup)(
  ({ fullwidth }: { fullwidth?: string }) =>
    fullwidth === 'true'
      ? { justifyContent: 'space-between' }
      : { display: 'inline-flex' }
)

/** 
 * `IpRadioGroup` component enables users to select a single option from a predefined set of 
 * choices. It is best suited when all available options need to be visible to the user. However,
 * if the list of options can be collapsed or hidden, it is recommended to use an `IpSelect` 
 * component as it occupies less space.

 * To enhance usability, it is advisable to have the most commonly used option preselected by
 * default when presenting radio buttons. This ensures a smoother user experience by reducing the 
 * need for unnecessary interactions, allowing users to quickly make their selections.

 * Radio buttons will be generated from a simple config `radioButtons`.
*/
export const IpRadioGroup = forwardRef(function IpRadioGroup(
  props: IpRadioGroupPropTypesReact,
  ref
) {
  const {
    ariaLabel,
    fullWidth = false,
    radioButtons,
    classes,
    orientation = 'vertical',
    size,
    ...fields
  } = props

  const customClasses = formatClassName(
    generateClasses('radio-group', 'react'),
    classes
  )

  return (
    <IpRadioGroupStyled
      aria-label={ariaLabel}
      className={customClasses}
      fullwidth={fullWidth.toString()}
      row={orientation === 'horizontal'}
      ref={ref}
      data-testid={IpRadioGroupTestId}
      {...fields}
    >
      {radioButtons.map(
        ({ checked, color, disabled, label, onChange, required, value }) => (
          <IpRadioStyled
            key={value}
            size={size}
            control={
              <Radio
                checked={checked}
                color={color}
                disabled={disabled}
                onChange={onChange}
                required={required}
                value={value}
              />
            }
            label={<Typography>{label}</Typography>}
          />
        )
      )}
    </IpRadioGroupStyled>
  )
})
