import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetBugs } from '../../api'
import type { GetBugParams, GetBugsResponse } from '../../api/apiTypes'
import { setBugs } from '../slices/bugsSlice'

export const fetchBugs = createAsyncThunk<GetBugsResponse, GetBugParams>(
  'fetchBugs',
  async (params, thunkAPI) => {
    try {
      const data = await apiGetBugs(params)
      thunkAPI.dispatch(setBugs(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)
