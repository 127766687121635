import type {
  DictionaryCommonPayloadContent,
  DictionaryContent,
  PermissionsPayloadContent,
} from '../types/dictionaries'

export const mockedSignsOfCrimes: DictionaryContent[] = [
  {
    id: 's00',
    name: 'Визначення захищеної групи',
  },
  {
    id: 's01',
    name: 'Заклики до геноциду',
  },
  {
    id: 's02',
    name: 'Заклики до ведення агресивної війни',
  },
  {
    id: 's03',
    name: 'Дегуманізація',
  },
]

export const mockedNetworks: DictionaryCommonPayloadContent = {
  data: [
    {
      id: 'site_url',
      name: 'Site url',
    },
    {
      id: 'wiki_url',
      name: 'Wiki url',
    },
    {
      id: 'facebook_page',
      name: 'Facebook page',
    },
    {
      id: 'youtube_channel',
      name: 'Youtube channel',
    },
    {
      id: 'telegram_channel',
      name: 'Telegram channel',
    },
    {
      id: 'vk_page',
      name: 'Vk page',
    },
  ],
}

export const MockPermissions: PermissionsPayloadContent = {
  data: [
    {
      id: 'user_tags',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'export_reports_and_transcriptions',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'transcription_context_menu',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'speaker_annotation',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'report_basket',
      roles: ['researcher'],
    },
    {
      id: 'bug_report',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'person_library',
      roles: [],
    },
    {
      id: 'person_library_edit',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'narratives_annotation',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'speaker_score_filter',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'signs_of_crimes_filter',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'signs_of_crimes_annotation',
      roles: ['annotator', 'researcher'],
    },
    {
      id: 'media_processing',
      roles: ['media_processing'],
    },
    {
      id: 'admin_panel',
      roles: ['administrator'],
    },
  ],
}
