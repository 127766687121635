"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColor = exports.capitalize = exports.updateResolutionClass = exports.formatClassName = exports.generateClasses = void 0;
var global_config_constants_1 = require("../constants/global.config.constants");
var generateClasses = function (componentName, project) {
    return "".concat(global_config_constants_1.PREFIX, "-").concat(componentName, " ").concat(global_config_constants_1.PREFIX, "-").concat(componentName, "--").concat(project);
};
exports.generateClasses = generateClasses;
var formatClassName = function () {
    var arr = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arr[_i] = arguments[_i];
    }
    return arr
        .filter(function (word) { return word && word.trim(); })
        .map(function (word) { return word.trim(); })
        .join(' ');
};
exports.formatClassName = formatClassName;
function updateResolutionClass(curr, prev) {
    document.body.classList.add(curr);
    if (prev) {
        document.body.classList.remove(prev);
    }
}
exports.updateResolutionClass = updateResolutionClass;
var capitalize = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.capitalize = capitalize;
var getColor = function (palette, colorPath) {
    if (colorPath) {
        try {
            var _a = colorPath.split('.'), area = _a[0], color = _a[1];
            if (palette[area] && palette[area][color]) {
                return palette[area][color];
            }
        }
        catch (e) {
            window.console.warn("Error in getColor: ".concat(e, ". colorPath: ").concat(colorPath));
            return undefined;
        }
    }
    return undefined;
};
exports.getColor = getColor;
