import React from 'react'
import type { ReactNode, ElementType, MouseEvent, CSSProperties } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import type { IpListItemPropTypes } from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpList/IpListItem/styles.scss'

export const IpListItemTestId = 'IpListItemTestId'
export const disablePaddingClassName = 'IpListItem-disablePadding'
export const disableHoverClassName = 'IpListItem-disableHover'

type IpListItemPropTypesReact = IpListItemPropTypes<
  ElementType,
  MouseEvent<HTMLElement>,
  CSSProperties
> & {
  children: ReactNode
}

export function IpListItem(props: IpListItemPropTypesReact) {
  const {
    alignItems,
    autoFocus,
    children,
    classes,
    dense,
    disableGutters,
    disabled,
    onClick,
    selected,
    style,
    disablePadding,
    disableHover,
    component = 'li',
  } = props

  const customClasses = formatClassName(
    generateClasses('list-item', 'react'),
    classes,
    disablePadding ? disablePaddingClassName : '',
    disableHover ? disableHoverClassName : ''
  )

  return (
    <ListItemButton
      data-testid={IpListItemTestId}
      role={null}
      alignItems={alignItems}
      autoFocus={autoFocus}
      className={customClasses}
      dense={dense}
      disableGutters={disableGutters}
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      style={style}
      component={component}
    >
      {children}
    </ListItemButton>
  )
}
