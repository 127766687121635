import React, { forwardRef } from 'react'
import type { ElementType, ReactNode, SyntheticEvent } from 'react'
import Slider from '@mui/material/Slider'
import type { IpSliderPropTypes } from '@infopulse-design-system/shared/types/IpSlider.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpSlider/styles.scss'

export const IpSliderTestId = 'IpSliderTestId'

export type IpSliderPropTypesReact = IpSliderPropTypes<
  ElementType,
  ReactNode,
  SyntheticEvent,
  Event
>

/**
 * `IpSlider` is user-friendly component that allow users to easily make selections within a designated
 * range of values. By visually displaying a spectrum of values along a bar, `IpSlider` provides a
 * straightforward method for users to pick a specific value. That flexible element is particularly
 * handy for modifying settings such as volume, brightness, or applying filters, granting users the
 * ability to precisely control and refine various parameters to their preferences.
 */
export const IpSlider = forwardRef(function IpSlider(
  props: IpSliderPropTypesReact,
  ref
) {
  const {
    classes,
    color,
    component = 'span',
    defaultValue,
    disableSwap,
    disabled,
    getAriaLabel,
    getAriaValueText,
    marks,
    max,
    min,
    onChange,
    onChangeCommitted,
    orientation,
    size,
    step,
    value,
    valueLabelDisplay,
    valueLabelFormat,
  } = props

  const customClasses = formatClassName(
    generateClasses('slider', 'react'),
    `IpSlider-${color}`,
    classes
  )

  return (
    <Slider
      className={customClasses}
      data-testid={IpSliderTestId}
      component={component}
      defaultValue={defaultValue}
      disableSwap={disableSwap}
      disabled={disabled}
      getAriaLabel={getAriaLabel}
      getAriaValueText={getAriaValueText}
      marks={marks}
      max={max}
      min={min}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      orientation={orientation}
      size={size}
      step={step}
      value={value}
      valueLabelDisplay={valueLabelDisplay}
      valueLabelFormat={valueLabelFormat}
      ref={ref}
    />
  )
})
