import type { IpLinearChartPropTypes } from '@infopulse-design-system/shared/types/IpLinearChart.types'
import html2canvas from 'html2canvas'
import React, { useRef } from 'react'
import { DashboardMenu, SearchWords } from '../../components/custom/dashboard'
import { FiltersPanel } from '../../components/custom/FiltersPanel'
import { IpBarChart } from '../../components/ipDesignSystemComponents/atoms/IpBarChart'
import { IpLinearChart } from '../../components/ipDesignSystemComponents/atoms/IpLinearChart'
import type { IpPieChartPropTypesReact } from '../../components/ipDesignSystemComponents/atoms/IpPieChart'
import { IpProgressBar } from '../../components/ipDesignSystemComponents/atoms/IpProgressBar'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import type { ChartType } from '../../constants/cart'
import { useDashboard } from '../../hooks/dataLoaders/useDashboard'
import { getExportFileName } from '../../utils'
import './index.scss'

const mockLegends: IpLinearChartPropTypes['legend'] = {
  align: 'start',
  position: 'bottom',
  labels: {
    padding: 20,
  },
}

function roundAndCheck(num: number): string {
  const roundedNum = Math.round(num)

  if (roundedNum >= 7) {
    return roundedNum.toString()
  }
  return ''
}

const labelOptions: IpPieChartPropTypesReact['labelOptions'] = {
  font: {
    size: 9,
  },
  color: '#FFFFFF',
  formatter: (val) => roundAndCheck(val),
}

const colors = [
  '#000080',
  '#800000',
  '#008000',
  '#808000',
  '#800080',
  '#FFA500',
  '#FF69B4',
  '#808080',
  '#8B4513',
  '#000000',
]

const percentageVerticalScaleArray = [
  '0%',
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
  '100%',
]

const colorsConfig = colors.map((color) => ({
  borderColor: color,
  backgroundColor: color,
}))

const dots: IpLinearChartPropTypes['dots'] = colors.map((item) => ({
  backgroundColor: item,
  borderColor: item,
  borderWidth: 1,
  radius: 2,
  hoverRadius: 4,
  hoverBorderWidth: 2,
}))

const animation = {
  duration: 1,
  delay: 0,
}

const interaction = {
  mode: 'index' as const,
  intersect: false,
}

export function transformDataArray(
  inputDataArray: { label: string; data: number[]; backgroundColor?: string }[]
): { label: string; data: number[]; backgroundColor?: string }[] {
  if (inputDataArray.length === 0) return inputDataArray
  const maxValues = inputDataArray[0].data.map((_, i) => {
    return inputDataArray.reduce((acc, curr) => {
      return acc + curr.data[i]
    }, 0)
  })

  const transformedDataArray = inputDataArray.map((dataObj) => ({
    ...dataObj,
    data: dataObj.data.map((num, index) => (num * 100) / maxValues[index]),
  }))

  return transformedDataArray
}

export type ChartTypeValue = typeof ChartType[keyof typeof ChartType]

function Dashboard() {
  const {
    horizontalScaleArray,
    data,
    updateSearchWords,
    searchWords,
    isLinear,
    isBar,
    exportHandler,
    exportLoading,
    loading,
  } = useDashboard()
  const [percentage, setPercentage] = React.useState(false)
  const chartRef = useRef(null)

  const downloadImage = () => {
    if (!chartRef.current) return
    const chartElement = chartRef.current

    html2canvas(chartElement).then((canvas) => {
      const link = document.createElement('a')
      link.href = canvas.toDataURL('image/png')
      const fileName = getExportFileName(isBar ? 'bar_png' : 'linear_png')
      link.download = `${fileName}.png`
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      })
      link.dispatchEvent(event)
    })
  }

  return (
    <div className="page main dashboard">
      <FiltersPanel classes="main-filter" />
      <div className="dashboard-result">
        <IpText
          color="primary"
          variant="h2"
          classes="main-subtitle main-subtitle_result semi-bold"
        >
          Dashboard
        </IpText>
        <SearchWords
          searchWords={searchWords}
          updateSearchWords={updateSearchWords}
        />
        <DashboardMenu
          exportLoading={exportLoading}
          exportHandler={exportHandler}
          downloadImage={downloadImage}
          percentage={percentage}
          setPercentage={setPercentage}
        />
        <div className="dashboard-chart-wrapper" ref={chartRef}>
          {loading && <IpProgressBar />}
          {isLinear && (
            <IpLinearChart
              horizontalScaleArray={horizontalScaleArray}
              data={data}
              legend={mockLegends}
              lines={colorsConfig}
              dots={dots}
              animation={animation}
            />
          )}
          {isBar && (
            <IpBarChart
              {...(percentage
                ? {
                    verticalScaleArray: percentageVerticalScaleArray,
                    data: transformDataArray(data),
                    verticalScaleType: 'array',
                  }
                : { data })}
              bars={colorsConfig}
              horizontalScaleArray={horizontalScaleArray}
              legend={mockLegends}
              stacked={true}
              grouped={false}
              animation={animation}
              labelOptions={labelOptions}
              interaction={interaction}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
