import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import React from 'react'
import { useIsInitialFilters } from '../../../../hooks/useIsInitialFilters'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'

export const FilterActionButton = ({
  onClick,
  state,
}: {
  onClick: () => void
  state: 'collapse' | 'smart'
}) => {
  const { getIsInitialFilters } = useIsInitialFilters()

  const isDotVisible = state === 'smart'
  const isActive = isDotVisible ? !getIsInitialFilters() : false

  return (
    <IpButton
      variant="contained"
      classes="filters_action-btn"
      onClick={onClick}
    >
      <IpIcon fontSize="25px" classes={isActive ? 'active' : ''}>
        {state === 'collapse' ? <ArrowBackIcon /> : <FilterAltIcon />}
      </IpIcon>
    </IpButton>
  )
}
