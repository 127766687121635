import type { Theme } from '@mui/material'
import formStyles from '@infopulse-design-system/shared/theme/components/IpForm'
import dateTimePickerStyles from '@infopulse-design-system/shared/theme/components/IpDateTimePicker'

export default (theme: Theme) => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '16px',

          '&.IpDateTimePicker .MuiInputBase-root.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: theme.palette.error.main,
            },
          },
          '&.IpDateTimePicker .MuiInput-underline': {
            paddingTop: 0,
          },
          '& + .MuiPickersPopper-root': {
            '& .MuiClock-root .MuiButtonBase-root': {
              color: theme.palette.primary.dark,
            },
          },
          '&.IpTime-am + .MuiPickersPopper-root': {
            '& .MuiClock-root .MuiClock-amButton': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.text.secondary,
            },
          },
          '&.IpTime-pm + .MuiPickersPopper-root': {
            '& .MuiClock-root .MuiClock-pmButton': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.text.secondary,
            },
          },
          '& > .IpForm-element-label': {
            margin: formStyles.form_label_margin,
          },
          '&.IpTime-pm,&.IpTime-am': {
            minWidth: dateTimePickerStyles.date_time_picker_input_min_width,
            '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline,& .MuiInputBase-colorPrimary:before':
              {
                borderColor: theme.palette.primary.dark,
              },
            '& .MuiSvgIcon-root ': {
              color: theme.palette.primary.dark,
            },
            '& .MuiInputBase-root .MuiInputBase-input': {
              padding: dateTimePickerStyles.date_time_picker_button_padding,
            },
          },
        },
      },
    },
  }
}
