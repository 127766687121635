import { useState } from 'react'
import { SORT_MENU_CONFIG } from '../components/custom/SortMenu/constants/sort-menu-config'
import type {
  SortMenuConfigItem,
  SortMenuType,
} from '../components/custom/SortMenu/types/sort-menu-config.type'
import type { OrderDirection } from '../constants/enums'
import { OrderByFields } from '../constants/enums'
import { updateFilters } from '../redux/slices/filtersSlice'
import { useAppDispatch } from '../redux/store'
import useFilters from './useFilters'

export const useSortMenu = (sortMenuType: SortMenuType) => {
  const dispatch = useAppDispatch()
  const { filterType, filters } = useFilters()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuConfig = SORT_MENU_CONFIG[sortMenuType]
  const menuState = Boolean(anchorEl)
  const isAscending = filters.order_direction !== 'desc'

  const onMenuItemChange = (
    orderBy: SortMenuConfigItem,
    orderDirection: keyof typeof OrderDirection
  ) => {
    dispatch(
      updateFilters({
        filterType,
        type: 'order_by',
        value: orderBy,
      })
    )
    dispatch(
      updateFilters({
        filterType,
        type: 'order_direction',
        value: orderDirection,
      })
    )
    setAnchorEl(null)
  }

  const isMenuItemDisabled = (config: SortMenuConfigItem): boolean => {
    return OrderByFields.found_texts === config && !filters.search
  }

  return {
    isAscending,
    isMenuItemDisabled,
    anchorEl,
    menuState,
    menuConfig,
    currentOrderBy: filters.order_by ?? menuConfig[0],
    onMenuItemChange,
    setAnchorEl,
  }
}
