import React from 'react'
import type { ReactNode } from 'react'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import type { IpListItemIconPropTypes } from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpList/IpListItemIcon/styles.scss'

type IpListItemIconPropTypesReact = IpListItemIconPropTypes<ReactNode>

export function IpListItemIcon(props: IpListItemIconPropTypesReact) {
  const { classes, icon } = props
  const customClasses = formatClassName(
    generateClasses('list-item-icon', 'react'),
    classes
  )

  return <ListItemAvatar className={customClasses}>{icon}</ListItemAvatar>
}
