import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpCheckbox'

export default (theme: Theme) => {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiSvgIcon-root': { fontSize: '1em' },
          '& + .MuiTypography-root': {
            margin: `0 0 2px ${styles.checkbox_space_between}`,
            fontSize: `calc(1em - ${styles.checkbox_diff_between_input_and_text})`,
            fontWeight: styles.checkbox_font_weight,
            lineHeight: 1,
          },
          '.MuiFormControlLabel-labelPlacementStart > & + .MuiTypography-root':
            {
              marginRight: styles.checkbox_space_between,
              marginLeft: 0,
            },
          '&.Mui-disabled': {
            color: theme.palette.grey['500'],
            '&.Mui-checked': {
              color: theme.palette.grey['900'],
            },
          },
        },
      },
    },
  }
}
