import { permissionsSelector, rolesSelector } from '../redux/selectors'
import type { PermissionList } from '../redux/slices/profileSlice'
import { useAppSelector } from '../redux/store'
import { hasPermission } from '../utils'

export type GetIsPermission = (permissionKey?: PermissionList) => boolean

export const usePermission = (key?: PermissionList) => {
  const roles = useAppSelector(rolesSelector)
  const permissions = useAppSelector(permissionsSelector)

  const getIsPermission: GetIsPermission = (permissionKey) => {
    if (!(permissions && permissionKey)) return false

    return hasPermission(roles, permissions[permissionKey])
  }

  const isPermission = getIsPermission(key)
  const isAnonymous = !roles.length

  return {
    isPermission,
    isAnonymous,
    permissions,
    roles,
    getIsPermission,
  }
}
