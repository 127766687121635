import React from 'react'
import type { ElementType, ReactNode } from 'react'
import Badge from '@mui/material/Badge'
import type {
  IpBadgeAnchorOriginTypes,
  IpBadgePropTypes,
} from '@infopulse-design-system/shared/types/IpBadge.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpBadge/styles.scss'

export type IpBadgePropTypesReact = IpBadgePropTypes<ReactNode, ElementType> & {
  children: ReactNode
}

/**
 * `IpBadge` generates a small badge to the top-right (by default) of its child(ren).
 * While the child component can be of any type, it is commonly used with text content.
 * `IpBadge` effectively highlights or adds additional information to the child
 * component, providing a visually appealing way to draw attention or convey important
 * details.
 */
export function IpBadge(props: IpBadgePropTypesReact) {
  const {
    badgeContent,
    children,
    classes,
    color,
    component = 'span',
    horizontalPosition,
    invisible,
    max,
    overlap,
    showZero,
    variant,
    verticalPosition,
  } = props

  const isCentered = verticalPosition === 'center'

  const anchorOriginPlaceholder: IpBadgeAnchorOriginTypes = isCentered
    ? { vertical: 'top', horizontal: horizontalPosition || 'right' }
    : {
        vertical: verticalPosition || 'top',
        horizontal: horizontalPosition || 'right',
      }

  const customClasses = formatClassName(
    generateClasses('badge', 'react'),
    isCentered ? 'IpBadge--centered' : '',
    classes
  )

  return (
    <Badge
      anchorOrigin={anchorOriginPlaceholder}
      badgeContent={badgeContent}
      className={customClasses}
      color={color}
      component={component}
      invisible={invisible}
      max={max}
      overlap={overlap}
      showZero={showZero}
      variant={variant}
    >
      {children}
    </Badge>
  )
}
