import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpTreeView'

export default (theme: Theme) => {
  return {
    MuiTreeView: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          overflow: 'hidden',
          '.IpTreeView-startIcon, .IpTreeView-endIcon': {
            color: theme.palette.primary.dark,
            margin: styles.tree_view_icon_container_margin,
          },
          '.IpTreeView-startIcon': {
            padding: styles.tree_view_start_icon_padding,
          },
          '.IpTreeView-endIcon': {
            padding: styles.tree_view_end_icon_padding,
          },
          '& .MuiTreeItem-root': {
            '& .MuiTreeItem-label': {
              display: 'flex',
              alignItems: 'center',
              padding: 0,
            },
            '& .MuiTreeItem-iconContainer': {
              margin: 0,
              paddingRight: styles.tree_view_icon_container_space_between,
              '& .MuiSvgIcon-root': {
                fontSize: styles.tree_view_collapse_icon_size,
                color: theme.palette.primary.main,
              },
            },
          },
          '& .MuiTreeItem-content': {
            position: 'relative',
            padding: `${styles.tree_view_item_content_padding_vertical} ${styles.tree_view_item_content_padding_horizontal}`,
            width: 'auto',
            '&.MuiTreeItem-content:hover:before': {
              background: theme.palette.primary.main,
            },
            '&.MuiTreeItem-content.Mui-focused:before': {
              background: theme.palette.primary.dark,
            },
            '&.MuiTreeItem-content:hover': {
              background: theme.palette.primary.main,
              color: styles.tree_view_text_color_focus_and_hover,
              '& .MuiSvgIcon-root': {
                color: styles.tree_view_text_color_focus_and_hover,
              },
            },
            '&.MuiTreeItem-content.Mui-focused': {
              background: theme.palette.primary.dark,
              color: styles.tree_view_text_color_focus_and_hover,
              '& .MuiSvgIcon-root': {
                color: styles.tree_view_text_color_focus_and_hover,
              },
            },
          },
          '& .MuiCollapse-root': {
            margin: 0,
          },
          '& .Mui-selected.Mui-selected': {
            background: theme.palette.primary.light,
          },
        },
      },
    },
  }
}
