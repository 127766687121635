/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LANG_RU } from '../../constants'
import type { SettingsLang } from '../types/settings'

export type SettingsState = {
  lang?: SettingsLang
}

export const initialSettingsState = {
  lang: {
    language: DEFAULT_LANG_RU.lang,
    locale: DEFAULT_LANG_RU.locale,
    attribute_suffix: DEFAULT_LANG_RU.suffix,
  },
}

export const SettingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    setLanguage: (state, { payload }: PayloadAction<SettingsLang>) => {
      state.lang = payload
    },
  },
})

export const { setLanguage } = SettingsSlice.actions

export default SettingsSlice.reducer
