import { Delete } from '@mui/icons-material'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  onClick: () => void
  placement: IpTooltipPropTypesReact['placement']
}

export const RemoveMedia = ({ placement, onClick }: Props) => {
  return (
    <IpTooltip
      arrow
      title="Remove Media"
      placement={placement}
      content={
        <IpIcon onClick={onClick} color="primary" classes="remove-media">
          <Delete />
        </IpIcon>
      }
    />
  )
}
