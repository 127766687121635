import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import type { IpTextEllipsisPropTypes } from '@infopulse-design-system/shared/types/IpTextEllipsis.types'
import { styled } from '@mui/material/styles'
import useResponsive from '../../../../hooks/useResponsive'
import { IpTooltip } from '../../atoms/IpTooltip'

export const StyledIpTextEllipsis = styled('span')(() => ({
  display: 'flex',
  width: '100%',
  '& span': {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export type IpTextEllipsisPropTypeReact = IpTextEllipsisPropTypes & {
  children: ReactNode
}

export function IpTextEllipsis(props: IpTextEllipsisPropTypeReact) {
  const { isTextOverflow = false, classes, children, placement } = props
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLSpanElement>(null)
  const { windowWidth } = useResponsive()

  const resizeHandler = () => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current!.scrollWidth >
          textElementRef.current!.offsetWidth
      )
    }
  }

  useEffect(() => {
    resizeHandler()
  }, [windowWidth])

  const customClasses = formatClassName(
    generateClasses('text-ellipsis', 'react'),
    classes
  )

  return (
    <StyledIpTextEllipsis className={customClasses}>
      {isTextOverflow ? (
        <IpTooltip
          arrow
          placement={placement}
          disableHoverListener={!isOverflowed}
          content={<span ref={textElementRef}>{children}</span>}
          title={children}
        />
      ) : (
        <span>{children}</span>
      )}
    </StyledIpTextEllipsis>
  )
}
