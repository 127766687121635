import { debounce } from '@mui/material'
import { useCallback, useEffect } from 'react'
import {
  FILTERS_DEBOUNCE_TIMEOUT,
  INFINITE_SCROLL_PORTION_SIZE,
} from '../../constants'
import { profileSelector } from '../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { fetchBugs } from '../../redux/thunk/bugsThunk'
import type { FiltersState } from '../../redux/types/filters'
import useFilters from '../useFilters'
import { useIsInitialFilters } from '../useIsInitialFilters'
import useMainPageActiveTab from '../useMainPageActiveTab'

export const useMainBugs = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(profileSelector)
  const { isMainBugsTab } = useMainPageActiveTab()
  const { mainBugsFilters } = useFilters()
  const { getIsInitialFilters } = useIsInitialFilters()
  const isAuth = Boolean(profile.data.name)

  const handleFetchBugsDebounced = useCallback(
    debounce((filters: FiltersState) => {
      dispatch(fetchBugs(filters))
    }, FILTERS_DEBOUNCE_TIMEOUT),
    []
  )

  useEffect(() => {
    if (!isAuth || !isMainBugsTab) return

    const isNextPage = mainBugsFilters.limit > INFINITE_SCROLL_PORTION_SIZE
    const isInitialFilters = getIsInitialFilters()

    if (isInitialFilters || isNextPage) {
      dispatch(fetchBugs(mainBugsFilters))
    } else if (!isInitialFilters) {
      handleFetchBugsDebounced(mainBugsFilters)
    }
  }, [mainBugsFilters])

  useEffect(() => {
    if (!isAuth || !isMainBugsTab) return
    dispatch(fetchBugs(mainBugsFilters))
  }, [isMainBugsTab])
}
