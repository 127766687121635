import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  INFINITE_SCROLL_PORTION_SIZE,
  INFINITE_SCROLL_THRESHOLD,
} from '../../../../constants'
import useFilters from '../../../../hooks/useFilters'
import { useResultListScrollTo } from '../../../../hooks/useResultListScrollTo'
import {
  activePersonaSelector,
  personaListSelector,
} from '../../../../redux/selectors'
import { updateFilters } from '../../../../redux/slices/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import { PersonaResultItem } from '../PersonaResultItem'

const endMessage = <div className="info-message">You have seen all results</div>

type Props = {
  isLessLg: boolean
}

export const PersonasResultPanel = ({ isLessLg }: Props) => {
  const dispatch = useAppDispatch()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { filters, filterType } = useFilters()
  const { data, isLoading } = useAppSelector(personaListSelector)
  const activePersonaState = useAppSelector(activePersonaSelector)
  const activePersonaId = activePersonaState?.personaId ?? ''
  const { data: personData, hasMoreResults } = data

  useResultListScrollTo({
    refNode: scrollRef.current,
    activeId: activePersonaId,
    data: personData,
  })

  const getMoreItems = async () => {
    await dispatch(
      updateFilters({
        filterType,
        type: 'skip',
        value: filters.skip + INFINITE_SCROLL_PORTION_SIZE,
      })
    )
  }

  const resultsCount = personData.length

  return (
    <div id="InfiniteScroll" ref={scrollRef}>
      {isLoading && <IpProgressBar />}
      {resultsCount ? (
        <InfiniteScroll
          scrollThreshold={INFINITE_SCROLL_THRESHOLD}
          dataLength={hasMoreResults ? resultsCount + 1 : resultsCount}
          next={getMoreItems}
          loader="Loading..."
          hasMore={hasMoreResults}
          scrollableTarget="InfiniteScroll"
          endMessage={endMessage}
        >
          {personData.map((item, index) => (
            <PersonaResultItem
              key={item.id}
              index={index}
              isLessLg={isLessLg}
            />
          ))}
        </InfiniteScroll>
      ) : (
        'No data found by current filters'
      )}
    </div>
  )
}
