import { MsalProvider } from '@azure/msal-react'
import '@infopulse-design-system/shared/assets/styles/index.scss'
import { ThemeProvider } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import msalInstance from './services/msalService'
import { IpNotificationProvider } from './services/notificationService'
import theme from './theme'

const container = document.getElementById('root')!
const root = createRoot(container)
const persistor = persistStore(store)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <IpNotificationProvider
              position={{ horizontal: 'right', vertical: 'bottom' }}
              domRoot={document.body}
            >
              <StyledEngineProvider injectFirst>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </StyledEngineProvider>
            </IpNotificationProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
