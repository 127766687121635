import React from 'react'
import { generatePath } from 'react-router-dom'
import { usePageName } from '../../../../hooks/usePageName'
import { CopyMediaLink, FullScreenButton } from '../../icons'

import './index.scss'

type Props = {
  id: string
  path: string
}

export const PersonaAdditionMenu = ({ id, path }: Props) => {
  const { isPersonaPage } = usePageName()

  const to = generatePath(`/${path}/:id`, { id })

  return (
    <div className="persona_addition-menu">
      {!isPersonaPage && <FullScreenButton placement="left" to={to} />}
      <CopyMediaLink placement="left" id={id} path={path} />
    </div>
  )
}
