export const ChartType = {
  bar: 'bar',
  linear: 'linear',
} as const

export const ChartLabel = {
  personas: 'persona_id',
  sources: 'source_id',
  programs: 'program_id',
  authors: 'author_id',
} as const

export enum CardInterval {
  'day' = 'day',
  'month' = 'month',
  'year' = 'year',
}
