import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { INFINITE_SCROLL_THRESHOLD } from '../../../../constants'
import useFilters from '../../../../hooks/useFilters'
import type { ReportBasketState } from '../../../../redux/slices/reportBasketSlice'
import { useAppDispatch } from '../../../../redux/store'
import { fetchReportBasket } from '../../../../redux/thunk/reportBasketThunk'
import { IpCheckbox } from '../../../ipDesignSystemComponents/atoms/IpCheckbox'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import { EndMessage } from '../../EndMessage'
import { BasketItem } from '../BasketItem'
import './index.scss'

type Props = {
  activeItems: string[]
  setActiveItems: Dispatch<SetStateAction<string[]>>
  basketState: ReportBasketState
  setIsSelectedAll: Dispatch<boolean>
}

export const BasketResultPanel = ({
  activeItems,
  setActiveItems,
  basketState,
  setIsSelectedAll,
}: Props) => {
  const dispatch = useAppDispatch()
  const { filters } = useFilters()
  const basketItems = basketState.data.data
  const resultsCount = basketItems.length

  const isActiveItem = (id: string): boolean | undefined =>
    activeItems.includes(id)

  const handleItemClick = (id: string) => {
    if (isActiveItem(id)) {
      setActiveItems((prev) => prev.filter((item) => item !== id))
      setIsSelectedAll(false)
    } else {
      setActiveItems((prev) => [...prev, id])
    }
  }

  const getMoreItems = async () => {
    dispatch(
      fetchReportBasket({
        ...filters,
        continuationToken: basketState.data.continuationToken,
      })
    )
  }

  return (
    <>
      {basketState.isLoading && <IpProgressBar />}
      {resultsCount ? (
        <InfiniteScroll
          scrollThreshold={INFINITE_SCROLL_THRESHOLD}
          dataLength={
            basketState.data.hasMoreResults ? resultsCount + 1 : resultsCount
          }
          next={getMoreItems}
          loader=""
          hasMore={basketState.data.hasMoreResults}
          scrollableTarget="InfiniteScroll"
          endMessage={<EndMessage />}
        >
          {basketItems.map((item, index) => {
            return (
              <div className="basket-item" key={item.id}>
                <IpCheckbox
                  checked={isActiveItem(item.id)}
                  onChange={() => handleItemClick(item.id)}
                  size="24px"
                />
                <BasketItem
                  index={index}
                  isActive={!!isActiveItem(item.id)}
                  onClick={() => handleItemClick(item.id)}
                />
              </div>
            )
          })}
        </InfiniteScroll>
      ) : (
        'No data found by current filters'
      )}
    </>
  )
}
