import React from 'react'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import { DICTIONARY } from '../../../../../redux/types/dictionaries'
import { CheckBox } from '../CheckBox'
import './index.scss'

type FilterCheckboxesVariants = DICTIONARY.media_type | DICTIONARY.networks

export type FilterCheckboxProps = {
  variant: FilterCheckboxesVariants
}

export const FilterCheckboxes = ({ variant }: FilterCheckboxProps) => {
  const { data } = useAppSelector(dictionariesSelector)
  const { suffix } = useLanguage()
  const checkboxes = data[variant]

  return (
    <div className="filter-checkbox">
      {checkboxes.map((item) => {
        return (
          <CheckBox
            key={item.id}
            id={item.id}
            name={item[`name${variant === DICTIONARY.networks ? '' : suffix}`]}
            type={variant}
            labelColor="text.primary"
          />
        )
      })}
    </div>
  )
}
