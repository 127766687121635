import type { SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import useFilters from '../../../../../hooks/useFilters'
import { updateFilters } from '../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../redux/store'
import { IpSlider } from '../../../../ipDesignSystemComponents/atoms/IpSlider'
import { IpTextField } from '../../../../ipDesignSystemComponents/atoms/IpTextField'

type AvailableTypes = 'percentage' | 'similar_embeddings_num' | 'media_num'

type Props = {
  min: number
  max: number
  updateFilterTypeFrom: `${AvailableTypes}_start`
  updateFilterTypeTo: `${AvailableTypes}_end`
  resetType: AvailableTypes
}

export const FilterRangeNumber = ({
  min,
  max,
  updateFilterTypeFrom,
  updateFilterTypeTo,
  resetType,
}: Props) => {
  const dispatch = useAppDispatch()
  const { filterType, reset, resetValue } = useFilters(resetType)
  const [sliderValue, setSliderValue] = useState<number[]>([min, max])
  const [value, setValue] = useState<number[] | undefined>()

  const onChangeFrom = (e: any) => {
    let newValue = parseInt(e.target.value, 10)

    if (newValue > max) newValue = max
    if (newValue < 0) newValue = 0
    if (newValue) {
      setSliderValue((sliderState) => [newValue, sliderState[1]])
      dispatch(
        updateFilters({
          filterType,
          type: updateFilterTypeFrom,
          value: newValue,
        })
      )
    }
  }

  const onChangeTo = (e: any) => {
    let newValue = parseInt(e.target.value, 10)

    if (newValue > max) newValue = max
    if (newValue < 0) newValue = 0
    if (newValue) {
      setSliderValue((sliderState) => [sliderState[0], newValue])
      dispatch(
        updateFilters({
          filterType,
          type: updateFilterTypeTo,
          value: newValue,
        })
      )
    }
  }

  const onSliderChange = (_: Event, newValue: number | number[]) => {
    const newSliderValue = newValue as number[]
    setSliderValue(newSliderValue)
  }

  const onSliderChangeCommitted = (
    _: Event | SyntheticEvent<Element, Event>,
    values: number | number[]
  ) => {
    if (typeof values !== 'number') {
      setValue(values)
    }
  }

  useEffect(() => {
    if (value) {
      const [start, end] = value
      dispatch(
        updateFilters({
          filterType,
          type: updateFilterTypeFrom,
          value: start,
        })
      )
      dispatch(
        updateFilters({
          filterType,
          type: updateFilterTypeTo,
          value: end,
        })
      )
    }
  }, [value])

  useEffect(() => {
    setSliderValue([min, max])
  }, [resetValue, reset[filterType]])

  return (
    <div className="filter-range">
      <div className="filter-range-inputs">
        <IpTextField
          variant="standard"
          label="From:"
          type="number"
          value={sliderValue[0]}
          inputProps={{ min: 0, max }}
          onChange={onChangeFrom}
        />
        <div className="filter-range-divider"></div>
        <IpTextField
          variant="standard"
          label="To:"
          type="number"
          value={sliderValue[1]}
          inputProps={{ min: 0, max }}
          onChange={onChangeTo}
        />
      </div>
      <IpSlider
        max={max}
        size="small"
        onChange={onSliderChange}
        onChangeCommitted={onSliderChangeCommitted}
        value={sliderValue}
      />
    </div>
  )
}
