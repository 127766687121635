import React from 'react'
import { IpLink } from '../../components/ipDesignSystemComponents/atoms/IpLink'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import { useLanguage } from '../../hooks/useLanguage'
import { content } from './content'

import './index.scss'

function getText(text: { [key: string]: string }, lang: string) {
  return text[lang] || text.ua || text.en
}

function AboutUs() {
  const { lang } = useLanguage()

  return (
    <div className="page about-us">
      <IpText variant="h1" align="center" color="primary" gutterBottom>
        {getText(content.title, lang)}
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line1, lang)}
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line2, lang)}
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line3, lang)}{' '}
        <IpLink target="_blank" href={content.line3.link}>
          {content.line3.link}
        </IpLink>
        .
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line4, lang)}{' '}
        <IpText color="primary" variant="body4" component="span">
          {content.line4.link}
        </IpText>
        .
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line5, lang)}{' '}
        <IpText color="primary" variant="body4" component="span">
          {content.line5.link}
        </IpText>
        .
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line6, lang)}{' '}
        <IpText color="primary" variant="body4" component="span">
          {content.line6.link}
        </IpText>
        .
      </IpText>
      <IpText variant="body4" paragraph>
        {getText(content.line7, lang)}
      </IpText>
    </div>
  )
}

export default AboutUs
