/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SimilarSpeakersResponse } from '../../api/apiTypes'
import {
  fetchSimilarSpeakers,
  fetchSimilarSpeakersNext,
} from '../thunk/similarSpeakersThunk'
import type { ApiData } from '../types'

export type SimilarSpeakersState = ApiData<SimilarSpeakersResponse>

export const initialSimilarSpeakersState: SimilarSpeakersState = {
  data: { data: [], hasMoreResults: false },
  isLoading: false,
  error: '',
}

export const SimilarSpeakersSlice = createSlice({
  name: 'similarSpeakers',
  initialState: initialSimilarSpeakersState,
  reducers: {
    setSimilarSpeakersList: (
      state,
      { payload }: PayloadAction<SimilarSpeakersResponse>
    ) => {
      state.data = payload
    },
    resetSimilarSpeakersList: (state) => {
      state.data = initialSimilarSpeakersState.data
      state.isLoading = initialSimilarSpeakersState.isLoading
      state.error = initialSimilarSpeakersState.error
    },
    setSimilarSpeakersListNext: (
      state,
      { payload }: PayloadAction<SimilarSpeakersResponse>
    ) => {
      state.data.data = [...state.data.data, ...payload.data]
      state.data.hasMoreResults = payload.hasMoreResults
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSimilarSpeakers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSimilarSpeakers.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchSimilarSpeakers.rejected, (state, action) => {
      if (action.payload !== 'canceled') {
        state.isLoading = false
        state.error = action.payload as string
      }
    })
    builder.addCase(fetchSimilarSpeakersNext.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSimilarSpeakersNext.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchSimilarSpeakersNext.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  setSimilarSpeakersList,
  resetSimilarSpeakersList,
  setSimilarSpeakersListNext,
} = SimilarSpeakersSlice.actions

export default SimilarSpeakersSlice.reducer
