import type { ReactNode, SyntheticEvent } from 'react'
import React, { createContext } from 'react'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { IpCardPropTypes } from '@infopulse-design-system/shared/types/IpCard.types'
import shadows from '@infopulse-design-system/shared/theme/shadows'
import '@infopulse-design-system/shared/theme/components/IpCard/styles.scss'

export const IpCardTestId = 'IpCardTestId'
const defaultSkeletonAnimation = 'pulse'

export type IpCardPropTypesReact = IpCardPropTypes<SyntheticEvent> & {
  children: ReactNode
}

type SkeletonAnimationType = 'pulse' | 'wave' | false

const IpCardStyled = styled(Card)(
  ({ disabled, hidden, raised }: IpCardPropTypesReact) => {
    return {
      boxShadow: raised
        ? shadows.card_raised_box_shadow
        : shadows.card_box_shadow,
      pointerEvents: disabled ? 'none' : 'auto',
      display: hidden ? 'none' : 'block',
    }
  }
)

export const getCardSkeletonAnimation = (
  animation: IpCardPropTypesReact['skeleton'] = defaultSkeletonAnimation
): SkeletonAnimationType =>
  animation === 'none' || animation === 'static' ? false : animation

export const CardContext = createContext<{
  isLoading: boolean
  skeletonAnimation: SkeletonAnimationType
}>({
  isLoading: false,
  skeletonAnimation: getCardSkeletonAnimation(defaultSkeletonAnimation),
})

/**
 * `IpCard`'s are versatile components used to present information and actions related to a specific subject.
 * They provide a structured surface to display content and interactive elements in a visually appealing manner.
 *
 * The primary goal of cards is to make it easy for users to scan and find relevant information quickly.
 * By carefully organizing elements such as text, images, and buttons, cards ensure that the hierarchy of
 * information is clearly communicated.
 *
 * Cards are particularly useful for presenting concise and focused content, making them ideal for displaying
 * snippets, summaries, or previews of larger pieces of information. They can contain text, images, icons,
 * and various interactive elements that allow users to take actions related to the displayed content.
 *
 * With their clean and organized layout, cards enhance the user experience by providing a visually appealing
 * and easily scannable presentation of content and actions.
 *
 * When using the `IpCard` component, we recommend incorporating the following components:
 *
 * `IpCardActionArea`: This component provides an interactive area within the card that users can click or tap.
 * It is typically used to wrap the main content of the card, allowing users to perform actions or navigate
 * to more detailed information.
 *
 * `IpCardActions`: Use this component to include various buttons or interactive elements that trigger specific
 * actions related to the card's content. It provides a convenient way to incorporate actions such as liking,
 * sharing, or deleting.
 *
 * `IpCardContent`: This component serves as the main content container within the card. It allows you to
 *  present text, images, or any other relevant information associated with the card's subject. Use this
 *  component to display the core content of the card.
 *
 * `IpCardHeader`: Use this component to add a header block to the card. It typically contains a title,
 * subtitle, or any other information that provides additional context or description for the card's content.
 * The header helps users quickly understand the card's purpose or topic.
 *
 * `IpCardMedia`: If you need to display images or video content within the card, use this component.
 * It provides a dedicated area for visually representing media elements associated with the card's subject.
 *
 * Each of these components includes an isLoading prop, allowing you to indicate loading states while data
 * is being fetched or processed. During this loading state, you can display a skeleton (`IpSkeleton`) to
 * provide visual feedback to the user, indicating that the content is being loaded.
 *
 * By utilizing these components, you can create visually appealing and functional cards with seamless loading
 * experiences for your users.
 */

export const IpCard = (props: IpCardPropTypesReact) => {
  const {
    isLoading = false,
    skeleton = defaultSkeletonAnimation,
    children,
    classes,
    disabled = false,
    hidden = false,
    onClick,
    raised = true,
  } = props

  const customClasses = formatClassName(
    generateClasses('card', 'react'),
    classes
  )

  const skeletonAnimation = getCardSkeletonAnimation(skeleton)

  const contextValue = {
    isLoading: isLoading && skeleton !== 'none',
    skeletonAnimation,
  }

  return (
    <IpCardStyled
      disabled={disabled}
      hidden={hidden || (isLoading && skeleton === 'none')}
      className={customClasses}
      data-testid={IpCardTestId}
      onClick={!disabled ? onClick : undefined}
      raised={raised}
    >
      <CardContext.Provider value={contextValue}>
        {children}
      </CardContext.Provider>
    </IpCardStyled>
  )
}
