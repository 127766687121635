import styles from '@infopulse-design-system/shared/theme/components/IpCard/IpCardActions'

export default {
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: styles.card_actions_padding,
        '& > :not(:first-of-type)': {
          marginLeft: styles.card_actions_space_between,
        },
      },
    },
  },
}
