import { createAsyncThunk } from '@reduxjs/toolkit'
import type { CreateProcessParams } from '../../api/mediaProcessing'
import {
  apiCreateProcess,
  apiDeleteProcessById,
  apiGetProcessById,
  apiGetProcessList,
} from '../../api/mediaProcessing'
import {
  removeProcessingEl,
  setProcessingEl,
  updateProcessingEl,
  updateProcessingList,
} from '../slices/MediaProcessingSlice'
import type { CreateProcessRes, ProcessRes } from '../types/mediaProcessing'

export const createMediaProcessing = createAsyncThunk<
  CreateProcessRes,
  CreateProcessParams
>('mediaProcessing/create', async (params, thunkAPI) => {
  try {
    const data = await apiCreateProcess(params)
    thunkAPI.dispatch(setProcessingEl(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

export const updateMediaProcessingThunk = createAsyncThunk<ProcessRes, string>(
  'mediaProcessing/update',
  async (id, thunkAPI) => {
    try {
      const data = await apiGetProcessById(id)
      thunkAPI.dispatch(updateProcessingEl(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)

export const getMediaProcessingList = createAsyncThunk<ProcessRes[]>(
  'mediaProcessing/get-list',
  async (_, thunkAPI) => {
    try {
      const data = await apiGetProcessList()
      thunkAPI.dispatch(updateProcessingList(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)

export const deleteMediaProcessingById = createAsyncThunk<void, string>(
  'mediaProcessing/delete',
  async (id, thunkAPI) => {
    try {
      const data = await apiDeleteProcessById(id)
      thunkAPI.dispatch(removeProcessingEl(id))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)
