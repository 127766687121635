import { useEffect, useState } from 'react'

export const useWindowInnerHeight = () => {
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight)

  const resizeHandler = () => {
    const { innerHeight } = window
    setWindowInnerHeight(innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return { windowInnerHeight }
}
