import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import { Popover } from '@mui/material'
import type { Dispatch } from 'react'
import React, { useEffect, useState } from 'react'
import { DEFAULT_LANG_RU, UnknownSpeakerData } from '../../../../../constants'
import { PERMISSIONS } from '../../../../../constants/enums'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { usePermission } from '../../../../../hooks/usePermission'
import { useSimilarSpeakerList } from '../../../../../hooks/useSimilarSpeakerList'
import type { NarrativeSubcategory } from '../../../../../redux/types/dictionaries'
import type { MediaData, Transcription } from '../../../../../redux/types/media'
import type { IpListItemPropTypesReact } from '../../../../ipDesignSystemComponents/atoms/IpList'
import { IpList } from '../../../../ipDesignSystemComponents/atoms/IpList'
import './index.scss'
import { NarrativesContent } from './NarrativesContent'
import { ReportingBasketContent } from './ReportingBasketContent'
import { SignOfCrimesContent } from './SignOfCrimesContent'
import { UpdateTranscriptionContent } from './UpdateTranscriptionContent'

type StateModes =
  | 'menu'
  | 'incorrectText'
  | 'reportingBasket'
  | 'signOfCrimes'
  | 'narratives'

type TranscriptionMenuProps = {
  anchorEl: HTMLElement | null
  setAnchorEl: Dispatch<HTMLElement | null>
  setSelectSpeakerAnchorEl: Dispatch<HTMLElement | null>
  open: boolean
  setWrongSpeakerMode: Dispatch<boolean>
  transcription: Transcription
  currentMediaData: MediaData
}

const customClasses: Partial<Record<StateModes, string>> = {
  incorrectText: 'transcription-menu_update-text',
  reportingBasket: 'transcription-menu_reporting',
  signOfCrimes: 'transcription-menu_reporting',
  narratives: 'transcription-menu_narratives',
}

// used only for result tab
export const TranscriptionMenu = ({
  anchorEl,
  setAnchorEl,
  setSelectSpeakerAnchorEl,
  open,
  transcription,
  currentMediaData,
  setWrongSpeakerMode,
}: TranscriptionMenuProps) => {
  const { suffix, lang } = useLanguage()
  const isOriginalLang = lang === DEFAULT_LANG_RU.lang
  const { reset } = useSimilarSpeakerList()
  const { getIsPermission } = usePermission()

  const transcriptionText = transcription[`text${suffix}` as const] ?? ''

  const [stateMode, setStateMode] = useState<StateModes>('menu')
  const [textValue, setTextValue] = useState<string>(transcriptionText)
  const [selectedNarratives, setSelectedNarratives] = useState<
    NarrativeSubcategory[]
  >([])

  const isMenuMode = stateMode === 'menu'
  const isReportingBasketMode = stateMode === 'reportingBasket'
  const isIncorrectTextMode = stateMode === 'incorrectText'
  const isSignOfCrimes = stateMode === 'signOfCrimes'
  const isNarratives = stateMode === 'narratives'
  const isUnknownSpeaker = transcription.persona_id === UnknownSpeakerData.id

  const onCloseHandler = () => {
    setAnchorEl(null)
    setWrongSpeakerMode(false)
  }

  const onSimilarSpeakerLinkClick = () => {
    onCloseHandler()
    reset()
  }

  useEffect(() => {
    setStateMode('menu')
    setTextValue(transcriptionText)
  }, [anchorEl])

  useEffect(() => {
    setSelectedNarratives([])
  }, [isNarratives])

  const listConfig: IpListItemPropTypesReact[] = [
    transcription?.persona_id
      ? {
          textData: {
            text: 'Incorrect speaker',
          },
          onClick: () => {
            setWrongSpeakerMode(true)
            setSelectSpeakerAnchorEl(anchorEl)
            setAnchorEl(null)
          },
        }
      : {},
    {
      textData: {
        text: 'Incorrect text in transcription',
      },
      onClick: () => setStateMode('incorrectText'),
    },
    getIsPermission(PERMISSIONS.report_basket)
      ? {
          textData: {
            text: 'Reporting Basket',
          },
          onClick: () => setStateMode('reportingBasket'),
        }
      : {},
    getIsPermission(PERMISSIONS.signs_of_crimes_annotation) && isOriginalLang
      ? {
          textData: {
            text: 'Annotate signs of crimes',
          },
          onClick: () => setStateMode('signOfCrimes'),
        }
      : {},
    getIsPermission(PERMISSIONS.narratives_annotation) && isOriginalLang
      ? {
          textData: {
            text: 'Annotate narratives',
          },
          onClick: () => setStateMode('narratives'),
        }
      : {},
    !isUnknownSpeaker && transcription.speaker
      ? {
          textData: {
            text: (
              <a
                href={`${process.env.REACT_APP_HOST}?mediaId=${
                  transcription.media_id ?? ''
                }&speaker=${transcription.speaker ?? ''}`}
                target="_blank"
                rel="noreferrer"
              >
                Find similar speakers
              </a>
            ),
          },
          onClick: onSimilarSpeakerLinkClick,
        }
      : {},
  ]

  const classNames = formatClassName(
    'transcription-menu',
    customClasses[stateMode]
  )

  return (
    <Popover
      className={classNames}
      anchorEl={anchorEl}
      open={open}
      onClose={onCloseHandler}
      onBackdropClick={onCloseHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {isIncorrectTextMode && (
        <UpdateTranscriptionContent
          transcription={transcription}
          currentMediaData={currentMediaData}
          textValue={textValue}
          setTextValue={setTextValue}
          onSubmit={onCloseHandler}
        />
      )}
      {isReportingBasketMode && (
        <ReportingBasketContent
          transcription={transcription}
          currentMediaData={currentMediaData}
          textValue={textValue}
          setTextValue={setTextValue}
          onSubmit={onCloseHandler}
        />
      )}
      {isSignOfCrimes && (
        <SignOfCrimesContent
          transcription={transcription}
          textValue={textValue}
          setTextValue={setTextValue}
          onSubmit={onCloseHandler}
        />
      )}
      {isNarratives && (
        <NarrativesContent
          transcription={transcription}
          textValue={textValue}
          setTextValue={setTextValue}
          onSubmit={onCloseHandler}
          selectedNarratives={selectedNarratives}
          setSelectedNarratives={setSelectedNarratives}
        />
      )}
      {isMenuMode && <IpList subheader="Menu" itemsConfig={listConfig} />}
    </Popover>
  )
}
