import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpSwitch'

export default (theme: Theme) => {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: styles.switch_medium_width,
          height: styles.switch_medium_height,
          padding: 0,
          '& .MuiButtonBase-root': {
            top: 0,
            bottom: 0,
            padding: 0,
            height: styles.switch_icon_medium_size,
            width: styles.switch_icon_medium_size,
            backgroundColor: theme.palette.text.secondary,
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: `calc(${styles.switch_border_thickness}*2)`,
            transitionDuration: '300ms',
            '&.MuiSwitch-switchBase:hover': {
              backgroundColor: theme.palette.text.secondary,
            },
            '&.Mui-checked': {
              transform: `translateX(calc(${styles.switch_medium_width} - ${styles.switch_icon_medium_size} - ${styles.switch_border_thickness}*2))`,
              marginLeft: 0,
              '&.MuiSwitch-colorPrimary:not(.Mui-disabled)': {
                color: theme.palette.primary.dark,
              },
              '&:not(.Mui-disabled):before': {
                content: '""',
                color: 'inherit',
                position: 'absolute',
                display: 'block',
                top: styles.switch_check_mark_medium_top,
                width: styles.switch_check_mark_width,
                height: styles.switch_check_mark_height,
                border: 'solid',
                borderWidth: `0 ${styles.switch_mark_thickness} ${styles.switch_mark_thickness} 0`,
                transform: 'rotate(45deg)',
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: '100%',
              height: '100%',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.text.secondary,
              backgroundColor: theme.palette.grey[900],
              borderRadius: '50%',
              '&:before,:after': {
                content: '""',
                position: 'absolute',
                top: '44%',
                left: '23%',
                width: '55%',
                borderTop: `${styles.switch_mark_thickness} solid`,
                transform: 'rotate(-45deg)',
              },
              '&:after': {
                transform: 'rotate(45deg)',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.grey[900],
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 1,
              border: `${styles.switch_border_thickness} solid ${theme.palette.grey[500]}`,
              backgroundColor: theme.palette.text.secondary,
              height: `calc(${styles.switch_medium_height} - ${styles.switch_border_thickness}*2)`,
            },
          },
          '& .MuiSvgIcon-root': {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.grey[600],
            zIndex: 1,
            padding: 0,
            borderRadius: '50%',
            height: '100%',
            width: '100%',
          },
          '& .MuiSwitch-track': {
            borderRadius: styles.switch_border_radius,
            backgroundColor: theme.palette.grey[500],
            opacity: 1,
          },
          '& .MuiButtonBase-root.Mui-checked.MuiSwitch-colorPrimary:not(.Mui-disabled) ~ .MuiSwitch-track':
            {
              backgroundColor: theme.palette.primary.dark,
              opacity: 1,
            },
          '&.MuiSwitch-sizeSmall': {
            backgroundColor: 'inherit',
            height: styles.switch_small_height,
            width: styles.switch_small_width,
            '& .MuiButtonBase-root': {
              marginLeft: styles.switch_border_thickness,
              width: styles.switch_icon_small_size,
              height: styles.switch_icon_small_size,
              '&.Mui-disabled': {
                backgroundColor: 'inherit',
              },
              '&.MuiSwitch-switchBase:hover': {
                backgroundColor: theme.palette.text.secondary,
              },
              '&.Mui-checked': {
                transform: `translateX(calc(${styles.switch_small_width} - ${styles.switch_icon_small_size} - ${styles.switch_border_thickness}))`,
                marginLeft: 0,
                '&:not(.Mui-disabled):before, &:not(.Mui-disabled):after': {
                  top: `calc(${styles.switch_check_mark_small_top} - 1px*2)`,
                },
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                height: `calc(${styles.switch_small_height} - ${styles.switch_border_thickness}*2)`,
              },
            },
            '& .MuiSvgIcon-root': {
              padding: 0.5,
            },
          },
          '~ .MuiTypography-root': {
            margin: styles.switch_label_margin,
          },
        },
      },
    },
  }
}
