import React from 'react'
import { Excel } from '..'
import type { IpIconPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  onClick?: IpIconPropTypesReact['onClick']
  placement: IpTooltipPropTypesReact['placement']
  loading: boolean
  fontSize?: string
}

export const ExportToExcel = ({
  placement,
  onClick,
  loading,
  fontSize,
}: Props) => {
  return (
    <IpTooltip
      arrow
      title={onClick ? 'Export to excel' : ''}
      placement={placement}
      content={
        <IpIcon
          fontSize={fontSize}
          disabled={!onClick}
          onClick={loading ? undefined : onClick}
          color="primary"
          classes={loading ? 'pr-i-circle-animation' : ''}
        >
          <Excel />
        </IpIcon>
      }
    />
  )
}
