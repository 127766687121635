import { FilterTypes } from '../constants/enums'
import { initialFiltersState } from '../redux/slices/filtersSlice'
import type { FilterType } from '../redux/types/filters'
import useFilters from './useFilters'

const getInitialFiltersJson = (
  arr: FilterType[]
): Record<FilterType, string> => {
  return arr.reduce((acc, filterType) => {
    acc[filterType] = JSON.stringify(initialFiltersState[filterType])
    return acc
  }, {} as Record<FilterType, string>)
}

const InitialFiltersJson = getInitialFiltersJson(
  Object.keys(FilterTypes) as FilterType[]
)

export const useIsInitialFilters = () => {
  const { filters, filterType } = useFilters()

  const getIsInitialFilters = () =>
    JSON.stringify(filters) === InitialFiltersJson[filterType]

  return {
    getIsInitialFilters,
  }
}
