import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useEffect, useState } from 'react'
import { apiGetHighlightTranscriptionsData } from '../../../../api'
import useFilters from '../../../../hooks/useFilters'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../hooks/useLanguage'
import { queryTypeSelector } from '../../../../redux/selectors'
import type { QueryType } from '../../../../redux/slices/profileSlice'
import { useAppSelector } from '../../../../redux/store'
import { scrollToCurrentElWithTimeOut } from '../../../../utils'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import type { HighlightsData } from '../../PreviewPanel'
import { isValidInputValue } from '../helpers'
import '../index.scss'

const HighlightPre = '{{'
const HighlightPostTag = '}}'

type Props = {
  highlightsData: HighlightsData
  setHighlightsData: React.Dispatch<React.SetStateAction<HighlightsData>>
  id: string
}

const scroll = (index: number) => {
  scrollToCurrentElWithTimeOut('.text-found', false, 200, index - 1)
}

export const QuickCognigySearch = ({
  highlightsData,
  setHighlightsData,
  id,
}: Props) => {
  const { suffix } = useLanguage()
  const queryType = useAppSelector(queryTypeSelector)
  const { notification } = useHoveredNotification()
  const { filters, isMainResultsType } = useFilters()
  const globalSearchTrimmedText = (filters.search ?? '').trim() as string
  const [inputValue, setInputValue] = useState('')
  const foundElementsLength = Object.keys(highlightsData).length
  const [error, setError] = useState('')
  const [currentIndex, setCurrentIndex] = useState(-1)

  const initSettersOnValueUpdate = async (value: string, initial?: boolean) => {
    setInputValue(value)

    if (value.trim() === inputValue.trim() && !initial) return

    let errorMessage = ''
    let newLength = 0

    let foundNodeList = {} as HighlightsData

    if (!isValidInputValue(value)) {
      errorMessage = 'Invalid characters'
    }
    if (value.length > 2) {
      try {
        const res = await apiGetHighlightTranscriptionsData({
          id,
          search: value,
          queryType: queryType as QueryType,
          search_model: filters.search_model,
          highlightPreTag: HighlightPre,
          highlightPostTag: HighlightPostTag,
        })

        const field = `text${suffix}` as const
        foundNodeList = res.reduce((acc, item) => {
          const highlights = item['@search.highlights']
          if (highlights && highlights[field]) {
            acc[item.id] = highlights[field]
          }
          return acc
        }, {} as HighlightsData)
        newLength = Object.keys(foundNodeList).length
      } catch (e: any) {
        notification(`Error: ${e.message}`, 'error', true)
      }
    }
    if (errorMessage) {
      setError(errorMessage)
    }
    setCurrentIndex(newLength > 0 ? 1 : 0)
    setHighlightsData(foundNodeList)
  }

  useEffect(() => {
    initSettersOnValueUpdate(globalSearchTrimmedText, true)
  }, [id])

  useEffect(() => {
    if (inputValue) {
      initSettersOnValueUpdate(inputValue, true)
      // setHighlightsData({})
      // setError('')
      // setCurrentIndex(-1)
    }
  }, [suffix])

  useEffect(() => {
    if (currentIndex !== -1 && inputValue.trim()) {
      scroll(currentIndex)
    }
  }, [currentIndex])

  if (!isMainResultsType) return <></>

  const goNext = () => {
    if (foundElementsLength > 1) {
      const newValue =
        currentIndex >= foundElementsLength ? 1 : currentIndex + 1
      setCurrentIndex(newValue)
    } else {
      scroll(currentIndex)
    }
  }

  const goPrev = () => {
    if (foundElementsLength > 1) {
      const newValue =
        currentIndex <= 1 ? foundElementsLength : currentIndex - 1
      setCurrentIndex(newValue)
    } else {
      scroll(currentIndex)
    }
  }

  return (
    <div className="quick-search">
      <IpTextField
        value={inputValue}
        onChange={(e) => initSettersOnValueUpdate(e.target.value)}
        variant="standard"
        placeholder="Search..."
        error={!!error}
        helperText={error}
      />
      {foundElementsLength ? (
        <div className="quick-search_navigation">
          <IpText>
            {currentIndex}/{foundElementsLength}
          </IpText>
          <IpIcon color="primary" fontSize="25px" onClick={goNext}>
            <KeyboardArrowDownIcon />
          </IpIcon>
          <IpIcon color="primary" fontSize="25px" onClick={goPrev}>
            <KeyboardArrowUpIcon />
          </IpIcon>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
