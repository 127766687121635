import type { Theme, Palette } from '@mui/material'
import type { IpColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import { capitalize } from '@infopulse-design-system/shared/utils/ui.utils'
import styles from '@infopulse-design-system/shared/theme/components/IpChip'

const colorNames: IpColorTypes[] = [
  'error',
  'info',
  'primary',
  'secondary',
  'success',
  'warning',
]

const getColorStyles = (colors: Palette) => {
  return colorNames.reduce((acc, color) => {
    return {
      ...acc,
      [`&.MuiChip-color${capitalize(color)}`]: {
        borderColor: colors[color].main,
        '.MuiAvatar-root': {
          backgroundColor: colors[color].main,
        },
      },
    }
  }, {})
}

export default (theme: Theme) => {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: styles.chip_border_radius,
          minWidth: styles.chip_min_width,

          ...getColorStyles(theme.palette),
          '&.MuiChip-colorDefault': {
            borderColor: theme.palette.grey['500'],
            '.MuiAvatar-root': {
              backgroundColor: theme.palette.grey['500'],
            },
          },
          '.MuiChip-label': {
            fontWeight: styles.chip_label_font_weight,
            paddingLeft: 0,
            paddingRight: 0,
          },
          '&.MuiChip-sizeExtraSmall': {
            height: styles.chip_extra_small_height,
            fontSize: styles.chip_extra_small_font_size,
            paddingLeft: styles.chip_extra_small_padding,
            paddingRight: styles.chip_extra_small_padding,
            '.MuiAvatar-root': {
              width: `calc(${styles.chip_extra_small_height} - (${styles.chip_extra_small_padding} * 2))`,
              height: `calc(${styles.chip_extra_small_height} - (${styles.chip_extra_small_padding} * 2))`,
              marginRight: styles.chip_extra_small_padding,
            },
            '.MuiChip-deleteIcon': {
              paddingLeft: styles.chip_extra_small_padding,
            },
          },
          '&.MuiChip-sizeSmall': {
            height: styles.chip_small_height,
            fontSize: styles.chip_small_font_size,
            paddingLeft: styles.chip_small_padding,
            paddingRight: styles.chip_small_padding,
            '.MuiAvatar-root': {
              width: `calc(${styles.chip_small_height} - (${styles.chip_small_padding} * 2))`,
              height: `calc(${styles.chip_small_height} - (${styles.chip_small_padding} * 2))`,
              marginRight: styles.chip_small_padding,
            },
            '.MuiChip-deleteIcon': {
              paddingLeft: styles.chip_small_padding,
            },
          },
          '&.MuiChip-sizeMedium': {
            height: styles.chip_medium_height,
            fontSize: styles.chip_medium_font_size,
            paddingLeft: styles.chip_medium_padding,
            paddingRight: styles.chip_medium_padding,
            '.MuiAvatar-root': {
              width: `calc(${styles.chip_medium_height} - (${styles.chip_medium_padding} * 2))`,
              height: `calc(${styles.chip_medium_height} - (${styles.chip_medium_padding} * 2))`,
              marginRight: styles.chip_medium_padding,
            },
            '.MuiChip-deleteIcon': {
              paddingLeft: styles.chip_medium_padding,
            },
          },
          '&.MuiChip-sizeLarge': {
            height: styles.chip_large_height,
            fontSize: styles.chip_large_font_size,
            paddingLeft: styles.chip_large_padding,
            paddingRight: styles.chip_large_padding,
            '.MuiAvatar-root': {
              width: `calc(${styles.chip_large_height} - (${styles.chip_large_padding} * 2))`,
              height: `calc(${styles.chip_large_height} - (${styles.chip_large_padding} * 2))`,
              marginRight: styles.chip_large_padding,
            },
            '.MuiChip-deleteIcon': {
              paddingLeft: styles.chip_large_padding,
            },
          },
          'a&': {
            cursor: 'pointer',
          },
          '.MuiChip-deleteIcon': {
            marginLeft: 'auto',
            marginRight: 0,
            fontSize: '1.5em',
          },
        },
      },
    },
  }
}
