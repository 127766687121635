import React from 'react'
import { IpTabs } from '../../components/ipDesignSystemComponents/atoms/IpTabs'
import { IpTabPanel } from '../../components/ipDesignSystemComponents/atoms/IpTabs/IpTabPanel'
import { FootnoteTable } from './FootnoteTable'
import './index.scss'

function AdminPanel() {
  return (
    <div className="page admin-panel">
      <IpTabs tabs={[{ label: 'Footnotes' }]}>
        <IpTabPanel value="0">
          <FootnoteTable />
        </IpTabPanel>
      </IpTabs>
    </div>
  )
}

export default AdminPanel
