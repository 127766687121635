import type { Theme } from '@mui/material'
import { primaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'

export default (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: primaryFontFamily,
          fontSize: inputStyles.input_label_font_size,
          top: 0,
          left: 0,
          maxWidth: `calc(100% - (
            ${inputStyles.input_padding_horizontal} + ${inputStyles.input_icon_padding_right}
          ))`,
          color: theme.palette.grey['600'],
          '&:not(.Mui-disabled)': {
            '&.Mui-focused, &.MuiFormLabel-filled': {
              color: theme.palette.primary.main,
            },
            '&.Mui-error': {
              '&.Mui-focused, &.MuiFormLabel-filled': {
                color: theme.palette.error.main,
              },
            },
          },
          '&.MuiInputLabel-standard': {
            '&.Mui-focused, &.MuiFormLabel-filled': {
              transform: 'translate(0px, -9px) scale(0.75)',
            },
          },
          '&:not(.Mui-focused, .MuiFormLabel-filled)': {
            lineHeight: inputStyles.input_height,
            left: inputStyles.input_padding_horizontal,
            transform: 'none',
          },
          '&.IpSelect-label--small, &.MuiInputLabel-sizeSmall': {
            '&:not(.Mui-focused, .MuiFormLabel-filled)': {
              lineHeight: inputStyles.input_small_height,
            },
          },
        },
      },
    },
  }
}
