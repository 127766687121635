import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack } from '@mui/system'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'

type Props = {
  length: number
  setIndex: React.Dispatch<React.SetStateAction<number>>
  index: number
}

export const TelegramNav = ({ length, index, setIndex }: Props) => {
  const back = () => {
    setIndex((state) => (state > 0 ? state - 1 : length - 1))
  }

  const next = () => {
    setIndex((state) => (state < length - 1 ? state + 1 : 0))
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="4px"
      className="telegram-nav"
    >
      <IpIcon onClick={back} color="primary">
        <ArrowBackIcon />
      </IpIcon>
      <span>
        {index + 1}/{length}
      </span>
      <IpIcon onClick={next} color="primary">
        <ArrowForwardIcon />
      </IpIcon>
    </Stack>
  )
}
