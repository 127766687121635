export default {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        subtitle3: 'h6',
        body3: 'p',
        body4: 'p',
        button: 'button',
        caption: 'caption',
        overline: 'span',
      },
    },
  },
}
