import React, { forwardRef } from 'react'
import type { ElementType, RefObject } from 'react'
import { MenuItem } from '@mui/material'
import { NavLink } from 'react-router-dom'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type {
  IpMenuItemComponentType,
  IpMenuItemPropTypesReact,
} from './index.types'
import '@infopulse-design-system/shared/theme/components/IpMenuItem/styles.scss'

export const IpMenuItemTestId = 'IpMenuItemTestId'

export const IpMenuItem: IpMenuItemComponentType = forwardRef(
  function IpMenuItem<D extends ElementType>(
    props: IpMenuItemPropTypesReact<D>,
    ref:
      | ((instance: HTMLAnchorElement | null) => void)
      | RefObject<HTMLAnchorElement>
      | null
      | undefined
  ) {
    const {
      autoFocus,
      children,
      classes,
      component,
      disabled,
      divider,
      href,
      isLinkSafe = false,
      onClick,
      onKeyDown = (e) => e.key === 'Tab' && e.stopPropagation(),
      target,
      tabIndex,
      to,
      value,
      ...overrideProps
    } = props

    const linkProps = href
      ? {
          href,
          target,
          rel: isLinkSafe ? '' : 'noopener noreferrer',
        }
      : {}

    const customClasses = formatClassName(
      generateClasses('menu-item', 'react'),
      classes
    )

    const renderedComponent = component ?? to ? NavLink : href ? 'a' : 'li'

    return (
      <MenuItem
        autoFocus={autoFocus}
        className={customClasses}
        component={renderedComponent}
        data-testid={IpMenuItemTestId}
        disabled={disabled}
        divider={divider}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={ref}
        value={value}
        tabIndex={tabIndex}
        to={to}
        {...linkProps}
        {...overrideProps}
      >
        {children}
      </MenuItem>
    )
  }
) as IpMenuItemComponentType
