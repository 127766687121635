import React, { useCallback } from 'react'
import { useUserTags } from '../../../../hooks/useUserTags'
import { SelectedList } from '../../SelectedList'

type Props = {
  mediaId: string
  userTags?: string[]
}

export const PreviewUserTags = ({
  mediaId,
  userTags: userTagList = [],
}: Props) => {
  const { removeMediaUserTag, addMediaUserTag, getUserTags, userTags } =
    useUserTags()

  const handleRemoveTagFromMedia = useCallback(
    async (tag: string) => {
      await removeMediaUserTag({ mediaId, tag })
      getUserTags()
    },
    [mediaId]
  )

  const handleAddTagToMedia = useCallback(
    async (tag: string) => {
      await addMediaUserTag({ mediaId, tag })
      getUserTags()
    },
    [mediaId]
  )

  const onNewTagToMediaAdd = useCallback(
    async (tag: string) => {
      await addMediaUserTag({ mediaId, tag })
      getUserTags()
    },
    [mediaId]
  )
  return (
    <div className="main-preview_tags-wrapper">
      <SelectedList
        list={userTags}
        title="User Tags:"
        currentList={userTagList}
        onItemAdd={handleAddTagToMedia}
        onItemRemove={handleRemoveTagFromMedia}
        onNewItemAdd={onNewTagToMediaAdd}
      />
    </div>
  )
}
