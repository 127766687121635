import SearchIcon from '@mui/icons-material/Search'
import { debounce } from '@mui/material'
import type { ChangeEvent, MouseEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLanguage } from '../../../../../hooks/useLanguage'
import {
  dictionariesSelector,
  userTagsSelector,
} from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import type {
  DictionaryCommonName,
  DictionaryName,
} from '../../../../../redux/types/dictionaries'
import { DICTIONARY_ADDITION } from '../../../../../redux/types/dictionaries'
import {
  filterObjectsByStringField,
  getNoneUnknownValueList,
  getSliceByNumber,
} from '../../../../../utils'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpTextField } from '../../../../ipDesignSystemComponents/atoms/IpTextField'
import type { PersonaInfoIconState } from '../../../persona'
import { PersonaInfoIcon, PersonaInfoPopup } from '../../../persona'
import { CheckBox } from '../CheckBox'

import './index.scss'

const LIST_START_LENGTH = 15
const LOADING_DELAY = 400
const DEBOUNCE_TIMEOUT = 500

const endAdornment = (
  <IpIcon
    classes="filter-search-icon"
    ariaLabel="Filter search icon"
    color="primary"
    fontSize="18px"
  >
    <SearchIcon />
  </IpIcon>
)

const userTagsMapper = (arr: string[]) => {
  return arr.map((name, id) => ({
    name,
    id: id.toString(),
  }))
}

type UserTagsKeyType = 'user_tags'

export type FilterSearchCheckboxProps = {
  variant: Exclude<DictionaryCommonName, 'models'> | UserTagsKeyType
}

export const FilterSearchCheckbox = ({
  variant,
}: FilterSearchCheckboxProps) => {
  const isUserTags = variant === 'user_tags'
  const isPersonas = variant === DICTIONARY_ADDITION.personas
  const { data } = useAppSelector(dictionariesSelector)
  const { suffix } = useLanguage()
  const userTagsData = useAppSelector(userTagsSelector)
  const [personaInfoIconState, setPersonaInfoIconState] =
    useState<PersonaInfoIconState>(null)
  const initialData = data[variant as DictionaryName]
  const nameKey = `name${isUserTags ? '' : suffix}`

  const defaultList = useMemo(() => {
    return isUserTags
      ? userTagsMapper(userTagsData)
      : getNoneUnknownValueList(suffix, initialData)
  }, [userTagsData, initialData, suffix])

  const [searchValue, setSearchValue] = useState('')
  const [checkboxes, setCheckboxes] = useState(defaultList)
  const [visibleCheckboxes, setVisibleCheckboxes] = useState(
    getSliceByNumber(checkboxes, LIST_START_LENGTH)
  )
  const hasMore = checkboxes.length > visibleCheckboxes.length

  useEffect(() => {
    setVisibleCheckboxes(getSliceByNumber(checkboxes, LIST_START_LENGTH))
  }, [checkboxes])

  useEffect(() => {
    setCheckboxes(
      filterObjectsByStringField(
        searchValue,
        defaultList,
        nameKey as keyof typeof defaultList[0]
      )
    )
  }, [defaultList, nameKey])

  const getMoreItems = () => {
    setTimeout(() => {
      setVisibleCheckboxes(
        getSliceByNumber(
          checkboxes,
          visibleCheckboxes.length + LIST_START_LENGTH
        )
      )
    }, LOADING_DELAY)
  }

  const handleUpdateFiltersDebounced = useCallback(
    debounce((newValue: string) => {
      setCheckboxes(
        filterObjectsByStringField(
          newValue,
          defaultList,
          nameKey as keyof typeof defaultList[0]
        )
      )
    }, DEBOUNCE_TIMEOUT),
    [defaultList, nameKey]
  )

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    handleUpdateFiltersDebounced(e.target.value)
  }

  const onPersonaIconClick = (e: MouseEvent<HTMLElement>, id: string) => {
    setPersonaInfoIconState({
      anchor: e.currentTarget,
      id,
    })
    e.stopPropagation()
  }

  return (
    <div className="filter-search-checkbox">
      <IpTextField
        size="small"
        classes="filter-search-input"
        value={searchValue}
        variant="standard"
        onChange={onChangeHandler}
        endAdornment={endAdornment}
      />
      <div
        className="filter-search-checkbox-wrapper"
        id={`InfiniteScroll-${variant}`}
      >
        <InfiniteScroll
          dataLength={visibleCheckboxes.length + 1}
          next={getMoreItems}
          loader="Loading..."
          hasMore={hasMore}
          scrollableTarget={`InfiniteScroll-${variant}`}
        >
          {visibleCheckboxes.map((item) => {
            const selector = isUserTags ? item.name : item.id

            return (
              <CheckBox
                key={selector}
                id={selector}
                name={
                  <>
                    {item[nameKey as keyof typeof item] ?? ''}
                    {isPersonas && (
                      <PersonaInfoIcon
                        onClick={(e: MouseEvent<HTMLElement>) =>
                          onPersonaIconClick(e, item.id)
                        }
                      />
                    )}
                  </>
                }
                type={variant}
                classes="filter-search-checkbox-item"
                labelColor="text.primary"
              />
            )
          })}
        </InfiniteScroll>
      </div>
      <PersonaInfoPopup
        personaInfoIconState={personaInfoIconState}
        setPersonaInfoIconState={setPersonaInfoIconState}
      />
    </div>
  )
}
