import React from 'react'
import type { ReactNode, ElementType } from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import type { IpListSubheaderPropTypes } from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpList/IpListSubheader/styles.scss'

type IpListSubheaderPropTypesReact = IpListSubheaderPropTypes & {
  children?: ReactNode
  component?: ElementType
}

export function IpListSubheader(props: IpListSubheaderPropTypesReact) {
  const {
    children,
    classes,
    color = 'inherit',
    disableGutters,
    disableSticky,
    inset,
  } = props

  const customClasses = formatClassName(
    generateClasses('list-item-subheader', 'react'),
    classes
  )

  return (
    <ListSubheader
      className={customClasses}
      color={color}
      disableGutters={disableGutters}
      disableSticky={disableSticky}
      inset={inset}
    >
      {children}
    </ListSubheader>
  )
}
