import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpList/IpListItem'

export default (theme: Theme) => {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: `${styles.list_item_padding_top_bottom} ${styles.list_item_padding_left_right}`,
          '&.MuiListItemButton-dense': {
            paddingTop: `calc(${styles.list_item_padding_top_bottom}/2)`,
            paddingBottom: `calc(${styles.list_item_padding_top_bottom}/2)`,
          },
          '&.IpListItem-disablePadding': {
            padding: 0,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            '&.IpListItem-disableHover:hover': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiTouchRipple-root span': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .IpList-collapseIcon-open': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  }
}
