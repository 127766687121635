import styles from '@infopulse-design-system/shared/theme/components/IpCard/IpCardHeader'
import type { Theme } from '@mui/material'

export default (theme: Theme) => {
  return {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: styles.card_header_padding,
          '.MuiCardHeader-avatar': {
            margin: styles.card_header_avatar_margin,
          },
          '.MuiCardHeader-title': {
            margin: styles.card_header_title_margin,
            color: theme.palette.text.primary,
            font: styles.card_header_title_font,
          },
          '.MuiCardHeader-subheader': {
            color: theme.palette.grey[900],
            font: styles.card_header_subheader_font,
          },
          '.MuiCardHeader-action': {
            alignSelf: 'center',
            margin: styles.card_header_action_margin,
          },
        },
      },
    },
  }
}
