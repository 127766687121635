import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpTabs'

export default (theme: Theme) => {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            font: styles.tabs_font,
          },
          '& .Mui-disabled': {
            color: theme.palette.grey[500],
          },
          '& .MuiTabs-indicator': {
            height: styles.tabs_indicator_height,
            width: styles.tabs_indicator_height,
          },
          '& .MuiTabs-scrollButtons': {
            '&.Mui-disabled': {
              display: 'none',
            },
            '& > .MuiSvgIcon-root': {
              width: styles.tabs_scroll_btn_size,
              height: styles.tabs_scroll_btn_size,
            },
            '& ~ .MuiTabs-scroller .MuiTabs-flexContainer': {
              justifyContent: 'left',
            },
          },
        },
      },
    },
  }
}
