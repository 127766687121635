import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  apiGetPersonaData,
  apiGetPersonaMediasData,
  apiUpdatePersonaData,
} from '../../api'
import {
  setPersonaData,
  setPersonaMediasData,
  updatePersonaData,
} from '../slices/personaDataSlice'
import { updatePersonaList } from '../slices/personaListSlice'
import type { PersonaItem, PersonaItemMedias } from '../types/personas'

export const fetchPersonaData = createAsyncThunk<PersonaItem, string>(
  'personaData/fetch',
  async (id, thunkAPI) => {
    try {
      const data = await apiGetPersonaData(id)
      thunkAPI.dispatch(setPersonaData({ id, data }))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
    }
  }
)

export const updatePersonaDataThunk = createAsyncThunk<
  PersonaItem,
  PersonaItem
>('personaData/update', async (params, thunkAPI) => {
  try {
    const data = await apiUpdatePersonaData(params)
    thunkAPI.dispatch(updatePersonaData(data))
    thunkAPI.dispatch(updatePersonaList(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data.message ?? e.message)
  }
})

export const fetchPersonaMediasData = createAsyncThunk<
  PersonaItemMedias,
  string
>('personaData/fetch', async (id, thunkAPI) => {
  try {
    const data = await apiGetPersonaMediasData(id)
    thunkAPI.dispatch(setPersonaMediasData({ id, data }))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
  }
})
