import type {
  FootnoteCreateParams,
  FootnoteData,
  FootnoteUpdateParams,
} from '../redux/types/dictionaries'
import { api } from './axiosInstance'

export const apiFootnoteCreate = async (
  params: FootnoteCreateParams
): Promise<FootnoteData> => {
  try {
    const response = await api.post('footnotes/create', params)
    return response.data
  } catch (error) {
    console.error('Error on footnote create api', error)
    throw error
  }
}

export const apiFootnoteUpdate = async (
  id: string,
  params: FootnoteUpdateParams
): Promise<FootnoteData> => {
  try {
    const response = await api.patch(`footnotes/${id}`, params)
    return response.data
  } catch (error) {
    console.error('Error on footnote update api', error)
    throw error
  }
}

export const apiFootnoteDelete = async (id: string): Promise<FootnoteData> => {
  try {
    const response = await api.delete('footnotes/delete', {
      data: { id },
    })
    return response.data
  } catch (error) {
    console.error('Error on footnote delete api', error)
    throw error
  }
}
