type SupportedLang = 'en' | 'ua'

export type Content = {
  [key: string]: Record<SupportedLang | string, string>
}

export const content: Content = {
  title: {
    en: 'About the service',
    ua: 'Про сервіс',
  },
  line1: {
    en: 'War of Words is the first English-language tool based on artificial intelligence that allows to analyze 100+ thousand hours of content from TV, Telegram, RuTube or radio in a few clicks. With the service, you will have access to a huge archive of Russian propaganda since 2012, fully transcribed into text — in English and in the original language.',
    ua: 'War of Words — перший англомовний інструмент на основі штучного інтелекту, який дозволяє аналізувати 100+ тисяч годин контенту з ТБ, Telegram, RuTube чи радіо в декілька кліків. За допомогою сервісу ви отримаєте доступ до величезного архіву російської пропаганди з 2012 року, повністю транскрибованого в текст — англійською мовою та мовою оригіналу.',
  },
  line2: {
    en: 'The service will be useful if you are studying Russian propaganda as a lawyer, researcher, linguist, computer linguist, journalist, or media analyst.',
    ua: 'Сервіс буде корисним, якщо ви вивчаєте російську пропаганду як юрист, дослідник, лінгвіст, комп’ютерний лінгвіст, журналіст, медіа-аналітик.',
  },
  line3: {
    en: 'Using War of Words is easy. Enter your search query, add special search operators and filters if necessary. In the central column you will see all the materials that match your query. Learn more about search operators at',
    ua: 'Користуватись War of Words дуже просто. Введіть пошуковий запит, за необхідності додайте спеціальні оператори пошуку та фільтри. В центральному стовпчику ви побачите всі матеріали, які відповідають запиту. Більше про оператори пошуку —',
    link: 'https://learn.microsoft.com/en-us/azure/search/query-simple-syntax',
  },
  line4: {
    en: 'To access the full version of the service with the ability to download texts and tables for linguistic and statistical analysis, please contact',
    ua: 'Для доступу до повної версії сервісу із можливістю завантаження текстів та таблиць для лінгвістичного та статистичного аналізу звертайтесь, будь ласка, за адресою',
    link: 'service@warofwords.info',
  },
  line5: {
    en: 'Media requests for comments and interviews with representatives of the service please send to',
    ua: 'Запити від медіа на коментарі та інтерв’ю представників сервісу надсилайте, будь ласка, на',
    link: 'pr@warofwords.info',
  },
  line6: {
    en: 'All texts and speaker recognition are created using artificial intelligence. If you find any mistakes, please write to',
    ua: 'Всі тексти та розпізнання спікерів створено за допомогою штучного інтелекту. Якщо ви знайшли помилки — напишіть, будь ласка, на',
    link: 'error@warofwords.info',
  },
  line7: {
    en: 'The service is based on Microsoft Azure cloud services.',
    ua: 'Сервіс побудовано на основі хмарних сервісів Microsoft Azure.',
  },
}
