import { debounce } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { updateFilters } from '../redux/slices/filtersSlice'
import { useAppDispatch } from '../redux/store'
import type { SearchStringFilters } from '../redux/types/filters'
import { validateSearchInput } from '../utils'
import useFilters from './useFilters'

const DEBOUNCE_TIMEOUT = 300

export const useSearchFilter = (type: SearchStringFilters) => {
  const dispatch = useAppDispatch()
  const {
    filters: { [type]: search = '' },
    filterType,
  } = useFilters()

  const [error, setError] = useState('')
  const [value, setValue] = useState(search)

  const handleUpdateFiltersDebounced = useCallback(
    debounce((newValue: string) => {
      dispatch(updateFilters({ type, value: newValue, filterType }))
    }, DEBOUNCE_TIMEOUT),
    [type, filterType]
  )

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    const isValid = validateSearchInput(e.target.value, setError)
    if (isValid) {
      handleUpdateFiltersDebounced(e.target.value)
    }
  }

  useEffect(() => {
    if (search === '' && value) {
      setValue('')
      setError('')
    }
  }, [search])

  return {
    error,
    setError,
    value,
    setValue,
    onChange,
  }
}
