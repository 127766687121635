import { getLocalDate } from '../utils/data'
import { EXPORT_FILE_FORMATS } from './enums'

export const DEFAULT_LANG_RU = {
  lang: 'ru',
  locale: 'ru-RU',
  suffix: '',
}

export const FILTERS_DEBOUNCE_TIMEOUT = 2300
export const INFINITE_SCROLL_PORTION_SIZE = 50
export const INFINITE_SCROLL_THRESHOLD = 0.95

export const DATE_FORMAT = 'dd.MM.yyyy'
export const INITIAL_DATE_TO = getLocalDate(new Date())
export const INITIAL_DATE_FROM = getLocalDate(
  new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
) // 14 - days

export const MIN_MEDIA_LENGTH = 0
export const MAX_MEDIA_LENGTH = 300

export const MIN_FILTER_PERCENTAGE = 0
export const MAX_FILTER_PERCENTAGE = 100

export const MIN_SIMILAR_EMBEDDING_NUM = 0
export const MAX_SIMILAR_EMBEDDING_NUM = 20_000

export const MIN_MEDIA_NUM = 0
export const MAX_MEDIA_NUM = 10_000

export const WEEK_SHORT_DAYS = [
  'Sun' as const,
  'Mon' as const,
  'Tue' as const,
  'Wed' as const,
  'Thu' as const,
  'Fri' as const,
  'Sat' as const,
]

export const EXPORT_FILE_EXT = {
  [EXPORT_FILE_FORMATS.DOCX]: 'docx',
  [EXPORT_FILE_FORMATS.EXCEL]: 'xlsx',
}

export const UnknownSpeakerData = {
  id: '00000000-0000-0000-0000-000000000000' as const,
  name: 'Unknown speaker' as const,
}

export const PROCESSING_REFRESH_TIMER = 1000 * 60 * 1.5 // 1.5 minutes
