import styles from '@infopulse-design-system/shared/theme/components/IpDateTimePicker'
import shadows from '@infopulse-design-system/shared/theme/shadows'

export default () => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPickersPopper-paper': {
            boxShadow: shadows.calendar_box_shadow,
            borderRadius: styles.date_time_picker_border_radius,
          },
        },
      },
    },
  }
}
