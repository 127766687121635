import type { Palette, Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpSlider'
import tableStyles from '@infopulse-design-system/shared/theme/components/IpTable'
import type { IpColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import { darken } from '@mui/system'

const colorNames: IpColorTypes[] = [
  'error',
  'info',
  'primary',
  'secondary',
  'success',
  'warning',
]

const getColorStyles = (palette: Palette) => {
  return colorNames.reduce((acc, color) => {
    return {
      ...acc,
      [`&.IpSlider-${color}`]: {
        '.MuiSlider-track': {
          color: palette[color].main,
        },
        '.MuiSlider-thumb': {
          color: darken(palette[color].main, 0.3),
        },
      },
    }
  }, {})
}

export default (theme: Theme) => {
  return {
    MuiSlider: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            '&:not(.MuiSlider-vertical)': {
              height: styles.slider_small_thickness,
            },
            '&.MuiSlider-vertical': {
              width: styles.slider_small_thickness,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '&:not(.MuiSlider-vertical)': {
              height: styles.slider_medium_thickness,
            },
            '&.MuiSlider-vertical': {
              width: styles.slider_medium_thickness,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            '&:not(.MuiSlider-vertical)': {
              height: styles.slider_large_thickness,
            },
            '&.MuiSlider-vertical': {
              width: styles.slider_large_thickness,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          ...getColorStyles(theme.palette),

          '& .MuiSlider-thumb': {
            width: styles.slider_thumb_size,
            height: styles.slider_thumb_size,
            '&::before': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-markLabel': {
            font: styles.slider_mark_label_font,
          },
          '&:not(.MuiSlider-vertical) .MuiSlider-markLabel': {
            top: styles.slider_mark_label_top,
          },
          '&.MuiSlider-vertical .MuiSlider-markLabel': {
            left: styles.slider_mark_label_left,
          },
          '& .MuiSlider-rail': {
            color: theme.palette.grey['500'],
            opacity: 1,
          },
          '& .MuiSlider-valueLabel': {
            minWidth: styles.slider_value_label_min_width,
            minHeight: styles.slider_value_label_min_height,
            font: styles.slider_value_label_font,
            backgroundColor: theme.palette.grey['600'],
            padding: styles.slider_value_label_padding,
            borderRadius: styles.slider_value_label_border_radius,
            top: styles.slider_value_label_top,
            '&::before': {
              width: styles.slider_value_label_before_size,
              height: styles.slider_value_label_before_size,
            },
            '&:not(.MuiSlider-vertical)::before': {
              bottom: styles.slider_value_label_before_bottom,
            },

            '& .MuiSlider-valueLabelCircle': {
              textAlign: 'center',
              fontSize: styles.slider_value_label_circle_font_size,
              fontStyle: 'normal',
              lineHeight: styles.slider_value_label_circle_line_height,
              zIndex: styles.slider_value_label_circle_z_index,
            },
          },
          '&.MuiSlider-vertical .MuiSlider-valueLabel': {
            top: styles.slider_value_label_vertical_top,
            right: styles.slider_value_label_vertical_right,
            '&::before': {
              right: styles.slider_value_label_vertical_before_right,
              top: styles.slider_value_label_vertical_before_top,
            },
          },
          '&.IpTableFilter-range': {
            width: tableStyles.table_filter_range_width,
          },
        },
      },
    },
  }
}
