import type { IpTextColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import type { ReactNode } from 'react'
import React, { useCallback } from 'react'
import useFilters from '../../../../../hooks/useFilters'
import { updateFilterArr } from '../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../redux/store'
import { MemoIpCheckbox } from '../../../ipDSMemo'
import type { FilterCheckboxProps } from '../FilterCheckboxes'
import type { FilterSearchCheckboxProps } from '../FilterSearchCheckbox'

type CheckBoxProps = {
  id: string
  name: ReactNode
  type: FilterSearchCheckboxProps['variant'] | FilterCheckboxProps['variant']
  classes?: string
  labelColor?: IpTextColorTypes
}

export const CheckBox = ({
  id,
  name,
  type,
  classes,
  labelColor,
}: CheckBoxProps) => {
  const dispatch = useAppDispatch()
  const { filters, filterType } = useFilters()
  const checked = filters[type]?.includes(id)

  const onChange = useCallback(() => {
    dispatch(
      updateFilterArr({
        filterType,
        type,
        value: id,
      })
    )
  }, [filterType, type, id])

  return (
    <MemoIpCheckbox
      classes={classes}
      labelColor={labelColor}
      key={id}
      label={name}
      onChange={onChange}
      checked={checked}
    />
  )
}
