import React from 'react'
import type {
  ElementType,
  ReactElement,
  SyntheticEvent,
  ReactNode,
} from 'react'
import type { IpChipPropTypes } from '@infopulse-design-system/shared/types/IpChip.types'
import { Chip } from '@mui/material'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpChip/styles.scss'

export type IpChipPropTypesReact = IpChipPropTypes<
  SyntheticEvent,
  ReactNode
> & {
  avatar?: ReactElement
  component?: ElementType
  deleteIcon?: ReactElement
  icon?: ReactElement
}

/**
 * `IpChip`s are simple and concise elements that serve as inputs, attributes, or triggers for actions.
 * They provide users with the ability to input information, make selections, filter content, or
 * initiate actions in a compact and user-friendly manner.
 */
export function IpChip(props: IpChipPropTypesReact) {
  const {
    avatar,
    classes,
    clickable,
    color,
    component = 'div',
    deleteIcon,
    disabled,
    href,
    icon,
    label,
    onClick,
    onDelete,
    size = 'small',
    target,
    variant,
  } = props

  const customClasses = formatClassName(
    generateClasses('chip', 'react'),
    classes
  )

  return (
    <Chip
      avatar={avatar}
      className={customClasses}
      clickable={clickable || !!href || !!onClick}
      color={color}
      component={href ? 'a' : component}
      deleteIcon={deleteIcon}
      disabled={disabled}
      href={href}
      icon={icon}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      size={size}
      target={target}
      variant={variant}
    />
  )
}
