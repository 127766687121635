import { debounce } from '@mui/material'
import { useCallback, useEffect } from 'react'
import type { GetMediaParams } from '../../api/apiTypes'
import { FILTERS_DEBOUNCE_TIMEOUT } from '../../constants'
import { MainPageTabTypes } from '../../constants/enums'
import { profileSelector } from '../../redux/selectors'
import { resetActiveMediaByType } from '../../redux/slices/appStateSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  fetchMediaList,
  fetchMediaListNext,
} from '../../redux/thunk/mediaListThunk'
import useFilters from '../useFilters'
import { useIsInitialFilters } from '../useIsInitialFilters'

export const useMainResults = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(profileSelector)
  const { mainResultsFilters } = useFilters()
  const { getIsInitialFilters } = useIsInitialFilters()

  const { queryType } = profile.data
  const isAuth = Boolean(profile.data.name)

  const handleFetchMediaDebounced = useCallback(
    debounce((filters: GetMediaParams) => {
      dispatch(fetchMediaList(filters))
    }, FILTERS_DEBOUNCE_TIMEOUT),
    []
  )

  useEffect(() => {
    if (!isAuth || !queryType) return

    const isFirstMainPageFilters = mainResultsFilters.skip === 0
    const isInitialFilters = getIsInitialFilters()

    if (isFirstMainPageFilters && isInitialFilters) {
      dispatch(fetchMediaList({ ...mainResultsFilters, queryType }))
    } else if (isFirstMainPageFilters && !isInitialFilters) {
      handleFetchMediaDebounced({ ...mainResultsFilters, queryType })
    } else if (!isFirstMainPageFilters) {
      dispatch(fetchMediaListNext({ ...mainResultsFilters, queryType }))
    }
  }, [mainResultsFilters, profile.data.name])

  useEffect(() => {
    if (!queryType) return
    dispatch(resetActiveMediaByType(MainPageTabTypes.results))
  }, [mainResultsFilters, queryType])
}
