import type { FiltersState } from '../redux/types/filters'
import type { AddBasketParams, DeleteItemFromBasketParams } from './apiTypes'
import { api } from './axiosInstance'
import { mapFiltersToBasketParams } from './mappers'

export const apiAddToBasket = async (params: AddBasketParams) => {
  try {
    const response = await api.post('basket', params)
    return response.data
  } catch (error) {
    console.error('Error during add report to basket', error)
    throw error
  }
}

export const apiGetBasket = async (filters: FiltersState) => {
  try {
    const params = mapFiltersToBasketParams(filters)
    const response = await api.post('basket/filter', params)
    return response.data
  } catch (error) {
    console.error('Error during get report', error)
    throw error
  }
}

export const apiDeleteItemFromBasket = async (
  params: DeleteItemFromBasketParams
) => {
  try {
    const response = await api.post('basket/delete', params)
    return response
  } catch (error) {
    console.error('Error during deleting item from basket', error)
    throw error
  }
}
