import type { ReactNode } from 'react'
import React from 'react'
import { usePermission } from '../../../hooks/usePermission'
import type { PermissionList } from '../../../redux/slices/profileSlice'

type PermissionsGateProps = {
  children: ReactNode
  permissionKey: PermissionList
  permissionDeniedMessage?: string
  showPermissionDeniedMessage?: boolean
}

export default function PermissionsGate({
  children,
  permissionKey,
  permissionDeniedMessage = 'Permission Denied',
  showPermissionDeniedMessage = false,
}: PermissionsGateProps) {
  const { isPermission, permissions } = usePermission(permissionKey)

  if (!permissions) return <></>

  if (!isPermission) {
    if (!showPermissionDeniedMessage) return <></>
    return <>{permissionDeniedMessage}</>
  }

  return <>{children}</>
}
