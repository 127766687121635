import type { Dispatch, SetStateAction } from 'react'
import React, { useCallback, useState } from 'react'
import type { ActiveTrData, HighlightsData } from '..'
import { PERMISSIONS } from '../../../../constants/enums'
import { useLanguage } from '../../../../hooks/useLanguage'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import type {
  Bug,
  MediaData,
  Transcription as TranscriptionType,
} from '../../../../redux/types/media'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { EndMessage } from '../../EndMessage'
import PermissionsGate from '../../PermissionsGate'
import type { PersonaInfoIconState } from '../../persona'
import { PersonaInfoPopup } from '../../persona'
import { SelectSpeaker } from '../SelectSpeaker'
import type { TranscriptionProps } from './Transcription'
import { Transcription } from './Transcription'
import { TranscriptionMenu } from './TranscriptionMenu'
import { TranscriptionWithBug } from './TranscriptionWithBug'

type Props = {
  activeTrData: ActiveTrData
  setActiveTrData: Dispatch<SetStateAction<ActiveTrData>>
  highlightsData: HighlightsData
  currentTrData: (TranscriptionType | Bug)[]
  transcription: TranscriptionType | Bug
  currentMediaData: MediaData
}

export const TranscriptionList = ({
  activeTrData,
  setActiveTrData,
  highlightsData,
  currentTrData,
  transcription,
  currentMediaData,
}: Props) => {
  const { isMainBugsTab, isMainResultsTab } = useMainPageActiveTab()
  const { suffix } = useLanguage()
  const [wrongSpeakerMode, setWrongSpeakerMode] = useState(false)
  const [selectSpeakerAnchorEl, setSelectSpeakerAnchorEl] =
    useState<null | HTMLElement>(null)
  const [reportIconAnchor, setReportIconAnchor] = useState<HTMLElement | null>(
    null
  )
  const [personaInfoIconState, setPersonaInfoIconState] =
    useState<PersonaInfoIconState>(null)

  const isReportPopoverOpen = Boolean(reportIconAnchor)
  const isTranscriptions = !!currentTrData

  const onReportBugIconClick: TranscriptionProps['onReportBugIconClick'] =
    useCallback((event) => {
      setReportIconAnchor(event.currentTarget as HTMLElement)
    }, [])

  const onTranscriptionClick = (
    { target }: React.MouseEvent<HTMLLIElement, MouseEvent>,
    tIndex: number
  ) => {
    const isMenuIcon = !!(target as HTMLElement).closest(
      '.transcription-icon_anchor'
    )
    setActiveTrData({
      tIndex,
      setManualTime: true,
      pause: isMenuIcon,
    })
  }

  return isTranscriptions ? (
    <div className="transcription-list_wrapper">
      <IpText variant="subtitle3" color="primary">
        Full text:
      </IpText>
      <ul className="transcription-list">
        {currentTrData.map((item, index) => {
          return (
            <li
              key={item.id + index}
              onClick={(e) => onTranscriptionClick(e, index)}
              style={{ cursor: 'pointer' }}
            >
              {isMainBugsTab ? (
                <TranscriptionWithBug
                  active={index === activeTrData.tIndex}
                  correctText={(item as Bug).correct_text}
                  correctPersonaId={(item as Bug).correct_persona_id}
                  mediaId={item.media_id}
                  transcriptionId={(item as Bug).transcription_id}
                  speakerId={item.speaker}
                  setPersonaInfoIconState={setPersonaInfoIconState}
                />
              ) : (
                <Transcription
                  footnotes={
                    suffix
                      ? (item as TranscriptionType).footnotes_en
                      : undefined
                  }
                  highlights={highlightsData[item.id]}
                  active={index === activeTrData.tIndex}
                  time={item.start ?? 0}
                  text={
                    (item as TranscriptionType)[`text${suffix}` as const] ?? ''
                  }
                  personaId={item.persona_id}
                  speakerId={item.speaker}
                  speakers={currentMediaData.speakers}
                  onReportBugIconClick={onReportBugIconClick}
                  setSelectSpeakerAnchorEl={setSelectSpeakerAnchorEl}
                  setPersonaInfoIconState={setPersonaInfoIconState}
                />
              )}
            </li>
          )
        })}
      </ul>
      <EndMessage />
      {!!transcription && (
        <>
          {isMainResultsTab && (
            <TranscriptionMenu
              anchorEl={reportIconAnchor}
              setAnchorEl={setReportIconAnchor}
              setSelectSpeakerAnchorEl={setSelectSpeakerAnchorEl}
              open={isReportPopoverOpen}
              transcription={transcription as TranscriptionType}
              setWrongSpeakerMode={setWrongSpeakerMode}
              currentMediaData={currentMediaData}
            />
          )}
          <PermissionsGate permissionKey={PERMISSIONS.speaker_annotation}>
            <SelectSpeaker
              activeTranscription={transcription as TranscriptionType}
              anchorEl={selectSpeakerAnchorEl}
              setAnchorEl={setSelectSpeakerAnchorEl}
              isBug={wrongSpeakerMode}
              setWrongSpeakerMode={setWrongSpeakerMode}
              setPersonaInfoIconState={setPersonaInfoIconState}
            />
          </PermissionsGate>
          <PersonaInfoPopup
            personaInfoIconState={personaInfoIconState}
            setPersonaInfoIconState={setPersonaInfoIconState}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  )
}
