/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { sortObjectByStringFieldWithLang } from '../../utils'
import { mockedNetworks } from '../mocks/dictionaries'
import { fetchDictionaries } from '../thunk/dictionariesThank'
import type {
  DictionariesData,
  DictionariesPayloadData,
  DictionaryPersonaContent,
  FootnoteData,
  Language,
} from '../types/dictionaries'
import {
  DICTIONARY,
  DICTIONARY_ADDITION,
  DICTIONARY_CUSTOM,
} from '../types/dictionaries'

export type DictionariesState = {
  data: DictionariesData
  isLoading: boolean
  error: string
}

// todo: update structure with inside { data, error, isLoading}
export const initialDictionariesState: DictionariesState = {
  data: {
    [DICTIONARY.authors]: [],
    [DICTIONARY.citizenship]: [],
    [DICTIONARY.media_type]: [],
    [DICTIONARY.networks]: mockedNetworks.data,
    [DICTIONARY.persona_category]: [],
    [DICTIONARY.programs]: [],
    [DICTIONARY.sanctions]: [],
    [DICTIONARY.signs_of_crimes]: [],
    [DICTIONARY.sources]: [],
    [DICTIONARY_CUSTOM.models]: [],
    [DICTIONARY_CUSTOM.narratives]: [],
    [DICTIONARY_CUSTOM.languages]: [],
    [DICTIONARY_ADDITION.personas]: [],
    [DICTIONARY_CUSTOM.footnotes]: [],
    [DICTIONARY_CUSTOM.telegram_channels]: [],
  },
  isLoading: false,
  error: '',
}

export const DictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: initialDictionariesState,
  reducers: {
    setDictionaries: (
      state,
      { payload }: PayloadAction<DictionariesPayloadData>
    ) => {
      state.data.telegram_channels = payload?.telegram_channels?.data ?? []
      state.data.footnotes = payload.footnotes?.data ?? []
      state.data.authors = payload.authors?.data ?? []
      state.data.citizenship = payload.citizenship?.data ?? []
      state.data.media_type = payload.media_type?.data ?? []
      state.data.programs = payload.programs?.data ?? []
      state.data.sources = payload.sources?.data ?? []
      state.data.sanctions = payload.sanctions?.data ?? []
      state.data.signs_of_crimes = payload.signs_of_crimes?.data ?? []
      state.data.models =
        payload.models?.data?.filter(({ classes_id }) => classes_id) ?? []
      state.data.narratives = payload.narratives?.data ?? []
      state.data[DICTIONARY_CUSTOM.languages] =
        payload[DICTIONARY_CUSTOM.languages]?.data ?? []
      state.data[DICTIONARY.persona_category] =
        payload?.[DICTIONARY.persona_category]?.data ?? []
      // state.data.networks = payload.networks?.data
      state.isLoading = false
    },
    addPerson: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: DictionaryPersonaContent
        locale: Language['locale']
        suffix: Language['attribute_suffix']
      }>
    ) => {
      state.data.personas = sortObjectByStringFieldWithLang(
        [...state.data.personas, payload.data],
        `name${payload.suffix}`,
        payload.locale
      )
    },
    setPersonas: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: DictionariesData['personas']
        locale: Language['locale']
        suffix: Language['attribute_suffix']
      }>
    ) => {
      state.data.personas = sortObjectByStringFieldWithLang(
        payload.data,
        `name${payload.suffix}`,
        payload.locale
      )
    },
    updateFootnote: (
      state,
      {
        payload: { index, data },
      }: PayloadAction<{
        data: FootnoteData
        index: number
      }>
    ) => {
      state.data.footnotes[index] = {
        ...state.data.footnotes[index],
        ...data,
      }
    },
    addFootnote: (state, { payload }: PayloadAction<FootnoteData>) => {
      state.data.footnotes.unshift(payload)
    },
    deleteFootnote: (state, { payload }: PayloadAction<number>) => {
      state.data.footnotes[payload].is_deleted = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDictionaries.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDictionaries.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  setDictionaries,
  addPerson,
  setPersonas,
  updateFootnote,
  addFootnote,
  deleteFootnote,
} = DictionariesSlice.actions

export default DictionariesSlice.reducer
