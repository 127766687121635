import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpAutocomplete'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'

export default (theme: Theme) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: inputStyles.input_min_width,
          display: 'inline-block',
          color: theme.palette.primary.main,
          '&.MuiAutocomplete-fullWidth': {
            width: 'auto',
            '& .MuiInputBase-root': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
          '&.IpMultiple': {
            '& .MuiInputLabel-shrink:not(.Mui-focused)': {
              top: styles.autocomplete_shrink_label_top,
              left: styles.autocomplete_shrink_label_left,
              fontSize: styles.autocomplete_shrink_label_font_size,
              color: theme.palette.primary.main,
            },
            '& .MuiInputLabel-shrink.MuiInputLabel-standard': {
              left: 0,
            },
            '& .MuiInput-underline': {
              padding: styles.autocomplete_underline_padding,
              '& .MuiButtonBase-root': {
                marginLeft: 0,
              },
            },
            '& .MuiOutlinedInput-root': {
              padding: styles.autocomplete_outlined_padding,
            },
          },
          '&.MuiAutocomplete-hasClearIcon': {
            '& .MuiFormLabel-root': {
              transition: '0s',
            },
          },
          '& .MuiButtonBase-root': {
            padding: 0,
            '&.Mui-disabled > .MuiSvgIcon-root': {
              color: theme.palette.grey[500],
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
            '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
              color: theme.palette.primary.dark,
            },
          },
          '& .MuiInputBase-root.MuiInputBase-root.MuiInputBase-root:not(.MuiInputBase-sizeSmall)':
            {
              minHeight: inputStyles.input_height,
              height: '100%',
              alignContent: 'center',
            },
          '& .MuiInputBase-sizeSmall': {
            minHeight: inputStyles.input_small_height,
            alignContent: 'center',
          },
          '& .MuiInputBase-sizeSmall.MuiInputBase-adornedStart': {
            height: 'auto',
          },
          '& .MuiOutlinedInput-root, & .MuiInput-root': {
            '& .MuiAutocomplete-endAdornment': {
              top: 0,
            },
          },
          '& button.MuiButtonBase-root': {
            visibility: 'visible',
          },
          '& .MuiChip-filled': {
            background: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
  }
}
