import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import React from 'react'
import { apiDeleteItemFromBasket } from '../../../../../api'
import useFilters from '../../../../../hooks/useFilters'
import { deleteReports } from '../../../../../redux/slices/reportBasketSlice'
import { useAppDispatch } from '../../../../../redux/store'
import { IpButton } from '../../../../ipDesignSystemComponents/atoms/IpButton'

type Props = {
  activeItems: string[]
}

function DeleteItemFromBasketBtn({ activeItems }: Props) {
  const dispatch = useAppDispatch()
  const { filters } = useFilters()

  const handleDelete = async () => {
    await apiDeleteItemFromBasket({
      report_ids: activeItems,
      ...filters,
    })
    dispatch(deleteReports(activeItems))
  }
  return (
    <IpButton
      startIcon={<DeleteOutlineIcon />}
      onClick={handleDelete}
      classes="basket-actions-panel-btn"
    >
      Delete
    </IpButton>
  )
}

export default DeleteItemFromBasketBtn
