import React, { useState } from 'react'
import type { ChangeEvent, Dispatch } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'

type SelectedListInputProps = {
  onItemAdd: (item: string) => void
  setFilteredValues: Dispatch<React.SetStateAction<string[]>>
  list: string[]
}

export const SelectedListInput = ({
  onItemAdd,
  setFilteredValues,
  list,
}: SelectedListInputProps) => {
  const [searchValue, setSearchValue] = useState('')

  const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    setSearchValue(targetValue)
    setFilteredValues(
      targetValue
        ? list.filter((item) =>
            item.toLowerCase().includes(targetValue.toLowerCase())
          )
        : list
    )
  }

  const submitHandler = () => {
    setSearchValue('')
    setFilteredValues(list)
    onItemAdd(searchValue)
  }

  return (
    <div className="select-list-input">
      <IpTextField
        placeholder="Search.."
        variant="standard"
        onChange={changeInputHandler}
        value={searchValue}
      />
      <IpIcon
        color="primary"
        onClick={submitHandler}
        disabled={!searchValue.length}
      >
        <CheckIcon />
      </IpIcon>
    </div>
  )
}
