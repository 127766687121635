import type { Theme } from '@mui/material'
import { secondaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import styles from '@infopulse-design-system/shared/theme/components/IpBreadcrumbs'

export default (theme: Theme) => {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '.MuiBreadcrumbs-li': {
            '& > .MuiTypography-root': {
              textDecoration: 'underline',
              textDecorationColor: theme.palette.grey[900],
              fontFamily: secondaryFontFamily,
              fontWeight: styles.breadcrumbs_font_weight,
              fontSize: styles.breadcrumbs_font_size,
              lineHeight: styles.breadcrumbs_line_height,
              color: theme.palette.grey[900],
            },
            '&:last-of-type .MuiTypography-root, &:hover .MuiTypography-root': {
              color: theme.palette.primary.main,
              textDecorationColor: theme.palette.primary.main,
            },
            '&:last-of-type .MuiTypography-root': {
              pointerEvents: 'none',
            },
          },
          '.MuiBreadcrumbs-separator': {
            color: theme.palette.grey[900],
            margin: styles.breadcrumbs_separator_margin,
          },
        },
      },
    },
  }
}
