import { OrderByFields } from '../../../../constants/enums'
import type { SortMenuConfigItem } from '../types/sort-menu-config.type'
import { SortMenuType } from '../types/sort-menu-config.type'

export const MEDIA_SORT_MENU_NAME_CONFIG = {
  [OrderByFields.relevance]: 'Relevance',
  [OrderByFields.air_date]: 'Air date',
  [OrderByFields.media_duration]: 'Media duration',
  [OrderByFields.found_texts]: 'Found texts',
  [OrderByFields.name]: 'Name',
  [OrderByFields.create_date]: 'Create date',
}

export const MEDIA_SORT_MENU_CONFIG: SortMenuConfigItem[] = [
  OrderByFields.relevance,
  OrderByFields.air_date,
  OrderByFields.media_duration,
  OrderByFields.found_texts,
]

export const PERSON_LIBRARY_SORT_MENU_CONFIG: SortMenuConfigItem[] = [
  OrderByFields.name,
  OrderByFields.create_date,
]

export const SORT_MENU_CONFIG = {
  [SortMenuType.media]: MEDIA_SORT_MENU_CONFIG,
  [SortMenuType.persona]: PERSON_LIBRARY_SORT_MENU_CONFIG,
}
