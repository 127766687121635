import React from 'react'
import { INITIAL_DATE_TO } from '../../../../../../constants'
import useFilters from '../../../../../../hooks/useFilters'
import { updateFilters } from '../../../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../../../redux/store'
import type { DateFilters } from '../../../../../../redux/types/filters'
import { getLocalDate } from '../../../../../../utils/data'
import { MemoIpButton } from '../../../../ipDSMemo'
import { getDate } from './helper'

type PeriodOFTime = {
  period: string
  variant: DateFilters
}
type Period = {
  variant: DateFilters
  disableDays?: boolean
}

const PERIODS_OF_TIME = ['1D', '1W', '1M', '6M', '1Y', '5Y', 'All']

const ButtonPeriodOfTime = ({ period, variant }: PeriodOFTime) => {
  const dispatch = useAppDispatch()
  const { filterType, filters } = useFilters()
  const prefixTo = `${variant}_to` as const
  const prefixFrom = `${variant}_from` as const

  const onClickButtonHandler = () => {
    dispatch(
      updateFilters({
        filterType,
        type: prefixFrom,
        value: getLocalDate(getDate(period)),
      })
    )
    dispatch(
      updateFilters({
        filterType,
        type: prefixTo,
        value: INITIAL_DATE_TO,
      })
    )
  }

  const variantStyle =
    getLocalDate(getDate(period)) === filters?.date_from &&
    INITIAL_DATE_TO === filters?.date_to
      ? 'contained'
      : 'outlined'

  return (
    <MemoIpButton onClick={onClickButtonHandler} variant={variantStyle}>
      {period}
    </MemoIpButton>
  )
}

export const FilterPeriodsOfTime = ({ variant, disableDays }: Period) => {
  return !disableDays ? (
    <div className="filter-period-btns">
      {PERIODS_OF_TIME.map((period) => (
        <ButtonPeriodOfTime key={period} period={period} variant={variant} />
      ))}
    </div>
  ) : (
    <></>
  )
}
