import { breakpoints as defaultBreakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import { updateResolutionClass } from '@infopulse-design-system/shared/utils/ui.utils'
import { useEffect, useState } from 'react'

/** The type of resolution. */
type IpResolutionType = 'largeDesktop' | 'desktop' | 'tablet' | 'mobile'

export function getResolutionType(
  width: number,
  breakpoints = defaultBreakpoints.values
): IpResolutionType {
  const { sm, md, xl } = breakpoints
  return width < sm
    ? 'mobile'
    : width >= sm && width < md
    ? 'tablet'
    : width >= md && width < xl
    ? 'desktop'
    : 'largeDesktop'
}

export default function useResponsive(
  initialState = getResolutionType(window.innerWidth)
) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [resolution, setResolution] = useState<IpResolutionType>(initialState)

  let type = initialState

  const updateResolution = (width: number) => {
    const currentType = getResolutionType(width)

    if (type !== currentType) {
      setResolution(currentType)
      updateResolutionClass(currentType, type)
      type = currentType
    }
  }

  const resizeHandler = () => {
    const { innerWidth } = window
    setWindowWidth(innerWidth)
    updateResolution(innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    updateResolutionClass(resolution)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return {
    windowWidth,
    getResolution: resolution,
    isMobileResolution: resolution === 'mobile',
    isTabletResolution: resolution === 'tablet',
    isDesktopResolution: resolution === 'desktop',
    isLargeDesktopResolution: resolution === 'largeDesktop',
  }
}
