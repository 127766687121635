export enum ROLES {
  annotator = 'annotator',
  researcher = 'researcher',
  media_processing = 'media_processing',
  administrator = 'administrator',
}

export enum PERMISSIONS {
  speaker_annotation = 'speaker_annotation',
  user_tags = 'user_tags',
  export_reports_and_transcriptions = 'export_reports_and_transcriptions',
  transcription_context_menu = 'transcription_context_menu',
  report_basket = 'report_basket',
  person_library = 'person_library',
  person_library_edit = 'person_library_edit',
  bug_report = 'bug_report',
  speaker_score_filter = 'speaker_score_filter',
  signs_of_crimes_filter = 'signs_of_crimes_filter',
  signs_of_crimes_annotation = 'signs_of_crimes_annotation',
  narratives_annotation = 'narratives_annotation',
  media_processing = 'media_processing',
  admin_panel = 'admin_panel',
}

export enum WeekFullDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum BugTypes {
  text = '1',
  persona = '2',
}

export enum AnnotationClassesIdTypes {
  signsOfCrimes = '1.0',
  narratives = '2.0',
}

export enum QueryTypes {
  full = 'full',
  semantic = 'semantic',
}

export enum MainPageTabTypes {
  results = 'correct',
  bugs = 'incorrect',
}

export enum FilterTypes {
  basket = 'basket',
  dashboard = 'dashboard',
  mainBugs = 'mainBugs',
  mainResults = 'mainResults',
  personLibrary = 'personLibrary',
}

export enum RESPONSE_ERROR_REASON {
  CANCELED = 'canceled',
}

export enum EXPORT_FILE_FORMATS {
  DOCX = 'docx',
  EXCEL = 'excel',
}

export enum PersonaNetworkFieldsEnum {
  site_url = 'site_url',
  wiki_url = 'wiki_url',
  facebook_page = 'facebook_page',
  youtube_channel = 'youtube_channel',
  rutube_channel = 'rutube_channel',
  telegram_channel = 'telegram_channel',
  vk_page = 'vk_page',
}

export enum PersonaFieldsEnum {
  id = 'id',
  name_en = 'name_en',
  name = 'name',
  name_ukr = 'name_ukr',
  birth_date = 'birth_date',
  citizenship_id = 'citizenship_id',
  categories_id = 'categories_id',
  photo_url = 'photo_url',
  title = 'title',
  title_en = 'title_en',
  title_ukr = 'title_ukr',
  sanctions_list_id = 'sanctions_list_id',
  sanctions_justification = 'sanctions_justification',
  sanctions_justification_ukr = 'sanctions_justification_ukr',
  sanctions_justification_en = 'sanctions_justification_en',
}

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum OrderByFields {
  relevance = 'relevance',
  air_date = 'air_date',
  media_duration = 'media_duration',
  found_texts = 'found_texts',
  name = 'name',
  create_date = 'create_date',
}
