import React from 'react'
import { IpCheckbox } from '../../../ipDesignSystemComponents/atoms/IpCheckbox'
import DeleteItemFromBasketBtn from './DeleteItemFromBasketBtn'
import ExportBasketBtn from './ExportBasketBtn'
import './index.scss'

type Props = {
  selectAllHandler: (value: string) => void
  isSelectedAll: boolean
  activeItems: string[]
}

export function BasketActionsPanel({
  selectAllHandler,
  isSelectedAll,
  activeItems,
}: Props) {
  return (
    <div className="basket-actions-panel">
      <IpCheckbox
        onChange={(e) => selectAllHandler(e.target.value)}
        checked={isSelectedAll}
        value={isSelectedAll ? 'on' : 'off'}
        label="Select all"
        size="24px"
        classes="basket-actions-panel-checkbox"
      />
      <ExportBasketBtn activeItems={activeItems} />
      <DeleteItemFromBasketBtn activeItems={activeItems} />
    </div>
  )
}
