import { ChartType } from '../constants/cart'
import type {
  ChartLabelType,
  DashboardIntervalType,
} from '../hooks/dataLoaders/useDashboard'
import type { ChartTypeValue } from '../pages/Dashboard'
import type { GetMediaParams } from './apiTypes'
import { api } from './axiosInstance'
import { mapFiltersToSearchParams } from './mappers'

export type GetTranscriptionStatsProps = GetMediaParams & {
  chartType: ChartTypeValue
  /** is required for linear chart */
  interval: DashboardIntervalType
  /** is required for bar chart */
  chartLabelType: ChartLabelType
  searchKeys: string[]
}

export type GetTranscriptionStatsRes = {
  search: string
  data: {
    count: number
    value: string
  }[]
}[]

export const apiGetTranscriptionStats = async ({
  filters,
  isExport,
}: {
  filters: GetTranscriptionStatsProps
  isExport?: true
}): Promise<GetTranscriptionStatsRes> => {
  try {
    const { chartType, interval, chartLabelType, searchKeys } = filters
    const isBar = chartType === ChartType.bar
    const params = mapFiltersToSearchParams(filters)
    const intervalValue = isBar ? '' : `,interval:${interval}`
    const response = await api.post(
      `media/transcription/stats${isExport ? '/export' : ''}`,
      {
        ...params,
        searchKeys,
        chartType,
        facets: [`air_date${intervalValue}`],
        searchInterval: interval,
        ...(isBar ? { chartLabelType } : {}),
      },
      {
        responseType: isExport ? 'blob' : 'json',
      }
    )
    return response.data
  } catch (error) {
    console.error('Error during get statistics', error)
    throw error
  }
}
