import React from 'react'
import { Link } from '@mui/material'
import { Link as NavLink } from 'react-router-dom'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import colors from '@infopulse-design-system/shared/theme/colors'
import type { IpLinkComponentType, IpLinkPropTypesReact } from './index.types'
import '@infopulse-design-system/shared/theme/components/IpLink/styles.scss'

/**
 * `IpLink` component offers a convenient way to customize anchor elements with your desired theme colors and typography styles.
 *
 * #### Third-party routing library
 * A common scenario involves conducting client-side navigation without requiring an HTTP request to the server.
 * To address this use case, the `IpLink` component provides the `component` attribute.
 * If the `to` attribute is used, the default behavior will be handled by the `NavLink` component from `react-router-dom`.
 *
 * #### Security
 * When linking to third-party content with the `target="_blank"` attribute, it is advisable to always set `isLinkSafe=false`.
 * In this case, the `rel="noopener"` and `rel="noreferrer"` attributes will be automatically applied.
 * * The rel="noopener" attribute prevents the newly opened page from accessing the window.opener property,
 *   ensuring it operates independently. This helps prevent potential malicious redirects.
 * * The rel="noreferrer" attribute has the same effect as rel="noopener", but it additionally prevents the sending
 *   of the Referer header to the newly opened page.
 *
 * ⚠️ Removing the referrer header will affect analytics.
 */
export const IpLink: IpLinkComponentType = (props: IpLinkPropTypesReact) => {
  const {
    children,
    classes,
    color,
    component,
    href,
    isLinkSafe = false,
    onClick,
    target,
    to,
    underline,
    ...overrideProps
  } = props

  const customClasses = formatClassName(
    generateClasses('link', 'react'),
    classes
  )
  const renderedComponent = component ?? to ? NavLink : 'a'

  return (
    <Link
      className={customClasses}
      color={color ? colors[color].main : undefined}
      component={renderedComponent}
      href={href}
      onClick={onClick}
      rel={isLinkSafe ? '' : 'noopener noreferrer'}
      target={target}
      to={to}
      underline={underline}
      {...overrideProps}
    >
      {children}
    </Link>
  )
}
