import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PersonaPreviewPanel } from '../../components/custom/persona/PersonaPreviewPanel'
import { ROUTER_PATHS } from '../../components/custom/Router'
import { IpButton } from '../../components/ipDesignSystemComponents/atoms/IpButton'
import { IpProgressBar } from '../../components/ipDesignSystemComponents/atoms/IpProgressBar'
import { usePersonaData } from '../../hooks/usePersonaData'
import { personaListSelector } from '../../redux/selectors'
import { setActivePersonaId } from '../../redux/slices/appStateSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'

import './index.scss'

function Persona() {
  const dispatch = useAppDispatch()
  const { id = '' } = useParams()
  const { data: personaList } = useAppSelector(personaListSelector).data
  const { isPersonaDataLoading } = usePersonaData(id)
  const [index, setIndex] = useState(-1)

  const isInList = index !== -1

  useEffect(() => {
    if (personaList) {
      const listIndex = personaList.findIndex((item) => item.id === id)
      setIndex(listIndex)
      dispatch(setActivePersonaId([id, listIndex]))
    }
  }, [personaList, id])

  return (
    <div className="page persona-wrapper">
      {!!personaList.length && (
        <IpButton
          variant="text"
          classes="btn_go-back"
          to={`/${isInList ? ROUTER_PATHS.person_library : ''}`}
        >
          <ArrowBackIcon /> {isInList ? 'Go person library' : 'Go home'}
        </IpButton>
      )}
      {isPersonaDataLoading && <IpProgressBar />}
      <PersonaPreviewPanel />
    </div>
  )
}

export default Persona
