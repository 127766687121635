import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import EditIcon from '@mui/icons-material/Edit'
import { Popover } from '@mui/material'
import type { Dispatch, MouseEvent } from 'react'
import React, { memo, useCallback, useState } from 'react'
import { UnknownSpeakerData } from '../../../../../constants'
import { useLanguage } from '../../../../../hooks/useLanguage'
import {
  dictionariesSelector,
  incorrectTranscriptionsByMediaIdSelector,
} from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import { DICTIONARY_ADDITION } from '../../../../../redux/types/dictionaries'
import {
  findDictionaryItemById,
  formatSecondsToTime,
  getNameFromDictionaryByLang,
  getValueByLang,
} from '../../../../../utils'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../../ipDesignSystemComponents/atoms/IpText'
import type { PersonaInfoIconState } from '../../../persona'
import { PersonaInfoIcon } from '../../../persona'

import './index.scss'

export type TranscriptionWithBugsProps = {
  active: boolean
  correctText: string | null
  correctPersonaId: string | null
  speakerId: string
  mediaId: string
  transcriptionId: string
  setPersonaInfoIconState: Dispatch<PersonaInfoIconState>
}

export const TranscriptionWithBug = memo(function TranscriptionComponent(
  props: TranscriptionWithBugsProps
) {
  const {
    correctText,
    active,
    correctPersonaId,
    speakerId,
    mediaId,
    transcriptionId,
    setPersonaInfoIconState,
  } = props
  const { suffix } = useLanguage()
  const { data: dictionary } = useAppSelector(dictionariesSelector)
  const transcriptions =
    useAppSelector((state) =>
      incorrectTranscriptionsByMediaIdSelector(state, mediaId)
    ) || []

  const isUnknownSpeaker = correctPersonaId === UnknownSpeakerData.id

  const correctPersonData = isUnknownSpeaker
    ? UnknownSpeakerData
    : findDictionaryItemById(
        dictionary,
        DICTIONARY_ADDITION.personas,
        correctPersonaId || ''
      )

  const correctPersonDataName = isUnknownSpeaker
    ? UnknownSpeakerData.name
    : getValueByLang(suffix, 'name', correctPersonData)

  const [editIconAnchor, setEditIconAnchor] = useState<HTMLElement | null>(null)
  const onEditIconAnchorClick: (event: MouseEvent<HTMLElement>) => void =
    useCallback((event) => {
      setEditIconAnchor(event.currentTarget as HTMLElement)
    }, [])
  const isEditedPopoverOpen = Boolean(editIconAnchor)
  const onCloseHandler = () => {
    setEditIconAnchor(null)
  }

  // todo: heavy
  const transcription = Object.values(transcriptions).find(
    (el) => el.transcription_id === transcriptionId
  )

  const personName = getNameFromDictionaryByLang({
    suffix,
    dictionary,
    key: DICTIONARY_ADDITION.personas,
    id: transcription?.persona_id,
  })

  const customClasses = formatClassName(
    active ? 'transcription_active' : '',
    'transcription_bug'
  )

  const onPersonaIconClick = (event: MouseEvent<HTMLElement>, id: string) => {
    setPersonaInfoIconState({
      anchor: event.currentTarget,
      id,
    })
  }

  const getIncorrectTextMessage = (correctPhrase: string | null) => {
    if (correctPhrase) {
      return `Incorrect text: ${transcription?.text}`
    }
    return `Incorrect persona: ${personName}`
  }

  return (
    <>
      <IpText variant="body1" classes={customClasses}>
        <span className="transcription_time">
          {formatSecondsToTime(transcription?.start)}
        </span>
        {transcription?.persona_id ? (
          <span className="transcription_persona">
            <>
              {' - '}
              {correctPersonDataName || personName}
              {!isUnknownSpeaker && (
                <PersonaInfoIcon
                  onClick={(e) =>
                    onPersonaIconClick(
                      e,
                      correctPersonaId ?? transcription?.persona_id ?? ''
                    )
                  }
                />
              )}
            </>
          </span>
        ) : (
          <span className="transcription-icon_anchor">
            <span className="transcription_speaker">{speakerId}</span>
          </span>
        )}
        <span className="transcription_text">
          {correctText || transcription?.text}
        </span>
        <IpIcon
          onClick={onEditIconAnchorClick}
          color="error"
          classes="edit-icon"
          fontSize="18px"
        >
          <EditIcon />
        </IpIcon>
      </IpText>

      <Popover
        className="incorrect-transcription-text"
        anchorEl={editIconAnchor}
        open={isEditedPopoverOpen}
        onClose={onCloseHandler}
        onBackdropClick={onCloseHandler}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {getIncorrectTextMessage(correctText)}
        {correctPersonaId && transcription?.persona_id && (
          <PersonaInfoIcon
            onClick={(e) =>
              onPersonaIconClick(e, transcription.persona_id as string)
            }
          />
        )}
      </Popover>
    </>
  )
})
