import React from 'react'

export const Excel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 3L2 5V19L12 21V3ZM14 5V7H16V9H14V11H16V13H14V15H16V17H14V19H21C21.552 19 22 18.552 22 18V6C22 5.448 21.552 5 21 5H14ZM18 7H20V9H18V7ZM4.17578 8.29688H5.95312L6.87695 10.5117C6.95195 10.6927 7.00841 10.9026 7.06641 11.1406H7.0918C7.1248 10.9976 7.19197 10.7791 7.29297 10.4941L8.32227 8.29688H9.94336L8.00781 11.9668L10 15.7031H8.27148L7.1582 13.2891C7.1162 13.2041 7.06639 13.0329 7.02539 12.7949H7.00977C6.98477 12.9089 6.93437 13.0796 6.85938 13.3086L5.73633 15.7031H4L6.06055 11.9961L4.17578 8.29688ZM18 11H20V13H18V11ZM18 15H20V17H18V15Z"
      fill="#06037D"
    />
  </svg>
)
