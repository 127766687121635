import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { useSearchFilter } from '../../../../hooks/useSearchFilter'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'

const endAdornment = (
  <IpIcon ariaLabel="Search Icon" color="primary" fontSize="24px">
    <SearchIcon />
  </IpIcon>
)

export const Search = () => {
  const { error, value, onChange } = useSearchFilter('search')
  const { isMainBugsTab } = useMainPageActiveTab()

  return (
    <IpTextField
      disabled={isMainBugsTab}
      error={!!error}
      helperText={error}
      classes="header-search"
      placeholder="Enter word or collocation"
      value={value}
      onChange={onChange}
      endAdornment={endAdornment}
    />
  )
}
