import React from 'react'
import { styled } from '@mui/material/styles'
import { FixedSizeList } from 'react-window'
import type { IpFixedSizeListPropTypes } from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpList/IpFixedSizeList/styles.scss'

export const IpFixedSizeListClassName = 'IpList--fixed-size'
export const IpFixedSizeListFullWidthClassName =
  'IpList--fixed-size--full-width'

export type IpFixedSizeListPropTypesReact = IpFixedSizeListPropTypes & {
  children?: any
}

const IpFixedSizeListStyled = styled(FixedSizeList)(
  ({ itemRowsCount }: { itemRowsCount: number | undefined }) => {
    const isRowLines = itemRowsCount
      ? {
          display: '-webkit-box',
          '-webkit-line-clamp': itemRowsCount.toString(),
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        }
      : {}
    return {
      [`&.${IpFixedSizeListClassName}`]: {
        maxWidth: '100%',
        '&--full-width': {
          width: '100% !important',
        },
      },
      '.MuiListItemButton-root': {
        paddingTop: 0,
        paddingBottom: 0,
        height: '100%',
      },
      '.MuiTypography-root': { ...isRowLines },
    }
  }
)

export function IpFixedSizeList(props: IpFixedSizeListPropTypesReact) {
  const {
    children,
    classes,
    fullWidth,
    height,
    itemCount,
    itemData,
    itemSize,
    itemRowsCount,
    overscanCount,
    width,
  } = props

  const customClasses = formatClassName(
    generateClasses('fixed-size-list', 'react'),
    IpFixedSizeListClassName,
    fullWidth ? IpFixedSizeListFullWidthClassName : '',
    classes
  )

  return (
    <IpFixedSizeListStyled
      className={customClasses}
      height={height}
      itemCount={itemCount}
      itemData={itemData}
      itemSize={itemSize}
      itemRowsCount={itemRowsCount}
      overscanCount={overscanCount}
      width={width}
    >
      {children}
    </IpFixedSizeListStyled>
  )
}
