import { useEffect, useState } from 'react'
import { dictionariesSelector } from '../redux/selectors'
import { useAppSelector } from '../redux/store'
import {
  DICTIONARY,
  DICTIONARY_ADDITION,
  DICTIONARY_CUSTOM,
} from '../redux/types/dictionaries'
import { getNameFromDictionaryByLang } from '../utils'
import { useLanguage } from './useLanguage'

type Props = {
  media_type_id?: string
  source_id?: string
  program_id?: string
  author_id?: string | null
  persona_id?: string | null
  categories_id?: string[]
  citizenship_id?: string
  sanctions_list_id?: string[]
  telegram_channel_id?: string
}

export const useDictionaryData = ({
  media_type_id,
  source_id,
  program_id,
  author_id,
  persona_id,
  categories_id,
  citizenship_id,
  sanctions_list_id,
  telegram_channel_id,
}: Props) => {
  const { suffix } = useLanguage()
  const dictionary = useAppSelector(dictionariesSelector).data

  const [dictionaryData, setDictionaryData] = useState<Record<string, string>>(
    {}
  )

  useEffect(() => {
    if (telegram_channel_id) {
      const telegramChanel = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY_CUSTOM.telegram_channels,
        id: telegram_channel_id,
      })
      setDictionaryData((prev) => ({ ...prev, telegramChanel }))
    }
  }, [
    dictionary[DICTIONARY_CUSTOM.telegram_channels],
    suffix,
    telegram_channel_id,
  ])

  useEffect(() => {
    if (sanctions_list_id) {
      const sanctions = sanctions_list_id
        .map((sanction) =>
          getNameFromDictionaryByLang({
            suffix,
            dictionary,
            key: DICTIONARY.sanctions,
            id: sanction,
          })
        )
        .join('; ')

      setDictionaryData((prev) => ({ ...prev, sanctions }))
    }
  }, [dictionary[DICTIONARY.sanctions], suffix, sanctions_list_id])

  useEffect(() => {
    if (citizenship_id) {
      const citizenship = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY.citizenship,
        id: citizenship_id,
      })
      setDictionaryData((prev) => ({ ...prev, citizenship }))
    }
  }, [dictionary[DICTIONARY.citizenship], suffix, citizenship_id]) // todo: create key from dictionary names

  useEffect(() => {
    if (categories_id) {
      const categories = categories_id
        .map((category) =>
          getNameFromDictionaryByLang({
            suffix,
            dictionary,
            key: DICTIONARY.persona_category,
            id: category,
          })
        )
        .join('; ')

      setDictionaryData((prev) => ({ ...prev, categories }))
    }
  }, [dictionary[DICTIONARY.persona_category], suffix, categories_id])

  useEffect(() => {
    if (media_type_id) {
      const mediaType = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY.media_type,
        id: media_type_id,
      })

      setDictionaryData((prev) => ({ ...prev, mediaType }))
    }
  }, [dictionary[DICTIONARY.media_type], suffix, media_type_id])

  useEffect(() => {
    if (source_id) {
      const source = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY.sources,
        id: source_id,
      })

      setDictionaryData((prev) => ({ ...prev, source }))
    }
  }, [dictionary[DICTIONARY.sources], suffix, source_id])

  useEffect(() => {
    if (program_id) {
      const program = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY.programs,
        id: program_id,
      })

      setDictionaryData((prev) => ({ ...prev, program }))
    }
  }, [dictionary[DICTIONARY.programs], suffix, program_id])

  useEffect(() => {
    if (author_id) {
      const author = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY.authors,
        id: author_id,
      })

      setDictionaryData((prev) => ({ ...prev, author }))
    }
  }, [dictionary[DICTIONARY.authors], suffix, author_id])

  useEffect(() => {
    if (persona_id) {
      const persona = getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY_ADDITION.personas,
        id: persona_id,
      })

      setDictionaryData((prev) => ({ ...prev, persona }))
    }
  }, [dictionary[DICTIONARY_ADDITION.personas], suffix, persona_id])

  return dictionaryData
}
