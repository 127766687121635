import type {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react'
import React, { useState } from 'react'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import styled from '@emotion/styled'
import type { IpTabPropTypes } from '@infopulse-design-system/shared/types/IpTabs.types'
import type { Theme } from '@mui/material'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpTabs/styles.scss'

export const IpTabsTestId = 'IpTabsTestId'

export type IpTabPropTypesReact = IpTabPropTypes<
  string | ReactElement<any, string | JSXElementConstructor<any>> | undefined
> & {
  children: ReactNode
}

type StyledWrapperPropTypes = {
  activecolor: IpTabPropTypesReact['activeColor']
  direction: IpTabPropTypesReact['direction']
  indicatorcolor: IpTabPropTypesReact['indicatorColor']
  orientation: IpTabPropTypesReact['orientation']
  textcolor: IpTabPropTypesReact['textColor']
  theme?: Theme
}

const StyledWrapper = styled('div')(
  ({
    activecolor = 'primary',
    direction,
    indicatorcolor = 'primary',
    orientation,
    textcolor,
    theme,
  }: StyledWrapperPropTypes) => {
    const isVertical = orientation === 'vertical'
    return {
      width: '100%',
      height: '100%',
      display: isVertical ? 'flex' : 'block',
      ...(isVertical ? { flexGrow: 1 } : {}),
      '& .MuiTab-root': {
        color: textcolor
          ? theme?.palette?.[textcolor].main
          : theme?.palette?.grey[900],
        '&.Mui-selected': {
          color: theme?.palette?.[activecolor].main,
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme?.palette?.[indicatorcolor].main,
      },
      '& .MuiButtonBase-root': {
        [isVertical
          ? 'borderRight'
          : 'borderBottom']: `1px solid ${theme?.palette?.grey[500]}`,
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: direction,
      },
      '& .MuiTabs-scrollButtons': {
        color: theme?.palette?.[activecolor].main,
      },
    }
  }
)

/**
 * `IpTabs` provides a convenient way to navigate and switch between various views or sections. It help
 * organize related content that exists at the same level of hierarchy, making it effortless for users
 * to explore different groups of information or functionalities. By utilizing tabs, users can seamlessly
 * switch between different sections while maintaining a clear and structured navigation experience.
 *
 * It can be easily to create the tabs by providing a simple configuration object called `tabs`.
 * To add the content for each tab, include it as children within the `IpTabs` component and use the
 * `IpTabPanel` component for each item. Make sure to set the `value` prop of the `IpTabPanel` to match
 * the corresponding `value` in the tabs configuration. This ensures proper synchronization between the
 * tabs and their respective content, creating a smooth and user-friendly tabbed interface.
 */
export function IpTabs(props: IpTabPropTypesReact) {
  const {
    activeColor,
    allowScrollButtonsMobile = true,
    children,
    classes,
    direction = 'left',
    indicatorColor,
    orientation,
    tabs,
    textColor,
    value = '0',
    variant,
    visibleScrollbar,
  } = props
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setCurrentValue(newValue)
  }

  const customClasses = formatClassName(
    generateClasses('tabs', 'react'),
    classes
  )

  return (
    <StyledWrapper
      activecolor={activeColor}
      className={customClasses}
      data-testid={IpTabsTestId}
      direction={direction}
      indicatorcolor={indicatorColor}
      orientation={orientation}
      textcolor={textColor}
    >
      <TabContext value={currentValue}>
        <TabList
          allowScrollButtonsMobile={allowScrollButtonsMobile}
          onChange={handleChange}
          orientation={orientation}
          scrollButtons={!visibleScrollbar}
          variant={variant === 'standard' ? 'scrollable' : variant}
          visibleScrollbar={visibleScrollbar}
        >
          {tabs.map(({ classes: tabClasses, ...tabProps }, key) => {
            return (
              <Tab
                className={tabClasses}
                key={key}
                value={key.toString()}
                {...tabProps}
              />
            )
          })}
        </TabList>
        {children}
      </TabContext>
    </StyledWrapper>
  )
}
