import { MainPageTabTypes } from '../constants/enums'
import { mainActiveTabSelector } from '../redux/selectors'
import { useAppSelector } from '../redux/store'

const useMainPageActiveTab = () => {
  const mainActiveTab = useAppSelector(mainActiveTabSelector)

  return {
    mainActiveTab,
    isMainBugsTab: mainActiveTab === MainPageTabTypes.bugs,
    isMainResultsTab: mainActiveTab === MainPageTabTypes.results,
  }
}

export default useMainPageActiveTab
