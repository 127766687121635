import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Stack } from '@mui/system'
import React from 'react'
import { PROCESSING_REFRESH_TIMER } from '../../../../constants'
import { useLanguage } from '../../../../hooks/useLanguage'
import { processSelector, profileSelector } from '../../../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import {
  deleteMediaProcessingById,
  getMediaProcessingList,
} from '../../../../redux/thunk/mediaProcessingThunk'
import { ProcessStatus } from '../../../../redux/types/mediaProcessing'
import { getProcessStatus } from '../../../../utils'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpLink } from '../../../ipDesignSystemComponents/atoms/IpLink'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { RemoveMedia } from '../../icons/RemoveMedia'

type ListItemLinkProps = {
  to: string
  text: string
  status: ProcessStatus
  id: string
  error?: string
}

const getTimeFormat = (locale: string, string: string) => {
  if (!string) return ''
  const date = new Date(string)
  return date.toLocaleString(locale, { hour12: false })
}

const InfoIcon = ({ text }: { text: string }) => {
  return (
    <IpTooltip
      arrow
      title={text}
      placement="top"
      content={
        <IpIcon color="error" classes="error-info-icon" fontSize="15px">
          <InfoOutlinedIcon />
        </IpIcon>
      }
    />
  )
}

function ListItemLink(props: ListItemLinkProps) {
  const { to, text, status, id, error } = props
  const dispatch = useAppDispatch()
  const color = getProcessStatus(status)

  return (
    <ListItem
      sx={{
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        gap={2}
      >
        {color !== 'error' ? (
          <IpLink underline="hover" to={to}>
            {text}
          </IpLink>
        ) : (
          text
        )}
        <span>
          <IpText component="span" color={color}>
            {error && <InfoIcon text={error} />}
            {status}
          </IpText>{' '}
          {status !== ProcessStatus.REMOVED && (
            <RemoveMedia
              placement="top"
              onClick={() => {
                dispatch(deleteMediaProcessingById(id))
              }}
            />
          )}
        </span>
      </Stack>
    </ListItem>
  )
}

export const MediaList = () => {
  const [loading, setLoading] = React.useState(false)
  const mediaProcessingData = useAppSelector(processSelector)
  const dispatch = useAppDispatch()
  const profile = useAppSelector(profileSelector)
  const isAuth = Boolean(profile.data.name)
  const { locale } = useLanguage()

  const handleLoad = async () => {
    setLoading(true)
    await dispatch(getMediaProcessingList())
    setLoading(false)
  }

  React.useEffect(() => {
    if (!isAuth) return
    handleLoad()
  }, [isAuth])

  React.useEffect(() => {
    const timerId = setInterval(() => {
      if (isAuth) {
        handleLoad()
      }
    }, PROCESSING_REFRESH_TIMER)

    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [])

  const items = Object.values(mediaProcessingData).sort(
    (a, b) => Date.parse(b.air_date) - Date.parse(a.air_date)
  )

  return (
    <Box className="media-list">
      {loading && 'Media list loading...'}
      {loading && <IpProgressBar />}
      <List>
        {items.map(({ id, status, air_date, error }) => (
          <ListItemLink
            key={id}
            id={id}
            to={`/media-processing/${id}`}
            text={getTimeFormat(locale, air_date)}
            status={status}
            error={error}
          />
        ))}
      </List>
    </Box>
  )
}
