import {
  MAX_MEDIA_LENGTH,
  MAX_MEDIA_NUM,
  MAX_SIMILAR_EMBEDDING_NUM,
} from '../constants'
import { OrderByFields, OrderDirection } from '../constants/enums'
import type { FiltersState } from '../redux/types/filters'
import { filterDateMapper } from '../utils/data'
import type {
  GetBugMappedParams,
  GetBugParams,
  GetMediaMappedParams,
  GetMediaParams,
  GetPersonaListMappedParams,
  GetPersonaListParams,
  GetReportBasketMappedParams,
} from './apiTypes'

export const mapFiltersToSearchParams = (
  filters: GetMediaParams
): GetMediaMappedParams => {
  return {
    weekday: filters.weekdays?.map(Number),
    media_type_id: filters.media_type,
    source_id: filters.sources,
    persona_id: filters.personas,
    author_id: filters.authors,
    program_id: filters.programs,
    skip: filters.skip,
    user_tags: filters.user_tags,
    limit: filters.limit,
    date_to: filterDateMapper(filters.date_to, 'day-end'),
    date_from: filterDateMapper(filters.date_from, 'day-start'),
    duration_from:
      filters.media_length_start && filters.media_length_start !== 0
        ? filters.media_length_start * 60
        : undefined,
    duration_to:
      filters.media_length_end && filters.media_length_end !== MAX_MEDIA_LENGTH
        ? filters.media_length_end * 60
        : undefined,
    search: filters.search,
    queryType: filters.queryType,
    search_model: filters.search_model,
    signs_of_crimes: filters.signs_of_crimes,
    percentage_start:
      filters.percentage_start && filters.percentage_start !== 0
        ? filters.percentage_start / 100
        : 0,
    percentage_end: filters.percentage_end ? filters.percentage_end / 100 : 1,
    similar_embeddings_num_start: filters.similar_embeddings_num_start ?? 0,
    similar_embeddings_num_end:
      filters.similar_embeddings_num_end ?? MAX_SIMILAR_EMBEDDING_NUM,
    telegram_channel_id: filters.telegram_channels,
    order_by: filters.order_by || OrderByFields.relevance,
    order_direction: filters.order_direction || OrderDirection.asc,
  }
}

export const mapFiltersToBasketParams = (
  filters: FiltersState
): GetReportBasketMappedParams => {
  return {
    // air_date_from: filters?.air_date_from,
    // air_date_to: filters?.air_date_to,
    limit: filters?.limit,
    persona_id: filters?.personas,
    program_id: filters?.programs,
    search: filters?.search,
    skip: filters?.skip,
    source_id: filters?.sources,
    submit_date_from: filterDateMapper(filters?.submit_date_from, 'day-start'),
    submit_date_to: filterDateMapper(filters?.submit_date_to, 'day-end'),
    title: filters?.program_title,
    user_tags: filters?.user_tags,
  }
}

export const mapBugParams = (filters: GetBugParams): GetBugMappedParams => {
  return {
    create_date_from: filterDateMapper(filters?.date_from, 'day-start'),
    create_date_to: filterDateMapper(filters?.date_to, 'day-end'),
    limit: filters?.limit,
    skip: filters?.skip,
  }
}

export const mapPersonaParams = ({
  filters,
  continuationToken,
}: GetPersonaListParams): GetPersonaListMappedParams => {
  const {
    persona_category: categories_id,
    media_num_start = 0,
    media_num_end = MAX_MEDIA_NUM,
    order_by = OrderByFields.name,
    order_direction = OrderDirection.asc,
    ...rest
  } = filters
  return {
    categories_id,
    continuationToken,
    media_num_start,
    media_num_end,
    order_by,
    order_direction,
    ...rest,
  }
}
