/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  SearchApiResponse,
  SearchDataType,
} from '../../components/custom/TestResultTable'
import { fetchSearchData } from '../thunk/searchThunk'

export interface SearchState {
  data: SearchDataType[]
  isLoading: boolean
  error: string
  totalRowCount: number
}

export const initialSearchState: SearchState = {
  data: [],
  isLoading: false,
  error: '',
  totalRowCount: 20,
}

export const SearchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    setSearch: (state, { payload }: PayloadAction<SearchApiResponse>) => {
      state.data = payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSearchData.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const { setSearch } = SearchSlice.actions

export default SearchSlice.reducer
