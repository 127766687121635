import type { ElementType, ForwardedRef } from 'react'
import React, { forwardRef } from 'react'
import { Button } from '@mui/material'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { Link } from 'react-router-dom'
import type {
  IpButtonComponentType,
  IpButtonPropTypesReact,
} from './index.types'
import '@infopulse-design-system/shared/theme/components/IpButton/styles.scss'

export const IpButtonTestId = 'IpButtonTestId'

/**
 * `IpButton` grants users the capability to execute actions and make decisions with a solitary click.
 *
 * #### Third-party routing library
 * A common scenario involves carrying out client-side navigation without requiring an HTTP request to the server.
 * The `IpButton` element incorporates the `component` attribute to address this particular scenario.
 * By utilizing the `to` attribute, the default behavior will be handled by the `Link` component from `react-router-dom`.
 */
export const IpButton: IpButtonComponentType = forwardRef(function IpButton<
  D extends ElementType
>(props: IpButtonPropTypesReact<D>, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    component,
    children,
    classes,
    color,
    disabled,
    endIcon,
    fullWidth,
    href,
    onClick,
    size,
    startIcon,
    target,
    to,
    variant,
    type = 'button',
    ...overrideProps
  } = props

  const customClasses = formatClassName(
    generateClasses('button', 'react'),
    classes
  )

  const renderedComponent = component ?? to ? Link : href ? 'a' : 'button'

  return (
    <Button
      className={customClasses}
      color={color}
      component={renderedComponent}
      data-testid={IpButtonTestId}
      disabled={disabled}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      ref={ref}
      size={size}
      startIcon={startIcon}
      target={target}
      to={to}
      type={href ? undefined : type}
      variant={variant}
      {...overrideProps}
    >
      <span className="hover-animation" />
      <span className="button-content">{children}</span>
    </Button>
  )
}) as IpButtonComponentType
