import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpRadioGroup'

export default (theme: Theme) => {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiSvgIcon-root': { fontSize: '1em' },
          '& ~ .MuiTypography-root': {
            font: styles.radio_label_font,
            marginLeft: styles.radio_space_between,
          },
          '&.MuiRadio-colorPrimary, &.MuiRadio-colorPrimary + .MuiTypography-root':
            {
              color: theme.palette.primary.main,
            },
          '&.MuiRadio-colorSecondary, &.MuiRadio-colorSecondary + .MuiTypography-root':
            {
              color: theme.palette.secondary.main,
            },
          '&.MuiRadio-colorSuccess, &.MuiRadio-colorSuccess + .MuiTypography-root':
            {
              color: theme.palette.success.main,
            },
          '&.MuiRadio-colorError, &.MuiRadio-colorError + .MuiTypography-root':
            {
              color: theme.palette.error.main,
            },
          '&.MuiRadio-colorWarning, &.MuiRadio-colorWarning + .MuiTypography-root':
            {
              color: theme.palette.warning.main,
            },
          '&.Mui-disabled.Mui-checked, &.Mui-disabled + .MuiTypography-root': {
            color: theme.palette.grey['900'],
          },
          '&.Mui-disabled': {
            color: theme.palette.grey['500'],
          },
        },
      },
    },
  }
}
