import React from 'react'
import { ProcessingForm } from '../../components/custom/processing'
import { MediaList } from '../../components/custom/processing/MediaList'
import './index.scss'

function MediaProcessing() {
  return (
    <div className="page media-processing">
      <div className="form-wrapper">
        <ProcessingForm />
        <MediaList />
      </div>
    </div>
  )
}

export default MediaProcessing
