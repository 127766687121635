import type {
  IpChartScaleTypes,
  IpChartScaleTypeTypes,
} from '@infopulse-design-system/shared/types/IpChartBase'
import type { SlideProps } from '@mui/material'
import { Fade, Grow, Slide, Zoom } from '@mui/material'
import { format } from 'date-fns'
import type { Dispatch, SetStateAction } from 'react'
import React, { forwardRef } from 'react'
import SafeColor from 'safecolor'
import type { ExportTranscriptionsParams } from '../api'
import { ChartType } from '../constants/cart'
import { EXPORT_FILE_FORMATS } from '../constants/enums'
import type { Role } from '../redux/slices/profileSlice'
import type {
  DictionariesData,
  DictionaryContent,
  LANGUAGE,
  Language,
} from '../redux/types/dictionaries'
import type { MediaListItem, MediaWithBug } from '../redux/types/media'
import { ProcessStatus } from '../redux/types/mediaProcessing'
import type { PersonaItem } from '../redux/types/personas'

export type DirectionTypes = 'down' | 'left' | 'right' | 'up'

export const getCustomSlideTransition = (direction: DirectionTypes) => {
  return forwardRef(function CustomSlideTransition(props: SlideProps, ref) {
    return <Slide {...props} direction={direction} ref={ref} />
  })
}

const transitionComponents = {
  grow: Grow,
  zoom: Zoom,
  fade: Fade,
  'slide-up': getCustomSlideTransition('up'),
  'slide-right': getCustomSlideTransition('right'),
  'slide-down': getCustomSlideTransition('down'),
  'slide-left': getCustomSlideTransition('left'),
}

export function getCustomTransitionComponent(
  transitionType?: keyof typeof transitionComponents
) {
  return transitionType ? transitionComponents[transitionType] : undefined
}

const safeColor = new SafeColor({ color: [255, 255, 255] })

export const getRandomColor = () => safeColor.random()

export const setManualRange = (min: number, max: number, step: number) => {
  let range = step
  if (step === 0) range = 1
  const length = (max - min) / range + 1
  return Array.from({ length }, (_, i) => min + i * range)
}

export const getChartTicks = ({
  verticalScaleType,
  verticalScaleArray,
  minVerticalScale,
  maxVerticalScale,
  verticalScaleStep,
}: {
  verticalScaleType: IpChartScaleTypes['verticalScaleType']
  verticalScaleArray?: IpChartScaleTypes['verticalScaleArray']
  minVerticalScale?: IpChartScaleTypes['minVerticalScale']
  maxVerticalScale?: IpChartScaleTypes['maxVerticalScale']
  verticalScaleStep?: IpChartScaleTypes['verticalScaleStep']
}) =>
  verticalScaleType === 'array'
    ? {
        ticks: {
          callback: (_: unknown, index: number) =>
            verticalScaleArray ? verticalScaleArray[index] : undefined,
        },
      }
    : verticalScaleType === 'manual'
    ? {
        min: minVerticalScale,
        max: maxVerticalScale,
        ticks: {
          stepSize: verticalScaleStep,
        },
      }
    : {}

export const getChartLabels = ({
  type,
  min,
  max,
  step,
  arr,
}: {
  type: IpChartScaleTypeTypes
  min: number
  max: number
  step: number
  arr: (string | number)[]
}) => (type === 'manual' ? setManualRange(min, max, step) : arr)

export const findDictionaryItemById = (
  dictionary: DictionariesData,
  key: keyof DictionariesData,
  id?: string | null
  // @ts-ignore
) => (id ? dictionary[key]?.find((item) => item.id === id) : undefined)

export function getValueByLang<T>(
  suffix: Language['attribute_suffix'],
  field: keyof T,
  item: T | undefined
) {
  if (!item) return ''
  return (item[`${field as string}${suffix}` as keyof typeof item] ??
    '') as string
}

export const getNameFromDictionaryByLang = ({
  suffix,
  dictionary,
  key,
  id,
}: {
  suffix: Language['attribute_suffix']
  dictionary: DictionariesData
  key: keyof DictionariesData
  id?: string | null
}): string => {
  const item = findDictionaryItemById(dictionary, key, id)
  if (!item) return ''

  return getValueByLang(suffix, 'name', item)
}

export const updateStringArrValues = (
  data: string[],
  id?: string
): string[] => {
  if (!id) return data
  const filteredData = data.filter((i) => i !== id)
  if (filteredData.length < data.length) {
    return filteredData
  }
  return [...data, id]
}

// todo: update with date-fns
export const formatSecondsToTime = (seconds?: number): string => {
  if (!seconds) return '00:00:00'

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(Math.floor(remainingSeconds)).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export function filterObjectsByStringField<T>(
  value: string,
  arr: T[],
  field: keyof T
): T[] {
  if (!value) {
    return arr
  }

  return arr.filter(
    (props, i, array) =>
      typeof array[i][field] === 'string' &&
      String(props[field]).toLowerCase().match(new RegExp(value, 'i'))
  )
}

// todo: update with date-fns
/**
 * @param time in `seconds` or in format `hh:mm:ss`
 * @returns number in seconds
 */
export const formatTimeToSeconds = (time: string | number): number => {
  if (typeof time === 'number') {
    return time
  }
  const parts = time.split(':')
  const hours = parseInt(parts[0], 10) || 0
  const minutes = parseInt(parts[1], 10) || 0
  const seconds = parseInt(parts[2], 10) || 0

  return hours * 3600 + minutes * 60 + seconds
}

type ThrottleFunction = (...args: any[]) => void

export const throttle = (fn: ThrottleFunction, wait = 1000) => {
  let inThrottle: boolean
  let lastFn: ReturnType<typeof setTimeout>
  let lastTime: number
  return function (this: any, ...args: any[]) {
    if (!inThrottle) {
      fn.apply(this, args)
      lastTime = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFn)
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(this, args)
          lastTime = Date.now()
        }
      }, Math.max(wait - (Date.now() - lastTime), 0))
    }
  }
}

export const getNoneUnknownValueList = (
  suffix: Language['attribute_suffix'],
  list: DictionaryContent[] = []
): DictionaryContent[] => {
  if (list[0] && list[0][`name${suffix}`]?.match(/unknown/i)) {
    return list.slice(1)
  }
  return list
}

export const validateSearchInput = (
  value: string,
  errorMessageCallBack?: Dispatch<SetStateAction<string>>
): boolean => {
  if (!/^[^<>%#[\]\\/{}]*$/.test(value)) {
    if (errorMessageCallBack) errorMessageCallBack('Invalid characters')
    return false
  }
  if (value.length > 300) {
    if (errorMessageCallBack) errorMessageCallBack('Exceeded maximum length')
    return false
  }
  if (errorMessageCallBack) errorMessageCallBack('')
  return true
}

export const findMediaById = (
  data: MediaListItem[] | MediaWithBug[],
  id: string
): MediaListItem | MediaWithBug | undefined => {
  // @ts-ignore
  return data.find((el) => el.id === id)
}

export const scrollToCurrentElWithTimeOut = (
  querySelector: string,
  options?: any,
  delay = 50,
  index = 0
) => {
  setTimeout(() => {
    if (index) {
      const el = document.querySelectorAll(querySelector)
      if (el[index]) {
        el[index].scrollIntoView(options)
      }
    } else {
      document.querySelector(querySelector)?.scrollIntoView(options)
    }
  }, delay)
}

export function convertArrayToObjWithKeys<T>(
  array: T[],
  key: string
): { [key: string]: T } {
  return array.reduce((acc, curr: T): { [key: string]: T } => {
    // @ts-ignore
    acc[curr[key]] = curr
    return acc
  }, {})
}

export const getSliceByNumber = (startArr: DictionaryContent[], n: number) => {
  if (startArr.length > n) {
    return startArr.slice(0, n)
  }
  return startArr
}

export const hasPermission = (roles: Role[], rolesList?: Role[]): boolean => {
  if (!rolesList) return false
  const hasRole = roles.some((role) => rolesList.includes(role))
  return hasRole
}

export function sortObjectByStringFieldWithLang<T>(
  arr: T[],
  field: keyof T,
  locale: Language[LANGUAGE.locale]
) {
  return arr.sort((a, b) => {
    const fieldA = a[field] as unknown as string
    const fieldB = b[field] as unknown as string

    if (!fieldA && !fieldB) {
      return 1
    }

    if (!fieldA) {
      return 1
    }

    if (!fieldB) {
      return -1
    }
    return fieldA
      .toLocaleLowerCase(locale)
      .localeCompare(fieldB.toLocaleLowerCase(locale), locale)
  })
}

export function isFirefox(): boolean {
  if (
    typeof window !== 'undefined' &&
    /firefox/i.test(window.navigator.userAgent)
  ) {
    return true
  }
  return false
}

export const formatDateWithSeparator = (
  dateString?: string,
  separator = '.'
): string => {
  if (!dateString) return ''
  const date = new Date(dateString)

  return `${date.getDate()}${separator}${
    date.getMonth() + 1
  }${separator}${date.getFullYear()}`
}

export const getPersonInfoByLang = (
  personaData: PersonaItem | undefined,
  suffix: Language['attribute_suffix']
): {
  create_date: string
  sanctions_justification: string
  birth_date: string
  name: string
  photo_url: string
  title: string
  media: {
    site_url: string
    wiki_url: string
    facebook_page: string
    youtube_channel: string
    rutube_channel: string
    telegram_channel: string
    vk_page: string
  }
} => {
  return {
    birth_date: personaData?.birth_date || '',
    create_date: formatDateWithSeparator(personaData?.create_date),
    name: getValueByLang(suffix, 'name' as keyof PersonaItem, personaData),
    photo_url: personaData?.photo_url || '',
    sanctions_justification: getValueByLang(
      suffix,
      'sanctions_justification',
      personaData
    ),
    title: getValueByLang(suffix, 'title' as keyof PersonaItem, personaData),
    media: {
      site_url: personaData?.site_url || '',
      telegram_channel: personaData?.telegram_channel || '',
      vk_page: personaData?.vk_page || '',
      wiki_url: personaData?.wiki_url || '',
      youtube_channel: personaData?.youtube_channel || '',
      facebook_page: personaData?.facebook_page || '',
      rutube_channel: personaData?.rutube_channel || '',
    },
  }
}

export const getProcessStatus = (status: ProcessStatus) => {
  switch (status) {
    case ProcessStatus.DONE:
    case ProcessStatus.SUCCESS:
      return 'success'
    case ProcessStatus.FAILED:
    case ProcessStatus.ERROR:
    case ProcessStatus.REMOVED:
      return 'error'
    default:
      return 'info'
  }
}

export const getExportFileName = (
  type:
    | ExportTranscriptionsParams['fileType']
    | 'bar'
    | 'linear'
    | 'bar_png'
    | 'linear_png'
) => {
  const prefix = 'warofwords_downloads'

  const currentDate = new Date()
  const formattedDate = format(currentDate, 'yyMMdd_HHmmss')

  const name = `${formattedDate}_${prefix}`

  switch (type) {
    case EXPORT_FILE_FORMATS.EXCEL:
      return `${name}_table`
    case EXPORT_FILE_FORMATS.DOCX:
      return `${name}_texts`
    case ChartType.bar:
      return `${name}_bar_chart_table`
    case ChartType.linear:
      return `${name}_linear_chart_table`
    case 'bar_png':
      return `${name}_bar_chart_image`
    case 'linear_png':
      return `${name}_linear_chart_image`
    default:
      return name
  }
}
