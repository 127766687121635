import React from 'react'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'

type Props = {
  sourceId?: string | undefined
  authorId?: string | null
  airDate?: string | null
  programId?: string
  telegram_channel_id?: string
}
const divider = ', '

export const MediaInfo = ({
  sourceId,
  authorId,
  airDate,
  programId,
  telegram_channel_id,
}: Props) => {
  const {
    source = '',
    author = '',
    program = '',
    telegramChanel = '',
  } = useDictionaryData({
    author_id: authorId,
    source_id: sourceId,
    program_id: programId,
    telegram_channel_id,
  })

  const additionInfo = author || program

  const text = telegramChanel
    ? `Telegram, ${telegramChanel + divider}${airDate || ''}`
    : `${source ? source + divider : ''}${
        additionInfo ? additionInfo + divider : ''
      }${airDate || ''}`

  return (
    <IpText variant="h1" color="primary" classes="main-preview_program-name">
      {text}
    </IpText>
  )
}
