import colors from '@infopulse-design-system/shared/theme/colors'
import '@infopulse-design-system/shared/theme/components/IpText/styles.scss'
import type { IpTypographyPropTypes } from '@infopulse-design-system/shared/types/IpTypography.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import type {
  ElementType,
  ForwardedRef,
  ReactNode,
  SyntheticEvent,
} from 'react'
import React, { forwardRef } from 'react'
import { IpTextEllipsis } from '../../core/IpTextEllipsis'

export type IpTextPropsTypeReact = IpTypographyPropTypes<SyntheticEvent> & {
  children: ReactNode
  component?: ElementType
}

const IpTypographyStyled = styled(Typography)(
  ({ color }: IpTextPropsTypeReact) => {
    const getColor = !color
      ? undefined
      : color === 'text.primary' || color === 'text.secondary'
      ? colors[color as keyof typeof colors]
      : colors[color]?.main

    return {
      color: getColor,
    }
  }
)

/**
 * Typography plays a crucial role in presenting your design and content with clarity and efficiency.
 * By carefully selecting and implementing typography, you can effectively stylize your message,
 * enhance readability, and create a visually pleasing experience for your users. Thoughtfully chosen
 * typography helps to establish the hierarchy of information, improve legibility, and convey the
 * desired tone and personality of your design. Using the IpText component you will work with
 * typography quicker and in a more convenient way.
 */
export const IpText = forwardRef(function IpText(
  props: IpTextPropsTypeReact,
  ref: ForwardedRef<HTMLSpanElement>
) {
  const {
    align,
    children,
    classes,
    component,
    color,
    gutterBottom,
    isTextOverflow,
    paragraph,
    variant,
    onClick,
  } = props

  const customClasses = formatClassName(
    generateClasses('text', 'react'),
    classes
  )

  return (
    <IpTypographyStyled
      align={align}
      className={customClasses}
      component={component}
      color={color}
      gutterBottom={gutterBottom}
      paragraph={paragraph}
      variant={variant}
      onClick={onClick}
      ref={ref}
    >
      {isTextOverflow ? (
        <IpTextEllipsis isTextOverflow={true}> {children} </IpTextEllipsis>
      ) : (
        children
      )}
    </IpTypographyStyled>
  )
})
