import styles from '@infopulse-design-system/shared/theme/components/IpDialog'
import shadows from '@infopulse-design-system/shared/theme/shadows'

export default () => {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            borderRadius: 0,
            minWidth: styles.dialog_min_width,
            boxShadow: shadows.dialog_box_shadow,
            '&.MuiDialog-paperFullWidth': {
              maxWidth: styles.dialog_full_width,
            },
            '&.MuiDialog-paperFullScreen': {
              width: '100%',
            },
          },
        },
      },
    },
  }
}
