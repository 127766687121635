import React, { useState } from 'react'
import {
  DateTimePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers'
import type { ReactNode, ReactElement, JSXElementConstructor } from 'react'
import type { IpDateTimePickerPropTypes } from '@infopulse-design-system/shared/types/IpDateTimePicker.types'
import DateFnsUtils from '@date-io/date-fns'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { TextFieldProps } from '@mui/material/TextField'
import type { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay'
import TextField from '@mui/material/TextField'
import '@infopulse-design-system/shared/theme/components/IpDateTimePicker/styles.scss'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import type { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models/views'
import type { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation'
import type { TransitionProps } from '@mui/material/transitions'
import { isWeekend } from 'date-fns'
import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import { getCustomTransitionComponent } from '../../../../utils'
import useResponsive from '../../../../hooks/useResponsive'

export type IpDateTimePickerPropTypesReact = IpDateTimePickerPropTypes<
  ReactNode,
  Promise<any>,
  CalendarOrClockPickerView,
  (
    props: TextFieldProps
  ) => ReactElement<any, string | JSXElementConstructor<any>>,
  DateTimeValidationError
> & {
  renderDay?: (
    day: Date,
    selectedDays: Date[] | null,
    pickersDayProps: PickersDayProps<any>
  ) => any
}

/**
 * The `IpDateTimePicker` component provides the user with a wide variety of options to display either
 * a date picker, a time picker, or a combination of both.
 * Other key features of `IpDateTimePicker`:
 *
 * The ability to switch with an ease between native or desktop version with a single boolean property called `native`.
 *
 * Providing a possibility to restrict the option of the selectable dates between a specific range by providing `maxDate` and `minDate`.
 *
 * Disable date selection for future or past by using either `disableFuture` or `disablePast`.
 *
 * Switch between 12/24 clock format with the `ampm` property.
 */

export function IpDateTimePicker(props: IpDateTimePickerPropTypesReact) {
  const {
    ampm,
    classes,
    closeOnSelect,
    dayOfWeekFormatter,
    defaultCalendarMonth,
    defaultValue,
    disabled,
    disableFuture,
    disableOpenPicker,
    disablePast,
    inputFormat = 'Pp',
    label,
    mask,
    maxDate,
    minDate,
    native = false,
    onAccept,
    onChange,
    onClose,
    onError,
    onMonthChange,
    onViewChange,
    onOpen,
    onYearChange,
    open,
    openTo = 'year',
    placeholder,
    popperPlacement,
    size,
    toolbarPlaceholder,
    toolbarTitle,
    transitionType = 'fade',
    type = 'datetime-local',
    value,
    variant,
    views = ['year', 'day', 'hours', 'minutes'],
    renderDay,
    renderInput = (params) => (
      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
          className: formatClassName(
            generateClasses('date-time-picker--input', 'react')
          ),
          placeholder,
        }}
        size={size}
        variant={variant}
      />
    ),
  } = props

  const [isPm, setIsPm] = useState(false)

  const onChangeExt = (e: Date) => {
    setIsPm(e?.getHours() >= 12)
    onChange(e)
  }

  const customClasses = formatClassName(
    generateClasses('date-time-picker', 'react'),
    native ? '' : 'IpDateTimePicker',
    isPm ? 'IpTime-pm' : 'IpTime-am',
    classes
  )

  const { isMobileResolution } = useResponsive()

  const transitionComponent = getCustomTransitionComponent(transitionType) as
    | JSXElementConstructor<TransitionProps>
    | undefined

  const customDayRenderer = (
    date: Date,
    selectedDates: Date[] | null,
    pickersDayProps: PickersDayProps<any>
  ) => {
    if (isWeekend(date)) {
      return <PickersDay className="IpHoliday" {...pickersDayProps} />
    }
    return <PickersDay {...pickersDayProps} />
  }

  const generateDesktopPicker = (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DateTimePicker
        ampm={ampm}
        desktopModeMediaQuery={`@media (min-width: ${breakpoints.values.xs})`}
        onChange={onChangeExt}
        className={customClasses}
        closeOnSelect={closeOnSelect}
        components={{ OpenPickerIcon: CalendarTodayOutlinedIcon }}
        dayOfWeekFormatter={dayOfWeekFormatter}
        defaultCalendarMonth={defaultCalendarMonth}
        disabled={disabled}
        disableFuture={disableFuture}
        disableOpenPicker={disableOpenPicker}
        disablePast={disablePast}
        disableMaskedInput
        inputFormat={inputFormat}
        label={label}
        mask={mask}
        maxDate={maxDate}
        minDate={minDate}
        onAccept={onAccept}
        onClose={onClose}
        onError={onError}
        onMonthChange={onMonthChange}
        onViewChange={onViewChange}
        onOpen={onOpen}
        onYearChange={onYearChange}
        open={open}
        openTo={openTo}
        PopperProps={{
          placement: popperPlacement,
          disablePortal: true,
        }}
        toolbarPlaceholder={toolbarPlaceholder}
        toolbarTitle={toolbarTitle}
        value={value}
        views={views}
        renderInput={renderInput}
        renderDay={renderDay || customDayRenderer}
        TransitionComponent={transitionComponent}
      />
    </LocalizationProvider>
  )

  const generateNativePicker = (
    <TextField
      className={customClasses}
      label={label}
      type={type}
      defaultValue={defaultValue}
    />
  )

  return native && isMobileResolution
    ? generateNativePicker
    : generateDesktopPicker
}
