/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import EditIcon from '@mui/icons-material/Edit'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { memo, useState } from 'react'
import { useHoveredNotification } from '../../../hooks/useHoveredNotification'
import {
  dictionariesFootnotesSelector,
  footnoteSelector,
} from '../../../redux/selectors'
// todo: import store should be before slices import
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { IpButton } from '../../../components/ipDesignSystemComponents/atoms/IpButton'
import { IpButtonGroup } from '../../../components/ipDesignSystemComponents/atoms/IpButtonGroup'
import { IpIcon } from '../../../components/ipDesignSystemComponents/atoms/IpIcon'
import { IpTextField } from '../../../components/ipDesignSystemComponents/atoms/IpTextField'
import {
  createFootnoteThunk,
  deleteFootnoteThunk,
  updateFootnoteThunk,
} from '../../../redux/thunk/dictionariesThank'
import type {
  DictionaryFootnotesStringKeys,
  FootnoteData,
} from '../../../redux/types/dictionaries'
import './index.scss'

const list: [DictionaryFootnotesStringKeys, string][] = [
  ['text', 'Phrase'],
  ['transliteration', 'Transliteration'],
  ['meaning_en', 'Meaning'],
  ['origin_en', 'Origin'],
]

const TableHeader = ({ onAdd }: { onAdd: () => void }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <IpButton variant="contained" size="small" onClick={onAdd}>
            Add
          </IpButton>
        </TableCell>
        {list.map(([_, title], i) => (
          <TableCell key={i}>{title}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EditableCell = memo(function CellMemo({
  value,
  onChange,
  isEditing,
  isAddMode,
}: {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isEditing: boolean
  isAddMode?: boolean
}) {
  return (
    <TableCell>
      {isEditing ? (
        <IpTextField
          error={isAddMode ? false : !value}
          type="text"
          multiline
          value={value}
          onChange={onChange}
          minRows={3}
        />
      ) : (
        value
      )}
    </TableCell>
  )
})

const TableRowCustom = ({
  isAddMode,
  setIsAddMode,
  index,
}: {
  index?: number
  isAddMode?: boolean
  setIsAddMode?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const [isEditing, setIsEditing] = useState(!!isAddMode)
  const [values, setValues] = useState<Partial<FootnoteData>>({})
  const data =
    typeof index === 'number'
      ? useAppSelector((state) => footnoteSelector(state, index))
      : undefined

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: DictionaryFootnotesStringKeys
  ) => {
    const { value } = e.target
    setValues((state) => ({ ...state, [key]: value }))
  }

  const discardHandler = () => {
    setIsEditing(false)
    setValues({})
    if (isAddMode && setIsAddMode) {
      setIsAddMode(false)
    }
  }

  const deleteHandler = async () => {
    try {
      await dispatch(
        deleteFootnoteThunk({
          id: data!.id,
          index: index as number,
        })
      )
      discardHandler()
    } catch (e: any) {
      notification(e.message, 'error', true)
    }
  }

  const onNewItemAdd = async () => {
    if (
      !values.meaning_en ||
      !values.origin_en ||
      !values.text ||
      !values.transliteration
    ) {
      notification('All values are required', 'error', true)
    } else {
      try {
        await dispatch(
          createFootnoteThunk({
            meaning_en: values.meaning_en,
            origin_en: values.origin_en,
            text: values.text,
            transliteration: values.transliteration,
          })
        )
        discardHandler()
      } catch (e: any) {
        notification(e.message, 'error', true)
      }
    }
  }

  const onItemUpdate = async () => {
    const updatedValuesArr = Object.values(values)
    if (!updatedValuesArr.length) {
      discardHandler()
    } else if (updatedValuesArr.some((value) => !value)) {
      notification('All values are required', 'error', true)
    } else {
      try {
        await dispatch(
          updateFootnoteThunk({
            id: data!.id,
            params: values,
            index: index as number,
          })
        )
        discardHandler()
      } catch (e: any) {
        notification(e.message, 'error', true)
      }
    }
  }

  const saveHandler = () => {
    if (isAddMode) {
      onNewItemAdd()
    } else {
      onItemUpdate()
    }
  }

  const customClasses = formatClassName(
    isEditing ? 'edit' : '',
    data?.is_deleted ? 'disabled' : ''
  )

  return (
    <TableRow className={customClasses}>
      <TableCell>
        {isEditing ? (
          <IpButtonGroup
            orientation="vertical"
            variant="contained"
            size="small"
            offset="1px"
          >
            <IpButton onClick={saveHandler}>Save</IpButton>
            <IpButton onClick={discardHandler}>Discard</IpButton>
            <IpButton onClick={deleteHandler}>Delete</IpButton>
          </IpButtonGroup>
        ) : data?.is_deleted ? (
          'Removed'
        ) : (
          <IpIcon color="primary" onClick={() => setIsEditing(true)}>
            <EditIcon />
          </IpIcon>
        )}
      </TableCell>
      {list.map(([item], i) => (
        <EditableCell
          key={i}
          onChange={(e) => onChange(e, item)}
          isEditing={isEditing}
          value={values[item] ?? data?.[item] ?? ''}
          isAddMode={isAddMode}
        />
      ))}
    </TableRow>
  )
}

export const FootnoteTable = memo(function FootnoteTableMemo() {
  const footnotes = useAppSelector(dictionariesFootnotesSelector)
  const [isAddMode, setIsAddMode] = React.useState(false)
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 166px)' }}>
      <Table
        stickyHeader
        sx={{ minWidth: 500 }}
        aria-label="footnote table"
        className="footnote-table"
      >
        <TableHeader onAdd={() => setIsAddMode(true)} />
        <tbody>
          {isAddMode && (
            <TableRowCustom isAddMode setIsAddMode={setIsAddMode} />
          )}
          {footnotes?.map((row, index) => (
            <TableRowCustom key={row.id} index={index} />
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )
})
