import type { ForwardedRef } from 'react'
import React, { forwardRef } from 'react'
import { Line } from 'react-chartjs-2'
import type { ChartOptions } from 'chart.js'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import type {
  IpChartLineTypes,
  IpChartPointTypes,
  IpLinearChartDatasetTypes,
  IpLinearChartPropTypes,
} from '@infopulse-design-system/shared/types/IpLinearChart.types'
import { primaryFontFamilyString } from '@infopulse-design-system/shared/theme/typography'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpLinearChart/styles.scss'
import {
  getChartLabels,
  getChartTicks,
  getRandomColor,
} from '../../../../utils'

export const IpLinearChartTestId = 'IpLinearChartTestId'

defaults.font.family = primaryFontFamilyString

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const getChartData = (
  chartData: IpLinearChartDatasetTypes[],
  dots?: IpChartPointTypes[],
  lines?: IpChartLineTypes[]
) =>
  chartData.map(({ data, label }, i) => {
    const dotConfig = dots ? dots[i] ?? {} : {}
    const lineConfig = lines ? lines[i] ?? {} : {}
    const color = getRandomColor()

    return {
      data,
      label,
      backgroundColor: color,
      borderColor: color,
      ...dotConfig,
      ...lineConfig,
    }
  })

/**
 * `IpLinearChart` is created for graphical representation of data points plotted along a line.
 * It is commonly employed to illustrate trend data or compare two different sets of data. By
 * visually depicting the relationship between data points, line charts provide valuable insights
 * into patterns, fluctuations, or correlations within the data.
 * 
 * To create a simple linear chart, you need to provide a `data` configuration that includes items
 * with `label` and `data` properties, where `data` is an array of numbers. Additionally, you have
 * the flexibility to customize various elements of the chart by providing additional configurations.
 * 
 * For example, you can specify different styles for lines, dots, and other elements by applying 
 * additional configurations. Each item in the configuration corresponds to the same index in the
 * data array.
 * `data = [{
    label: 'Apple',
    data: [5, 10, 15, 20, 25, 20, 10],
  },
  {
    label: 'Google',
    data: [8, 7, 1, 12, 8, 17, 20],
  },
  {
    label: 'Amazon',
    data: [18, 17, 11, 2, 18, 7, 2],
  }]
  lines = [
    {  borderColor: 'red' },
    {  borderColor: 'green' },
    {  borderColor: 'orange' },
  ]`
* In this example, the line representing the Apple data will be red, the Google line will be green
* and the Amazon line will be orange. But if `borderColor` is not provided it will be a random
* color.
*
* By providing these additional configurations, you can easily customize the visual representation
* of your linear chart, making it more visually appealing and informative for your users.
*
* Please note that `IpLinearChart` based on the chart.js library.
*/
export const IpLinearChart = forwardRef(function IpLinearChart(
  props: IpLinearChartPropTypes,
  ref: ForwardedRef<any>
) {
  const {
    animation,
    ariaLabel,
    classes,
    data,
    dots,
    grid,
    horizontalScaleArray = [],
    horizontalScaleLabel = {},
    horizontalScaleStep = 1,
    horizontalScaleType = 'array',
    legend,
    lines,
    maxHorizontalScale = 100,
    maxVerticalScale = 100,
    minHorizontalScale = 0,
    minVerticalScale = 0,
    title,
    tooltip,
    verticalScaleArray = [],
    verticalScaleLabel = {},
    verticalScaleStep = 1,
    verticalScaleType = 'auto',
  } = props

  const customClasses = formatClassName(
    generateClasses('linear-chart', 'react'),
    classes
  )

  const options: ChartOptions<'line'> = {
    ...animation,
    scales: {
      y: {
        title: {
          display: true,
          ...verticalScaleLabel,
        },
        display: true,
        ...getChartTicks({
          verticalScaleType,
          verticalScaleArray,
          minVerticalScale,
          maxVerticalScale,
          verticalScaleStep,
        }),
        grid: {
          display: grid,
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          ...horizontalScaleLabel,
        },
        grid: {
          display: grid,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        ...(legend ?? { display: false }),
        labels: {
          usePointStyle: true,
          ...legend?.labels,
        },
        title: {
          display: true,
          ...legend?.title,
        },
      },
      tooltip: {
        enabled: tooltip,
      },
      title: {
        display: true,
        ...title,
      },
    },
  }

  const datasets = getChartData(data, dots, lines)

  const labels = getChartLabels({
    type: horizontalScaleType,
    min: minHorizontalScale,
    max: maxHorizontalScale,
    step: horizontalScaleStep,
    arr: horizontalScaleArray,
  })

  const config = {
    labels,
    datasets,
  }

  return (
    <Line
      aria-label={ariaLabel}
      data-testid={IpLinearChartTestId}
      className={customClasses}
      options={options}
      data={config}
      ref={ref}
    />
  )
})
