import React, { useState } from 'react'
import type { ReactNode, ElementType, MouseEvent, CSSProperties } from 'react'
import List from '@mui/material/List'
import type {
  IpListPropTypes,
  IpListItemConfigPropTypes,
} from '@infopulse-design-system/shared/types/IpList.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { IpFixedSizeList } from './IpFixedSizeList'
import type { IpFixedSizeListPropTypesReact } from './IpFixedSizeList'
import { IpListItemIcon } from './IpListItemIcon'
import { IpListItemText } from './IpListItemText'
import { IpListItem } from './IpListItem'
import type { IpCollapsePropTypesReact } from '../../core/IpCollapse'
import { IpCollapse } from '../../core/IpCollapse'
import { IpDivider } from '../IpDivider'
import { IpListSubheader } from './IpListSubheader'
import '@infopulse-design-system/shared/theme/components/IpList/styles.scss'

export const IpListEndIconRootClassName = 'IpList-endIcon'
export const IpListCollapseIconRootClassName = 'IpList-collapseIcon'
export const IpListCollapseIconOpenClassName = 'IpList-collapseIcon-open'

export type IpListItemPropTypesReact = IpListItemConfigPropTypes<
  ReactNode,
  ElementType,
  MouseEvent<HTMLElement>,
  CSSProperties,
  IpCollapsePropTypesReact
>

export type IpListItemConfigPropTypesReact = IpListItemPropTypesReact[]

export type IpListPropTypesReact =
  | IpListPropTypes<
      ReactNode,
      ElementType,
      MouseEvent<HTMLElement>,
      CSSProperties,
      IpCollapsePropTypesReact
    >
  | IpFixedSizeListPropTypesReact

/**
 * `IpList` component is a collection of text or images divided into separate elements.
 * Each item in the list may include both the primary content and supplementary actions,
 * which are represented by text and icons. This enables you to design an
 * interactive list that looks good and can support different kinds of data and user interactions.
 * The `IpList` can have some default properties and can be configured by `itemsConfig`.
 * `itemsConfig` individually configures each item. It is possible to add
 * a nested list with `nestedList` prop inside each item.
 * To configure a fixed-size list you should apply props
 * defined in the type `IpFixedSizeListPropTypesReact`.
 */
export function IpList(props: IpListPropTypesReact) {
  const isSimpleList = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): value is {
    itemsConfig: IpListItemConfigPropTypesReact
  } => value.itemsConfig?.length > 0

  if (isSimpleList(props)) {
    const {
      classes,
      component = 'ul',
      dense,
      divider,
      itemsConfig,
      subheader,
    } = props

    const getItem = (item: IpListItemPropTypesReact) => {
      const {
        alignItems: itemAlignItems,
        autoFocus,
        classes: itemClasses,
        nestedList,
        collapseData,
        component: itemComponent = 'li',
        dense: itemDense,
        disableGutters,
        disablePadding,
        disabled,
        divider: itemDivider = divider,
        onClick,
        endIcon,
        selected,
        style,
        iconData,
        textData,
      } = item
      const [open, setOpen] = useState<boolean>(!!collapseData?.inside)

      const clickHandler = (e: MouseEvent<HTMLElement>) => {
        setOpen(!open)
        if (onClick) onClick(e)
      }

      const isText = !!textData?.text
      const isIcon = !!iconData?.icon
      const isContent = isText || isIcon
      const isCollapse = !!collapseData?.children

      const endIconClasses = `${IpListEndIconRootClassName} ${
        isCollapse
          ? `${IpListCollapseIconRootClassName} ${
              open ? IpListCollapseIconOpenClassName : ''
            }`
          : ''
      }`

      return (
        <>
          {isContent && (
            <>
              <IpListItem
                alignItems={itemAlignItems}
                autoFocus={autoFocus}
                classes={itemClasses}
                component={itemComponent}
                dense={itemDense ?? dense}
                disableGutters={disableGutters}
                disablePadding={disablePadding}
                style={style}
                disabled={disabled}
                onClick={clickHandler}
                selected={selected}
              >
                {isIcon && <IpListItemIcon {...iconData} />}
                {isText && <IpListItemText {...textData} />}
                {endIcon && <div className={endIconClasses}>{endIcon}</div>}
              </IpListItem>
              {itemDivider && (
                <IpDivider
                  component="li"
                  variant={itemDivider === 'inset' ? 'inset' : 'fullWidth'}
                />
              )}
            </>
          )}
          {isCollapse && (
            <IpListItem disablePadding={true} disableHover={true}>
              <IpCollapse
                classes={collapseData.classes}
                collapsedSize={collapseData.collapsedSize}
                inside={open}
                orientation={collapseData.orientation}
                timeout={collapseData.timeout}
              >
                {collapseData.children}
              </IpCollapse>
            </IpListItem>
          )}

          {nestedList && (
            <IpListItem disablePadding={true} disableHover={true}>
              {nestedList}
            </IpListItem>
          )}
        </>
      )
    }

    const customClasses = formatClassName(
      generateClasses('list', 'react'),
      classes
    )

    return (
      <List
        component={component}
        className={customClasses}
        dense={dense}
        subheader={
          subheader ? <IpListSubheader>{subheader}</IpListSubheader> : undefined
        }
      >
        {itemsConfig.map((item, index) => (
          <React.Fragment key={item?.id ?? index}>
            {getItem(item)}
          </React.Fragment>
        ))}
      </List>
    )
  }

  const {
    children,
    classes,
    fullWidth,
    height,
    itemCount,
    itemData,
    itemSize,
    itemRowsCount,
    overscanCount,
    width,
  } = props
  return (
    <IpFixedSizeList
      classes={classes}
      height={height}
      itemCount={itemCount}
      itemData={itemData}
      itemSize={itemSize}
      itemRowsCount={itemRowsCount}
      overscanCount={overscanCount}
      width={width}
      fullWidth={fullWidth}
    >
      {children}
    </IpFixedSizeList>
  )
}
