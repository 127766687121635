import type { ReactNode } from 'react'
import React, { useContext } from 'react'
import CardHeader from '@mui/material/CardHeader'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { IpCardHeaderPropTypes } from '@infopulse-design-system/shared/types/IpCard.types'
import '@infopulse-design-system/shared/theme/components/IpCard/IpCardHeader/styles.scss'
import { CardContext } from '..'
import { IpSkeleton } from '../../IpSkeleton'

export const IpCardHeaderTestId = 'IpCardHeaderTestId'

export type IpCardHeaderPropTypesReact = IpCardHeaderPropTypes<ReactNode>

export const IpCardHeader = (props: IpCardHeaderPropTypesReact) => {
  const { action, avatar, classes, disableTypography, subheader, title } = props
  const { isLoading, skeletonAnimation } = useContext(CardContext)

  const customClasses = formatClassName(
    generateClasses('card-header', 'react'),
    classes
  )

  const renderedAvatar = isLoading ? (
    <IpSkeleton
      animation={skeletonAnimation}
      variant="circular"
      width="52px"
      height="52px"
    ></IpSkeleton>
  ) : (
    avatar
  )

  const renderedTitle = isLoading ? (
    <IpSkeleton animation={skeletonAnimation} width="50%"></IpSkeleton>
  ) : (
    title
  )

  const renderedSubheader = isLoading ? (
    <IpSkeleton animation={skeletonAnimation} width="50%"></IpSkeleton>
  ) : (
    subheader
  )

  return (
    <CardHeader
      className={customClasses}
      data-testid={IpCardHeaderTestId}
      disableTypography={disableTypography}
      avatar={renderedAvatar}
      title={renderedTitle}
      subheader={renderedSubheader}
      action={isLoading ? null : action}
    />
  )
}
