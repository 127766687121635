import type { ForwardedRef } from 'react'
import React, { forwardRef } from 'react'
import { Bar } from 'react-chartjs-2'
import type { Context } from 'chartjs-plugin-datalabels'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import type {
  IpChartBarTypes,
  IpBarChartDatasetTypes,
  IpBarChartPropTypes,
} from '@infopulse-design-system/shared/types/IpBarChart.types'
import { primaryFontFamilyString } from '@infopulse-design-system/shared/theme/typography'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import {
  getChartLabels,
  getChartTicks,
  getRandomColor,
} from '../../../../utils'
import '@infopulse-design-system/shared/theme/components/IpBarChart/styles.scss'

export const IpBarChartTestId = 'IpBarChartTestId'

defaults.font.family = primaryFontFamilyString

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const getChartData = (
  barsData: IpBarChartDatasetTypes[],
  bars?: IpChartBarTypes[]
) =>
  barsData.map((item, i) => {
    const { label, data, backgroundColor, borderColor, borderWidth } = item
    const barsConfig = bars ? bars[i] ?? {} : {}
    const color = getRandomColor()

    return {
      label,
      data,
      backgroundColor: backgroundColor ?? barsConfig.backgroundColor ?? color,
      borderColor: borderColor ?? barsConfig.borderColor ?? color,
      borderWidth: borderWidth ?? barsConfig.borderWidth ?? 0,
    }
  })

export type IpBarChartPropTypesReact = IpBarChartPropTypes<Context>

/**
 * `IpBarChart` is a component designed for visually representing data using vertical bars. It is
 * commonly used to visualize trend data or compare different sets of data. By depicting the
 * relationships between data points, bar charts provide valuable insights into patterns, comparisons,
 * or distributions within the data.
 *
 * To create a simple bar chart, you need to provide a `data` configuration that includes items
 * with `label`, `data` and other style properties. The `data` property should be an array of numbers
 * representing the values for each bar. Additionally, you have the flexibility to customize various
 * elements of the chart by providing additional configurations.
 *
 * By providing these additional configurations, you can easily customize the visual representation
 * of your bar chart, making it more visually appealing and informative for your users.
 *
 * Please note that `IpBarChart` is based on the chart.js library.
 */
export const IpBarChart = forwardRef(function IpBarChart(
  props: IpBarChartPropTypesReact,
  ref: ForwardedRef<any>
) {
  const {
    animation,
    ariaLabel,
    classes,
    data,
    grid,
    bars,
    grouped,
    horizontalScaleArray = [],
    horizontalScaleLabel = {},
    horizontalScaleStep = 1,
    horizontalScaleType = 'array',
    indexAxis,
    labelOptions,
    legend,
    maxHorizontalScale = 100,
    maxVerticalScale = 100,
    minHorizontalScale = 0,
    minVerticalScale = 0,
    title,
    stacked,
    tooltip,
    interaction,
    verticalScaleArray = [],
    verticalScaleLabel = {},
    verticalScaleStep = 1,
    verticalScaleType = 'auto',
  } = props

  const customClasses = formatClassName(
    generateClasses('bar-chart', 'react'),
    classes
  )

  const options = {
    ...animation,
    responsive: true,
    indexAxis,
    grouped,
    interaction,
    plugins: {
      legend: {
        ...(legend ?? { display: true }),
        labels: {
          usePointStyle: true,
          ...legend?.labels,
        },
        title: {
          display: true,
          ...legend?.title,
        },
      },
      labels: {
        usePointStyle: true,
        ...legend?.labels,
      },
      datalabels: labelOptions,
      tooltip: {
        enabled: tooltip,
      },
      title: {
        display: true,
        ...title,
      },
    },
    scales: {
      x: {
        display: true,
        stacked,
        title: {
          display: true,
          ...horizontalScaleLabel,
        },
        grid: {
          display: grid,
        },
      },
      y: {
        display: true,
        stacked,
        title: {
          display: true,
          ...verticalScaleLabel,
        },
        ...getChartTicks({
          verticalScaleType,
          verticalScaleArray,
          minVerticalScale,
          maxVerticalScale,
          verticalScaleStep,
        }),
        grid: {
          display: grid,
        },
      },
    },
  }

  const datasets = getChartData(data, bars)

  const labels = getChartLabels({
    type: horizontalScaleType,
    min: minHorizontalScale,
    max: maxHorizontalScale,
    step: horizontalScaleStep,
    arr: horizontalScaleArray,
  })

  const config = {
    labels,
    datasets,
  }

  return (
    <Bar
      aria-label={ariaLabel}
      data-testid={IpBarChartTestId}
      className={customClasses}
      options={options}
      plugins={[ChartDataLabels]}
      data={config}
      ref={ref}
    />
  )
})
