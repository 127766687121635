import { primaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'

import type { Theme } from '@mui/material'

export default (theme: Theme) => {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: primaryFontFamily,
          fontSize: inputStyles.input_helper_text_font_size,
          lineHeight: inputStyles.input_helper_text_line_height,
          marginTop: inputStyles.input_helper_text_margin,
          marginRight: 0,
          marginLeft: 0,
          color: theme.palette.error.main,
        },
      },
    },
  }
}
