import React, { forwardRef } from 'react'
import type { ReactNode, ChangeEvent, FocusEvent, KeyboardEvent } from 'react'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import type { InputBaseComponentProps } from '@mui/material'
import { InputAdornment } from '@mui/material'
import type { IpTextFieldPropTypes } from '@infopulse-design-system/shared/types/IpTextField.types'
import type { IpColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import '@infopulse-design-system/shared/theme/components/IpTextField/styles.scss'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'

export const IpTextFieldTestId = 'IpTextFieldTestId'

export type IpTextFieldPropTypesReact = IpTextFieldPropTypes<
  ReactNode,
  InputBaseComponentProps,
  ChangeEvent<HTMLInputElement>,
  FocusEvent<HTMLInputElement>
> & {
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

const IpTextFieldStyled = styled(TextField)(
  ({ color, theme }: { color: IpColorTypes; theme?: Theme }) => ({
    '.MuiInputBase-inputMultiline::-webkit-scrollbar-thumb': {
      background: theme?.palette[color].main,
    },
  })
)

/**
 * `IpTextField` provides users with the ability to input and edit text. They serve as interactive
 * components that enable users to enter and modify textual information as needed. `IpTextField` is
 * a fundamental element for capturing user-generated content, facilitating data entry, and allowing
 * users to interact with text-based input fields in a seamless manner.
 */
export const IpTextField = forwardRef(function IpTextField(
  props: IpTextFieldPropTypesReact,
  ref
) {
  const {
    autoComplete,
    autoFocus,
    classes,
    color = 'primary',
    defaultValue,
    disabled,
    endAdornment,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    label,
    margin,
    maxRows,
    minRows,
    multiline,
    name,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    required,
    rows,
    select,
    size,
    startAdornment,
    type,
    value,
    variant,
  } = props

  const getAdornment = (start: ReactNode, end: ReactNode) => {
    return {
      startAdornment: start ? (
        <InputAdornment position="start">{start}</InputAdornment>
      ) : (
        start
      ),
      endAdornment: end ? (
        <InputAdornment position="end">{end}</InputAdornment>
      ) : (
        end
      ),
    }
  }

  const customClasses = formatClassName(
    generateClasses('text-field', 'react'),
    generateClasses('input', 'react'),
    startAdornment ? 'MuiTextField-startAdornment' : '',
    endAdornment ? 'MuiTextField-endAdornment' : '',
    classes
  )

  return (
    <IpTextFieldStyled
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={customClasses}
      color={color}
      data-testid={IpTextFieldTestId}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      inputProps={inputProps}
      inputRef={ref}
      InputProps={getAdornment(startAdornment, endAdornment)}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      rows={rows}
      select={select}
      size={size}
      type={type}
      value={value}
      variant={variant}
    />
  )
})
