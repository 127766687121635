import React, { useEffect, memo } from 'react'
import type { IpTablePropTypesReact } from '../../ipDesignSystemComponents/atoms/IpTable'
import { IpTable } from '../../ipDesignSystemComponents/atoms/IpTable'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { fetchSearchData } from '../../../redux/thunk/searchThunk'
import { searchSelector } from '../../../redux/selectors'

export type SearchDataType = {
  date: string
  quantity: number
  dynamics: number
  channel: string
  person: string
  details: string
}

export type SearchApiResponse = SearchDataType[]

export const tableTestSearchData: SearchDataType[] = [
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
  {
    date: '2023-03-12',
    quantity: 175,
    dynamics: 117,
    channel: 'ТБ “ Вечерние Новости”',
    person: 'Ольга Скабеева',
    details: '4 відео',
  },
  {
    date: '2023-03-12',
    quantity: 300,
    dynamics: 117,
    channel: 'ТБ “Соловьев LIVE”',
    person: 'Владимир Соловьев',
    details: '3 відео',
  },
]

const headConfigWithFilters: IpTablePropTypesReact['headConfig'] = [
  {
    accessorKey: 'date',
    header: 'Date',
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    filterVariant: 'range',
    maxRangeValue: 1000, // todo: need to be defined
  },
  {
    accessorKey: 'dynamics',
    header: 'Dynamics',
    filterVariant: 'range',
    maxRangeValue: 1000, // todo: need to be defined
  },
  {
    accessorKey: 'channel',
    header: 'Channel',
    filterVariant: 'text',
    filterFn: 'contains',
  },
  {
    accessorKey: 'person',
    header: 'Person',
    filterVariant: 'text',
    filterFn: 'contains',
  },
  {
    accessorKey: 'details',
    header: 'Details',
  },
]

export function Table() {
  const search = useAppSelector(searchSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!search.data.length) dispatch(fetchSearchData({ size: 100 }))
  }, [])

  return (
    <IpTable
      headConfig={headConfigWithFilters}
      enableTopToolbar={true}
      enableBottomToolbar={true}
      enableColumnFilters={true}
      enablePagination={true}
      enablePinning={true}
      enableSorting={true}
      data={search.data}
      isLoading={search.isLoading}
      totalRowCount={search.totalRowCount}
    />
  )
}

export const ResultTable = memo(Table)
