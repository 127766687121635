import type { RootState } from '../store'
import type { Bug } from '../types/media'

export const transcriptionsDataSelector = (state: RootState) =>
  state.transcriptionsData

export const incorrectTranscriptionsByMediaIdSelector = (
  state: RootState,
  mediaId: string
) => state.transcriptionsData.data.incorrect[mediaId] as Bug[] | undefined
