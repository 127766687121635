/* eslint-disable no-param-reassign */
import type { IpDialogVariantTypes } from '@infopulse-design-system/shared/types/IpDialog.types'
import { createSlice } from '@reduxjs/toolkit'

export type IpDialogTypes = {
  name: string
  isOpen: boolean
  variant: IpDialogVariantTypes
}

export type IpDialogsManagerStateTypes = {
  dialogs: IpDialogTypes[]
  openDialogs: Array<IpDialogTypes['name']>
}

export const initialDialogsManagerState: IpDialogsManagerStateTypes = {
  dialogs: [],
  openDialogs: [],
}

export const dialogsManagerSlice = createSlice({
  name: 'dialogsManager',
  initialState: initialDialogsManagerState,
  reducers: {
    setDialogsManager: (state, { payload }) => {
      state.dialogs.push(payload)
      if (payload.isOpen) {
        state.openDialogs.push(payload.name)
      }
    },
    updateDialogsManager: (state, { payload: currentDialog }) => {
      let nextDialogs: IpDialogTypes[] = state.dialogs.map((dlg) =>
        currentDialog.name === dlg.name ? currentDialog : dlg
      )
      let nextOpenDialogs: Array<IpDialogTypes['name']> = state.openDialogs

      const closeOtherDialogs = () => {
        nextDialogs = nextDialogs.map((dlg) =>
          currentDialog.name === dlg.name
            ? currentDialog
            : { ...dlg, isOpen: false }
        )
      }

      const closeAllDialogs = () => {
        nextOpenDialogs = []
        nextDialogs = nextDialogs.map((dlg) => ({ ...dlg, isOpen: false }))
      }

      const openPrevDialogs = () => {
        nextOpenDialogs = nextDialogs
          .filter(
            (dlg) =>
              nextOpenDialogs.includes(dlg.name) &&
              currentDialog.name !== dlg.name
          )
          .map((item) => item.name)
        nextDialogs = nextDialogs.map((dlg) =>
          nextOpenDialogs.includes(dlg.name) ? { ...dlg, isOpen: true } : dlg
        )
      }

      const updateOpenDialogsInfo = () => {
        nextOpenDialogs = nextDialogs
          .filter((dlg) => dlg.isOpen)
          .map((item) => item.name)
      }

      if (currentDialog.isOpen) {
        nextOpenDialogs.push(currentDialog.name)
      }

      switch (currentDialog.variant) {
        case 'one-by-one':
          if (currentDialog.isOpen) {
            closeOtherDialogs()
          } else {
            openPrevDialogs()
          }
          break
        case 'unique':
          if (currentDialog.isOpen) {
            closeOtherDialogs()
          } else {
            closeAllDialogs()
          }
          break
        case 'multiple':
          if (!currentDialog.isOpen) {
            updateOpenDialogsInfo()
          }
          break
        default:
          updateOpenDialogsInfo()
      }

      state.dialogs = nextDialogs
      state.openDialogs = nextOpenDialogs
    },
  },
})

export const { setDialogsManager, updateDialogsManager } =
  dialogsManagerSlice.actions

export default dialogsManagerSlice.reducer
