import type {
  CreateProcessRes,
  ProcessRes,
} from '../redux/types/mediaProcessing'
import { api } from './axiosInstance'

export type CreateProcessParams = {
  file: File
}
export const apiCreateProcess = async (
  formData: CreateProcessParams
): Promise<CreateProcessRes> => {
  try {
    const response = await api.post('media-processing/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error during create media processing', error)
    throw error
  }
}

export const apiGetProcessById = async (id: string): Promise<ProcessRes> => {
  try {
    const response = await api.get(`media-processing/${id}`)
    return response.data
  } catch (error) {
    console.error('Error during get media processing', error)
    throw error
  }
}

export const apiDeleteProcessById = async (id: string): Promise<void> => {
  try {
    const response = await api.delete('media-processing/delete', {
      data: { id },
    })
    return response.data
  } catch (error) {
    console.error('Error during delete media processing', error)
    throw error
  }
}

export const apiGetProcessList = async (): Promise<ProcessRes[]> => {
  try {
    const response = await api.get('media-processing')
    return response.data
  } catch (error) {
    console.error('Error during get media processing list', error)
    throw error
  }
}

export const apiExportProcessData = async (params: {
  fileType: string
  id: string
}) => {
  try {
    const { data } = await api.get(
      `media-processing/export/${params.fileType}/${params.id}`,
      {
        responseType: 'blob',
      }
    )
    return data
  } catch (error) {
    console.error('Error during exporting processing data', error)
    throw error
  }
}
