import colors from '@infopulse-design-system/shared/theme/colors'
import { Edit } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { PERMISSIONS } from '../../../../constants/enums'
import { useDictionaryData } from '../../../../hooks/useDictionaryData'
import { usePageName } from '../../../../hooks/usePageName'
import { usePersonaData } from '../../../../hooks/usePersonaData'
import { usePersonaMediasData } from '../../../../hooks/usePersonaMediasData'
import { activePersonaSelector } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux/store'
import { IpAvatar } from '../../../ipDesignSystemComponents/atoms/IpAvatar'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import PermissionsGate from '../../PermissionsGate'
import { ROUTER_PATHS } from '../../Router'
import { PersonaAdditionMenu } from '../PersonaAdditionMenu'
import { PersonaEditPopup } from '../PersonaEditPopup'
import { PersonaMediaCard } from '../PersonaMediaCard'
import { PersonaMediaList } from '../PersonaMediaList'

import './index.scss'

export const PersonaPreviewPanel = () => {
  const { isPersonaPage } = usePageName()
  const activePersonaState = useAppSelector(activePersonaSelector)
  const activePersonaId = activePersonaState?.personaId ?? ''

  const {
    isPersonaData,
    isPersonaDataLoading,
    personaDataError,
    birth_date,
    categories_id,
    citizenship_id,
    create_date,
    media,
    name,
    photo_url,
    sanctions_justification,
    sanctions_list_id,
    title,
  } = usePersonaData(activePersonaId)
  const { isMediasLoading, mediasError, medias, uploaded } =
    usePersonaMediasData(activePersonaId)
  const dictionaryData = useDictionaryData({
    categories_id,
    citizenship_id,
    sanctions_list_id,
  })

  const [open, setOpen] = useState(false)
  const [openIndex, setOpenIndex] = useState(-1)

  useEffect(() => {
    setOpenIndex(-1)
  }, [activePersonaId])

  if (personaDataError) return <>{personaDataError}</>
  if (isPersonaDataLoading && !isPersonaPage) return <>Loading...</>
  if (!isPersonaData) return <></>

  const onPersonaEdit = () => {
    setOpen(true)
  }

  return (
    <div className="persona-preview">
      <div className="persona-preview_top">
        <IpText variant="h1" color="primary" classes="persona-preview_title">
          {name}
          <PermissionsGate permissionKey={PERMISSIONS.person_library_edit}>
            <IpIcon color="primary" onClick={onPersonaEdit}>
              <Edit />
            </IpIcon>
          </PermissionsGate>
        </IpText>
        <PersonaAdditionMenu id={activePersonaId} path={ROUTER_PATHS.persona} />
      </div>
      <div className="persona-info">
        <IpAvatar
          sizes="168px"
          avatarsData={[{ src: photo_url, color: colors.grey['500'] }]}
          classes="persona-info_avatar"
        />
        <div className="persona-info_left">
          <IpText
            variant="body2"
            classes="persona-info_title bold"
            color="primary"
          >
            Common data:
          </IpText>
          <ul>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Date of birth:
              </IpText>
              {birth_date}
            </IpText>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Citizenship:
              </IpText>
              {dictionaryData.citizenship}
            </IpText>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Title:
              </IpText>
              {title}
            </IpText>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Categories:
              </IpText>
              {dictionaryData.categories}
            </IpText>
            <IpText variant="body2" classes="medium" component="li">
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Create date:
              </IpText>
              {create_date}
            </IpText>
          </ul>
        </div>
        <div className="persona-info_right">
          <IpText
            variant="body2"
            classes="persona-info_title bold"
            color="primary"
          >
            Sanction data:
          </IpText>
          <ul>
            <IpText
              variant="body2"
              classes="persona-info_sanctions medium"
              component="li"
            >
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Sanctions list:
              </IpText>
              {dictionaryData.sanctions}
            </IpText>
            <IpText
              variant="body2"
              classes="persona-info_sanctions medium"
              component="li"
            >
              <IpText
                variant="body2"
                classes="semi-bold"
                component="span"
                color="primary"
              >
                Sanctions justification:
              </IpText>
              {sanctions_justification}
            </IpText>
            <li className="persona-media">
              <IpText
                variant="body2"
                classes="persona-media_title bold"
                color="primary"
              >
                Social network data:
              </IpText>
              <PersonaMediaList {...media} />
            </li>
          </ul>
        </div>
      </div>
      <div className="persona-tv">
        <IpText variant="body2" classes="persona-tv_title bold" color="primary">
          Media - TV:
          <IpText component="span" classes="normal" color="text.primary">
            {!isMediasLoading &&
              uploaded &&
              !medias?.length &&
              ' Nothing found'}
          </IpText>
        </IpText>
        {mediasError}
        {isMediasLoading ? (
          'Loading...'
        ) : (
          <div>
            {medias.map(({ id }, index) => {
              return (
                <PersonaMediaCard
                  onExpandClick={() =>
                    setOpenIndex((state) => (state !== index ? index : -1))
                  }
                  open={openIndex === index}
                  key={id}
                  personaId={activePersonaId}
                  index={index}
                />
              )
            })}
          </div>
        )}
      </div>
      <PersonaEditPopup
        open={open}
        setOpen={setOpen}
        activePersonaId={activePersonaId}
      />
    </div>
  )
}
