import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetSimilarSpeakerData } from '../../api'
import type {
  GetSimilarSpeakerParams,
  SimilarSpeakersResponse,
} from '../../api/apiTypes'
import {
  setSimilarSpeakersList,
  setSimilarSpeakersListNext,
} from '../slices/similarSpeakersSlice'

export const fetchSimilarSpeakers = createAsyncThunk<
  SimilarSpeakersResponse,
  GetSimilarSpeakerParams
>('similarSpeakers/fetch', async (params, thunkAPI) => {
  try {
    const data = await apiGetSimilarSpeakerData(params)
    thunkAPI.dispatch(setSimilarSpeakersList(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
  }
})

export const fetchSimilarSpeakersNext = createAsyncThunk<
  SimilarSpeakersResponse,
  GetSimilarSpeakerParams
>('similarSpeakers/fetch/next', async (params, thunkAPI) => {
  try {
    const data = await apiGetSimilarSpeakerData(params)
    thunkAPI.dispatch(setSimilarSpeakersListNext(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
  }
})
