import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import type { CreateProcessParams } from '../../../../api/mediaProcessing'
import { createMediaProcessing } from '../../../../redux/thunk/mediaProcessingThunk'
import type { IpButtonPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpButton/index.types'
import type { IpFormPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpForm'
import { IpForm } from '../../../ipDesignSystemComponents/atoms/IpForm'
import { ROUTER_PATHS } from '../../Router'
import './index.scss'

const isValidVideoFormat = (format: string): boolean =>
  format.startsWith('video/') || format.startsWith('audio/')

const submitButton: IpButtonPropTypesReact = {
  variant: 'contained',
  children: 'Process',
  size: 'small',
}

const formData: IpFormPropTypesReact['formElements'] = [
  {
    name: 'file',
    defaultValue: '',
    elementType: 'input',
    elementProps: {
      fullWidth: true,
      autoFocus: true,
      classes: 'file-input',
      label: 'Please add video/audio file',
      variant: 'standard',
      type: 'file',
      inputProps: { accept: 'video/*, audio/*' },
    },
    yupValidation: yup.mixed().test('fileType', 'Incorrect file', (value) => {
      if (!value) return false
      const isValid = isValidVideoFormat(value.type)
      return isValid
    }),
  },
]

export const ProcessingForm = () => {
  const [uploading, setUploading] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submitHandler = async (params: CreateProcessParams) => {
    setUploading(true)
    const res = await dispatch(createMediaProcessing(params) as any)
    if (res.payload.id) {
      navigate(`/${ROUTER_PATHS.media_processing}/${res.payload.id}`)
    }
    setUploading(false)
  }

  return (
    <>
      <IpForm
        formElements={formData}
        submitHandler={submitHandler}
        classes="processing-form"
        submitElementProps={submitButton}
      />
      {uploading && 'New media uploading, please wait...'}
    </>
  )
}
