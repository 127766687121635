import type { Theme } from '@mui/material'

export default (theme: Theme) => {
  return {
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
          '& .MuiAvatar-root': {
            borderColor: theme.palette.background,
          },
          '&.IpAvatarGroup--only-child': {
            '& .MuiAvatar-root': {
              border: 0,
            },
          },
        },
      },
    },
  }
}
