import React from 'react'

export const Word = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 3L2 5V19L12 21V3ZM14 5V7H20V9H14V11H20V13H14V15H20V17H14V19H21C21.552 19 22 18.552 22 18V6C22 5.448 21.552 5 21 5H14ZM3.29492 8.29492H4.83008L5.32227 11.7734C5.35727 11.9824 5.38458 12.2671 5.39258 12.6191H5.41797C5.42597 12.3621 5.46244 12.0689 5.52344 11.7539L6.1543 8.29492H8.02344L8.60742 11.7422C8.64242 11.9232 8.66736 12.1892 8.69336 12.5332H8.71289C8.72089 12.2662 8.74725 11.9891 8.78125 11.7031L9.25977 8.29492H10.7031L9.32031 15.7051H8.00586L7.12695 11.7441C7.08295 11.4971 7.05883 11.2191 7.04883 10.9141H7.03125C7.01325 11.2471 6.97855 11.5241 6.93555 11.7441L6.03906 15.7051H4.67188L3.29492 8.29492Z"
      fill="#06037D"
    />
  </svg>
)
