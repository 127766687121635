import type { UserTagsCustomPageTypes } from './apiTypes'
import { api } from './axiosInstance'

export const apiGetUserTags = async (
  page?: UserTagsCustomPageTypes
): Promise<string[]> => {
  try {
    const path = page ? `${page}/user-tags` : 'user-tags'
    const response = await api.get(path)
    return response.data
  } catch (error) {
    console.error('Error during get user tags', error)
    throw error
  }
}
