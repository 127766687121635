import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { apiCreateBug } from '../../../../../../api'
import { BugTypes } from '../../../../../../constants/enums'
import { useHoveredNotification } from '../../../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../../../hooks/useLanguage'
import type {
  MediaData,
  Transcription,
} from '../../../../../../redux/types/media'
import { IpButton } from '../../../../../ipDesignSystemComponents/atoms/IpButton'
import { IpTextField } from '../../../../../ipDesignSystemComponents/atoms/IpTextField'

type Props = {
  textValue: string
  setTextValue: Dispatch<SetStateAction<string>>
  onSubmit: () => void
  transcription: Transcription
  currentMediaData: MediaData
}

export const UpdateTranscriptionContent = ({
  textValue,
  setTextValue,
  onSubmit,
  transcription,
  currentMediaData,
}: Props) => {
  const { notification } = useHoveredNotification()
  const { suffix } = useLanguage()

  const onSubmitHandler = async () => {
    const text = transcription[`text${suffix}` as const] ?? ''

    if (textValue === text) {
      notification('Text is not updated', 'warning', true)
      return
    }

    await apiCreateBug({
      transcription_id: transcription.id,
      media_id: currentMediaData.id,
      air_date: currentMediaData.air_date,
      weekday: currentMediaData.weekday,
      media_type_id: currentMediaData.media_type_id,
      source_id: currentMediaData.source_id,
      author_id: currentMediaData.author_id ?? undefined,
      program_id: currentMediaData.program_id ?? '',
      speaker: transcription.speaker,
      text,
      correct_text: textValue,
      bug_type_id: BugTypes.text,
      start: transcription.start,
      end: transcription.end,
      persona_id: transcription.persona_id,
    })

    onSubmit()
  }

  return (
    <>
      <IpTextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        variant="standard"
        multiline
        maxRows={3}
      />
      <IpButton
        variant="outlined"
        size="small"
        onClick={onSubmit}
        classes="cancel-btn"
      >
        Cancel
      </IpButton>
      <IpButton variant="contained" size="small" onClick={onSubmitHandler}>
        Update
      </IpButton>
    </>
  )
}
