/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { fetchPersonaData } from '../thunk/personaDataThunk'
import type { ApiData } from '../types'
import type {
  PersonaItem,
  PersonaItemData,
  PersonaItemMedias,
} from '../types/personas'

export type PersonaDataState = ApiData<Record<string, PersonaItemData>>

export const initialPersonaDataState: PersonaDataState = {
  data: {},
  isLoading: false,
  error: '',
}

export const PersonaDataSlice = createSlice({
  name: 'personaData',
  initialState: initialPersonaDataState,
  reducers: {
    setPersonaData: (
      state,
      {
        payload: { id, data },
      }: PayloadAction<{ id: string; data: PersonaItem }>
    ) => {
      state.data[id] = data
    },
    updatePersonaData: (
      state,
      { payload: { id, ...rest } }: PayloadAction<PersonaItem>
    ) => {
      state.data[id] = { ...state.data[id], ...rest }
    },
    setPersonaMediasData: (
      state,
      {
        payload: { id, data },
      }: PayloadAction<{ id: string; data: PersonaItemMedias }>
    ) => {
      ;(state.data[id] as PersonaItemData).medias = data
    },
    resetPersonaDataError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonaData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPersonaData.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchPersonaData.rejected, (state, action) => {
      if (action.payload !== 'canceled') {
        state.isLoading = false
        state.error = action.payload as string
      }
    })
  },
})

export const {
  setPersonaData,
  updatePersonaData,
  setPersonaMediasData,
  resetPersonaDataError,
} = PersonaDataSlice.actions

export default PersonaDataSlice.reducer
