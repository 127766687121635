import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiAddMediaUserTag, apiRemoveMediaUserTag } from '../../api'
import type { UpdateMediaUserTagsParams } from '../../api/apiTypes'
import { updateUserTagsInMedia } from '../slices/mediaDataSlice'

export const addMediaUserTag = createAsyncThunk<
  void,
  UpdateMediaUserTagsParams
>(
  'mediaUserTags/add',
  // @ts-ignore
  async (params, thunkAPI) => {
    try {
      const data = await apiAddMediaUserTag(params)
      thunkAPI.dispatch(updateUserTagsInMedia(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message ?? e.response.data.message)
    }
  }
)

export const removeMediaUserTag = createAsyncThunk<
  void,
  UpdateMediaUserTagsParams
>(
  'mediaUserTags/remove',
  // @ts-ignore
  async (params, thunkAPI) => {
    try {
      const data = await apiRemoveMediaUserTag(params)
      thunkAPI.dispatch(updateUserTagsInMedia(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }
  }
)
