import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetDictionaries, apiGetPersonas } from '../../api'
import {
  apiFootnoteCreate,
  apiFootnoteDelete,
  apiFootnoteUpdate,
} from '../../api/footnotes'
import {
  addFootnote,
  deleteFootnote,
  setDictionaries,
  setPersonas,
  updateFootnote,
} from '../slices/dictionariesSlice'
import { setPermissions } from '../slices/profileSlice'
import type {
  DictionariesPayloadData,
  DictionaryPersonaContent,
  FootnoteCreateParams,
  FootnoteData,
  FootnoteUpdateParams,
  Language,
} from '../types/dictionaries'

export const fetchDictionaries = createAsyncThunk<DictionariesPayloadData>(
  'dictionaries/fetch',
  async (_, thunkAPI) => {
    try {
      const data = await apiGetDictionaries()
      thunkAPI.dispatch(setDictionaries(data))
      if (data.permissions) {
        thunkAPI.dispatch(setPermissions(data.permissions))
      }
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)

export const fetchPersonas = createAsyncThunk<
  DictionaryPersonaContent[],
  { locale: Language['locale']; suffix: Language['attribute_suffix'] }
>('dictionaries/get/person', async ({ locale, suffix }, thunkAPI) => {
  try {
    const data = await apiGetPersonas()
    thunkAPI.dispatch(setPersonas({ data, locale, suffix }))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export const createFootnoteThunk = createAsyncThunk<
  FootnoteData,
  FootnoteCreateParams
>('dictionaries/footnote/create', async (params, thunkAPI) => {
  try {
    const data = await apiFootnoteCreate(params)
    thunkAPI.dispatch(addFootnote(data))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export const updateFootnoteThunk = createAsyncThunk<
  FootnoteData,
  { id: string; params: FootnoteUpdateParams; index: number }
>('dictionaries/footnote/update', async ({ id, params, index }, thunkAPI) => {
  try {
    const data = await apiFootnoteUpdate(id, params)
    thunkAPI.dispatch(updateFootnote({ index, data }))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export const deleteFootnoteThunk = createAsyncThunk<
  FootnoteData,
  { id: string; index: number }
>('dictionaries/footnote/delete', async ({ id, index }, thunkAPI) => {
  try {
    const data = await apiFootnoteDelete(id)
    thunkAPI.dispatch(deleteFootnote(index))
    return data
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.message)
  }
})
