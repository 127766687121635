import React from 'react'

export const Logo = () => (
  <svg
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00781722 1.07813C-0.0781203 0.757813 1.39844 0.59375 4.44532 0.59375C7.8125 0.59375 9.53907 0.875 9.625 1.42969L11.7656 19.9062L16.4219 1.20312C16.5703 0.765625 17.7109 0.546875 19.8438 0.546875C21.9766 0.546875 23.125 0.75 23.2656 1.16406L27.8281 20L30.375 1.34375C30.4375 1.02344 31.1094 0.804688 32.3984 0.6875C33.6875 0.570313 34.7266 0.515625 35.5156 0.515625C38.5859 0.515625 40.0781 0.71875 39.9922 1.13281L34.6797 30.2344C34.5625 30.6719 33.7266 31 32.1797 31.2031C30.6328 31.4063 29.3672 31.5078 28.4063 31.5078C24.7734 31.5078 22.875 31.1016 22.6953 30.2812L19.7109 18.2109L16.4609 30.2422C16.3438 30.7734 15.3203 31.1484 13.3906 31.3828C12.3984 31.4688 11.3984 31.5156 10.4063 31.5156L7.59375 31.3828C5.66407 31.1797 4.64063 30.8125 4.52344 30.2891L0.00781722 1.07813Z"
      fill="url(#paint0_linear_73_2980)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_73_2980"
        x1="3.78344"
        y1="-2.77797"
        x2="35.7814"
        y2="29.22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5487" />
        <stop offset="1" stopColor="#1C00C7" />
      </linearGradient>
    </defs>
  </svg>
)
