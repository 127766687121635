import { addDays, format, parse } from 'date-fns'
import { useEffect, useState } from 'react'
import type { GetTranscriptionStatsRes } from '../../api/dashboard'
import { apiGetTranscriptionStats } from '../../api/dashboard'
import { DATE_FORMAT, EXPORT_FILE_EXT } from '../../constants'
import type { CardInterval } from '../../constants/cart'
import { ChartLabel, ChartType } from '../../constants/cart'
import type { ChartTypeValue } from '../../pages/Dashboard'
import {
  dictionariesSelector,
  isAuthSelector,
  queryTypeSelector,
} from '../../redux/selectors'
import { resetFilters } from '../../redux/slices/filtersSlice'
import type { QueryType } from '../../redux/slices/profileSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { DICTIONARY_ADDITION } from '../../redux/types/dictionaries'
import { getExportFileName, getNameFromDictionaryByLang } from '../../utils'
import { useExportFile } from '../useExportFile'
import useFilters from '../useFilters'
import { useHoveredNotification } from '../useHoveredNotification'
import { useLanguage } from '../useLanguage'

export type ChartLabelType = typeof ChartLabel[keyof typeof ChartLabel]

const barInterceptionMapper = {
  [ChartLabel.personas]: DICTIONARY_ADDITION.personas,
  [ChartLabel.sources]: 'sources',
  [ChartLabel.programs]: 'programs',
  [ChartLabel.authors]: 'authors',
} as const

export type DashboardIntervalType = keyof typeof CardInterval

function updateDay(
  value: 1 | -1,
  inputDate?: string,
  dateFormat = DATE_FORMAT
): string | undefined {
  if (!inputDate) return undefined

  const parsedDate = parse(inputDate, dateFormat, new Date())
  const updatedDate = addDays(parsedDate, value)
  const formattedDate = format(updatedDate, dateFormat)
  return formattedDate
}

type HorizontalScaleArrayType = {
  data: string[]
}

type DataArrayType = {
  label: string
  data: number[]
}[]

/**
 *
 * @param res
 * @returns
 *
 * labels is an array of strings taken from the response as res[0].data.map(item => item.value)
 * data is an array of objects with label and data keys taken from the response
 * there data is data.map(el=> el.map(item => item.count))
 * there labels is data.map(el=> el.search)
 */
const prepareData = (
  res: GetTranscriptionStatsRes
): {
  labels: HorizontalScaleArrayType
  data: DataArrayType
} => {
  const labels = res[0].data.map((item) => item.value)
  const data = res.map((el) => {
    const label = el.search
    const data = el.data.map((item) => item.count)
    return { label, data }
  })
  return { labels: { data: labels }, data }
}

export const useDashboard = () => {
  const dispatch = useAppDispatch()
  const { handleExport } = useExportFile()
  const { notification } = useHoveredNotification()
  const { filters, filterType } = useFilters()
  const { chartLabelType, interval = 'day', chartType } = filters
  const isLinear = chartType === ChartType.linear
  const isBar = chartType === ChartType.bar
  const { suffix } = useLanguage()
  const dictionary = useAppSelector(dictionariesSelector).data
  const isAuth = useAppSelector(isAuthSelector)
  const queryType = useAppSelector(queryTypeSelector)
  const [searchWords, setSearchWords] = useState<string[]>([])
  const [linearLabels, setLinearLabels] = useState<HorizontalScaleArrayType>({
    data: [],
  })
  const [data, setData] = useState<DataArrayType>([])
  const [exportLoading, setExportLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const prepareApiParams = (searchKeys: string[]) => {
    const params = {
      ...filters,
      chartType: chartType as ChartTypeValue,
      chartLabelType: chartLabelType as ChartLabelType,
      interval: interval as DashboardIntervalType,
      searchKeys,
      date_from: isBar ? filters.date_from : updateDay(-1, filters.date_from),
      date_to: isBar ? filters.date_to : updateDay(1, filters.date_to),
      queryType: queryType as QueryType,
    }
    return params
  }

  const exportHandler = async () => {
    setExportLoading(true)
    await handleExport({
      api: apiGetTranscriptionStats,
      params: {
        filters: prepareApiParams(searchWords),
        isExport: true,
      },
      fileName: getExportFileName(chartType as ChartTypeValue),
      fileExt: EXPORT_FILE_EXT.excel,
    })
    setExportLoading(false)
  }

  const loadData = async (searchKeys: string[]) => {
    setLoading(true)
    try {
      const res = await apiGetTranscriptionStats({
        filters: prepareApiParams(searchKeys),
      })
      const { labels, data } = prepareData(res)
      setLinearLabels(labels)
      setData(data)
    } catch (e: any) {
      notification(
        `Errors: ${e.message}; ${e.response?.data?.message}`,
        'error',
        true
      )
    }
    setLoading(false)
  }

  const updateSearchWords = (data: string | number) => {
    if (typeof data === 'string') {
      const newArr = [...searchWords, data]
      setSearchWords(newArr)
      loadData(newArr)
    } else {
      setSearchWords((state) => state.filter((_, index) => index !== data))
      setData((state) => state.filter((_, index) => index !== data))
    }
  }

  useEffect(() => {
    if (!isAuth || !queryType || !searchWords?.length) {
      return
    }
    loadData(searchWords)
  }, [filters, queryType])

  useEffect(() => {
    dispatch(resetFilters({ filterType }))
  }, [])

  const getBarIntersectionLabels = (arr: string[]): string[] => {
    const barKey = barInterceptionMapper[chartLabelType as ChartLabelType]
    const barLabels = arr.map((id) =>
      getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: barKey,
        id,
      })
    )

    return barLabels
  }

  return {
    horizontalScaleArray: isBar
      ? getBarIntersectionLabels(linearLabels.data)
      : linearLabels.data,
    data,
    updateSearchWords,
    searchWords,
    isLinear,
    isBar,
    exportHandler,
    exportLoading,
    loading,
  }
}
