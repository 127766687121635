import { useEffect, useState } from 'react'
import type { MediaData } from '../redux/types/media'

export const useSimilarEmbeddingsNum = (
  speakers: MediaData['speakers'],
  speakerId = '',
  skip = false
) => {
  const [similarEmbeddingsNum, setSimilarEmbeddingsNum] = useState<
    number | undefined
  >()

  useEffect(() => {
    if (!skip && speakerId && speakers) {
      const speakerData = speakers.find(({ id }) => id === speakerId)
      const newSimilarEmbeddingsNum = speakerData
        ? speakerData.similar_embeddings_num
        : undefined
      setSimilarEmbeddingsNum(newSimilarEmbeddingsNum)
    }
  }, [skip, speakerId, speakers])

  return {
    similarEmbeddingsNum,
  }
}
