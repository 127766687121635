import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import React from 'react'
import { apiExportPersonasData } from '../../api'
import type { ExportTranscriptionsParams } from '../../api/apiTypes'
import { FiltersPanel } from '../../components/custom/FiltersPanel'
import { ExportToExcel } from '../../components/custom/icons'
import PermissionsGate from '../../components/custom/PermissionsGate'
import { PersonaPreviewPanel } from '../../components/custom/persona'
import { PersonasResultPanel } from '../../components/custom/persona/PersonasResultPanel'
import { SortMenu } from '../../components/custom/SortMenu'
import { SortMenuType } from '../../components/custom/SortMenu/types/sort-menu-config.type'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import { EXPORT_FILE_EXT } from '../../constants'
import { EXPORT_FILE_FORMATS, PERMISSIONS } from '../../constants/enums'
import { useExportFile } from '../../hooks/useExportFile'
import useFilters from '../../hooks/useFilters'
import useResponsivePerform from '../../hooks/useResponsivePerform'
import './index.scss'

function PersonLibrary() {
  const { isMore: isDetailsShown, isLess: isLessLg } = useResponsivePerform(
    breakpoints.values.lg
  )
  const { handleExport } = useExportFile()
  const [isExcelLoading, setIsExcelLoading] = React.useState(false)
  const { filters } = useFilters()

  const exportHandler = async (
    fileType: ExportTranscriptionsParams['fileType']
  ) => {
    setIsExcelLoading(true)
    await handleExport({
      api: apiExportPersonasData,
      params: {
        ...filters,
        export_type: fileType,
        limit: 0,
        skip: 0,
        categories_id: filters.persona_category,
      },
      fileName: 'personas',
      fileExt: EXPORT_FILE_EXT[fileType],
    })
    setIsExcelLoading(false)
  }

  return (
    <div className="page person-library">
      <FiltersPanel classes="person-library-filter" />
      <div className="person-library-result">
        <div className="person-library-header">
          <IpText
            color="primary"
            variant="h2"
            classes="main-subtitle main-subtitle_result semi-bold"
          >
            Person library
            <PermissionsGate
              permissionKey={PERMISSIONS.export_reports_and_transcriptions}
            >
              <ExportToExcel
                loading={isExcelLoading}
                placement="top"
                onClick={() => exportHandler(EXPORT_FILE_FORMATS.EXCEL)}
              />
            </PermissionsGate>
          </IpText>
          <div>
            <SortMenu type={SortMenuType.persona} />
          </div>
        </div>
        <PersonasResultPanel isLessLg={isLessLg} />
      </div>
      {isDetailsShown && (
        <div className="person-library-preview">
          <IpText
            color="primary"
            variant="h2"
            classes="main-subtitle semi-bold"
          >
            Details
          </IpText>
          <PersonaPreviewPanel />
        </div>
      )}
    </div>
  )
}

export default PersonLibrary
