import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { apiTranscriptionsAnnotation } from '../../../../../../api'
import { AnnotationClassesIdTypes } from '../../../../../../constants/enums'
import { useHoveredNotification } from '../../../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../../redux/selectors'
import { useAppSelector } from '../../../../../../redux/store'
import type { Transcription } from '../../../../../../redux/types/media'
import { IpButton } from '../../../../../ipDesignSystemComponents/atoms/IpButton'
import { IpText } from '../../../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../../../ipDesignSystemComponents/atoms/IpTextField'
import { SelectedList } from '../../../../SelectedList'

export const SignOfCrimesContent = ({
  textValue,
  setTextValue,
  onSubmit,
  transcription,
}: {
  textValue: string
  setTextValue: Dispatch<SetStateAction<string>>
  onSubmit: () => void
  transcription: Transcription
}) => {
  const { suffix } = useLanguage()
  const { notification } = useHoveredNotification()
  const { signs_of_crimes } = useAppSelector(dictionariesSelector).data
  const [signs, setSigns] = useState<string[]>([])
  const nameKey: `name${string}` = `name${suffix}`
  const list = signs_of_crimes.map((item) => item[nameKey] ?? '')

  useEffect(() => {
    setSigns([])
  }, [suffix])

  const onSignRemove = (value: string) => {
    if (!value) return
    setSigns((state) => state.filter((t) => t !== value))
  }

  const onSignAdd = (value: string) => {
    if (!value) return
    setSigns((state) => [...state, value])
  }

  const onCancelHandler = () => {
    setSigns([])
    onSubmit()
  }

  const onSubmitHandler = async () => {
    if (!signs.length) {
      notification('Signs of crimes are not selected', 'warning', true)
      return
    }

    const signsArr = signs.map(
      (el) => signs_of_crimes.find((item) => item[nameKey] === el)!.id
    )
    try {
      await apiTranscriptionsAnnotation({
        transcription_id: transcription.id,
        media_id: transcription.media_id,
        classes_id: AnnotationClassesIdTypes.signsOfCrimes,
        class_items_id: signsArr,
        quote: textValue,
      })
    } catch (e: any) {
      notification(`Error: ${e.message}`, 'error', true)
    }

    setSigns([])
    onSubmit()
  }

  return (
    <>
      <IpText variant="subtitle3" color="primary">
        Add sign of crimes
      </IpText>
      <IpTextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        variant="standard"
        multiline
        maxRows={3}
      />
      <SelectedList
        disableSearch={true}
        title="Sign of crimes:"
        color="primary"
        list={list}
        currentList={signs}
        onItemAdd={onSignAdd}
        onItemRemove={onSignRemove}
        onNewItemAdd={onSignAdd}
        classes="sign-of-crime-select-list"
        dropdownListClasses="sign-of-crime-dropdown"
      />
      <IpButton
        variant="outlined"
        size="small"
        onClick={onCancelHandler}
        classes="reporting-basket-btn cancel-btn"
      >
        Cancel
      </IpButton>
      <IpButton variant="contained" size="small" onClick={onSubmitHandler}>
        Add to signs of crimes
      </IpButton>
    </>
  )
}
