import type { ReactNode } from 'react'
import React, { useContext } from 'react'
import CardContent from '@mui/material/CardContent'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { IpCardContentPropTypes } from '@infopulse-design-system/shared/types/IpCard.types'
import '@infopulse-design-system/shared/theme/components/IpCard/IpCardContent/styles.scss'
import { CardContext } from '..'
import { IpSkeleton } from '../../IpSkeleton'

export const IpCardContentTestId = 'IpCardContentTestId'

export type IpCardContentPropTypesReact = IpCardContentPropTypes & {
  children: ReactNode
}

export const IpCardContent = (props: IpCardContentPropTypesReact) => {
  const { classes, children } = props
  const { isLoading, skeletonAnimation } = useContext(CardContext)

  const customClasses = formatClassName(
    generateClasses('card-content', 'react'),
    classes
  )

  return (
    <CardContent className={customClasses} data-testid={IpCardContentTestId}>
      {isLoading ? (
        <>
          <IpSkeleton
            animation={skeletonAnimation}
            variant="text"
            width="80%"
          ></IpSkeleton>
          <IpSkeleton
            animation={skeletonAnimation}
            variant="text"
            width="80%"
          ></IpSkeleton>
          <IpSkeleton
            animation={skeletonAnimation}
            variant="text"
            width="80%"
          ></IpSkeleton>
        </>
      ) : (
        children
      )}
    </CardContent>
  )
}
