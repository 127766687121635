import type { GetSimilarSpeakerParams } from './apiTypes'
import { api } from './axiosInstance'

export const apiGetSimilarSpeakerData = async (
  params: GetSimilarSpeakerParams
) => {
  try {
    const response = await api.post('speaker-embeding/find-similar', params)
    return response.data
  } catch (error) {
    console.error('Error get similar speakers media list', error)
    throw error
  }
}
