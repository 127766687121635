import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import AppStateSlice from './slices/appStateSlice'
import BugsSlice from './slices/bugsSlice'
import dialogsManagerReducer from './slices/dialogsManagerSlice'
import dictionariesReducer from './slices/dictionariesSlice'
import filtersReducer from './slices/filtersSlice'
import mediaDataReducer from './slices/mediaDataSlice'
import mediaListReducer from './slices/mediaListSlice'
import mediaProcessingReducer from './slices/MediaProcessingSlice'
import personaDataReducer from './slices/personaDataSlice'
import personaListReducer from './slices/personaListSlice'
import profileSlice from './slices/profileSlice'
import reportBasketSlice from './slices/reportBasketSlice'
import searchReducer from './slices/searchSlice'
import settingsSlice from './slices/settingsSlice'
import similarSpeakersReducer from './slices/similarSpeakersSlice'
import transcriptionsDataReducer from './slices/transcriptionsDataSlice'

const rootReducer = combineReducers({
  dialogsManager: dialogsManagerReducer,
  search: searchReducer,
  mediaList: mediaListReducer,
  mediaData: mediaDataReducer,
  transcriptionsData: transcriptionsDataReducer,
  dictionaries: dictionariesReducer,
  profile: profileSlice,
  filters: filtersReducer,
  settings: settingsSlice,
  reportBasket: reportBasketSlice,
  bugs: BugsSlice,
  appState: AppStateSlice,
  personaList: personaListReducer,
  personaData: personaDataReducer,
  similarSpeakers: similarSpeakersReducer,
  mediaProcessing: mediaProcessingReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings', 'appState'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({ reducer: rootReducer, preloadedState })
}
