import type { DictionaryPersonaContent } from '../redux/types/dictionaries'
import type { PersonaItem, PersonaItemMedias } from '../redux/types/personas'
import type {
  AddNewPersonParamsType,
  GetPersonaListParams,
  PersonaListResponse,
} from './apiTypes'
import { api } from './axiosInstance'
import { mapPersonaParams } from './mappers'

export const apiSetNewPerson = async (
  params: AddNewPersonParamsType
): Promise<DictionaryPersonaContent> => {
  try {
    const response = await api.post('persona', params)
    return response.data
  } catch (error) {
    // todo: Handle error if needed
    console.error('Error sending speakers data:', error)
    throw error
  }
}

export const apiGetPersonas = async () => {
  try {
    const response = await api.get('persona')
    return response.data
  } catch (error) {
    // todo: Handle error if needed
    console.error('Error during get persons:', error)
    throw error
  }
}

export const apiGetPersonaList = async (
  params: GetPersonaListParams
): Promise<PersonaListResponse> => {
  try {
    const response = await api.post('persona/filter', mapPersonaParams(params))
    return response.data
  } catch (error) {
    console.error('Error during get persona list', error)
    throw error
  }
}

export const apiGetPersonaData = async (id: string): Promise<PersonaItem> => {
  try {
    const response = await api.get(`persona/${id}`)
    return response.data
  } catch (error) {
    console.error('Error during get persona data:', error)
    throw error
  }
}

export const apiUpdatePersonaData = async ({
  id,
  ...params
}: PersonaItem): Promise<PersonaItem> => {
  try {
    const response = await api.patch(`persona/${id}`, params)
    return response.data
  } catch (error) {
    console.error('Error during update persona data:', error)
    throw error
  }
}

export const apiGetPersonaMediasData = async (
  id: string
): Promise<PersonaItemMedias> => {
  try {
    const response = await api.get(`persona/medias/${id}`)
    return response.data
  } catch (error) {
    console.error('Error during get persona medias data:', error)
    throw error
  }
}
