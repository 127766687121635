import { useEffect, useState } from 'react'

export default function useResponsivePerform(width = 0) {
  const [isMore, setIsMore] = useState<boolean>(window.innerWidth > width)

  const resizeHandler = () => {
    setIsMore(window.innerWidth > width)
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return { isMore, isLess: !isMore }
}
