/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { MainPageTabTypes } from '../../constants/enums'
import type {
  MainPageActiveTabs,
  MainPageStateData,
  PersonaLibraryPageStateData,
  SetActiveMediaPayloadType,
} from '../types/appState'

export type AppStateType = {
  pages: {
    main: MainPageStateData
    personaLibrary?: PersonaLibraryPageStateData
  }
}

export const initialAppState: AppStateType = {
  pages: {
    main: {
      activeTab: MainPageTabTypes.results,
    },
  },
}

export const AppStateSlice = createSlice({
  name: 'appState',
  initialState: initialAppState,
  reducers: {
    setActiveMedia: (
      state,
      {
        payload: { mainActiveTab, mediaIndex, mediaId },
      }: PayloadAction<SetActiveMediaPayloadType>
    ) => {
      if (!state.pages.main.activeMedia) {
        state.pages.main.activeMedia = {
          correct: {},
          incorrect: {},
        }
      }
      state.pages.main.activeMedia![mainActiveTab] = { mediaId, mediaIndex }
    },
    resetActiveMedia: (state) => {
      state.pages.main.activeMedia = undefined
    },
    resetActiveMediaByType: (
      state,
      { payload }: PayloadAction<MainPageActiveTabs>
    ) => {
      if (!state.pages.main.activeMedia) {
        state.pages.main.activeMedia = {
          correct: {},
          incorrect: {},
        }
      } else {
        state.pages.main.activeMedia[payload] = {}
      }
    },
    setMainActiveTab: (
      state,
      { payload }: PayloadAction<MainPageActiveTabs>
    ) => {
      state.pages.main.activeTab = payload
    },
    setActivePersonaId: (
      state,
      { payload: [id, index] }: PayloadAction<[string, number]>
    ) => {
      if (!state.pages.personaLibrary) {
        state.pages.personaLibrary = { activePersona: {} }
      }
      state.pages.personaLibrary!.activePersona.personaId = id
      state.pages.personaLibrary!.activePersona.personaIndex = index
    },
    resetActivePersonaId: (state) => {
      state.pages.personaLibrary = undefined
    },
  },
})

export const {
  setMainActiveTab,
  setActivePersonaId,
  resetActivePersonaId,
  resetActiveMedia,
  setActiveMedia,
  resetActiveMediaByType,
} = AppStateSlice.actions

export default AppStateSlice.reducer
