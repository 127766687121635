import {
  IP_NOTIFICATION_AUTO_HIDE_DURATION,
  IP_NOTIFICATION_MAX_SNACK,
} from '@infopulse-design-system/shared/constants/global.config.constants'
import type { IpNotificationProviderTypes } from '@infopulse-design-system/shared/types/IpNotificationServise.types'
import { SnackbarProvider } from 'notistack'
import type { ReactNode } from 'react'
import React from 'react'
import { getCustomTransitionComponent } from '../../utils'
import './index.scss'

export const getDuration = (value: number) => {
  return { enter: value, exit: value }
}

type IpNotificationProviderTypesReact = IpNotificationProviderTypes & {
  children?: ReactNode
  domRoot?: HTMLElement
}

export function IpNotificationProvider(
  props: IpNotificationProviderTypesReact
) {
  const {
    autoHideDuration = IP_NOTIFICATION_AUTO_HIDE_DURATION,
    children,
    disableWindowBlurListener,
    duration,
    maxItems = IP_NOTIFICATION_MAX_SNACK,
    position,
    preventDuplicate,
    transitionType,
    variant,
    domRoot,
  } = props

  const maxItemsValue =
    variant === 'oneByOne' ? 1 : maxItems ?? IP_NOTIFICATION_MAX_SNACK

  const customDuration = duration ? getDuration(duration) : undefined

  const transitionComponent = getCustomTransitionComponent(transitionType)

  return (
    <SnackbarProvider
      TransitionComponent={transitionComponent}
      anchorOrigin={position}
      autoHideDuration={autoHideDuration}
      disableWindowBlurListener={disableWindowBlurListener}
      maxSnack={maxItemsValue}
      preventDuplicate={preventDuplicate}
      transitionDuration={customDuration}
      domRoot={domRoot}
    >
      {children}
    </SnackbarProvider>
  )
}
