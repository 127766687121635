import { Close } from '@mui/icons-material'
import React from 'react'
import useFilters from '../../../hooks/useFilters'
import { useLanguage } from '../../../hooks/useLanguage'
import useResponsivePerform from '../../../hooks/useResponsivePerform'
import { dictionariesSelector, profileSelector } from '../../../redux/selectors'
import {
  resetFilterDate,
  resetFilterStartEnd,
  resetStringFilters,
  updateFilterArr,
} from '../../../redux/slices/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import type {
  DateFilters,
  FilterDateDataKeys,
  FilterNumberDataKeys,
  FiltersState,
  FilterStartEndDataKeys,
  PayloadUpdateFilterArrActionType,
  SearchStringFilters,
  StartEndFilters,
} from '../../../redux/types/filters'
import {
  DATE_FILTERS,
  SEARCH_STRING_FILTERS,
  START_END_FILTERS,
} from '../../../redux/types/filters'
import { IpChip } from '../../ipDesignSystemComponents/atoms/IpChip'
import { getChipDataFromFilters } from './helpers'

import './index.scss'

const IS_TAG_PANEL_VISIBLE_RESOLUTION = 1024

type CustomResetTypes = DateFilters | StartEndFilters

type OmittedResetTypes =
  | FilterDateDataKeys
  | FilterNumberDataKeys
  | FilterStartEndDataKeys
  | 'weekdays'

type FiltersNativeTypes = Omit<keyof FiltersState, OmittedResetTypes>

export type TagPropTypes = {
  label: string
  type: FiltersNativeTypes | CustomResetTypes
  id?: string
  title?: string
}

const Tag = (props: TagPropTypes) => {
  const { label, type, id = '', title } = props
  const dispatch = useAppDispatch()
  const { filterType } = useFilters()
  const isDateType = type.includes('date')

  const onDeleteHandler = () => {
    switch (type) {
      case DATE_FILTERS[type as DateFilters]:
        dispatch(
          resetFilterDate({ filterType, resetType: type as DateFilters })
        )
        break
      case START_END_FILTERS[type as StartEndFilters]:
        dispatch(
          resetFilterStartEnd({
            filterType,
            resetType: type as StartEndFilters,
          })
        )
        break
      case SEARCH_STRING_FILTERS[type as SearchStringFilters]:
        dispatch(
          resetStringFilters({ filterType, type: type as SearchStringFilters })
        )
        break
      case 'models':
        dispatch(resetStringFilters({ filterType, type: 'search_model' }))
        break
      default:
        dispatch(
          updateFilterArr({
            filterType,
            type: type as PayloadUpdateFilterArrActionType['type'],
            value: id,
          })
        )
    }
  }

  const chipLabel = title ? `${title}: ${label}` : label

  return (
    <IpChip
      deleteIcon={<Close />}
      label={chipLabel}
      color="primary"
      size="small"
      variant="outlined"
      classes="filter-tag"
      onDelete={isDateType ? undefined : onDeleteHandler}
    />
  )
}

export const FilterTags = () => {
  const { isMore } = useResponsivePerform(IS_TAG_PANEL_VISIBLE_RESOLUTION)
  const { filters, filterType } = useFilters()
  const { data: dictionary } = useAppSelector(dictionariesSelector)
  const {
    data: { userTags: userTagsArr },
  } = useAppSelector(profileSelector)
  const { suffix } = useLanguage()

  const chipsData = getChipDataFromFilters(
    filters,
    filterType,
    dictionary,
    userTagsArr,
    suffix
  )

  const showDesktopTagPanel = isMore

  const chips = chipsData.map(({ label, type, id, title }) => (
    <Tag key={label + type} label={label} type={type} id={id} title={title} />
  ))

  return chips.length && showDesktopTagPanel ? (
    <div className="filter-tags">
      <div className="filter-tags-wrapper">{chips}</div>
    </div>
  ) : null
}
