import { debounce } from '@mui/material'
import { useCallback, useEffect } from 'react'
import type { GetPersonaListParams } from '../../api/apiTypes'
import { FILTERS_DEBOUNCE_TIMEOUT } from '../../constants'
import { personaListSelector, profileSelector } from '../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  fetchPersonaList,
  fetchPersonaListNext,
} from '../../redux/thunk/personaListThunk'
import useFilters from '../useFilters'
import { useIsInitialFilters } from '../useIsInitialFilters'

export const usePersonLibrary = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(profileSelector)
  const { personLibraryFilters } = useFilters()
  const { getIsInitialFilters } = useIsInitialFilters()
  const { continuationToken } = useAppSelector(personaListSelector).data

  const isAuth = Boolean(profile.data.name)

  const handleFetchPersonasDebounced = useCallback(
    debounce((filters: GetPersonaListParams) => {
      dispatch(fetchPersonaList(filters))
    }, FILTERS_DEBOUNCE_TIMEOUT),
    []
  )

  useEffect(() => {
    if (!isAuth) return

    const isFirstPersonLibraryFilters = personLibraryFilters.skip === 0
    const isInitialFilters = getIsInitialFilters()

    if (isFirstPersonLibraryFilters && isInitialFilters) {
      dispatch(
        fetchPersonaList({ filters: personLibraryFilters, continuationToken })
      )
    } else if (isFirstPersonLibraryFilters && !isInitialFilters) {
      handleFetchPersonasDebounced({
        filters: personLibraryFilters,
        continuationToken,
      })
    } else if (!isFirstPersonLibraryFilters) {
      dispatch(
        fetchPersonaListNext({
          filters: personLibraryFilters,
          continuationToken,
        })
      )
    }
  }, [personLibraryFilters, profile.data.name])
}
