import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../components/custom/Auth/config'

export const useAuth = () => {
  const { instance } = useMsal()

  const logout = async () => {
    try {
      await instance.logoutPopup({
        mainWindowRedirectUri: '/',
      })
    } catch (e) {
      console.error(e)
    }
  }

  const login = async () => {
    try {
      await instance.loginRedirect(loginRequest)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    login,
    logout,
  }
}
