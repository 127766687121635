export default {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: 'inherit',
        cursor: 'pointer',
      },
    },
  },
}
