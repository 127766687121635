import { activeMediaSelector } from '../redux/selectors'
import { useAppSelector } from '../redux/store'
import useMainPageActiveTab from './useMainPageActiveTab'

export const useActiveMedia = () => {
  const state = useAppSelector(activeMediaSelector)

  const { mainActiveTab } = useMainPageActiveTab()

  const mediaId = state ? state[mainActiveTab].mediaId : undefined
  const mediaIndex = state ? state[mainActiveTab].mediaIndex : undefined

  return {
    mediaId,
    mediaIndex,
  }
}
