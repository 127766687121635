type SupportedLang = 'en' | 'ua'

export type Content = {
  [key: string]: Record<SupportedLang | string, string>
}

export const content: Content = {
  title: {
    en: 'Terms of use',
    ua: 'Правила використання',
  },
  line1: {
    en: 'Every second Russia produces hundreds of pieces of propaganda. Disinformation precedes all wars launched by the aggressor country - in Ukraine, Syria and Georgia.',
    ua: 'Щосекунди Росія генерує сотні одиниць пропаганди. Дезінформація передує усім війнам, які розпочинає країна-агресорка — в Україні, Сирії, Грузії.',
  },
  line2: {
    en: 'Information is a tailor-made, professional and effective weapon used by Russia to launch and conduct wars against Ukraine, Georgia, Syria and other countries.',
    ua: 'Інформація є створеною на замовлення професійною та ефективною зброєю у розв’язуванні та веденні війн Росії проти України, Грузії, Сирії та інших країн.',
  },
  line3: {
    en: "We are confident that the study and research of the aggressor country's information in dynamics and retrospect will help to expose the information crimes and wars taking place now and to prevent similar crimes in the future.",
    ua: 'Ми впевнені, що вивчення та дослідження інформації країни-агресорки у динаміці та ретроспективі допоможе викрити інформаційні злочини та війни, що кояться прямо зараз, та запобігти скоєнню аналогічних злочинів у майбутньому.',
  },
  line4: {
    en: 'Careful preservation and archiving of information used in information warfare is a safeguard against rewriting history, oblivion and impunity.',
    ua: 'Ретельне збереження та архівування інформації, яку використано у інформаційній війні, є захистом від переписування історії та запобіжником від забуття й безкарності.',
  },
  line5: {
    en: 'Therefore, we declare the purpose of creating and using this resource to be scientific research and study of the Russian information space, as well as archiving evidence of the Russian information war against Ukraine, and invite users to use the resource for their own scientific, research and investigative projects.',
    ua: 'Тому ми декларуємо за мету створення та використання цього ресурсу – наукові дослідження та вивчення російського інформаційного простору, а також архівування доказів інформаційної війни Росії проти України та запрошуємо користувачів використовувати ресурс для створення своїх наукових, дослідницьких  проєктів та проєктів-розслідувань.',
  },
  line6: {
    en: 'The video materials included in this database have been collected from open sources and, where possible, primary sources of information have been used.',
    ua: 'Відеоматеріали, що їх включено до цієї бази, зібрані із відкритих джерел. Там де це було можливо, було використано першоджерела інформації.',
  },
  line7: {
    en: 'All materials have been preserved in the form in which they appeared in the original sources at the time of upload.',
    ua: 'Всі матеріали збережено у тому вигляді, у якому вони знаходилися у доступі у першоджерелах на момент завантаження.',
  },
  line8: {
    en: 'We have not altered, shortened or added to the material, but we cannot rule out technical errors that may have occurred during uploading.',
    ua: 'Ми не змінювали, не скорочували та не доповнювали матеріали, але не можемо виключити технічних помилок, що могли статися при завантаженні.',
  },
  line9: {
    en: 'The resource is in test mode, so errors do occur, but we are working diligently to eliminate them and will be grateful for reports of such errors through special windows on the site.',
    ua: 'Ресурс працює у тестовому режимі, тому помилки можливі, але ми старанно працюємо над їх усуненням та будемо вдячні за повідомлення про такі помилки через спеціальні віконечка на сайті.',
  },
  line10: {
    en: 'Given the information collection from open sources, using automated transcription and speech recognition and translation, we cannot rule out the possibility of errors or inconsistencies and do not accept any responsibility for such errors.',
    ua: 'Враховуючи збір інформації з відкритих джерел, використання автоматизованого транскрибування, перекладу та розпізванання голосу, ми не можемо виключити наявність помилок або невіповідностей, та не беремо на себе жодної відповідальності за такі помилки.',
  },
  line11: {
    en: 'In the event of any discrepancy, the content of the original video material shall prevail over the content of the material from the resource. If you refer to the material in the resource in your academic or research work, we recommend that you include a similar disclaimer.',
    ua: 'Зміст оригінальних відеоматеріалів, у разі наявності розбіжностей, має перевагу над змістом матеріалів із ресурсу. Ми рекомендуємо при посиланні на матеріали із ресурсу у ваших наукових та дослідницьких  доробках робити аналогічне застереження.',
  },
}
