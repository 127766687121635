import React, { useEffect, useState } from 'react'
import { PROCESSING_REFRESH_TIMER } from '../../../../constants'
import { ProcessStatus } from '../../../../redux/types/mediaProcessing'

export const Timer = ({ status }: { status: ProcessStatus }) => {
  const [timer, setTimer] = useState(PROCESSING_REFRESH_TIMER / 1000)

  const isTrDataLoaded =
    status === ProcessStatus.SUCCESS || status === ProcessStatus.DONE

  useEffect(() => {
    const saved = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0))
    }, 1000)
    return () => {
      clearInterval(saved)
    }
  }, [])

  const minutes = Math.floor(timer / 60)
  const seconds = timer % 60

  return isTrDataLoaded ? (
    <></>
  ) : (
    <>{` - time before auto refresh: ${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`}</>
  )
}
