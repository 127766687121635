import { formatClassName } from '@infopulse-design-system/shared/utils/ui.utils'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import type { Dispatch, MouseEvent } from 'react'
import React, { memo } from 'react'
import { UnknownSpeakerData } from '../../../../../constants'
import { PERMISSIONS } from '../../../../../constants/enums'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { usePermission } from '../../../../../hooks/usePermission'
import { useSimilarEmbeddingsNum } from '../../../../../hooks/useSimilarEmbeddingsNum'
import { dictionariesSelector } from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import { DICTIONARY_ADDITION } from '../../../../../redux/types/dictionaries'
import type {
  FootnoteConfigType,
  MediaData,
} from '../../../../../redux/types/media'
import {
  formatSecondsToTime,
  getNameFromDictionaryByLang,
} from '../../../../../utils'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../../ipDesignSystemComponents/atoms/IpText'
import PermissionsGate from '../../../PermissionsGate'
import type { PersonaInfoIconState } from '../../../persona'
import { PersonaInfoIcon } from '../../../persona'
import { TrFootnote } from '../TrFootnote'

import './index.scss'

const endIcon = (
  <IpIcon fontSize="20px" color="primary">
    <ExpandMore aria-label="Expanded icon" />
  </IpIcon>
)

export type TranscriptionProps = {
  active: boolean
  time: number
  text: string
  personaId?: string | null
  speakerId: string
  speakers: MediaData['speakers']
  highlights?: string[]
  footnotes?: FootnoteConfigType[]
  onReportBugIconClick: (event: MouseEvent<HTMLElement>) => void
  setSelectSpeakerAnchorEl: Dispatch<HTMLElement | null>
  setPersonaInfoIconState: Dispatch<PersonaInfoIconState>
}

export const Transcription = memo(function TranscriptionComponent(
  props: TranscriptionProps
) {
  const {
    time,
    text,
    active,
    personaId,
    speakerId,
    speakers,
    highlights,
    footnotes,
    onReportBugIconClick,
    setSelectSpeakerAnchorEl,
    setPersonaInfoIconState,
  } = props
  const isUnknownSpeaker = personaId === UnknownSpeakerData.id
  const { suffix } = useLanguage()
  const { getIsPermission } = usePermission()
  const hasPermission = getIsPermission(PERMISSIONS.speaker_annotation)
  const { data: dictionary } = useAppSelector(dictionariesSelector)
  const { similarEmbeddingsNum } = useSimilarEmbeddingsNum(
    speakers,
    speakerId,
    isUnknownSpeaker && !hasPermission
  )

  const similarEmbeddingsNumNode =
    !isUnknownSpeaker && typeof similarEmbeddingsNum === 'number' ? (
      <PermissionsGate permissionKey={PERMISSIONS.speaker_annotation}>
        <span className="similar-embeddings-number">
          ({similarEmbeddingsNum})
        </span>
      </PermissionsGate>
    ) : (
      ''
    )

  const personaName = isUnknownSpeaker
    ? UnknownSpeakerData.name
    : getNameFromDictionaryByLang({
        suffix,
        dictionary,
        key: DICTIONARY_ADDITION.personas,
        id: personaId,
      })

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setSelectSpeakerAnchorEl(event.currentTarget)
  }

  const onPersonaIconClick = (event: MouseEvent<HTMLElement>) => {
    setPersonaInfoIconState({
      anchor: event.currentTarget,
      id: personaId ?? '',
    })
  }

  const customClasses = formatClassName(
    active ? 'transcription_active' : '',
    'transcription_details'
  )

  return (
    <IpText variant="body1" classes={customClasses}>
      <span className="transcription_time">{formatSecondsToTime(time)}</span>
      {personaId ? (
        <span className="transcription_persona">
          {' - '}
          {personaName}
          {similarEmbeddingsNumNode}
          {!isUnknownSpeaker && (
            <PersonaInfoIcon onClick={onPersonaIconClick} />
          )}
        </span>
      ) : speakerId ? (
        <span onClick={handleClick} className="transcription-icon_anchor">
          <span className="transcription_speaker">
            {speakerId}
            {similarEmbeddingsNumNode}
          </span>
          <PermissionsGate permissionKey={PERMISSIONS.speaker_annotation}>
            {endIcon}
          </PermissionsGate>
        </span>
      ) : (
        ' -  '
      )}
      <TrFootnote text={text} footnotes={footnotes} highlights={highlights} />
      <PermissionsGate permissionKey={PERMISSIONS.transcription_context_menu}>
        <IpIcon
          onClick={onReportBugIconClick}
          color="primary"
          classes="report-icon transcription-icon_anchor"
        >
          <MoreHorizIcon />
        </IpIcon>
      </PermissionsGate>
    </IpText>
  )
})
