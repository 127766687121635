import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpLink } from '../../../ipDesignSystemComponents/atoms/IpLink'
import type { IpTooltipPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  to: string
  placement: IpTooltipPropTypesReact['placement']
  openInNewTab?: boolean
}

export const FullScreenButton = ({ to, placement, openInNewTab }: Props) => {
  return (
    <IpTooltip
      arrow
      title={`Full screen${openInNewTab ? ' opens in new tab' : ''}`}
      placement={placement}
      content={
        <IpLink to={to} target={openInNewTab ? '_blank' : undefined}>
          <IpIcon color="primary">
            <OpenInFullIcon />
          </IpIcon>
        </IpLink>
      }
    />
  )
}
