import type { ChangeEvent } from 'react'
import React, { useCallback } from 'react'
import useFilters from '../../../../../../hooks/useFilters'
import { useLanguage } from '../../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../../redux/selectors'
import { updateFilters } from '../../../../../../redux/slices/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store'
import type { IpRadioGroupPropTypesReact } from '../../../../../ipDesignSystemComponents/atoms/IpRadioGroup'
import { IpRadioGroup } from '../../../../../ipDesignSystemComponents/atoms/IpRadioGroup'
import { IpText } from '../../../../../ipDesignSystemComponents/atoms/IpText'

export const FilterSearchModel = () => {
  const { data } = useAppSelector(dictionariesSelector)
  const { models } = data
  const { suffix } = useLanguage()
  const dispatch = useAppDispatch()
  const { filters, filterType } = useFilters()

  const radioButtons: IpRadioGroupPropTypesReact['radioButtons'] = models.map(
    (item) => ({
      value: item.id,
      label: item[`name${suffix}`] ?? '',
    })
  )

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateFilters({
          filterType,
          type: 'search_model',
          value: e.target.value,
        })
      )
    },
    [filterType]
  )

  return (
    <>
      <IpText variant="subtitle3" color="primary" classes="search-models-label">
        Models
      </IpText>
      <IpRadioGroup
        size="20px"
        ariaLabel="models"
        radioButtons={radioButtons}
        onChange={onChange}
        value={filters.search_model}
      />
    </>
  )
}
