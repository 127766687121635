import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import type { MouseEvent } from 'react'
import React from 'react'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'

type Props = {
  onClick: (event: MouseEvent<HTMLElement>) => void
}

export const PersonaInfoIcon = ({ onClick }: Props) => {
  return (
    <IpIcon
      classes="persona-info-icon transcription-icon_anchor"
      fontSize="15px"
      onClick={onClick}
    >
      <InfoOutlinedIcon />
    </IpIcon>
  )
}
