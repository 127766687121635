import type { ReactNode } from 'react'
import React, { useContext } from 'react'
import CardActions from '@mui/material/CardActions'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { IpCardActionsPropTypes } from '@infopulse-design-system/shared/types/IpCard.types'
import '@infopulse-design-system/shared/theme/components/IpCard/IpCardActions/styles.scss'
import { CardContext } from '..'
import { IpSkeleton } from '../../IpSkeleton'

export const IpCardActionsTestId = 'IpCardActionsTestId'

export type IpCardActionsPropTypesReact = IpCardActionsPropTypes & {
  children: ReactNode
}

export const IpCardActions = (props: IpCardActionsPropTypesReact) => {
  const { classes, children, disableSpacing } = props
  const { isLoading, skeletonAnimation } = useContext(CardContext)

  const customClasses = formatClassName(
    generateClasses('card-actions', 'react'),
    classes
  )

  return (
    <CardActions
      className={customClasses}
      data-testid={IpCardActionsTestId}
      disableSpacing={disableSpacing}
    >
      {isLoading ? (
        <IpSkeleton
          animation={skeletonAnimation}
          variant="text"
          width="33%"
        ></IpSkeleton>
      ) : (
        children
      )}
    </CardActions>
  )
}
