import type { TagPropTypes } from '.'
import {
  MAX_FILTER_PERCENTAGE,
  MAX_MEDIA_LENGTH,
  MAX_MEDIA_NUM,
  MAX_SIMILAR_EMBEDDING_NUM,
  WEEK_SHORT_DAYS,
} from '../../../constants'
import type {
  DictionariesData,
  DictionaryCommonName,
  DictionaryContent,
  Language,
} from '../../../redux/types/dictionaries'
import {
  DICTIONARY,
  DICTIONARY_ADDITION,
  DICTIONARY_CUSTOM,
} from '../../../redux/types/dictionaries'
import type {
  DateFilters,
  FiltersState,
  FilterType,
} from '../../../redux/types/filters'
import { getNameFromDictionaryByLang } from '../../../utils'

const getDataTag = (
  type: DateFilters,
  date_to: FiltersState['date_to'],
  date_from: FiltersState['date_from'],
  weekdays: FiltersState['weekdays'] = []
): TagPropTypes => {
  const daysData: string | undefined = weekdays
    .map((day) => WEEK_SHORT_DAYS[Number(day)])
    .join(', ')

  const label = `${date_from} - ${date_to}${daysData ? `, ${daysData}` : ''}`

  return {
    label,
    type,
  }
}

const getLengthTag = (start?: number, end?: number): TagPropTypes => {
  const startStr = start || ''
  const endStr = end || ''
  const divider = start && end ? '-' : ''
  const label = `${startStr}${divider}${endStr} min`

  return {
    label,
    type: 'media_length',
  }
}

const getTagById = (
  dictionary: DictionariesData,
  id: DictionaryContent['id'],
  type: DictionaryCommonName | DICTIONARY_CUSTOM.models,
  suffix: Language['attribute_suffix']
): TagPropTypes | undefined => {
  const label = getNameFromDictionaryByLang({
    suffix,
    dictionary,
    key: type,
    id,
  })

  return label
    ? {
        label,
        type,
        id,
      }
    : undefined
}

const getUserTags = (
  userTagsArr: FiltersState['user_tags'],
  id: string
): TagPropTypes | undefined => {
  const label = userTagsArr?.find((key) => key === id)

  return label
    ? {
        label,
        type: 'user_tags',
        id,
      }
    : undefined
}

export const getChipDataFromFilters = (
  filters: FiltersState,
  filterType: FilterType,
  dictionary: DictionariesData,
  userTagsArr: FiltersState['user_tags'],
  suffix: Language['attribute_suffix']
): TagPropTypes[] => {
  const {
    air_date_from,
    air_date_to,
    authors,
    date_from,
    date_to,
    media_length_end,
    media_type,
    personas,
    program_title,
    programs,
    search,
    search_model,
    sources,
    media_length_start,
    submit_date_from,
    submit_date_to,
    signs_of_crimes,
    user_tags,
    weekdays,
    percentage_start,
    percentage_end,
    similar_embeddings_num_start,
    similar_embeddings_num_end,
    media_num_end,
    media_num_start,
    title,
    telegram_channels,
    persona_category,
    networks,
  } = filters
  const arr: TagPropTypes[] = []

  if (
    percentage_start ||
    (percentage_end && percentage_end !== MAX_FILTER_PERCENTAGE)
  ) {
    arr.push({
      label: `${percentage_start}-${percentage_end} %`,
      type: 'percentage',
    })
  }
  if (
    similar_embeddings_num_start ||
    (similar_embeddings_num_end &&
      similar_embeddings_num_end !== MAX_SIMILAR_EMBEDDING_NUM)
  ) {
    arr.push({
      label: `${similar_embeddings_num_start}-${similar_embeddings_num_end}`,
      type: 'similar_embeddings_num',
    })
  }
  if (media_num_start || (media_num_end && media_num_end !== MAX_MEDIA_NUM)) {
    arr.push({
      label: `Media score: ${media_num_start}-${media_num_end}`,
      type: 'media_num',
    })
  }
  if (search) {
    arr.push({ label: search, type: 'search' })
  }
  if (title) {
    arr.push({ label: title, type: 'title' })
  }
  if (program_title) {
    arr.push({ label: program_title, type: 'program_title' })
  }
  if (filterType !== 'personLibrary' && date_from && date_to) {
    arr.push(getDataTag('date', date_to, date_from, weekdays))
  }
  if (air_date_to && air_date_from) {
    arr.push(getDataTag('air_date', air_date_to, air_date_from, []))
  }
  if (submit_date_to && submit_date_from) {
    arr.push(getDataTag('submit_date', submit_date_to, submit_date_from, []))
  }
  if (
    media_length_start ||
    (media_length_end && media_length_end !== MAX_MEDIA_LENGTH)
  ) {
    arr.push(getLengthTag(media_length_start, media_length_end))
  }

  if (media_type?.length) {
    media_type.forEach((id) => {
      const config = getTagById(dictionary, id, DICTIONARY.media_type, suffix)
      if (config) arr.push(config)
    })
  }
  if (sources?.length) {
    sources.forEach((id) => {
      const config = getTagById(dictionary, id, DICTIONARY.sources, suffix)
      if (config) arr.push(config)
    })
  }
  if (personas?.length) {
    personas.forEach((id) => {
      const config = getTagById(
        dictionary,
        id,
        DICTIONARY_ADDITION.personas,
        suffix
      )
      if (config) arr.push(config)
    })
  }
  if (authors?.length) {
    authors.forEach((id) => {
      const config = getTagById(dictionary, id, DICTIONARY.authors, suffix)
      if (config) arr.push(config)
    })
  }
  if (programs?.length) {
    programs.forEach((id) => {
      const config = getTagById(dictionary, id, DICTIONARY.programs, suffix)
      if (config) arr.push(config)
    })
  }
  if (networks?.length) {
    networks.forEach((id) => {
      const config = getTagById(dictionary, id, DICTIONARY.networks, suffix)
      if (config) arr.push(config)
    })
  }
  if (telegram_channels?.length) {
    telegram_channels.forEach((id) => {
      const config = getTagById(
        dictionary,
        id,
        DICTIONARY_CUSTOM.telegram_channels,
        suffix
      )
      if (config) arr.push(config)
    })
  }
  if (search_model) {
    const config = getTagById(
      dictionary,
      search_model,
      DICTIONARY_CUSTOM.models,
      suffix
    )
    if (config) arr.push(config)
  }
  if (signs_of_crimes?.length) {
    signs_of_crimes.forEach((id) => {
      const config = getTagById(
        dictionary,
        id,
        DICTIONARY.signs_of_crimes,
        suffix
      )
      if (config) arr.push(config)
    })
  }
  if (user_tags?.length) {
    user_tags.forEach((id) => {
      const config = getUserTags(userTagsArr, id)
      if (config) arr.push(config)
    })
  }
  if (persona_category?.length) {
    persona_category.forEach((id) => {
      const config = getTagById(
        dictionary,
        id,
        DICTIONARY.persona_category,
        suffix
      )
      if (config) arr.push(config)
    })
  }

  return arr
}
