import React from 'react'
import type { ReactNode, SyntheticEvent, MouseEventHandler } from 'react'
import { styled } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import type {
  IpNotificationPropTypes,
  IpNotificationAlignTypes,
} from '@infopulse-design-system/shared/types/IpNotification.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpNotification/styles.scss'

export type IpNotificationPropTypesReact = IpNotificationPropTypes<
  ReactNode,
  SyntheticEvent
> & {
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

const IpAlertStyled = styled(Alert)(
  ({ align }: { align: IpNotificationAlignTypes }) => {
    return {
      '& .MuiAlert-message': {
        flexGrow: 1,
        textAlign: align,
      },
    }
  }
)

/**
 * `IpNotification` effectively presents a brief yet crucial message in a manner that captures the user's
 * attention without causing disruption to their ongoing task. The primary purpose of `IpNotification` is
 * to convey system-related messages. `IpNotificationBar` is used for positioning of `IpNotification`.
 *
 * Hook `useNotification` can be used as a state manager.
 * It gives the opportunity to control the behaviour and positioning of messages.
 */

export function IpNotification(props: IpNotificationPropTypesReact) {
  const {
    align = 'left',
    classes,
    icon,
    message,
    onClose,
    severity,
    variant,
    onMouseEnter,
    onMouseLeave,
  } = props

  const customClasses = formatClassName(
    generateClasses('notification', 'react'),
    classes
  )

  return (
    <IpAlertStyled
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      align={align}
      className={customClasses}
      color={severity}
      icon={icon}
      severity={severity}
      variant={variant}
      onClose={onClose}
    >
      {message}
    </IpAlertStyled>
  )
}
