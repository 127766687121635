/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PERMISSIONS, ROLES } from '../../constants/enums'
import { QueryTypes } from '../../constants/enums'
import { fetchUserData } from '../thunk/userThunk'
import type { PermissionsPayloadContent } from '../types/dictionaries'

export type Role = keyof typeof ROLES
export type PermissionList = keyof typeof PERMISSIONS
export type Permissions = Partial<Record<PermissionList, Role[]>>
export type QueryType = keyof typeof QueryTypes

export type ProfileState = {
  data: {
    name?: string
    queryType?: QueryType
    roles: Role[]
    userTags: string[]
    permissions?: Permissions
  }
  isLoading: boolean
  error: string
}

export const initialProfileState: ProfileState = {
  data: {
    roles: [],
    userTags: [],
  },
  isLoading: false,
  error: '',
}

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {
    setRoles: (state, { payload }: PayloadAction<Role[]>) => {
      state.data.roles = payload
    },
    setUserInfo: (
      state,
      { payload }: PayloadAction<{ name: string; queryType: QueryType }>
    ) => {
      state.data.name = payload.name
      state.data.queryType = payload.queryType || QueryTypes.full
      state.isLoading = false
    },
    setPermissions: (
      state,
      { payload }: PayloadAction<PermissionsPayloadContent>
    ) => {
      state.data.permissions = payload.data.reduce(
        (acc: Permissions, curr: PermissionsPayloadContent['data']['0']) => {
          acc[curr.id] = curr.roles
          return acc
        },
        {} as Permissions
      )
    },
    updateUserTags: (state, { payload }: PayloadAction<string[]>) => {
      state.data.userTags = payload
    },
    updateQueryType: (state, { payload }: PayloadAction<QueryType>) => {
      state.data.queryType = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  setRoles,
  setUserInfo,
  updateUserTags,
  setPermissions,
  updateQueryType,
} = ProfileSlice.actions

export default ProfileSlice.reducer
