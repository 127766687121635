import type { Theme } from '@mui/material'
import tooltipStyles from '@infopulse-design-system/shared/theme/components/IpTooltip'

export default (theme: Theme) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          '.MuiTooltip-tooltip': {
            backgroundColor: theme.palette.grey['900'],
            font: tooltipStyles.tooltip_font,
            borderRadius: tooltipStyles.tooltip_border_radius,
            '& :before': {
              backgroundColor: theme.palette.grey['900'],
            },
          },
          '.MuiTooltip-tooltipArrow': {
            borderRadius: tooltipStyles.tooltip_border_radius_arrow,
          },
          '.MuiTooltip-tooltipPlacementLeft>.MuiTooltip-arrow': {
            marginRight: tooltipStyles.tooltip_arrow_margin_right,
          },
        },
      },
    },
  }
}
