import React from 'react'
import type { ReactNode } from 'react'
import Collapse from '@mui/material/Collapse'
import type { IpCollapsePropTypes } from '@infopulse-design-system/shared/types/IpCollapse.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpCollapse/styles.scss'

export const IpCollapseTestId = 'IpCollapseTestId'

export type IpCollapsePropTypesReact = IpCollapsePropTypes & {
  children: ReactNode
}

export function IpCollapse(props: IpCollapsePropTypesReact) {
  const { children, classes, collapsedSize, inside, orientation, timeout } =
    props

  const customClasses = formatClassName(
    generateClasses('collapse', 'react'),
    classes
  )

  return (
    <Collapse
      className={customClasses}
      collapsedSize={collapsedSize}
      data-testid={IpCollapseTestId}
      in={inside}
      orientation={orientation}
      timeout={timeout}
      unmountOnExit
    >
      {children}
    </Collapse>
  )
}
