import color from '@infopulse-design-system/shared/theme/colors'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { usePageName } from '../../../hooks/usePageName'
import { usePermission } from '../../../hooks/usePermission'
import { useSimilarSpeakerList } from '../../../hooks/useSimilarSpeakerList'
import { profileNameSelector } from '../../../redux/selectors'
import { useAppSelector } from '../../../redux/store'
import { IpAvatar } from '../../ipDesignSystemComponents/atoms/IpAvatar'
import { IpIcon } from '../../ipDesignSystemComponents/atoms/IpIcon'
import { FilterTags } from '../FilterTags'
import { Logo } from '../icons/Logo'
import { ROUTER_PATHS } from '../Router'
import './index.scss'
import { LanguageSwitcher } from './LanguageSwitcher'
import { ProfilePopover } from './ProfilePopover'
import { QueryTypeSwitcher } from './QueryTypeSwitcher'
import { ResetFilters } from './ResetFilters'
import { Search } from './Search'

export const Header = () => {
  const { isAnonymous } = usePermission()
  const profileName = useAppSelector(profileNameSelector)
  const { isSimilarSpeakerPage } = useSimilarSpeakerList()
  const {
    isAdminPage,
    isDashboardPage,
    isDetailsPage,
    isMainPage,
    isMediaProcessingPage,
    isPersonaPage,
    isAboutUsPage,
    isTermsOfUsePage,
  } = usePageName()
  const isQueryTypeSwitcher = isMainPage || isDashboardPage
  const isHeaderFiltersContent =
    !isSimilarSpeakerPage &&
    !isMediaProcessingPage &&
    !isAdminPage &&
    !isAboutUsPage &&
    !isTermsOfUsePage

  const [profileIconAnchor, setProfileIconAnchor] =
    useState<HTMLElement | null>(null)

  return (
    <header
      className={`header ${
        isMainPage || isDashboardPage ? 'header_main-page' : ''
      }`}
    >
      <Link to={ROUTER_PATHS.root} className="header-logo">
        <IpIcon ariaLabel="Search icon" width="40px" height="40px">
          <Logo />
        </IpIcon>
      </Link>
      {isHeaderFiltersContent && (
        <div className="header__main-content">
          {!isDetailsPage && !isPersonaPage && (
            <div className="header__main-content-top-line">
              {!isDashboardPage && <Search />}
              <ResetFilters />
              <FilterTags />
            </div>
          )}
          {isQueryTypeSwitcher && (
            <div className="header__main-content-bottom-line">
              <QueryTypeSwitcher />
            </div>
          )}
        </div>
      )}
      <div className="header_right-side-wrapper">
        <LanguageSwitcher />
        <IpIcon
          onClick={(e) => {
            setProfileIconAnchor(e.currentTarget)
          }}
          color="primary"
          fontSize="36px"
        >
          {isAnonymous ? (
            <AccountCircleIcon />
          ) : (
            <IpAvatar
              sizes="36px"
              avatarsData={[{ name: profileName, color: color.primary.main }]}
            ></IpAvatar>
          )}
        </IpIcon>
      </div>
      <ProfilePopover
        anchorEl={profileIconAnchor}
        setAnchorEl={setProfileIconAnchor}
        open={Boolean(profileIconAnchor)}
      />
    </header>
  )
}
