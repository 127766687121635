/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  addToReportBasket,
  fetchReportBasket,
} from '../thunk/reportBasketThunk'
import type { BasketItem } from '../types/basket'

type ReportBasket = {
  data: BasketItem[]
  hasMoreResults: boolean
  continuationToken?: string
}

export interface ReportBasketState {
  data: ReportBasket
  isLoading: boolean
  error: string
}

export const initialReportBasketState: ReportBasketState = {
  data: { data: [], hasMoreResults: false },
  isLoading: false,
  error: '',
}

export const ReportBasketSlice = createSlice({
  name: 'reportBasket',
  initialState: initialReportBasketState,
  reducers: {
    setDataIntoBasket: (
      state,
      { payload }: PayloadAction<ReportBasketState['data']>
    ) => {
      state.data = payload
    },
    moveToBasket: (state, { payload }: PayloadAction<BasketItem>) => {
      state.data.data.push(payload)
    },
    deleteReports: (state, { payload }: PayloadAction<Array<string>>) => {
      state.data.data = state.data.data.filter(
        (item) => !payload.includes(item.id)
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportBasket.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchReportBasket.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchReportBasket.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
    builder.addCase(addToReportBasket.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addToReportBasket.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(addToReportBasket.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const { setDataIntoBasket, moveToBasket, deleteReports } =
  ReportBasketSlice.actions

export default ReportBasketSlice.reducer
