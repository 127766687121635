import styles from '@infopulse-design-system/shared/theme/components/IpList/IpListItemIcon'

export default {
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: styles.list_item_icon_min_width,
        '& > *': {
          padding: styles.list_item_icon_padding,
        },
      },
    },
  },
}
