import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpMenuItem'

export default (theme: Theme) => {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: styles.list_item_min_height,
          '&:hover, &:active, &.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .Mui-focusVisible .MuiTouchRipple-root, & .MuiButtonBase-root.Mui-focusVisible .MuiTouchRipple-root':
            {
              color: theme.palette.primary.dark,
            },
          '& .MuiTouchRipple-root': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
  }
}
