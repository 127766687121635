import React, { useEffect, useState } from 'react'
import {
  BasketActionsPanel,
  BasketResultPanel,
} from '../../components/custom/basket'
import { FiltersPanel } from '../../components/custom/FiltersPanel'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import useFilters from '../../hooks/useFilters'
import { profileSelector, reportBasketSelector } from '../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { fetchReportBasket } from '../../redux/thunk/reportBasketThunk'

import './index.scss'

function Basket() {
  const dispatch = useAppDispatch()
  const { filters, isBasketType } = useFilters()
  const profile = useAppSelector(profileSelector)
  const basketState = useAppSelector(reportBasketSelector)
  const [activeItems, setActiveItems] = useState<string[]>([])
  const [isSelectedAll, setIsSelectedAll] = useState(false)

  const basketItems = basketState.data.data
  const isBasketActionsVisible = !!basketItems.length

  const selectAllHandler = (value: string) => {
    setIsSelectedAll((prev) => !prev)
    if (value !== 'on') {
      const allBasketItemsIds = basketItems.map((item) => item.id)
      setActiveItems(allBasketItemsIds)
    } else {
      setActiveItems([])
    }
  }

  useEffect(() => {
    if (isBasketType && profile.data.name) dispatch(fetchReportBasket(filters))
  }, [filters, isBasketType, profile.data.name])

  return (
    <div className="page basket">
      <FiltersPanel classes="basket-filter" />
      <div className="basket-result">
        <div id="InfiniteScroll">
          <IpText
            color="primary"
            variant="h2"
            classes="main-subtitle main-subtitle_result semi-bold"
          >
            Reporting basket
          </IpText>
          {isBasketActionsVisible ? (
            <BasketActionsPanel
              selectAllHandler={selectAllHandler}
              isSelectedAll={isSelectedAll}
              activeItems={activeItems}
            />
          ) : (
            ''
          )}
          <BasketResultPanel
            basketState={basketState}
            activeItems={activeItems}
            setActiveItems={setActiveItems}
            setIsSelectedAll={setIsSelectedAll}
          />
        </div>
      </div>
    </div>
  )
}

export default Basket
