import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react'
import type {
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  ElementType,
  JSXElementConstructor,
  KeyboardEvent,
} from 'react'
import type { NavLinkProps } from 'react-router-dom'
import type { IpMenuVerticalPropTypes } from '@infopulse-design-system/shared/types/IpMenuVertical.types'
import type { IpMenuButtonBaseTypes } from '@infopulse-design-system/shared/types/IpMenuBase.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import {
  ClickAwayListener,
  MenuList,
  Popper,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material'
import type { Theme } from '@mui/material'
import type { TransitionProps as MuiTransitionProps } from '@mui/material/transitions'
import menuListStyles from '@infopulse-design-system/shared/theme/components/IpMenuList'
import type { IpMenuItemPropTypesReact } from '../../core/IpMenuItem/index.types'
import { getCustomTransitionComponent } from '../../../../utils'
import { IpMenuItem } from '../../core/IpMenuItem'
import { IpMenuList, onMenuItemKeyDownHelper } from '../../core/IpMenuList'
import '@infopulse-design-system/shared/theme/components/IpMenuVertical/styles.scss'

export const IpMenuVerticalListClass = 'IpMenuVerticalList'
export const IpMenuVerticalTestId = 'IpMenuVerticalTestId'

export type IpMenuVerticalPropTypesReact = IpMenuVerticalPropTypes<
  ReactNode,
  Event | SyntheticEvent,
  KeyboardEvent<HTMLElement>,
  ElementType,
  NavLinkProps['to'],
  IpMenuItemPropTypesReact
>

type IpMenuVerticalButtonsConfigPropTypesReact = IpMenuButtonBaseTypes<
  ReactNode,
  Event | SyntheticEvent,
  KeyboardEvent<HTMLElement>,
  ElementType,
  NavLinkProps['to'],
  IpMenuItemPropTypesReact
>

type IpMenuStyledPropTypes = {
  activecolor?: IpMenuVerticalPropTypesReact['activeColor']
  bgactive?: IpMenuVerticalPropTypesReact['bgActiveColor']
  bg?: IpMenuVerticalPropTypesReact['bgColor']
  color?: IpMenuVerticalPropTypesReact['color']
  minwidth?: IpMenuVerticalPropTypesReact['minWidth']
  maxwidth?: IpMenuVerticalPropTypesReact['maxWidth']
}

type PopperWithTransitionPropTypes = Required<
  Pick<
    IpMenuVerticalPropTypesReact,
    'open' | 'placement' | 'transitionDuration' | 'transitionType'
  >
> &
  Pick<IpMenuVerticalPropTypesReact, 'anchorEl' | 'maxHeight' | 'classes'> & {
    children: ReactNode
  }

type MenuContentPropTypes = {
  onClose: (event: Event | SyntheticEvent | KeyboardEvent<HTMLElement>) => void
} & Pick<
  IpMenuVerticalPropTypesReact,
  'buttonsConfig' | 'classes' | 'divider' | 'onClickAway'
> &
  (
    | { submenuPlacement: 'left' | 'right' }
    | ({ submenuPlacement: 'collapsible' } & Required<
        Pick<IpMenuVerticalPropTypesReact, 'open'>
      >)
  )

type MenuContentItemPropTypes = IpMenuVerticalButtonsConfigPropTypesReact & {
  openedElem: HTMLElement | null
  setOpenedElem: Dispatch<SetStateAction<HTMLElement | null>>
  submenuPlacement: 'left' | 'right'
} & {
  onKeyDown: (
    event: KeyboardEvent<HTMLElement>,
    index: number,
    state: boolean
  ) => void
  isFocused: boolean
  index: number
}

type ContextValue = Required<
  Pick<
    IpMenuVerticalPropTypesReact,
    'transitionDuration' | 'transitionType' | 'endIcon'
  >
> &
  Pick<
    IpMenuVerticalPropTypesReact,
    | 'activeColor'
    | 'bgActiveColor'
    | 'bgColor'
    | 'color'
    | 'dense'
    | 'maxHeight'
    | 'minWidth'
    | 'maxWidth'
  >

const startValue: ContextValue = {
  transitionDuration: 400,
  transitionType: 'fade',
  endIcon: <ExpandMore color="primary" />,
}

const IpVerticalMenuContext = createContext<ContextValue>(startValue)

const StyledMenu = styled(MenuList)(
  ({
    activecolor,
    bgactive,
    bg,
    color,
    minwidth: minWidth,
    maxwidth: maxWidth,
  }: IpMenuStyledPropTypes) => ({
    [`&.${IpMenuVerticalListClass}`]: {
      '& .MuiMenuItem-root': {
        color,
        backgroundColor: bg,
        minWidth,
        maxWidth,
        '&:hover, &.Mui-focusVisible': {
          color: activecolor,
          backgroundColor: bgactive,
        },
      },
    },
  })
)

const StyledPopper = styled(Popper)(
  ({ maxheight: maxHeight }: { maxheight?: string }) => ({
    maxHeight,
    overflowY: 'auto',
    boxShadow: menuListStyles.menu_list_box_shadow,
  })
)

function PopperWithTransition(props: PopperWithTransitionPropTypes) {
  const {
    anchorEl,
    children,
    open,
    placement,
    transitionDuration,
    transitionType,
    classes,
    maxHeight,
  } = props

  const TransitionComponent = getCustomTransitionComponent(
    transitionType
  ) as JSXElementConstructor<MuiTransitionProps>

  const customClasses = formatClassName(
    generateClasses('menu-vertical', 'react'),
    classes
  )

  return (
    <StyledPopper
      anchorEl={anchorEl}
      className={customClasses}
      data-testid={IpMenuVerticalTestId}
      maxheight={maxHeight}
      open={open}
      placement={placement}
      role={undefined}
      transition
      sx={{ zIndex: (theme: Theme) => theme.zIndex.modal }}
    >
      {({ TransitionProps }) => (
        <TransitionComponent
          {...TransitionProps}
          in={open}
          timeout={{
            enter: transitionDuration,
            exit: 0,
          }}
          unmountOnExit
          style={{
            transformOrigin: 'left top',
          }}
        >
          {children}
        </TransitionComponent>
      )}
    </StyledPopper>
  )
}

function MenuContentItem(props: MenuContentItemPropTypes) {
  const {
    classes,
    disabled,
    divider,
    href,
    icon,
    inset,
    isLinkSafe,
    onClick,
    open,
    openedElem,
    setOpenedElem,
    subMenuContent = [],
    target,
    text,
    submenuPlacement,
    onKeyDown,
    isFocused,
    index,
    ...overrideProps
  } = props

  const { transitionDuration, transitionType, endIcon, maxHeight } = useContext(
    IpVerticalMenuContext
  )

  const buttonRef = useRef(null)

  useEffect(() => {
    if (open) {
      setOpenedElem(buttonRef.current)
    }
  }, [])

  const clickHandler = (event: Event | SyntheticEvent): void => {
    if (event.currentTarget !== openedElem) {
      setOpenedElem(buttonRef.current)
    } else {
      setOpenedElem(null)
    }
    if (onClick) onClick(event)
    event.stopPropagation()
  }

  const isSubMenuContent = subMenuContent.length > 0
  const isOpen = !!openedElem && openedElem === buttonRef.current
  const customClasses = formatClassName(
    classes,
    isOpen && isSubMenuContent ? 'open' : ''
  )

  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    onKeyDown(event, index, isOpen)
  }

  const onCloseHandler = (
    event: Event | SyntheticEvent | KeyboardEvent<HTMLElement>
  ) => {
    openedElem?.click()
    openedElem?.focus()
    event.preventDefault()
  }

  const renderIpMenuItem = (others: IpMenuItemPropTypesReact) => {
    return (
      <IpMenuItem
        autoFocus={isFocused}
        onKeyDown={onKeyDownHandler}
        classes={customClasses}
        disabled={disabled}
        divider={divider}
        href={href}
        isLinkSafe={isLinkSafe}
        onClick={clickHandler}
        ref={buttonRef}
        target={target}
        {...others}
      >
        {icon && <ListItemAvatar>{icon}</ListItemAvatar>}
        {text && <ListItemText inset={inset}>{text}</ListItemText>}
        {isSubMenuContent && endIcon && (
          <div className="IpList-endIcon">{endIcon}</div>
        )}
      </IpMenuItem>
    )
  }

  return (
    <>
      {renderIpMenuItem(overrideProps)}
      {isSubMenuContent && (
        <PopperWithTransition
          anchorEl={buttonRef.current ?? undefined}
          open={isOpen}
          placement={
            submenuPlacement === 'right' ? 'right-start' : 'left-start'
          }
          transitionDuration={transitionDuration}
          transitionType={transitionType}
          maxHeight={maxHeight}
        >
          <div className="IpMenuItem-subMenu">
            {
              // eslint-disable-next-line no-use-before-define
              <MenuContent
                buttonsConfig={subMenuContent}
                submenuPlacement={submenuPlacement}
                onClose={onCloseHandler}
              />
            }
          </div>
        </PopperWithTransition>
      )}
    </>
  )
}

function MenuContent(props: MenuContentPropTypes) {
  const { buttonsConfig, divider, submenuPlacement, onClickAway, onClose } =
    props

  const {
    transitionDuration,
    endIcon,
    activeColor,
    bgActiveColor,
    bgColor,
    color,
    dense,
    maxHeight,
    minWidth,
    maxWidth,
  } = useContext(IpVerticalMenuContext)

  const [openedElem, setOpenedElem] = useState<HTMLElement | null>(null)

  const onClickAwayHandler = (event: Event | SyntheticEvent) => {
    if (openedElem) setOpenedElem(null)
    if (onClickAway) onClickAway(event)
  }

  const [focusedIndex, setFocusedIndex] = useState<null | number>(0)

  const onKeyDownHandler = (
    event: KeyboardEvent<HTMLElement>,
    index: number,
    isOpen: boolean
  ) => {
    onMenuItemKeyDownHelper({
      event,
      index,
      isOpen,
      itemsLength: buttonsConfig.length,
      dispatchFocus: setFocusedIndex,
      dispatchElem: setOpenedElem,
      onClose,
    })
  }

  if (submenuPlacement !== 'collapsible') {
    return (
      <ClickAwayListener onClickAway={onClickAwayHandler}>
        <StyledMenu
          // @ts-ignore
          activecolor={activeColor}
          bgactive={bgActiveColor}
          bg={bgColor}
          className={IpMenuVerticalListClass}
          color={color}
          dense={dense}
          maxheight={maxHeight}
          minwidth={minWidth}
          maxwidth={maxWidth}
          onScroll={onClickAwayHandler}
        >
          {buttonsConfig.map((item, i) => (
            <MenuContentItem
              {...item}
              key={i}
              index={i}
              isFocused={focusedIndex === i}
              openedElem={openedElem}
              setOpenedElem={setOpenedElem}
              submenuPlacement={submenuPlacement}
              onKeyDown={onKeyDownHandler}
            />
          ))}
        </StyledMenu>
      </ClickAwayListener>
    )
  }

  return (
    <ClickAwayListener onClickAway={onClickAwayHandler}>
      <IpMenuList
        classes={IpMenuVerticalListClass}
        buttonsConfig={buttonsConfig}
        activeColor={activeColor}
        color={color}
        bgActiveColor={bgActiveColor}
        bgColor={bgColor}
        dense={dense}
        divider={divider}
        endIcon={endIcon}
        minWidth={minWidth}
        transitionDuration={transitionDuration}
      />
    </ClickAwayListener>
  )
}

/**
 * `IpMenuVertical` displays a list of choices on temporary surface. It's recommended to use it as
 * a popup menu shown after click on the button(anchor element). It's possible to set some pre-default
 * props that can be nested for all menu items such as `color`, `maxWidth`, `submenuPlacement`,
 * `transitionType`, etc. Menu items should be defined by `buttonsConfig` prop. Individual props can be set for
 * each item in a prop `buttonsConfig` such as `text`, `icon`, `divider`,
 * `subMenuContent`, etc. Nested menus will be automatically built by this config defined in a prop `subMenuContent`.
 * It can be a collapsible inside nested menu or an outside nested menu (`IpMenuVertical`)
 * depending on the `submenuPlacement` prop.
 *
 * #### Third-party routing library
 * One frequent use case is to perform navigation on the client only, without an HTTP round-trip to the server.
 * Integration with react-router can be realized with prop `to` to handle this use case. The component `NavLink`
 * from `react-router-dom` will be set automatically as a wrapper for each menu item with prop `to`.
 */
export function IpMenuVertical(props: IpMenuVerticalPropTypesReact) {
  const {
    activeColor,
    anchorEl,
    bgActiveColor,
    bgColor,
    classes,
    color,
    dense,
    endIcon = startValue?.endIcon,
    maxHeight,
    maxWidth,
    minWidth,
    onClickAway,
    open = true,
    placement = 'right-start',
    submenuPlacement = 'right',
    transitionDuration = startValue.transitionDuration,
    transitionType = startValue.transitionType,
    buttonsConfig,
    divider,
  } = props

  const contextValue = {
    activeColor,
    bgActiveColor,
    bgColor,
    color,
    dense,
    endIcon,
    maxHeight,
    maxWidth,
    minWidth,
    transitionDuration,
    transitionType,
  }

  const onCloseHandler = (
    event: Event | SyntheticEvent | KeyboardEvent<HTMLElement>
  ) => {
    anchorEl?.click()
    anchorEl?.focus()
    event.preventDefault()
  }

  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') onCloseHandler(event)
  }

  return open ? (
    <PopperWithTransition
      anchorEl={anchorEl ?? undefined}
      classes={classes}
      open={open}
      placement={placement}
      transitionDuration={transitionDuration}
      transitionType={transitionType}
      maxHeight={maxHeight}
    >
      <div onKeyDown={onKeyDownHandler}>
        <IpVerticalMenuContext.Provider value={contextValue}>
          <MenuContent
            buttonsConfig={buttonsConfig}
            divider={divider}
            onClickAway={onClickAway}
            open={true}
            submenuPlacement={submenuPlacement}
            onClose={onCloseHandler}
          />
        </IpVerticalMenuContext.Provider>
      </div>
    </PopperWithTransition>
  ) : null
}
