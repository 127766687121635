import { createTheme } from '@mui/material/styles'
import palette from '@infopulse-design-system/shared/theme/colors'
import typography from '@infopulse-design-system/shared/theme/typography'
import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import type { IpTypographyVariantTypes } from '@infopulse-design-system/shared/types/IpTypography.types'
import type { Variant } from '@mui/material/styles/createTypography'
import MuiAccordion from './components/MuiAccordion'
import MuiAlert from './components/MuiAlert'
import MuiAutocomplete from './components/MuiAutocomplete'
import MuiAvatarGroup from './components/MuiAvatarGroup'
import MuiBackdrop from './components/MuiBackdrop'
import MuiBadge from './components/MuiBadge'
import MuiBreadcrumbs from './components/MuiBreadcrumbs'
import MuiButton from './components/MuiButton'
import MuiButtonGroup from './components/MuiButtonGroup'
import MuiCard from './components/MuiCard'
import MuiCardActions from './components/MuiCardActions'
import MuiCardContent from './components/MuiCardContent'
import MuiCardHeader from './components/MuiCardHeader'
import MuiCardMedia from './components/MuiCardMedia'
import MuiCheckbox from './components/MuiCheckbox'
import MuiChip from './components/MuiChip'
import MuiCircularProgress from './components/MuiCircularProgress'
import MuiDialog from './components/MuiDialog'
import MuiDialogContent from './components/MuiDialogContent'
import MuiDialogFooter from './components/MuiDialogFooter'
import MuiDialogTitle from './components/MuiDialogTitle'
import MuiDateTimePicker from './components/MuiDateTimePicker'
import MuiDivider from './components/MuiDivider'
import MuiFormControl from './components/MuiFormControl'
import MuiFormControlLabel from './components/MuiFormControlLabel'
import MuiFormHelperText from './components/MuiFormHelperText'
import MuiInputLabel from './components/MuiInputLabel'
import MuiLinearProgress from './components/MuiLinearProgress'
import MuiLink from './components/MuiLink'
import MuiList from './components/MuiList'
import MuiListItemAvatar from './components/MuiListItemAvatar'
import MuiListItemButton from './components/MuiListItemButton'
import MuiListItemText from './components/MuiListItemText'
import MuiListSubheader from './components/MuiListSubheader'
import MuiMenuItem from './components/MuiMenuItem'
import MuiModal from './components/MuiModal'
import MuiPagination from './components/MuiPagination'
import MuiPaper from './components/MuiPaper'
import MuiPopover from './components/MuiPopover'
import MuiRadio from './components/MuiRadio'
import MuiSelect from './components/MuiSelect'
import MuiSlider from './components/MuiSlider'
import MuiSkeleton from './components/MuiSkeleton'
import MuiSwitch from './components/MuiSwitch'
import MuiTable from './components/MuiTable'
import MuiTabs from './components/MuiTabs'
import MuiTypography from './components/MuiTypography'
import MuiTextField from './components/MuiTextField'
import MuiTooltip from './components/MuiTooltip'
import MuiTreeView from './components/MuiTreeView'

declare module '@mui/material/Typography' {
  type CustomVariants = {
    [key in Exclude<IpTypographyVariantTypes, Variant>]: true
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides extends CustomVariants {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraSmall: true
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsSizeOverrides {
    extraSmall: true
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    extraSmall: true
    large: true
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsSizeOverrides {
    large: true
  }
}

const t = createTheme({
  // @ts-ignore
  typography,
  palette,
  breakpoints,
})

export default createTheme(t, {
  components: {
    ...MuiAccordion(t),
    ...MuiAlert(t),
    ...MuiAutocomplete(t),
    ...MuiAvatarGroup(t),
    ...MuiBackdrop(),
    ...MuiBadge,
    ...MuiBreadcrumbs(t),
    ...MuiButton(t),
    ...MuiButtonGroup(t),
    ...MuiCard,
    ...MuiCardActions,
    ...MuiCardContent(t),
    ...MuiCardHeader(t),
    ...MuiCardMedia,
    ...MuiCheckbox(t),
    ...MuiChip(t),
    ...MuiCircularProgress(t),
    ...MuiDialog(),
    ...MuiDialogContent(t),
    ...MuiDialogFooter(),
    ...MuiDialogTitle(t),
    ...MuiDateTimePicker(t),
    ...MuiDivider(t),
    ...MuiFormControl(t),
    ...MuiFormControlLabel,
    ...MuiFormHelperText(t),
    ...MuiInputLabel(t),
    ...MuiLinearProgress(t),
    ...MuiLink,
    ...MuiList(t),
    ...MuiListItemAvatar,
    ...MuiListItemButton(t),
    ...MuiListItemText(t),
    ...MuiListSubheader(t),
    ...MuiMenuItem(t),
    ...MuiModal(t),
    ...MuiPagination(t),
    ...MuiPaper(),
    ...MuiPopover(t),
    ...MuiRadio(t),
    ...MuiSelect(t),
    ...MuiSlider(t),
    ...MuiSkeleton(t),
    ...MuiSwitch(t),
    ...MuiTable(t),
    ...MuiTabs(t),
    ...MuiTypography,
    ...MuiTextField(t),
    ...MuiTooltip(t),
    ...MuiTreeView(t),
  },
})
