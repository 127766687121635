import type { Theme } from '@mui/material'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'

export default (theme: Theme) => {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          minWidth: inputStyles.input_min_width,
          borderRadius: inputStyles.input_border_radius,
          '&.IpSelect-select--small': {
            height: inputStyles.input_small_height,
            lineHeight: inputStyles.input_small_height,
            fontSize: inputStyles.input_select_item_small_font_size,
          },
          '& .MuiSelect-select.MuiSelect-select, & .MuiNativeSelect-select.MuiNativeSelect-select':
            {
              height: inputStyles.input_height,
              lineHeight: inputStyles.input_height,
              color: theme.palette.text.primary,
              paddingRight: `${inputStyles.input_icon_padding_right} !important`,
              paddingLeft: inputStyles.input_padding_horizontal,
              paddingTop: 0,
              paddingBottom: 0,
            },
          '&.Mui-disabled': {
            color: theme.palette.grey['900'],
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent',
            '& .MuiSelect-select, & .MuiNativeSelect-select': {
              backgroundColor: 'transparent',
            },
          },
          '&, &.MuiInput-root': {
            marginTop: '0px',
          },
          '& .MuiOutlinedInput-root .MuiSvgIcon-root': {
            right: inputStyles.input_icon_right,
          },
          '& .MuiSvgIcon-root:not(.Mui-disabled)': {
            color: theme.palette.primary.main,
          },
          '&:hover .MuiSvgIcon-root:not(.Mui-disabled)': {
            color: theme.palette.primary.dark,
          },
          '&:before': {
            borderColor: theme.palette.primary.main,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderColor: theme.palette.primary.dark,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.dark}`,
          },
          '&.Mui-error': {
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:after': {
              borderWidth: '1px',
            },
            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.error.main}`,
            },
            '&:hover:not(.Mui-disabled):before': {
              border: 'none',
            },
            '&:hover:not(.Mui-disabled):after': {
              borderWidth: '1px',
            },
            '& .MuiSvgIcon-root:not(.Mui-disabled)': {
              color: theme.palette.error.main,
            },
            '& .MuiSelect-select:not(.Mui-disabled)': {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
  }
}
