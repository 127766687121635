import React, { useEffect } from 'react'
import { MainPageTabTypes, PERMISSIONS } from '../../../../constants/enums'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { usePermission } from '../../../../hooks/usePermission'
import {
  mediaListSelector,
  mediaWithBugsSelector,
} from '../../../../redux/selectors'
import { setMainActiveTab } from '../../../../redux/slices/appStateSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import PermissionsGate from '../../PermissionsGate'
import { SortMenu } from '../../SortMenu'
import { SortMenuType } from '../../SortMenu/types/sort-menu-config.type'

export const MainTabButtons = () => {
  const dispatch = useAppDispatch()
  const { isPermission, roles, permissions } = usePermission(
    PERMISSIONS.bug_report
  )
  const { data: results } = useAppSelector(mediaListSelector)
  const { data: bugs } = useAppSelector(mediaWithBugsSelector)
  const { isMainResultsTab, isMainBugsTab } = useMainPageActiveTab()
  const setResultsState = () => {
    dispatch(setMainActiveTab(MainPageTabTypes.results))
  }
  const setBugState = () => {
    dispatch(setMainActiveTab(MainPageTabTypes.bugs))
  }
  const resultsContText = results.totalMedias ?? 0

  const bugsCountText = `${bugs.data.length}${
    bugs.data.length < bugs.total ? '+' : ''
  }`

  useEffect(() => {
    if (!roles || !permissions) return

    if (!isPermission) {
      setResultsState()
    }
  }, [isPermission, roles, permissions])

  return (
    <div className="main-result-btns">
      <SortMenu type={SortMenuType.media} />
      <IpButton
        classes={isMainResultsTab ? 'main-result-btn-active' : ''}
        onClick={setResultsState}
      >
        Results({resultsContText})
      </IpButton>
      <PermissionsGate permissionKey={PERMISSIONS.bug_report}>
        <IpButton
          classes={isMainBugsTab ? 'main-result-btn-active' : ''}
          onClick={setBugState}
        >
          Bug reports({bugsCountText})
        </IpButton>
      </PermissionsGate>
    </div>
  )
}
