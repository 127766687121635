import type { Dispatch, SetStateAction } from 'react'
import React, { useState } from 'react'
import { useLanguage } from '../../../../../hooks/useLanguage'
import type {
  ProcessStatus,
  ProcessTr,
} from '../../../../../redux/types/mediaProcessing'
import { getProcessStatus } from '../../../../../utils'
import { IpText } from '../../../../ipDesignSystemComponents/atoms/IpText'
import { EndMessage } from '../../../EndMessage'
import type { PersonaInfoIconState } from '../../../persona'
import { PersonaInfoPopup } from '../../../persona'
import type { ActiveTrData } from '../../../PreviewPanel'
import { Timer } from '../../Timer'
import { ProcessingTr } from './ProcessingTr'

type Props = {
  activeTrData: ActiveTrData
  setActiveTrData: Dispatch<SetStateAction<ActiveTrData>>
  list: ProcessTr[]
  status: ProcessStatus
  loading: boolean
  isSuccess: boolean
  error?: string
}

export const ProcessingTrList = ({
  activeTrData,
  setActiveTrData,
  list,
  status,
  loading,
  isSuccess,
  error,
}: Props) => {
  const [personaInfoIconState, setPersonaInfoIconState] =
    useState<PersonaInfoIconState>(null)
  const { suffix } = useLanguage()

  const isTranscriptions = !!list

  const activeTranscription = isTranscriptions
    ? list[activeTrData.tIndex]
    : undefined

  return isTranscriptions ? (
    <div className="transcription-list_wrapper">
      <IpText variant="subtitle3" color="primary">
        Transcriptions:{' '}
        {!isSuccess ? (
          <IpText
            component="span"
            variant="subtitle3"
            color={getProcessStatus(status)}
          >
            {status}
          </IpText>
        ) : (
          ''
        )}
        {!loading && !error && <Timer status={status} />}
      </IpText>
      {error && <IpText color="error">{error}</IpText>}
      <ul className="transcription-list">
        {list?.map((item, index) => {
          return (
            <li
              key={item.id + index}
              onClick={({ target }) => {
                const isMenuIcon = !!(target as HTMLElement).closest(
                  '.transcription-icon_anchor'
                )
                setActiveTrData({
                  tIndex: index,
                  setManualTime: true,
                  pause: isMenuIcon,
                })
              }}
              style={{ cursor: 'pointer' }}
            >
              <ProcessingTr
                active={index === activeTrData.tIndex}
                time={item.start ?? 0}
                text={item[`text${suffix}`] ?? ''}
                personaId={item.persona_id}
                speakerId={item.speaker}
                setPersonaInfoIconState={setPersonaInfoIconState}
              />
            </li>
          )
        })}
      </ul>
      {!!list.length && <EndMessage />}
      {!!activeTranscription && (
        <>
          <PersonaInfoPopup
            personaInfoIconState={personaInfoIconState}
            setPersonaInfoIconState={setPersonaInfoIconState}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  )
}
