import { Login, Logout } from '@mui/icons-material'
import { Popover } from '@mui/material'
import type { Dispatch } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { PERMISSIONS } from '../../../../constants/enums'
import { useAuth } from '../../../../hooks/useAuth'
import { usePermission } from '../../../../hooks/usePermission'
import useResponsivePerform from '../../../../hooks/useResponsivePerform'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpListItemPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpList'
import { IpList } from '../../../ipDesignSystemComponents/atoms/IpList'
import { ROUTER_PATHS } from '../../Router'

import './index.scss'

type Props = {
  anchorEl: HTMLElement | null
  setAnchorEl: Dispatch<HTMLElement | null>
  open: boolean
}

export const ProfilePopover = ({ anchorEl, setAnchorEl, open }: Props) => {
  const { getIsPermission, isAnonymous } = usePermission()
  const { isMore } = useResponsivePerform(400)

  const onCloseHandler = () => {
    setAnchorEl(null)
  }

  const { login, logout } = useAuth()

  const listConfig: IpListItemPropTypesReact[] = [
    {
      onClick: onCloseHandler,
      textData: {
        text: (
          <NavLink to={ROUTER_PATHS.root} end>
            Search
          </NavLink>
        ),
      },
    },
    isMore
      ? {
          onClick: onCloseHandler,
          textData: {
            text: (
              <NavLink to={ROUTER_PATHS.dashboard} end>
                Dashboard
              </NavLink>
            ),
          },
        }
      : {},
    getIsPermission(PERMISSIONS.report_basket)
      ? {
          onClick: onCloseHandler,
          textData: {
            text: (
              <NavLink to={ROUTER_PATHS.basket} end>
                Reporting basket
              </NavLink>
            ),
          },
        }
      : {},
    {
      onClick: onCloseHandler,
      textData: {
        text: (
          <NavLink to={ROUTER_PATHS.person_library} end>
            Person library
          </NavLink>
        ),
      },
    },
    getIsPermission(PERMISSIONS.media_processing)
      ? {
          onClick: onCloseHandler,
          textData: {
            text: (
              <NavLink to={ROUTER_PATHS.media_processing} end>
                Media Processing
              </NavLink>
            ),
          },
        }
      : {},
    getIsPermission(PERMISSIONS.admin_panel)
      ? {
          onClick: onCloseHandler,
          textData: {
            text: (
              <NavLink to={ROUTER_PATHS.admin_panel} end>
                Admin Panel
              </NavLink>
            ),
          },
        }
      : {},
    {
      onClick: onCloseHandler,
      textData: {
        text: (
          <NavLink to={ROUTER_PATHS.about_us} end>
            About us
          </NavLink>
        ),
      },
    },
    {
      onClick: onCloseHandler,
      textData: {
        text: (
          <NavLink to={ROUTER_PATHS.terms_of_use} end>
            Terms of use
          </NavLink>
        ),
      },
    },
    isAnonymous
      ? {
          onClick: onCloseHandler,
          textData: {
            text: (
              <div onClick={login} className="logout">
                <IpIcon height="24px" width="28px" color="primary">
                  <Login />
                </IpIcon>
                Login
              </div>
            ),
          },
        }
      : {
          onClick: onCloseHandler,
          textData: {
            text: (
              <div onClick={logout} className="logout">
                <IpIcon height="24px" width="31px" color="primary">
                  <Logout />
                </IpIcon>
                Logout
              </div>
            ),
          },
        },
  ]

  return (
    <Popover
      className="profile-popover"
      anchorEl={anchorEl}
      open={open}
      onClose={onCloseHandler}
      onBackdropClick={onCloseHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <IpList itemsConfig={listConfig} />
    </Popover>
  )
}
