import React from 'react'
import type { SyntheticEvent, ReactNode } from 'react'
import type { IpTooltipPropTypes } from '@infopulse-design-system/shared/types/IpTooltip.types'
import type { TooltipProps } from '@mui/material/Tooltip'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import {
  formatClassName,
  generateClasses,
  getColor,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { getCustomTransitionComponent } from '../../../../utils'
import '@infopulse-design-system/shared/theme/components/IpTooltip/styles.scss'

export const IpTooltipTestId = 'IpTooltipTestId'

export type IpTooltipPropTypesReact = IpTooltipPropTypes<
  ReactNode,
  Event | SyntheticEvent
>

const IpTooltipStyled = styled(
  ({
    className,
    children,
    ...props
  }: TooltipProps & IpTooltipPropTypesReact) => (
    <Tooltip {...props} color={undefined} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ color, theme, background }) => {
  return {
    '.MuiTooltip-tooltip, & .MuiTooltip-arrow:before': {
      ...(background
        ? { backgroundColor: theme.palette[background].main }
        : {}),

      ...(color ? { color: getColor(theme.palette, color) } : {}),
    },
  }
})

/**
 * `IpTooltip` provides informative text that is displayed when users hover over, focus on, or tap on
 * an element. When triggered, `IpTooltip` presents a concise text label that helps to identify the purpose
 * or function of the associated element. By offering this contextual information, `IpTooltip` enhance
 * user understanding and provides valuable guidance, improving the overall usability and user experience
 * of your interface.
 */
export function IpTooltip(props: IpTooltipPropTypesReact) {
  const {
    arrow,
    background,
    color,
    content,
    classes,
    disableFocusListener,
    disableHoverListener,
    disableInteractive,
    delay,
    duration,
    followCursor,
    id,
    open,
    onOpen,
    onClose,
    placement,
    title,
    transitionType,
  } = props

  const customClasses = formatClassName(
    generateClasses('tooltip', 'react'),
    classes
  )

  const transitionComponent = getCustomTransitionComponent(transitionType)

  return (
    <IpTooltipStyled
      arrow={arrow}
      background={background}
      color={color}
      data-testid={IpTooltipTestId}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableInteractive={disableInteractive}
      followCursor={followCursor}
      id={id}
      className={customClasses}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      TransitionComponent={transitionComponent}
      TransitionProps={duration ? { timeout: duration } : undefined}
      title={title}
      enterDelay={delay}
      leaveDelay={delay}
      content={content}
    >
      <span>{content}</span>
    </IpTooltipStyled>
  )
}
