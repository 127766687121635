import type { Theme } from '@mui/material'
import menuList from '@infopulse-design-system/shared/theme/components/IpMenuList'

export default (theme: Theme) => {
  return {
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.IpMenuBurger': {
            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent',
              '&:before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.grey[900],
                opacity: 0.4,
              },
            },
            '& > .MuiMenu-paper > .MuiMenu-list': {
              borderTop: `1px solid ${menuList.menu_list_divider_border_color}`,
            },
            '& .MuiMenu-paper': {
              position: 'fixed',
              bottom: '0px',
              backgroundColor: theme.palette.background.default,
              borderRadius: 0,
              boxShadow: 'none',
            },
          },
        },
      },
    },
  }
}
