import styles from '@infopulse-design-system/shared/theme/components/IpBackdrop'

export default () => {
  return {
    MuiBackdrop: {
      variants: [
        {
          props: { invisible: false },
          style: {
            backgroundColor: styles.backdrop_background_color,
          },
        },
      ],
    },
  }
}
