import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetMedia } from '../../api'
import type { GetMediaParams } from '../../api/apiTypes'
import { setMediaList, setMediaListNext } from '../slices/mediaListSlice'

export const fetchMediaList = createAsyncThunk<void, GetMediaParams>(
  'mediaList/fetch',
  async (params, thunkAPI) => {
    try {
      const data = await apiGetMedia(params)
      thunkAPI.dispatch(setMediaList(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)

export const fetchMediaListNext = createAsyncThunk<void, GetMediaParams>(
  'mediaList/fetch/next',
  async (params, thunkAPI) => {
    try {
      const data = await apiGetMedia(params)
      thunkAPI.dispatch(setMediaListNext(data))
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)
