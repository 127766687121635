import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTER_PATHS } from '../components/custom/Router'
import { FilterTypes, MainPageTabTypes } from '../constants/enums'
import { filterSelector, resetFiltersSelector } from '../redux/selectors'
import { useAppSelector } from '../redux/store'
import type { MainPageActiveTabs } from '../redux/types/appState'
import type { FilterType, StateFullResetFIlters } from '../redux/types/filters'
import useMainPageActiveTab from './useMainPageActiveTab'

const getFilterType = (
  pathname: string,
  mainActiveTab: MainPageActiveTabs
): FilterType => {
  if (pathname.split('/').includes(ROUTER_PATHS.person_library))
    return FilterTypes.personLibrary
  if (pathname.split('/').includes(ROUTER_PATHS.basket))
    return FilterTypes.basket
  if (pathname.split('/').includes(ROUTER_PATHS.dashboard))
    return FilterTypes.dashboard
  if (mainActiveTab === MainPageTabTypes.bugs) return FilterTypes.mainBugs
  return FilterTypes.mainResults
}

const useFilters = (resetType?: StateFullResetFIlters) => {
  const { pathname } = useLocation()
  const { mainActiveTab } = useMainPageActiveTab()
  const reset = useAppSelector(resetFiltersSelector)
  const [filterType, setFilterType] = useState<FilterType>(
    getFilterType(window.location.pathname, mainActiveTab)
  )

  useEffect(() => {
    const newFilterType = getFilterType(pathname, mainActiveTab)
    setFilterType(newFilterType)
  }, [pathname, mainActiveTab])

  const isBasketType = filterType === FilterTypes.basket
  const isMainResultsType = filterType === FilterTypes.mainResults
  const isMainBugsType = filterType === FilterTypes.mainBugs
  const isMainType = isMainResultsType || isMainBugsType
  const isPersonLibrary = filterType === FilterTypes.personLibrary

  const filtersObj = {
    basket: useAppSelector((state) =>
      filterSelector(state, FilterTypes.basket)
    ),
    mainResults: useAppSelector((state) =>
      filterSelector(state, FilterTypes.mainResults)
    ),
    mainBugs: useAppSelector((state) =>
      filterSelector(state, FilterTypes.mainBugs)
    ),
    personLibrary: useAppSelector((state) =>
      filterSelector(state, FilterTypes.personLibrary)
    ),
    dashboard: useAppSelector((state) =>
      filterSelector(state, FilterTypes.dashboard)
    ),
  }
  const filters = filtersObj[filterType]

  const resetValue = resetType ? reset[resetType] : undefined

  return {
    reset,
    resetValue,
    filters,
    filterType,
    isMainType,
    isMainResultsType,
    isMainBugsType,
    isBasketType,
    isPersonLibrary,
    basketFilters: filtersObj.basket,
    mainResultsFilters: filtersObj.mainResults,
    mainBugsFilters: filtersObj.mainBugs,
    personLibraryFilters: filtersObj.personLibrary,
  }
}

export default useFilters
