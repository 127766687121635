import { personaListSelector } from '../redux/selectors'
import { useAppSelector } from '../redux/store'
import type { PersonaItem } from '../redux/types/personas'
import { getPersonInfoByLang } from '../utils'
import { useLanguage } from './useLanguage'

export const usePersonaList = (index: number) => {
  const {
    data,
    isLoading: isPersonaListLoading,
    error: personaListError,
  } = useAppSelector(personaListSelector)
  const personaData: PersonaItem | undefined = data.data[index]
  const { suffix } = useLanguage()

  return {
    isPersonaListData: !!personaData,
    isPersonaListLoading,
    personaListError,
    id: personaData?.id ?? '',
    categories_id: personaData?.categories_id,
    citizenship_id: personaData?.citizenship_id,
    sanctions_list_id: personaData?.sanctions_list_id,
    ...getPersonInfoByLang(personaData, suffix),
  }
}
