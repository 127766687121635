import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useEffect, useState } from 'react'
import useFilters from '../../../../hooks/useFilters'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import {
  addFoundCurrentClass,
  cleanUp,
  highlightFoundText,
  isValidInputValue,
  removeFoundCurrentClass,
  scrollToCurrentEl,
} from '../helpers'
import '../index.scss'

type Props = {
  targetSelector: string
  id: string
}

export const QuickSearchCustom = ({ targetSelector, id }: Props) => {
  const { filters, filterType } = useFilters()
  const globalSearchTrimmedText = (filters.search ?? '').trim() as string
  const [inputValue, setInputValue] = useState(globalSearchTrimmedText)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [foundElementsLength, setFoundElementsLength] = useState<number>(0)
  const [error, setError] = useState('')

  const initSettersOnValueUpdate = (value: string) => {
    const nodeList = document.querySelectorAll(targetSelector)
    cleanUp(targetSelector, nodeList)
    let errorMessage = ''
    let foundNodeListLength = 0
    let elementIndex = 0

    if (!isValidInputValue(value)) {
      errorMessage = 'Invalid characters'
    } else if (value.length >= 3) {
      highlightFoundText(value, targetSelector, nodeList)
      foundNodeListLength = document.querySelectorAll('.text-found').length
      elementIndex = 1
    }

    setCurrentIndex(elementIndex)
    setFoundElementsLength(foundNodeListLength)
    setError(errorMessage)
    setInputValue(value)
  }

  useEffect(() => {
    initSettersOnValueUpdate(globalSearchTrimmedText)
    return () => cleanUp(targetSelector)
  }, [id, filters.search, filterType])

  useEffect(() => {
    removeFoundCurrentClass()
    addFoundCurrentClass(currentIndex)
    if (currentIndex) {
      scrollToCurrentEl()
    }
  }, [currentIndex])

  return (
    <div className="quick-search">
      <IpTextField
        value={inputValue}
        onChange={(e) => initSettersOnValueUpdate(e.target.value)}
        variant="standard"
        placeholder="Search..."
        error={!!error}
        helperText={error}
      />
      {foundElementsLength ? (
        <div className="quick-search_navigation">
          <IpText>
            {currentIndex}/{foundElementsLength}
          </IpText>
          <IpIcon
            color="primary"
            fontSize="25px"
            onClick={() => {
              setCurrentIndex((prev) =>
                prev >= foundElementsLength ? 1 : prev + 1
              )
            }}
          >
            <KeyboardArrowDownIcon />
          </IpIcon>
          <IpIcon
            color="primary"
            fontSize="25px"
            onClick={() => {
              setCurrentIndex((prev) =>
                prev <= 1 ? foundElementsLength : prev - 1
              )
            }}
          >
            <KeyboardArrowUpIcon />
          </IpIcon>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
