import colors from '@infopulse-design-system/shared/theme/colors'
import React, { memo } from 'react'
import { IpAvatar } from '../../ipDesignSystemComponents/atoms/IpAvatar'
import { IpButton } from '../../ipDesignSystemComponents/atoms/IpButton'
import type { IpButtonPropTypesReact } from '../../ipDesignSystemComponents/atoms/IpButton/index.types'
import type { IpCheckboxPropTypesReact } from '../../ipDesignSystemComponents/atoms/IpCheckbox'
import { IpCheckbox } from '../../ipDesignSystemComponents/atoms/IpCheckbox'

export const MemoIpCheckbox = memo(function MemoCheckBoxComponent(
  props: IpCheckboxPropTypesReact
) {
  return <IpCheckbox {...props} />
})

export const MemoIpButton = memo(function MemoIpButtonComponent(
  props: IpButtonPropTypesReact
) {
  return <IpButton {...props} />
})

export const MemoAvatar = memo(function MemoIpAvatarComponent({
  photo,
  classes,
  sizes,
}: {
  photo?: string
  classes?: string
  sizes: string
}) {
  return (
    <IpAvatar
      sizes={sizes}
      avatarsData={[{ src: photo, color: colors.grey['500'] }]}
      classes={classes}
    />
  )
})
