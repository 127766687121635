import type { LanguageSuffix } from './dictionaries'

export enum ProcessFields {
  id = 'id',
  status = 'status',
  media_id = 'media_id',
  temp_media_url = 'temp_media_url',
}

export enum ProcessStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  REMOVED = 'REMOVED',
  WARNING = 'WARNING',
}

export type CreateProcessRes = {
  id: string
  status: ProcessStatus
  error?: string
  air_date: string
  media_id: string | null
  temp_media_url: string
}

export type ProcessTr = {
  id: string
  start: number
  end: number
  persona_id?: string
  speaker: string
} & Record<`text${LanguageSuffix}`, string | null>

export type ProcessRes = CreateProcessRes & {
  transcriptions?: ProcessTr[] | null
}
