import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { apiTranscriptionsAnnotation } from '../../../../../../api'
import { AnnotationClassesIdTypes } from '../../../../../../constants/enums'
import { useHoveredNotification } from '../../../../../../hooks/useHoveredNotification'
import type { NarrativeSubcategory } from '../../../../../../redux/types/dictionaries'
import type { Transcription } from '../../../../../../redux/types/media'
import { IpButton } from '../../../../../ipDesignSystemComponents/atoms/IpButton'
import { IpText } from '../../../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../../../ipDesignSystemComponents/atoms/IpTextField'
import { NarrativeList } from './NarrativeList'

type Props = {
  textValue: string
  setTextValue: Dispatch<SetStateAction<string>>
  onSubmit: () => void
  selectedNarratives: NarrativeSubcategory[]
  setSelectedNarratives: Dispatch<SetStateAction<NarrativeSubcategory[]>>
  transcription: Transcription
}

export const NarrativesContent = ({
  textValue,
  setTextValue,
  onSubmit,
  transcription,
  selectedNarratives,
  setSelectedNarratives,
}: Props) => {
  const { notification } = useHoveredNotification()

  const onItemRemove = (item: NarrativeSubcategory) => {
    setSelectedNarratives((state) => state.filter(({ id }) => id !== item.id))
  }

  const onItemAdd = (item: NarrativeSubcategory) => {
    setSelectedNarratives((state) => [...state, item])
  }

  const onCancelHandler = () => {
    setSelectedNarratives([])
    onSubmit()
  }

  const onSubmitHandler = async () => {
    if (!selectedNarratives.length) {
      notification('Narratives are not selected', 'warning', true)
      return
    }

    try {
      await apiTranscriptionsAnnotation({
        transcription_id: transcription.id,
        media_id: transcription.media_id,
        classes_id: AnnotationClassesIdTypes.narratives,
        class_items_id: selectedNarratives.map((narrative) => narrative.id),
        quote: textValue,
      })
    } catch (e: any) {
      notification(`Error: ${e.message}`, 'error', true)
    }

    setSelectedNarratives([])
    onSubmit()
  }

  return (
    <>
      <IpText variant="subtitle3" color="primary">
        Add narrative
      </IpText>
      <IpTextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        variant="standard"
        multiline
        maxRows={3}
      />
      <NarrativeList
        currentList={selectedNarratives}
        onItemAdd={onItemAdd}
        onItemRemove={onItemRemove}
      />
      <IpButton
        variant="outlined"
        size="small"
        onClick={onCancelHandler}
        classes="reporting-basket-btn cancel-btn"
      >
        Cancel
      </IpButton>
      <IpButton variant="contained" size="small" onClick={onSubmitHandler}>
        Add
      </IpButton>
    </>
  )
}
