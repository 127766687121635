import React from 'react'
import { IpText } from '../../components/ipDesignSystemComponents/atoms/IpText'
import { useLanguage } from '../../hooks/useLanguage'
import { content } from './content'

import './index.scss'

function getText(text: { [key: string]: string }, lang: string) {
  return text[lang] || text.ua || text.en
}

function TermsOfUse() {
  const { lang } = useLanguage()

  return (
    <div className="page terms-of-use">
      <IpText variant="h1" align="center" color="primary" gutterBottom>
        {getText(content.title, lang)}
      </IpText>
      {new Array(11).fill(null).map((_, index) => (
        <IpText variant="body4" paragraph key={index}>
          {getText(content[`line${index + 1}`], lang)}
        </IpText>
      ))}
    </div>
  )
}

export default TermsOfUse
