import { isValid, parse } from 'date-fns'
import { DATE_FORMAT } from '../constants'

export const parseDateString = (
  dateString?: string,
  dateFormat = DATE_FORMAT
): Date | undefined => {
  if (!dateString) return undefined

  const parsedDate = parse(dateString, dateFormat, new Date(), {
    // @ts-ignore
    strict: false,
  })

  if (isValid(parsedDate)) {
    return parsedDate
  }
  console.error('Invalid date')
  return undefined
}

// we use format dd.mm.yyyy
export const filterDateMapper = (
  date: string | undefined,
  type: 'day-end' | 'day-start'
): Date | undefined => {
  if (!date) return undefined
  return new Date(
    date.split('.').reverse().join('-') +
      (type === 'day-start' ? 'T00:00:00.000Z' : 'T23:59:59.999Z')
  )
}

// we use format dd.mm.yyyy
export const getLocalDate = (date: Date): string => {
  return date.toLocaleDateString('ru-Ru')
}
