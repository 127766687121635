import React from 'react'
import type { ReactNode } from 'react'
import FormControl from '@mui/material/FormControl'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import type { IpFormControlPropTypes } from '@infopulse-design-system/shared/types/IpFormControl.types'

export const IpFormControlTestId = 'IpFormControlTestId'

type IpFormControlPropTypesReact = IpFormControlPropTypes & {
  children?: ReactNode
}

export function IpFormControl(props: IpFormControlPropTypesReact) {
  const { children, classes, disabled, error, focused, fullWidth, required } =
    props

  const customClasses = formatClassName(
    generateClasses('form-control', 'react'),
    classes
  )

  return (
    <FormControl
      className={customClasses}
      data-testid={IpFormControlTestId}
      disabled={disabled}
      error={error}
      focused={focused}
      fullWidth={fullWidth}
      required={required}
    >
      {children}
    </FormControl>
  )
}
