import type { RootState } from '../store'

export const personaListSelector = (state: RootState) => state.personaList

export const personaDataSelector = (state: RootState) => state.personaData

export const activePersonaSelector = (state: RootState) =>
  state.appState.pages.personaLibrary?.activePersona

export const isActivePersonaByIdSelector = (state: RootState, id: string) =>
  state.appState.pages.personaLibrary?.activePersona?.personaId === id
