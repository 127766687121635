/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { convertArrayToObjWithKeys } from '../../utils'
import {
  fetchTranscriptionsBugs,
  fetchTranscriptionsData,
} from '../thunk/transcriptionsDataThunk'
import type { ApiData } from '../types'
import type { Bug, MediaData, Transcription } from '../types/media'

type DataState = {
  correct: Record<MediaData['id'], Transcription[]>
  incorrect: Record<MediaData['id'], Bug[]>
}
export type TranscriptionsDataState = ApiData<DataState>

export const initialTranscriptionsDataState: TranscriptionsDataState = {
  data: { correct: {}, incorrect: {} },
  isLoading: false,
  error: '',
}

export const TranscriptionsDataSlice = createSlice({
  name: 'transcriptionsData',
  initialState: initialTranscriptionsDataState,
  reducers: {
    setCorrectTranscriptions: (
      state,
      {
        payload: { id, data },
      }: PayloadAction<{ id: string; data: Transcription[] }>
    ) => {
      state.data.correct[id] = data
    },
    setIncorrectTranscriptions: (
      state,
      { payload: { id, data } }: PayloadAction<{ id: string; data: Bug[] }>
    ) => {
      state.data.incorrect[id] = data
    },
    updateSpeakerInTranscriptionData: (
      state,
      { payload }: PayloadAction<Transcription[]>
    ) => {
      // TODO: update speaker for incorrect transcription
      const mediaDataItem = state.data.correct[payload[0].media_id]
      const obj = convertArrayToObjWithKeys(payload, 'id')
      mediaDataItem?.forEach((tr, i) => {
        const current = obj[tr.id]
        if (current) {
          mediaDataItem[i] = current
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTranscriptionsData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchTranscriptionsData.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchTranscriptionsData.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
    builder.addCase(fetchTranscriptionsBugs.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchTranscriptionsBugs.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchTranscriptionsBugs.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const {
  setCorrectTranscriptions,
  setIncorrectTranscriptions,
  updateSpeakerInTranscriptionData,
} = TranscriptionsDataSlice.actions

export default TranscriptionsDataSlice.reducer
