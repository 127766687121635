import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { transcriptionsDataSelector } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux/store'
import type { Bug, Transcription } from '../../../../redux/types/media'

export const useCurrentTrData = (mediaId: string, index = 0) => {
  const { data: transcriptionsData } = useAppSelector(
    transcriptionsDataSelector
  )

  const { mainActiveTab } = useMainPageActiveTab()

  const currentTrData = transcriptionsData[mainActiveTab][mediaId]

  const transcription = currentTrData ? currentTrData[index] : undefined

  return {
    isCurrentTrData: !!currentTrData,
    currentTrData: currentTrData as Transcription[] | Bug[] | undefined,
    transcription: transcription as Transcription | Bug | undefined,
  }
}
