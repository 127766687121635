import styles from '@infopulse-design-system/shared/theme/components/IpDialog/IpDialogHeader'
import type { Theme } from '@mui/material'

export default (theme: Theme) => {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          font: styles.dialog_header_font,
          padding: styles.dialog_header_padding,
          '&.IpDialogHeader--divider': {
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
          },
        },
      },
    },
  }
}
