import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProcessingPanel } from '../../components/custom/processing'
import { ROUTER_PATHS } from '../../components/custom/Router'
import { IpProgressBar } from '../../components/ipDesignSystemComponents/atoms/IpProgressBar'
import { PROCESSING_REFRESH_TIMER } from '../../constants'
import { processDataSelector, profileSelector } from '../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { updateMediaProcessingThunk } from '../../redux/thunk/mediaProcessingThunk'
import type { ProcessRes } from '../../redux/types/mediaProcessing'
import { ProcessStatus } from '../../redux/types/mediaProcessing'
import './index.scss'

function MediaProcessingId() {
  const { id = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const profile = useAppSelector(profileSelector)
  const data = useAppSelector((state) => processDataSelector(state, id))
  const isAuth = Boolean(profile.data.name)
  const [loading, setLoading] = React.useState(false)
  const isTrDataLoaded =
    data?.status === ProcessStatus.SUCCESS ||
    data?.status === ProcessStatus.DONE

  if (data?.status === ProcessStatus.REMOVED) {
    navigate(`/${ROUTER_PATHS.media_processing}`)
  }

  const loadMedia = async () => {
    setLoading(true)
    await dispatch(updateMediaProcessingThunk(id))
    setLoading(false)
  }

  useEffect(() => {
    if (!isAuth || isTrDataLoaded) {
      return
    }
    loadMedia()
  }, [id, isAuth, isTrDataLoaded])

  useEffect(() => {
    const timerId = setInterval(() => {
      if (isAuth && id && !isTrDataLoaded) {
        loadMedia()
      }
      if (isTrDataLoaded) {
        clearInterval(timerId)
      }
    }, PROCESSING_REFRESH_TIMER)

    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [isTrDataLoaded])

  const isData = Boolean(data)

  return (
    <div className="page media-processing">
      {loading && <IpProgressBar />}
      {!isData ? (
        <></>
      ) : (
        <ProcessingPanel
          processingData={data as ProcessRes}
          loading={loading}
        />
      )}
    </div>
  )
}

export default MediaProcessingId
