import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import { colors, FormControl, FormHelperText, Popover } from '@mui/material'
import type { Omit } from 'notistack'
import type { Dispatch } from 'react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { BaseSchema } from 'yup'
import * as yup from 'yup'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../hooks/useLanguage'
import { usePersonaData } from '../../../../hooks/usePersonaData'
import { dictionariesSelector } from '../../../../redux/selectors'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { updatePersonaDataThunk } from '../../../../redux/thunk/personaDataThunk'
import { DICTIONARY } from '../../../../redux/types/dictionaries'
import type { PersonaItem } from '../../../../redux/types/personas'
import { IpAvatar } from '../../../ipDesignSystemComponents/atoms/IpAvatar'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import type { IpFormPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpForm'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import type { IpSelectPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpSelect'
import { IpSelect } from '../../../ipDesignSystemComponents/atoms/IpSelect'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import type { IpTextFieldPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'

import './index.scss'

export enum EDIT {
  name = 'name', // 0
  name_en = 'name_en', // 1
  title = 'title', // 2
  title_en = 'title_en', // 3
  birth_date = 'birth_date', // 4
  citizenship = 'citizenship_id', // 5
  categories_id = 'categories_id', // 6
  sanctions_justification = 'sanctions_justification', // 7
  sanctions_justification_en = 'sanctions_justification_en', // 8
  sanctions_list_id = 'sanctions_list_id', // 9
  telegram_channel = 'telegram_channel', // 10
  site_url = 'site_url', // 11
  wiki_url = 'wiki_url', // 12
  vk_page = 'vk_page', // 13
  facebook_page = 'facebook_page', // 14
  youtube_channel = 'youtube_channel', // 15
  photo_url = 'photo_url', // 16
}

type FormElementsConfig = {
  defaultValues: { [key: string]: string | number | boolean }
  yupSchema: { [key: string]: BaseSchema<unknown> }
}

type FormProps = Pick<IpFormPropTypesReact, 'validationMode' | 'classes'> & {
  activePersonaId: string
  onClose: () => void
  submitHandler: (data: any, reset: () => void) => void
}

// const isValidImageFormat = (format: string): boolean =>
//   format.startsWith('image/')

function Form(props: FormProps) {
  const {
    submitHandler,
    validationMode = 'onChange',
    activePersonaId,
    onClose,
  } = props
  const {
    personaData: {
      telegram_channel = '',
      site_url = '',
      wiki_url = '',
      vk_page = '',
      facebook_page = '',
      youtube_channel = '',
      citizenship_id = '',
      categories_id = [],
      sanctions_list_id = [],
      photo_url = '',
      birth_date = '',
      name = '',
      name_en = '',
      sanctions_justification = '',
      sanctions_justification_en = '',
      title = '',
      title_en = '',
    } = {},
  } = usePersonaData(activePersonaId)
  const [urlValue, setUrlValue] = useState(photo_url)

  const { suffix } = useLanguage()
  const dictionary = useAppSelector(dictionariesSelector)

  const formElements: IpFormPropTypesReact['formElements'] = [
    {
      name: EDIT.name,
      defaultValue: name,
      elementType: 'input',
      elementProps: {
        label: 'Name',
        placeholder: 'ПУТИН Владимир Владимирович',
        fullWidth: true,
        variant: 'standard',
        autoFocus: true,
        required: true,
      },
      yupValidation: yup
        .string()
        .required('No name provided.')
        .max(150, 'Is more then 150 symbols.'),
    },
    {
      name: EDIT.name_en,
      defaultValue: name_en,
      elementType: 'input',
      elementProps: {
        label: 'Name_en',
        placeholder: 'Putin Vladimir Vladimirovich',
        fullWidth: true,
        variant: 'standard',
        autoFocus: true,
      },
      yupValidation: yup.string().max(150, 'Is more then 150 symbols.'),
    },
    {
      name: EDIT.title,
      defaultValue: title,
      elementType: 'input',
      elementProps: {
        placeholder: 'Title',
        label: 'Title',
        fullWidth: true,
        variant: 'standard',
        required: true,
        multiline: true,
        maxRows: 2,
      },
      yupValidation: yup
        .string()
        .required('No title provided.')
        .max(1500, 'Is more then 1500 symbols.'),
    },
    {
      name: EDIT.title_en,
      defaultValue: title_en,
      elementType: 'input',
      elementProps: {
        placeholder: 'Title_en',
        label: 'Title_en',
        fullWidth: true,
        variant: 'standard',
        multiline: true,
        maxRows: 2,
      },
      yupValidation: yup.string().max(1500, 'Is more then 1500 symbols.'),
    },
    {
      name: EDIT.birth_date,
      defaultValue: birth_date ?? '',
      elementType: 'input',
      elementProps: {
        placeholder: '25.06.1970',
        label: 'Birth date',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().max(10, 'Should be format "25.06.1970"'),
    },
    {
      name: EDIT.citizenship,
      defaultValue: citizenship_id,
      elementType: 'select',
      elementProps: {
        label: 'Citizenship',
        variant: 'standard',
        options:
          dictionary.data[DICTIONARY.citizenship]?.map((item) => ({
            value: item.id,
            title: item[`name${suffix}`] ?? '',
          })) || [],
      },
    },
    {
      name: EDIT.categories_id,
      defaultValue: categories_id,
      elementType: 'select',
      elementProps: {
        multiple: true,
        label: 'Categories',
        variant: 'standard',
        options:
          dictionary.data[DICTIONARY.persona_category]?.map((item) => ({
            value: item.id,
            title: item[`name${suffix}`] ?? '',
          })) || [],
      },
    },
    {
      name: EDIT.sanctions_justification,
      defaultValue: sanctions_justification ?? '',
      elementType: 'input',
      elementProps: {
        placeholder: 'Sanctions justification',
        label: 'Sanctions justification',
        fullWidth: true,
        variant: 'standard',
        multiline: true,
        maxRows: 2,
      },
      yupValidation: yup.string(),
    },
    {
      name: EDIT.sanctions_justification_en,
      defaultValue: sanctions_justification_en ?? '',
      elementType: 'input',
      elementProps: {
        placeholder: 'Sanctions justification en',
        label: 'Sanctions justification en',
        fullWidth: true,
        variant: 'standard',
        multiline: true,
        maxRows: 2,
      },
      yupValidation: yup.string(),
    },
    {
      name: EDIT.sanctions_list_id,
      defaultValue: sanctions_list_id,
      elementType: 'select',
      elementProps: {
        label: 'Sanctions list id',
        multiple: true,
        variant: 'standard',
        options:
          dictionary.data[DICTIONARY.sanctions]?.map((item) => ({
            value: item.id,
            title: item[`name${suffix}`] ?? '',
          })) || [],
      },
    },
    {
      name: EDIT.telegram_channel,
      defaultValue: telegram_channel,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://t.me/',
        label: 'Telegram channel',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.site_url,
      defaultValue: site_url,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://google.com',
        label: 'Site URL',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.wiki_url,
      defaultValue: wiki_url,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://en.wikipedia.org',
        label: 'Wiki URL',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.vk_page,
      defaultValue: vk_page,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://vk.com',
        label: 'VK page',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.facebook_page,
      defaultValue: facebook_page,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://www.facebook.com/',
        label: 'Facebook page',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.youtube_channel,
      defaultValue: youtube_channel,
      elementType: 'input',
      elementProps: {
        placeholder: 'https://www.youtube.com/',
        label: 'Youtube channel',
        fullWidth: true,
        variant: 'standard',
      },
      yupValidation: yup.string().url(),
    },
    {
      name: EDIT.photo_url,
      defaultValue: photo_url,
      elementType: 'input',
      elementProps: {
        classes: 'photo-url_input',
        placeholder: 'https://image.jpg',
        label: 'Photo URL',
        fullWidth: true,
        variant: 'standard',
        multiline: true,
        maxRows: 3,
      },
      yupValidation: yup.string().url(),
    },

    // {
    //   name: 'photo-img',
    //   defaultValue: undefined,
    //   elementType: 'input',
    //   elementProps: {
    //     placeholder: 'Image.jpg',
    //     label: 'Image',
    //     fullWidth: true,
    //     variant: 'standard',
    //     type: 'file',
    //     inputProps: { accept: 'image/*' },
    //   },
    //   yupValidation: yup
    //     .mixed()
    //     .test('fileType', 'Invalid file type', (value) => {
    //       if (!value) return true
    //       const isValid = isValidImageFormat(value.type)
    //       return isValid
    //     }),
    // },
  ]

  const { defaultValues, yupSchema }: FormElementsConfig = formElements.reduce(
    (acc, item) => ({
      ...acc,
      defaultValues: { ...acc.defaultValues, [item.name]: item.defaultValue },
      yupSchema: { ...acc.yupSchema, [item.name]: item.yupValidation },
    }),
    { defaultValues: {}, yupSchema: {} }
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(yupSchema)),
    mode: validationMode,
  })

  const onChange = () => {
    const newValue = getValues()[EDIT.photo_url]
    if (newValue !== urlValue) setUrlValue(newValue as string)
  }

  const onSubmit = (data: unknown) => {
    submitHandler(data, reset)
  }

  const onCloseHandler = () => {
    onClose()
  }

  useEffect(() => {
    setUrlValue(photo_url)
  }, [photo_url])

  const inputController = (data: IpFormPropTypesReact['formElements'][0]) => (
    <Controller
      name={data.name}
      control={control}
      render={({ field }) => {
        const additionalProps: any = {}

        if ((data.elementProps as IpTextFieldPropTypesReact)?.type === 'file') {
          additionalProps.onChange = (e: any) => {
            if (e.target.files) field.onChange(e.target.files[0])
          }
          additionalProps.value = undefined
        }

        if (data.elementType === 'select' && data.elementProps?.multiple) {
          additionalProps.value =
            typeof field.value === 'string'
              ? field.value
                ? field.value.split(',')
                : []
              : field.value
        }

        return (
          <>
            {data.label && (
              <label className="IpForm-element-label">{data.label}</label>
            )}
            {data.elementType === 'input' && (
              <IpTextField
                {...(data.elementProps as IpTextFieldPropTypesReact)}
                {...(field as IpTextFieldPropTypesReact)}
                error={!!errors[data.name]}
                {...additionalProps}
              />
            )}

            {data.elementType === 'select' && (
              <IpSelect
                {...(data.elementProps as IpSelectPropTypesReact)}
                {...(field as Partial<IpSelectPropTypesReact>)}
                defaultValue={(data.defaultValue ?? '') as string}
                error={!!errors[data.name]}
                {...additionalProps}
              ></IpSelect>
            )}
            {errors[data.name]?.message && (
              <FormHelperText className="IpForm-element-error">
                {errors[data.name]?.message}
              </FormHelperText>
            )}
          </>
        )
      }}
    />
  )

  const elements = formElements.map((_, i) => inputController(formElements[i]))

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset()}
      className="persona-form"
      onChange={onChange}
    >
      <div className="persona-form_photo-selection">
        <div>
          <IpAvatar
            sizes="168px"
            avatarsData={[
              {
                src: urlValue,
                color: colors.grey['500'],
              },
            ]}
            classes="persona-form_avatar"
          />
        </div>
        <div>
          <div className="persona-form_input-wrapper">{elements[16]}</div>
          {/* <div className="file-input-wrapper persona-form_input-wrapper">
            {photoImgInput}
          </div>
          <div className="file-delete-wrapper">
            <IpButton classes="underline">Delete photo</IpButton>
          </div> */}
        </div>
      </div>

      <IpText variant="h4" color="primary">
        Common data:
      </IpText>

      <div className="persona-form_elements">
        <div className="persona-form_input-wrapper">{elements[0]}</div>
        <div className="persona-form_input-wrapper">{elements[1]}</div>

        <div className="persona-form_input-wrapper">{elements[2]}</div>
        <div className="persona-form_input-wrapper">{elements[3]}</div>

        <div className="persona-form_input-wrapper">{elements[4]}</div>

        <div className="persona-form_input-wrapper">{elements[5]}</div>
        <div className="persona-form_input-wrapper">{elements[6]}</div>
      </div>

      <IpText variant="h4" color="primary">
        Sanction data:
      </IpText>

      <div className="persona-form_elements">
        <div className="persona-form_input-wrapper">{elements[7]}</div>
        <div className="persona-form_input-wrapper">{elements[8]}</div>
        <div className="persona-form_input-wrapper">{elements[9]}</div>
      </div>

      <IpText variant="h4" color="primary">
        Social data:
      </IpText>

      <div className="persona-form_elements">
        <div className="persona-form_input-wrapper">{elements[10]}</div>
        <div className="persona-form_input-wrapper">{elements[11]}</div>
        <div className="persona-form_input-wrapper">{elements[12]}</div>
        <div className="persona-form_input-wrapper">{elements[13]}</div>
        <div className="persona-form_input-wrapper">{elements[14]}</div>
        <div className="persona-form_input-wrapper">{elements[15]}</div>
      </div>

      <div className="persona-form_submit--wrapper">
        <FormControl>
          <IpButton variant="outlined" type="reset" onClick={onCloseHandler}>
            Cancel
          </IpButton>
        </FormControl>
        <FormControl>
          <IpButton variant="contained" type="submit">
            Save
          </IpButton>
        </FormControl>
      </div>
    </form>
  )
}

type PersonaEditPopupProps = {
  open: boolean
  setOpen: Dispatch<boolean>
  activePersonaId: string
}

export const PersonaEditPopup = ({
  open,
  setOpen,
  activePersonaId,
}: PersonaEditPopupProps) => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const [updating, setUpdating] = useState(false)

  const onCloseHandler = () => {
    setOpen(false)
    setUpdating(false)
  }

  const submitHandler = async (
    params: Omit<PersonaItem, 'id'>,
    reset: () => void
  ) => {
    setUpdating(true)

    const res: any = await dispatch(
      updatePersonaDataThunk({ id: activePersonaId, ...params })
    )

    if (res.error) {
      notification(
        `Errors: ${res.error?.message}; ${res.payload}`,
        'error',
        true
      )
      setUpdating(false)
    } else {
      notification('Person was updated', 'success')
      reset()
      onCloseHandler()
    }
  }

  return (
    <Popover
      className="persona-edit-popup"
      open={open}
      anchorEl={document.body}
      onClose={onCloseHandler}
      onBackdropClick={onCloseHandler}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      {updating && <IpProgressBar />}
      {/* <span
        style={{
          color: 'red',
          fontSize: '0.75rem',
          position: 'absolute',
          top: '5px',
          left: '10px',
        }}
      >
        In development mode
      </span> */}
      <IpIcon
        classes="persona-info-popup_close"
        color="primary"
        onClick={onCloseHandler}
      >
        <CloseIcon />
      </IpIcon>
      <div className="persona-edit-popup_content">
        <IpText variant="h3" color="primary" classes="semi-bold">
          Edit person data
        </IpText>
        <Form
          submitHandler={submitHandler}
          activePersonaId={activePersonaId}
          onClose={onCloseHandler}
        />
      </div>
    </Popover>
  )
}
