import React from 'react'
import type { FC } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import type { IpFormPropTypes } from '@infopulse-design-system/shared/types/IpForm.types'
import * as yup from 'yup'
import type { BaseSchema } from 'yup'
import { FormControl, FormHelperText } from '@mui/material'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import { ConditionalWrap } from '../../../../hoc/ConditionalWrap'
import { IpTextField } from '../IpTextField'
import { IpCheckbox } from '../IpCheckbox'
import { IpButton } from '../IpButton'
import { IpRadioGroup } from '../IpRadioGroup'
import { IpSlider } from '../IpSlider'
import { IpSwitch } from '../IpSwitch'
import { IpSelect } from '../IpSelect'
import type { IpCheckboxPropTypesReact } from '../IpCheckbox'
import type { IpTextFieldPropTypesReact } from '../IpTextField'
import type { IpButtonPropTypesReact } from '../IpButton/index.types'
import type { IpRadioGroupPropTypesReact } from '../IpRadioGroup'
import type { IpSliderPropTypesReact } from '../IpSlider'
import type { IpSwitchPropTypesReact } from '../IpSwitch'
import type { IpSelectPropTypesReact } from '../IpSelect'
import '@infopulse-design-system/shared/theme/components/IpForm/styles.scss'

export const IpFormTestId = 'IpFormTestId'

type ComponentsCompliance = {
  [key: string]: FC
}

type FormElementsConfig = {
  defaultValues: { [key: string]: string | number | boolean }
  yupSchema: { [key: string]: BaseSchema<unknown> }
}

export type IpFormPropTypesReact = IpFormPropTypes<
  BaseSchema<unknown>,
  IpTextFieldPropTypesReact,
  IpCheckboxPropTypesReact,
  IpRadioGroupPropTypesReact,
  IpSelectPropTypesReact,
  IpSliderPropTypesReact,
  IpSwitchPropTypesReact,
  IpButtonPropTypesReact
>

/**
 * `IpForm` component is designed to simplify the process of creating forms with validation. It allows
 * you to provide a configuration that includes field definitions (`formElements`), settings for submit
 * field (`submitElementProps`), and a submit handler (`submitHandler`).
 *
 * By providing this configuration, `IpForm` component automatically generates the necessary form elements
 * based on the defined fields. This includes input fields (`IpTextField`), checkbox (`IpCheckbox`),
 * dropdown (`IpSelect`), slider (`IpSlider`), switch (`IpSwitch`), radio buttons (`IpRadioGroup`)
 * depending on your requirements.
 *
 * One of the key features of our form component is the built-in validation functionality. User can
 * specify validation rules for each field in the configuration (into `yupValidation` field), such as
 * required fields, minimum and maximum lengths, numerical constraints, and custom validation functions.
 * The form component then handles the validation process, providing visual feedback to the user and
 * preventing the submission of invalid data.
 *
 * `validationMode` option provides flexibility in configuring the validation strategy of the form
 * before the user submits it. This option determines when and how the validation takes place.
 * The `validationMode` option grants control over the validation process during the onSubmit event,
 * which is activated when the submitHandler function is invoked. This grants the ability to tailor
 * the validation according to specific requirements, guaranteeing the appropriate validation of form
 * data before it is submitted.
 *
 * By leveraging our form component, you can streamline the process of creating forms with built-in
 * validation, reducing the development time and effort required. It provides a user-friendly interface,
 * ensuring that users can easily interact with and submit valid data through the form.
 *
 * The component is built on yup and react-hook-form libraries.
 */
export function IpForm(props: IpFormPropTypesReact) {
  const {
    formElements,
    submitElementProps,
    submitHandler,
    validationMode = 'onSubmit',
    classes,
  } = props

  const { defaultValues, yupSchema }: FormElementsConfig = formElements.reduce(
    (acc, item) => ({
      ...acc,
      defaultValues: { ...acc.defaultValues, [item.name]: item.defaultValue },
      yupSchema: { ...acc.yupSchema, [item.name]: item.yupValidation },
    }),
    { defaultValues: {}, yupSchema: {} }
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(yupSchema)),
    mode: validationMode,
  })

  const onSubmit = (data: unknown) => {
    submitHandler(data)
    reset()
  }

  const componentsCompliance: ComponentsCompliance = {
    input: IpTextField,
    checkbox: IpCheckbox,
    switch: IpSwitch,
    slider: IpSlider,
    // @ts-ignore
    select: IpSelect,
    // @ts-ignore
    radio: IpRadioGroup,
  }

  const customClasses = formatClassName(
    generateClasses('form', 'react'),
    classes
  )

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid={IpFormTestId}
      className={customClasses}
    >
      {formElements.map(({ name, elementProps, elementType, label }: any) => {
        const controlDefaultProps = {
          name,
          control,
        }
        const helperText = errors[name]?.message as string
        const Component = componentsCompliance[elementType]

        return (
          <ConditionalWrap
            key={name}
            condition={elementType !== 'input' && elementType !== 'select'}
            wrap={(children: any) => (
              <FormControl fullWidth>{children}</FormControl>
            )}
          >
            <Controller
              {...controlDefaultProps}
              render={({ field }) => {
                // todo: DS update
                const additionProps: any = {}
                if (elementType === 'select' && elementProps.multiple) {
                  additionProps.value =
                    typeof field.value === 'string'
                      ? field.value
                        ? field.value.split(',')
                        : []
                      : field.value
                }

                if (elementType === 'input' && elementProps.type === 'file') {
                  additionProps.onChange = (e: any) => {
                    if (e.target.files) {
                      field.onChange(e.target.files[0])
                    }
                  }
                  additionProps.value = undefined
                }

                return (
                  <>
                    {label && (
                      <label className="IpForm-element-label">{label}</label>
                    )}
                    <Component
                      {...elementProps}
                      {...field}
                      error={
                        elementType === 'input' || elementType === 'select'
                          ? !!errors[name]
                          : undefined
                      }
                      checked={
                        elementType === 'checkbox' ? !!field.value : undefined
                      }
                      {...additionProps}
                    />
                    {helperText && (
                      <FormHelperText className="IpForm-element-error">
                        {helperText}
                      </FormHelperText>
                    )}
                  </>
                )
              }}
            />
          </ConditionalWrap>
        )
      })}

      <FormControl fullWidth={submitElementProps.fullWidth}>
        <IpButton
          {...submitElementProps}
          type="submit"
          disabled={!!Object.keys(errors).length}
        />
      </FormControl>
    </form>
  )
}
