import { DEFAULT_LANG_RU } from '../constants'
import {
  dictionariesLanguageSelector,
  settingsLangSelector,
} from '../redux/selectors'
import { useAppSelector } from '../redux/store'

export const useLanguage = () => {
  const settingsLang = useAppSelector(settingsLangSelector)
  const langList = useAppSelector(dictionariesLanguageSelector)

  return {
    langList,
    lang: settingsLang?.language || DEFAULT_LANG_RU.lang,
    locale: settingsLang?.locale || DEFAULT_LANG_RU.locale,
    suffix: settingsLang?.attribute_suffix || DEFAULT_LANG_RU.suffix,
  }
}
