import React from 'react'
import type { ReactNode, ElementType } from 'react'
import { Skeleton } from '@mui/material'
import type { IpSkeletonPropTypes } from '@infopulse-design-system/shared/types/IpSkeleton.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpSkeleton/styles.scss'

export const IpSkeletonTestId = 'IpSkeletonTestId'

export type IpSkeletonTypeReact = IpSkeletonPropTypes<ElementType> & {
  children?: ReactNode
}

/** To enhance user experience and minimize load-time frustration, consider incorporating a placeholder
 * preview of your content while data is being loaded. This technique helps create a sense of immediate
 * response and responsiveness on your page. By using `IpSkeleton`, the user perceives that the content
 * is being loaded incrementally, providing a visual indication that progress is being made. This
 * approach significantly improves the overall perceived performance and user satisfaction by reducing
 * the perception of waiting time.
 */
export function IpSkeleton(props: IpSkeletonTypeReact) {
  const { animation, classes, component, children, height, variant, width } =
    props

  const customClasses = formatClassName(
    generateClasses('skeleton', 'react'),
    classes
  )

  return (
    <Skeleton
      animation={animation}
      className={customClasses}
      {...({ component } || {})}
      data-testid={IpSkeletonTestId}
      height={height}
      variant={variant}
      width={width}
    >
      {children}
    </Skeleton>
  )
}
