import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { useEffect, useRef, useState } from 'react'
import { useResizeObserverTopBottom } from '../../../../hooks/useResizeObserverTopBottom'
import useResponsivePerform from '../../../../hooks/useResponsivePerform'
import { useWindowInnerHeight } from '../../../../hooks/useWindowInnerHeight'
import type { ProcessRes } from '../../../../redux/types/mediaProcessing'
import { getProcessStatus } from '../../../../utils'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import type { ActiveTrData } from '../../PreviewPanel'
import { NextSpeakerButton } from '../../PreviewPanel/NextSpeakerButton'
import { VideoPlayer } from '../../PreviewPanel/VideoPlayer'
import { QuickSearchCustom } from '../../QuickSearch/QuickSearchCustom'
import { ROUTER_PATHS } from '../../Router'
import { ProcessingAdditionMenu } from './ProcessingAdditionMenu'
import { ProcessingTrList } from './ProcessingTrList'

const SCROLL_MARGIN_BOTTOM = 10
type Props = {
  processingData: ProcessRes
  loading: boolean
}

export const ProcessingPanel = ({ processingData, loading }: Props) => {
  const block1Ref = useRef<HTMLDivElement | null>(null)
  const { top, bottom } = useResizeObserverTopBottom(block1Ref.current)
  const { windowInnerHeight } = useWindowInnerHeight()
  const { isMore } = useResponsivePerform(breakpoints.values.md)
  const [trScrollHeight, setTrScrollHeight] = useState(0)
  const isSuccess = getProcessStatus(processingData.status) === 'success'

  const [activeTrData, setActiveTrData] = useState<ActiveTrData>({
    tIndex: 0,
    setManualTime: true,
    needScroll: true,
  })

  useEffect(() => {
    if (!block1Ref.current) return

    if (isMore && top) {
      setTrScrollHeight(windowInnerHeight - top)
    }
    if (!isMore && bottom) {
      setTrScrollHeight(windowInnerHeight - bottom)
    }
  }, [windowInnerHeight, top, bottom, isMore])

  const block1Styles = isMore
    ? {
        style: { height: `${trScrollHeight - SCROLL_MARGIN_BOTTOM}px` },
      }
    : {}

  return (
    <>
      <IpButton
        variant="text"
        classes="btn_go-back"
        to={`/${ROUTER_PATHS.media_processing}`}
      >
        <ArrowBackIcon /> Go to media processing
      </IpButton>
      <NextSpeakerButton
        activeTrData={activeTrData}
        setActiveTrData={setActiveTrData}
        currentTrData={processingData.transcriptions ?? []}
      />
      <QuickSearchCustom
        id={processingData.id}
        targetSelector=".transcription_text"
      />
      <div className="top-line">
        <ProcessingAdditionMenu
          placement={isMore ? 'bottom' : 'left'}
          isSuccess={isSuccess}
        />
      </div>
      <div className="content-wrapper">
        <div className="block-1" ref={block1Ref} {...block1Styles}>
          <VideoPlayer
            activeTrData={activeTrData}
            setActiveTrData={setActiveTrData}
            url={processingData.temp_media_url}
            currentTrData={processingData.transcriptions ?? []}
          />
        </div>
        <div
          className="block-2"
          style={{ height: `${trScrollHeight - SCROLL_MARGIN_BOTTOM}px` }}
        >
          <ProcessingTrList
            activeTrData={activeTrData}
            setActiveTrData={setActiveTrData}
            list={processingData.transcriptions ?? []}
            status={processingData.status}
            loading={loading}
            isSuccess={isSuccess}
            error={processingData.error}
          />
        </div>
      </div>
    </>
  )
}
