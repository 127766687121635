import { primaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import type { IpColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import type {
  IpDividerBorderTypeTypes,
  IpDividerSizeTypes,
} from '@infopulse-design-system/shared/types/IpDivider.types'
import type { Theme, Palette } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpDivider'

const colorNames: IpColorTypes[] = [
  'error',
  'primary',
  'secondary',
  'success',
  'warning',
]

const borderNames: IpDividerBorderTypeTypes[] = ['solid', 'dotted', 'dashed']

const borderThicknessProps: Record<IpDividerSizeTypes, string> = {
  bold: styles.divider_size_bold,
  medium: styles.divider_size_medium,
  thin: styles.divider_size_thin,
}

const getColorStyles = (colors: Palette) => {
  return colorNames.reduce((acc, color) => {
    return {
      ...acc,
      [`&.IpDivider-color-${color}`]: {
        'hr&, &:before, &:after': {
          // @ts-ignore
          borderColor: colors[color].main,
        },
      },
    }
  }, {})
}

const getBorderStyles = borderNames.reduce((acc, border) => {
  return {
    ...acc,
    [`&.IpDivider-${border}`]: {
      '&.MuiDivider-vertical': {
        '&:before, &:after, hr&': {
          borderLeftStyle: border,
        },
      },
      '&:not(.MuiDivider-vertical)': {
        '&:before, &:after, hr&': {
          borderTopStyle: border,
        },
      },
    },
  }
}, {})

const getBorderThickness = Object.entries(borderThicknessProps).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [`&.IpDivider-${key}`]: {
        '&.MuiDivider-vertical': {
          '&:before, &:after, hr&': {
            borderLeftWidth: value,
          },
        },
        '&:not(.MuiDivider-vertical)': {
          '&:before, &:after, hr&': {
            borderTopWidth: value,
          },
        },
      },
    }
  },
  {}
)

export default (theme: Theme) => {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          fontFamily: primaryFontFamily,
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,

          ...getColorStyles(theme.palette),
          ...getBorderStyles,
          ...getBorderThickness,

          '&.IpDivider-color-info': {
            '&:before, &:after, hr&': {
              borderColor: theme.palette.grey['500'],
            },
          },
          '&.MuiDivider-middle': {
            marginLeft: styles.divider_indent_middle_horizontal,
            marginRight: styles.divider_indent_middle_horizontal,
            '&.MuiDivider-vertical': {
              margin: 0,
              'div&': {
                '&:before': {
                  marginTop: styles.divider_indent_middle_vertical,
                },
                '&:after': {
                  marginBottom: styles.divider_indent_middle_vertical,
                },
              },
              'hr&': {
                position: 'relative',
                top: styles.divider_indent_middle_vertical,
                height: `calc(100% - ${styles.divider_indent_middle_vertical}*2)`,
              },
            },
          },
          '&.MuiDivider-inset': {
            marginLeft: styles.divider_indent_inset,
            '&.MuiDivider-vertical': {
              marginLeft: 0,
            },
          },
          '&.MuiDivider-textAlignLeft': {
            'div&': {
              '&:before': {
                width: styles.divider_text_align_indent,
              },
              '&:after': {
                width: `calc(100% - ${styles.divider_text_align_indent})`,
              },
            },
          },
          '&.MuiDivider-textAlignRight': {
            'div&': {
              '&:before': {
                width: `calc(100% - ${styles.divider_text_align_indent})`,
              },
              '&:after': {
                width: styles.divider_text_align_indent,
              },
            },
          },
          '& .MuiDivider-wrapper': {
            padding: `0 ${styles.divider_text_indent}`,
          },
          '&.MuiDivider-vertical .MuiDivider-wrapper': {
            padding: `${styles.divider_text_indent} 0`,
          },
        },
      },
    },
  }
}
