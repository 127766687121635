import ExpandMore from '@mui/icons-material/ExpandMore'
import type { ReactNode } from 'react'
import React, { memo, useMemo } from 'react'
import {
  MAX_FILTER_PERCENTAGE,
  MAX_MEDIA_NUM,
  MAX_SIMILAR_EMBEDDING_NUM,
  MIN_FILTER_PERCENTAGE,
  MIN_MEDIA_NUM,
  MIN_SIMILAR_EMBEDDING_NUM,
} from '../../../../constants'
import { PERMISSIONS } from '../../../../constants/enums'
import useFilters from '../../../../hooks/useFilters'
import type { GetIsPermission } from '../../../../hooks/usePermission'
import { usePermission } from '../../../../hooks/usePermission'
import { dictionariesSelector } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux/store'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import type { IpListItemConfigPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpList'
import { IpList } from '../../../ipDesignSystemComponents/atoms/IpList'
import type { ConfigItemType, FilterKeyType } from '../config'
import { filterConfigObject } from '../config'
import type { FilterCheckboxProps } from './FilterCheckboxes'
import { FilterCheckboxes } from './FilterCheckboxes'
import { FilterMediaLength } from './FilterMediaLength'
import type { FilterPeriodProps } from './FilterPeriod'
import { FilterPeriod } from './FilterPeriod'
import { FilterRangeNumber } from './FilterRangeNumber'
import type { FilterSearchProps } from './FilterSearch'
import { FilterSearch } from './FilterSearch'
import type { FilterSearchCheckboxProps } from './FilterSearchCheckbox'
import { FilterSearchCheckbox } from './FilterSearchCheckbox'
import { FilterSingsOfCrimes } from './FilterSingsOfCrimes'

import './index.scss'

const endIcon = (
  <IpIcon fontSize="20px" color="primary">
    <ExpandMore aria-label="Expanded icon" />
  </IpIcon>
)

type EnabledFiltersList = Array<FilterKeyType | 'weekdays'>

const getConfig = (
  config: ConfigItemType[],
  getIsPermission: GetIsPermission,
  enabledList?: EnabledFiltersList
): IpListItemConfigPropTypesReact => {
  return config.map(([key, { title, type }]: ConfigItemType) => {
    let children: ReactNode = null
    if (type === 'period') {
      children = <FilterPeriod variant={key as FilterPeriodProps['variant']} />
    } else if (key === 'media_length') {
      children = <FilterMediaLength />
    } else if (type === 'search-checkbox') {
      if (
        !getIsPermission(PERMISSIONS.user_tags) &&
        key === PERMISSIONS.user_tags
      ) {
        return {}
      }
      children = (
        <FilterSearchCheckbox
          variant={key as FilterSearchCheckboxProps['variant']}
        />
      )
    } else if (type === 'checkbox') {
      children = (
        <FilterCheckboxes variant={key as FilterCheckboxProps['variant']} />
      )
    } else if (type === 'search') {
      children = <FilterSearch variant={key as FilterSearchProps['variant']} />
    } else if (type === 'sings-of-crimes') {
      if (!getIsPermission(PERMISSIONS.signs_of_crimes_filter)) {
        return {}
      }
      children = <FilterSingsOfCrimes />
    } else if (type === 'percentage-range') {
      children = (
        <FilterRangeNumber
          min={MIN_FILTER_PERCENTAGE}
          max={MAX_FILTER_PERCENTAGE}
          updateFilterTypeFrom="percentage_start"
          updateFilterTypeTo="percentage_end"
          resetType="percentage"
        />
      )
    } else if (type === 'speaker-score-range') {
      if (!getIsPermission(PERMISSIONS.speaker_score_filter)) {
        return {}
      }
      children = (
        <FilterRangeNumber
          min={MIN_SIMILAR_EMBEDDING_NUM}
          max={MAX_SIMILAR_EMBEDDING_NUM}
          updateFilterTypeFrom="similar_embeddings_num_start"
          updateFilterTypeTo="similar_embeddings_num_end"
          resetType="similar_embeddings_num"
        />
      )
    } else if (type === 'media_num-range') {
      children = (
        <FilterRangeNumber
          min={MIN_MEDIA_NUM}
          max={MAX_MEDIA_NUM}
          updateFilterTypeFrom="media_num_start"
          updateFilterTypeTo="media_num_end"
          resetType="media_num"
        />
      )
    }

    const disabled = enabledList ? !enabledList.includes(key) : false

    return {
      disabled,
      textData: { text: title, color: 'primary' },
      endIcon,
      collapseData: {
        inside: false,
        timeout: 100,
        children,
      },
    }
  })
}

const Filters = () => {
  const { error, isLoading } = useAppSelector(dictionariesSelector)
  const { isMainBugsType, filterType } = useFilters()
  const { getIsPermission, permissions, roles } = usePermission()

  const itemsConfig: IpListItemConfigPropTypesReact = useMemo(() => {
    const config = filterConfigObject[filterType]

    const enabledList = isMainBugsType
      ? (['date'] as EnabledFiltersList)
      : undefined

    return getConfig(config, getIsPermission, enabledList)
  }, [filterType, permissions, roles])

  return (
    <div className="filters-list-wrapper">
      {!error && !isLoading && (
        <div className="filters-list">
          <IpList itemsConfig={itemsConfig} />
        </div>
      )}
    </div>
  )
}

export const FiltersList = memo(Filters)
