import React from 'react'

export const Ru = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
  >
    <path
      d="M10.0648 9.89389C10.8705 9.25361 11.3875 8.37761 11.3875 7.27802V6.79973C11.3875 4.86883 9.79881 3.45014 7.83982 3.45014L2.23727 3.42871L2.24063 14.2635L4.88685 14.2841V10.6065H7.60677L9.37982 14.2841H12.4768L10.0648 9.89389ZM8.68352 7.14663C8.68352 7.91549 8.05062 8.35189 7.27018 8.35189H4.88697V5.74998H7.27018C8.05062 5.74998 8.68352 6.2901 8.68352 7.0592V7.14663Z"
      fill="#06037D"
    />
    <path
      d="M17.3787 5.7832V10.7137C17.3787 11.5263 17.0784 12.0756 16.2534 12.0756H16.2125C15.3879 12.0756 15.0874 11.5263 15.0874 10.7137V5.7832H12.4768V10.9019C12.4768 12.8325 13.334 14.3977 15.9402 14.3977H16.4412C19.1855 14.3977 19.9895 12.8325 19.9895 10.9019V5.7832H17.3787Z"
      fill="#06037D"
    />
  </svg>
)
