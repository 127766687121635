import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpList/IpListItemText'

export default (theme: Theme) => {
  return {
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& > .MuiTypography-root': {
            color: theme.palette.text.primary,
            font: styles.list_item_text_font,
          },
        },
      },
    },
  }
}
