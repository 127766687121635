import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpPagination'

export default (theme: Theme) => {
  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            color: theme.palette.grey[900],
            '&:not(.MuiPaginationItem-outlined)': {
              margin: 0,
            },
            '&.MuiPaginationItem-textPrimary .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
            '&.MuiPaginationItem-outlinedPrimary .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
            '&.MuiPaginationItem-textSecondary .MuiSvgIcon-root': {
              color: theme.palette.secondary.main,
            },
            '&.MuiPaginationItem-outlinedSecondary .MuiSvgIcon-root': {
              color: theme.palette.secondary.main,
            },
          },
          '& .MuiPaginationItem-sizeSmall': {
            fontSize: styles.pagination_item_small_font_size,
            minWidth: styles.pagination_item_small_size,
            height: styles.pagination_item_small_size,
          },
          '& .MuiPaginationItem-sizeMedium': {
            fontSize: styles.pagination_item_medium_font_size,
            minWidth: styles.pagination_item_medium_size,
            height: styles.pagination_item_medium_size,
          },
          '& .MuiPaginationItem-sizeLarge': {
            fontSize: styles.pagination_item_large_font_size,
            minWidth: styles.pagination_item_large_size,
            height: styles.pagination_item_large_size,
          },
        },
      },
    },
  }
}
