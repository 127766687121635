import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import type { ActiveTrData } from '..'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import './index.scss'

type RequiredTrFields = { persona_id?: string | null }

const getNextSpeakerIndex = (
  arr: RequiredTrFields[],
  index: number
): number => {
  let foundIndex = -1

  for (let i = index + 1; i < arr.length; i += 1) {
    if (!arr[i].persona_id) {
      foundIndex = i
      break
    }
  }

  if (foundIndex === -1) {
    for (let i = 0; i < arr.length; i += 1) {
      if (!arr[i].persona_id) {
        foundIndex = i
        break
      }
    }
  }

  return foundIndex
}

type Props = {
  activeTrData: ActiveTrData
  setActiveTrData: Dispatch<SetStateAction<ActiveTrData>>
  currentTrData?: RequiredTrFields[]
}

export const NextSpeakerButton = ({
  activeTrData,
  setActiveTrData,
  currentTrData,
}: Props) => {
  const { notification } = useHoveredNotification()

  const onNextSpeakerButtonClick = () => {
    if (currentTrData) {
      const res = getNextSpeakerIndex(currentTrData, activeTrData.tIndex)
      if (res !== -1) {
        setActiveTrData({
          tIndex: res,
          setManualTime: true,
          needScroll: true,
        })
      } else {
        notification("We don't have the next not detected speaker", 'warning')
      }
    }
  }

  return (
    <IpButton
      variant="text"
      onClick={onNextSpeakerButtonClick}
      classes="btn_next-speaker"
    >
      <AssignmentIndOutlinedIcon />
      <NavigateNextOutlinedIcon />
    </IpButton>
  )
}
