import type { UpdateMediaUserTagsParams } from '../api/apiTypes'
import { profileSelector } from '../redux/selectors'
import { useAppDispatch, useAppSelector } from '../redux/store'
import {
  addMediaUserTag,
  removeMediaUserTag,
} from '../redux/thunk/mediaUserTagsThunk'
import { fetchUserTags } from '../redux/thunk/userTagsThunk'
import { useHoveredNotification } from './useHoveredNotification'
import { usePageName } from './usePageName'

export const useUserTags = () => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { isBasketPage } = usePageName()
  const { userTags } = useAppSelector(profileSelector).data

  const add = async ({ mediaId, tag }: UpdateMediaUserTagsParams) => {
    const res: any = await dispatch(addMediaUserTag({ mediaId, tag }))
    if (res.error) {
      const errorMessage = `Error on add user tag: ${res.payload}`
      notification(errorMessage, 'error', true)
    }
    return res
  }

  const removed = async ({ mediaId, tag }: UpdateMediaUserTagsParams) => {
    const res: any = await dispatch(removeMediaUserTag({ mediaId, tag }))
    if (res.error) {
      const errorMessage = `Error on remove user tag: ${res.payload}`
      notification(errorMessage, 'error', true)
    }
    return res
  }

  const fetch = async () => {
    const res: any = await dispatch(
      fetchUserTags(isBasketPage ? 'basket' : undefined)
    )
    if (res.error) {
      const errorMessage = `Error on fetch user tag: ${res.payload}`
      notification(errorMessage, 'error', true)
    }
    return res
  }

  return {
    getUserTags: fetch,
    isBasketTags: isBasketPage,
    userTags,
    addMediaUserTag: add,
    removeMediaUserTag: removed,
  }
}
