import type { Theme } from '@mui/material'
import { primaryFontFamily } from '@infopulse-design-system/shared/theme/typography'
import menuItemStyles from '@infopulse-design-system/shared/theme/components/IpMenuItem'
import inputStyles from '@infopulse-design-system/shared/theme/components/IpInput'

export default (theme: Theme) => {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            fontFamily: primaryFontFamily,
            color: theme.palette.text.primary,
            minHeight: menuItemStyles.list_item_min_height,
            fontSize: inputStyles.input_select_item_font_size,
            lineHeight: inputStyles.input_select_item_line_height,
            [theme.breakpoints.down('lg')]: {
              minHeight: `calc(${menuItemStyles.list_item_min_height} / 1.5)`,
            },
            [theme.breakpoints.down('md')]: {
              minHeight: `calc(${menuItemStyles.list_item_min_height} / 2)`,
            },
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.primary.light,
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
    },
  }
}
