import type { IpSelectOptionTypes } from '@infopulse-design-system/shared/types/IpSelect.types'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import type { SelectChangeEvent } from '@mui/material'
import React from 'react'
import { CardInterval, ChartLabel, ChartType } from '../../../../constants/cart'
import type { ChartLabelType } from '../../../../hooks/dataLoaders/useDashboard'
import useFilters from '../../../../hooks/useFilters'
import { updateFilters } from '../../../../redux/slices/filtersSlice'
import { useAppDispatch } from '../../../../redux/store'
import type { FilterStringDataKeys } from '../../../../redux/types/filters'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpSelect } from '../../../ipDesignSystemComponents/atoms/IpSelect'
import { IpSwitch } from '../../../ipDesignSystemComponents/atoms/IpSwitch'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { ExportToExcel } from '../../icons'
import { DownloadImage } from '../../icons/DownloadImage'
import './index.scss'

type Props = {
  downloadImage: () => void
  percentage: boolean
  setPercentage: React.Dispatch<React.SetStateAction<boolean>>
  exportHandler: () => Promise<void>
  exportLoading: boolean
}

export const DashboardMenu = ({
  downloadImage,
  percentage,
  setPercentage,
  exportHandler,
  exportLoading,
}: Props) => {
  const dispatch = useAppDispatch()
  const {
    filterType,
    filters: { chartLabelType, interval, chartType },
  } = useFilters()

  const isBarChart = chartType === ChartType.bar

  const intervalOptions: IpSelectOptionTypes[] = Object.values(
    CardInterval
  ).map((t) => ({
    title: t,
    value: t,
  }))

  const chartLabelOptions: IpSelectOptionTypes[] = Object.entries(
    ChartLabel
  ).map(([title, value]) => ({
    title,
    value,
  }))

  const update = (value: string, type: FilterStringDataKeys) => {
    dispatch(
      updateFilters({
        filterType,
        type,
        value,
      })
    )
  }

  return (
    <div className="dashboard-menu">
      <div className="dashboard-menu-wrapper">
        {isBarChart ? (
          <>
            <div className="menu-select">
              <IpText color="primary">Parameters:</IpText>
              <IpSelect
                size="small"
                options={chartLabelOptions}
                variant="standard"
                value={chartLabelType}
                onChange={(e: SelectChangeEvent) =>
                  update(e.target.value as ChartLabelType, 'chartLabelType')
                }
                isOptionIcon={false}
              />
            </div>
            <div className="switch">
              <IpText
                color="primary"
                classes={percentage ? 'switch-active' : ''}
              >
                Percentage
              </IpText>
              <IpSwitch
                size="small"
                checked={!percentage}
                onChange={() => setPercentage(!percentage)}
              />
              <IpText
                color="primary"
                classes={!percentage ? 'switch-active' : ''}
              >
                Absolute value
              </IpText>
            </div>
          </>
        ) : (
          <div className="menu-select">
            <IpText color="primary">Period:</IpText>
            <IpSelect
              size="small"
              options={intervalOptions}
              variant="standard"
              value={interval}
              onChange={(e: SelectChangeEvent) =>
                update(e.target.value as CardInterval, 'interval')
              }
              isOptionIcon={false}
            />
          </div>
        )}
      </div>
      <div className="dashboard-menu-wrapper">
        <IpText>Export</IpText>
        <DownloadImage
          fontSize="24px"
          placement="top"
          onClick={downloadImage}
        />
        <ExportToExcel
          fontSize="24px"
          placement="top"
          loading={exportLoading}
          onClick={exportHandler}
        />
        <IpText>Views</IpText>
        <IpIcon
          fontSize="24px"
          color="primary"
          classes={chartType === ChartType.linear ? 'cart-type-btn active' : ''}
          onClick={() => update(ChartType.linear, 'chartType')}
        >
          <TrendingUpIcon />
        </IpIcon>
        <IpIcon
          fontSize="24px"
          color="primary"
          classes={chartType === ChartType.bar ? 'cart-type-btn active' : ''}
          onClick={() => update(ChartType.bar, 'chartType')}
        >
          <LeaderboardIcon />
        </IpIcon>
      </div>
    </div>
  )
}
