import { breakpoints } from '@infopulse-design-system/shared/theme/breakpoints'
import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import useResponsivePerform from '../../../hooks/useResponsivePerform'
import { IpDrawer } from '../../ipDesignSystemComponents/atoms/IpDrawer'
import { IpText } from '../../ipDesignSystemComponents/atoms/IpText'
import { FilterActionButton } from './FilterActionButton'
import { FiltersList } from './FiltersList'

import './index.scss'

const Title = () => (
  <IpText
    color="primary"
    variant="h2"
    classes="main-subtitle main-subtitle_filter semi-bold"
  >
    Filters
  </IpText>
)

const MobFilters = ({
  classes,
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  classes: string
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <>
      <FilterActionButton state="smart" onClick={() => setIsDrawerOpen(true)} />
      <IpDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className={classes}>
          <Title />
          <FiltersList />
        </div>
      </IpDrawer>
    </>
  )
}

const DesktopFilters = ({
  classes,
  isSmartView,
  setIsSmartView,
}: {
  classes: string
  isSmartView: boolean
  setIsSmartView: Dispatch<SetStateAction<boolean>>
}) => {
  return isSmartView ? (
    <FilterActionButton state="smart" onClick={() => setIsSmartView(false)} />
  ) : (
    <div className={classes}>
      <div className="filters_top-line">
        {!isSmartView && (
          <FilterActionButton
            state="collapse"
            onClick={() => setIsSmartView(true)}
          />
        )}
        <Title />
      </div>
      <FiltersList />
    </div>
  )
}

export const FiltersPanel = ({ classes }: { classes: string }) => {
  const { isLess: isMobView } = useResponsivePerform(breakpoints.values.md)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSmartView, setIsSmartView] = useState(false)

  useEffect(() => {
    if (isMobView || isSmartView) {
      document.body.classList.add('smart-filters')
    } else {
      document.body.classList.remove('smart-filters')
    }
  }, [isMobView, isSmartView])

  return isMobView ? (
    <MobFilters
      classes={classes}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
    />
  ) : (
    <DesktopFilters
      classes={classes}
      isSmartView={isSmartView}
      setIsSmartView={setIsSmartView}
    />
  )
}
