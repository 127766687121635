import type { ReactNode } from 'react'

import type { OptionsObject } from 'notistack'
import { useSnackbar } from 'notistack'
import type { EnqueueNotificationOptionsType } from '@infopulse-design-system/shared/types/IpNotificationServise.types'
import { getDuration } from '../services/notificationService'
import { getCustomTransitionComponent } from '../utils'

export const getCustomOptions = (
  options: EnqueueNotificationOptionsType
): OptionsObject | undefined => {
  const { duration, transitionType, position, ...restProps } = options
  return {
    anchorOrigin: position,
    transitionDuration: duration ? getDuration(duration) : undefined,
    TransitionComponent: getCustomTransitionComponent(transitionType),
    ...restProps,
  }
}

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueueNotification = (
    message: string | ReactNode,
    options?: EnqueueNotificationOptionsType
  ) => {
    let customOptions: OptionsObject | undefined
    if (options) {
      customOptions = getCustomOptions(options)
    }
    return enqueueSnackbar(message, customOptions)
  }

  const closeNotification = (key: string | number) => closeSnackbar(key)

  return { enqueueNotification, closeNotification }
}
