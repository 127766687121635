import React, { useEffect, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  INFINITE_SCROLL_PORTION_SIZE,
  INFINITE_SCROLL_THRESHOLD,
} from '../../../../constants'
import { useActiveMedia } from '../../../../hooks/useActiveMedia'
import useFilters from '../../../../hooks/useFilters'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import useMainPageActiveTab from '../../../../hooks/useMainPageActiveTab'
import { useResultListScrollTo } from '../../../../hooks/useResultListScrollTo'
import { useSimilarSpeakerList } from '../../../../hooks/useSimilarSpeakerList'
import {
  mediaListSelector,
  mediaWithBugsSelector,
} from '../../../../redux/selectors'
import { updateFilters } from '../../../../redux/slices/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import type { MediaListItem, MediaWithBug } from '../../../../redux/types/media'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import { EndMessage } from '../../EndMessage'
import { SearchResultItem } from './SearchResultItem'

export const ResultPanel = () => {
  const { notification } = useHoveredNotification()
  const dispatch = useAppDispatch()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { mediaId = '' } = useActiveMedia()
  const { filters, filterType } = useFilters()
  const { isMainBugsTab } = useMainPageActiveTab()
  const {
    storeData,
    isSimilarSpeakerParams,
    getMoreItems: getMoreSimilarSpeakerMedia,
  } = useSimilarSpeakerList()

  const mediaWithBugs = useAppSelector(mediaWithBugsSelector)
  const media = useAppSelector(mediaListSelector)

  const results = isMainBugsTab
    ? mediaWithBugs
    : isSimilarSpeakerParams
    ? storeData
    : media

  useEffect(() => {
    if (results.error) {
      notification(`Error on data loading: ${results.error}`, 'error', true)
    }
  }, [results.error])

  useResultListScrollTo({
    refNode: scrollRef.current,
    activeId: mediaId,
    data: results.data.data,
  })

  const resultsCount = results.data.data.length
  const getMoreItems = async () => {
    await dispatch(
      updateFilters({
        filterType,
        type: 'skip',
        value: filters.skip + INFINITE_SCROLL_PORTION_SIZE,
      })
    )
  }

  const getMoreBugs = async () => {
    dispatch(
      updateFilters({
        filterType,
        type: 'limit',
        value: resultsCount + INFINITE_SCROLL_PORTION_SIZE,
      })
    )
  }

  const hasMore = isMainBugsTab
    ? filters.limit === resultsCount
    : results.data.hasMoreResults

  return (
    <div ref={scrollRef} id="InfiniteScroll">
      {results.isLoading && <IpProgressBar />}
      {resultsCount ? (
        <InfiniteScroll
          scrollThreshold={INFINITE_SCROLL_THRESHOLD}
          dataLength={hasMore ? resultsCount + 1 : resultsCount}
          next={
            isMainBugsTab
              ? getMoreBugs
              : isSimilarSpeakerParams
              ? getMoreSimilarSpeakerMedia
              : getMoreItems
          }
          loader="Loading..."
          hasMore={!!hasMore}
          scrollableTarget="InfiniteScroll"
          endMessage={<EndMessage />}
        >
          {results.data.data.map((item, index) => {
            return (
              <SearchResultItem
                key={
                  (item as MediaWithBug).bug?.id ??
                  (item as MediaListItem).transcription?.id ??
                  index
                }
                index={index}
                isActive={mediaId === item.id}
              />
            )
          })}
        </InfiniteScroll>
      ) : results.isLoading ? (
        'Loading...'
      ) : (
        'No data found by current filters'
      )}
    </div>
  )
}
