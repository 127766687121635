import React from 'react'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'

type Props = {
  title?: string
}

export const Title = ({ title }: Props) => {
  return title ? (
    <IpText variant="body2">
      <span className="semi-bold primary-main">Title: </span>
      {title}
    </IpText>
  ) : (
    <></>
  )
}
