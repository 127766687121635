import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useState } from 'react'
import { IpChip } from '../../../ipDesignSystemComponents/atoms/IpChip'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import './index.scss'

type Props = {
  searchWords: string[]
  updateSearchWords: (data: string | number) => void
}

export const SearchWords = ({ searchWords, updateSearchWords }: Props) => {
  const [isAdd, setIsAdd] = useState(false)
  const [value, setValue] = useState('')

  const update = (value: string) => {
    if (value) {
      updateSearchWords(value)
    }
    setIsAdd(false)
    setValue('')
  }

  const onDelete = (index: number) => {
    updateSearchWords(index)
    setIsAdd(false)
    setValue('')
  }

  return (
    <div className="search-words">
      {!searchWords.length && (
        <IpText component="p" color="primary" variant="h3" classes="semi-bold">
          Search words
        </IpText>
      )}
      {searchWords.map((item, index) => (
        <IpChip
          key={item}
          classes="search-words-chip"
          label={item}
          size="extraSmall"
          deleteIcon={<ClearIcon />}
          color="primary"
          onDelete={() => onDelete(index)}
        />
      ))}
      {searchWords.length < 10 &&
        (isAdd ? (
          <IpTextField
            autoFocus
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                update(value)
              }
            }}
            endAdornment={
              <IpIcon color="primary" onClick={() => update(value)}>
                <AddIcon />
              </IpIcon>
            }
          />
        ) : (
          <IpChip
            classes="search-words-add-btn"
            onClick={() => setIsAdd(true)}
            label={'+'}
            size="extraSmall"
            variant="outlined"
            color="primary"
          />
        ))}
    </div>
  )
}
