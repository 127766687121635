import type { Dispatch, SetStateAction } from 'react'
import React, { useState } from 'react'
import { useHoveredNotification } from '../../../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../../../hooks/useLanguage'
import { useUserTags } from '../../../../../../hooks/useUserTags'
import { useAppDispatch } from '../../../../../../redux/store'
import { addToReportBasket } from '../../../../../../redux/thunk/reportBasketThunk'
import type {
  MediaData,
  Transcription,
} from '../../../../../../redux/types/media'
import { IpButton } from '../../../../../ipDesignSystemComponents/atoms/IpButton'
import { IpText } from '../../../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../../../ipDesignSystemComponents/atoms/IpTextField'
import { SelectedList } from '../../../../SelectedList'

import './index.scss'

const getDispatchErrorMessage = (payload: unknown) => {
  if (typeof payload === 'string') return payload
  if (Array.isArray(payload)) {
    return payload.join(', ')
  }
  return 'Rejected'
}

type Props = {
  textValue: string
  setTextValue: Dispatch<SetStateAction<string>>
  onSubmit: () => void
  transcription: Transcription
  currentMediaData: MediaData
}

export const ReportingBasketContent = ({
  textValue,
  setTextValue,
  onSubmit,
  transcription,
  currentMediaData,
}: Props) => {
  const { notification } = useHoveredNotification()
  const dispatch = useAppDispatch()
  const { suffix } = useLanguage()
  const { userTags } = useUserTags()

  const [tags, setTags] = useState<string[]>([])

  const handleRemoveTagFromMedia = (tag: string) => {
    setTags((state) => state.filter((t) => t !== tag))
  }

  const handleAddTagToMedia = (tag: string) => {
    setTags((state) => [...state, tag])
  }

  const onCancelHandler = () => {
    setTags([])
    onSubmit()
  }

  const onSubmitHandler = async () => {
    const res = await dispatch(
      addToReportBasket({
        time_code: transcription?.start ?? 0,
        air_date: currentMediaData.air_date,
        author_id: currentMediaData.author_id ?? '',
        media_id: currentMediaData.id,
        media_type_id: currentMediaData.media_type_id,
        original_media_link: currentMediaData.original_media_link ?? '',
        persona_id: transcription.persona_id ?? '',
        program_id: currentMediaData.program_id ?? '',
        saved_file_link: currentMediaData.saved_file_link ?? '',
        source_id: currentMediaData.source_id ?? '',
        text: textValue,
        title: currentMediaData[`title${suffix}` as const] ?? '',
        transcription_id: transcription.id,
        user_tags: tags,
      })
    )
    if (res.type.includes('rejected')) {
      notification(getDispatchErrorMessage(res.payload), 'error', true)
    } else {
      setTags([])
      onSubmit()
    }
  }

  const onNewTagAdd = (tag: string) => {
    setTags((oldTags) => [...oldTags, tag])
  }

  return (
    <>
      <IpText variant="subtitle3" color="primary">
        Reporting text
      </IpText>
      <IpTextField
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        variant="standard"
        multiline
        maxRows={3}
      />
      <SelectedList
        title="Tags:"
        list={userTags}
        currentList={tags}
        onItemAdd={handleAddTagToMedia}
        onItemRemove={handleRemoveTagFromMedia}
        onNewItemAdd={onNewTagAdd}
      />
      <IpButton
        variant="outlined"
        size="small"
        onClick={onCancelHandler}
        classes="reporting-basket-btn cancel-btn"
      >
        Cancel
      </IpButton>
      <IpButton variant="contained" size="small" onClick={onSubmitHandler}>
        Add to reporting basket
      </IpButton>
    </>
  )
}
