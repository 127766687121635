import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { INFINITE_SCROLL_PORTION_SIZE } from '../constants'
import { profileNameSelector } from '../redux/selectors'
import { similarSpeakersListSelector } from '../redux/selectors/similarSpeakers'
import { resetSimilarSpeakersList } from '../redux/slices/similarSpeakersSlice'
import { useAppDispatch, useAppSelector } from '../redux/store'
import {
  fetchSimilarSpeakers,
  fetchSimilarSpeakersNext,
} from '../redux/thunk/similarSpeakersThunk'
import { useHoveredNotification } from './useHoveredNotification'
import { usePageName } from './usePageName'

let isInitialRender = false

export const useSimilarSpeakerList = () => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { isMainPage } = usePageName()
  const name = useAppSelector(profileNameSelector)
  const [searchParams] = useSearchParams()
  const mediaId = searchParams.get('mediaId')
  const speaker = searchParams.get('speaker')
  const storeData = useAppSelector(similarSpeakersListSelector)
  const { data, isLoading, error } = storeData
  const [pageIndex, setPageIndex] = useState(1)
  const resultsCount = data.data?.length ?? 0
  const isSimilarSpeakerParams = mediaId && speaker
  const isSimilarSpeakerPage = isSimilarSpeakerParams && isMainPage

  useEffect(() => {
    if (!name) return

    if (
      !isInitialRender &&
      isMainPage &&
      isSimilarSpeakerParams &&
      !resultsCount
    ) {
      const fetchStartList = async () => {
        isInitialRender = true
        const res: any = await dispatch(
          fetchSimilarSpeakers({
            mediaId,
            speaker,
            pageIndex,
            limit: INFINITE_SCROLL_PORTION_SIZE,
          })
        )

        if (res.error) {
          notification(`Error: ${res.payload}`, 'error', true)
        }
      }
      fetchStartList()
    }
  }, [name, isSimilarSpeakerParams, isMainPage, resultsCount])

  const getMoreItems = async () => {
    if (mediaId && speaker) {
      const res: any = await dispatch(
        fetchSimilarSpeakersNext({
          mediaId,
          speaker,
          pageIndex,
          limit: INFINITE_SCROLL_PORTION_SIZE,
        })
      )
      if (res.error) {
        notification(`Error: ${res.payload}`, 'error', true)
      } else {
        setPageIndex((i) => i + 1)
      }
    }
  }

  const reset = async () => {
    dispatch(resetSimilarSpeakersList())
  }

  const hasMoreResults = Boolean(mediaId && speaker && data.hasMoreResults)

  return {
    reset,
    isSimilarSpeakerPage,
    isSimilarSpeakerParams,
    storeData,
    mediaList: data.data,
    hasMoreResults,
    isLoading,
    error,
    getMoreItems,
    resultsCount,
    mediaId,
  }
}
