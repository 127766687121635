import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Menu } from '@mui/material'
import React from 'react'
import { useSortMenu } from '../../../hooks/useSortMenu'
import { IpButton } from '../../ipDesignSystemComponents/atoms/IpButton'
import { IpIcon } from '../../ipDesignSystemComponents/atoms/IpIcon'
import { MEDIA_SORT_MENU_NAME_CONFIG } from './constants/sort-menu-config'
import './index.scss'
import { SortMenuItem } from './SortMenuItem'
import type { SortMenuType } from './types/sort-menu-config.type'

type Props = {
  type: SortMenuType
}

const SortIcon = () => (
  <IpIcon color="primary">
    <FilterListIcon />
  </IpIcon>
)

export const SortMenu = ({ type }: Props) => {
  const {
    anchorEl,
    menuState,
    menuConfig,
    currentOrderBy,
    onMenuItemChange,
    setAnchorEl,
    isAscending,
    isMenuItemDisabled,
  } = useSortMenu(type)

  return (
    <>
      <IpButton
        classes="sort-menu-btn"
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
        startIcon={<SortIcon />}
        endIcon={isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      >
        {MEDIA_SORT_MENU_NAME_CONFIG[currentOrderBy]}
      </IpButton>
      <Menu
        disablePortal
        anchorEl={anchorEl}
        open={menuState}
        onClose={() => setAnchorEl(null)}
      >
        {menuConfig.map((item) => (
          <SortMenuItem
            onMenuItemChange={onMenuItemChange}
            key={item}
            orderBy={item}
            isDisabled={isMenuItemDisabled(item)}
            isSelected={currentOrderBy === item}
            isAscending={isAscending}
          />
        ))}
      </Menu>
    </>
  )
}
