/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PersonaListResponse } from '../../api/apiTypes'
import {
  fetchPersonaList,
  fetchPersonaListNext,
} from '../thunk/personaListThunk'
import type { ApiData } from '../types'
import type { PersonaItem } from '../types/personas'

export type PersonaListState = ApiData<PersonaListResponse>

export const initialPersonaListState: PersonaListState = {
  data: { data: [], hasMoreResults: false },
  isLoading: false,
  error: '',
}

export const PersonaListSlice = createSlice({
  name: 'personaList',
  initialState: initialPersonaListState,
  reducers: {
    setPersonaList: (
      state,
      { payload }: PayloadAction<PersonaListResponse>
    ) => {
      // state.data = payload
      state.data.continuationToken = payload.continuationToken
      state.data.data = payload.data ?? []
      state.data.hasMoreResults = payload.hasMoreResults ?? false
    },
    setPersonaListNext: (
      state,
      { payload }: PayloadAction<PersonaListResponse>
    ) => {
      state.data.data = [...state.data.data, ...payload.data]
      state.data.hasMoreResults = payload.hasMoreResults
      state.data.continuationToken = payload.continuationToken
    },
    updatePersonaList: (state, { payload }: PayloadAction<PersonaItem>) => {
      const personaListItemIndex = state.data.data?.findIndex(
        (item) => item.id === payload.id
      )

      if (personaListItemIndex !== -1) {
        state.data.data[personaListItemIndex] = {
          ...state.data.data[personaListItemIndex],
          ...payload,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonaList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPersonaList.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchPersonaList.rejected, (state, action) => {
      if (action.payload !== 'canceled') {
        state.isLoading = false
        state.error = action.payload as string
      }
    })
    builder.addCase(fetchPersonaListNext.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPersonaListNext.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchPersonaListNext.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as string
    })
  },
})

export const { setPersonaList, updatePersonaList, setPersonaListNext } =
  PersonaListSlice.actions

export default PersonaListSlice.reducer
