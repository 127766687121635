import React from 'react'
import type { ReactNode, ElementType } from 'react'
import { Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { IpDividerPropTypes } from '@infopulse-design-system/shared/types/IpDivider.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import styles from '@infopulse-design-system/shared/theme/components/IpDivider'
import '@infopulse-design-system/shared/theme/components/IpDivider/styles.scss'

export const IpDividerTestId = 'IpDividerTestId'

export type IpDividerPropTypesReact = IpDividerPropTypes & {
  children?: ReactNode
  component?: ElementType
}

type IpDividerStyledPropTypes = {
  pt: string
  pl: string
  pb: string
  pr: string
  component?: IpDividerPropTypesReact['component']
  children?: IpDividerPropTypesReact['children']
}

const IpDividerStyled = styled(
  ({ component: Component = 'div', ...props }: IpDividerStyledPropTypes) => (
    <Component {...props} />
  )
)(({ pt, pl, pb, pr }) => ({
  paddingTop: pt,
  paddingLeft: pl,
  paddingBottom: pb,
  paddingRight: pr,
}))

/**
 * `IpDivider` is a thin line, typically used to visually separate content within lists or layouts.
 * By default, it serves as an element that helps group related items. However, you have
 * the flexibility to customize the style of the divider to match your specific design requirements.
 * With the ability to modify its appearance, the divider can seamlessly integrate into your UI,
 * enhancing the overall visual organization and clarity of your content.
 */
export const IpDivider = ({
  borderType = 'solid',
  children,
  classes,
  color = 'info',
  component,
  orientation,
  paddingBottom = styles.divider_padding_bottom,
  paddingLeft = styles.divider_padding_left,
  paddingRight = styles.divider_padding_right,
  paddingTop = styles.divider_padding_top,
  size = 'thin',
  textAlign,
  variant,
}: IpDividerPropTypesReact) => {
  const customClasses = formatClassName(
    generateClasses('divider', 'react'),
    `IpDivider-${borderType}
     IpDivider-${size}
     IpDivider-color-${color}`,
    classes
  )

  return (
    <IpDividerStyled
      pt={paddingTop}
      pl={paddingLeft}
      pb={paddingBottom}
      pr={paddingRight}
      component={component}
    >
      <Divider
        className={customClasses}
        data-testid={IpDividerTestId}
        orientation={orientation}
        textAlign={textAlign}
        variant={variant}
      >
        {children}
      </Divider>
    </IpDividerStyled>
  )
}
