import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PERMISSIONS } from '../../../constants/enums'
import AboutUs from '../../../pages/AboutUs'
import AdminPanel from '../../../pages/AdminPanel'
import Basket from '../../../pages/Basket'
import Dashboard from '../../../pages/Dashboard'
import Details from '../../../pages/Details'
import Main from '../../../pages/Main'
import MediaProcessing from '../../../pages/MediaProcessing'
import MediaProcessingId from '../../../pages/MediaProcessingId'
import Persona from '../../../pages/Persona'
import PersonLibrary from '../../../pages/PersonLibrary'
import TermsOfUse from '../../../pages/TermsOfUse'
import { Layout } from '../Layout'
import PermissionsGate from '../PermissionsGate'

export const ROUTER_PATHS = {
  root: '/',
  admin_panel: 'admin-panel',
  basket: 'basket',
  dashboard: 'dashboard',
  details: 'details',
  media_processing: 'media-processing',
  person_library: 'person-library',
  persona: 'persona',
  about_us: 'theproject',
  terms_of_use: 'terms-of-use',
} as const

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={ROUTER_PATHS.root} element={<Layout />}>
        <Route index element={<Main />} />
        <Route
          path={ROUTER_PATHS.admin_panel}
          element={
            <PermissionsGate
              permissionKey={PERMISSIONS.admin_panel}
              showPermissionDeniedMessage
            >
              <AdminPanel />
            </PermissionsGate>
          }
        />
        <Route path={ROUTER_PATHS.dashboard} element={<Dashboard />} />
        <Route path={`${ROUTER_PATHS.details}/:id`} element={<Details />} />
        <Route
          path={ROUTER_PATHS.media_processing}
          element={
            <PermissionsGate
              permissionKey={PERMISSIONS.media_processing}
              showPermissionDeniedMessage
            >
              <MediaProcessing />
            </PermissionsGate>
          }
        />
        <Route
          path={`${ROUTER_PATHS.media_processing}/:id`}
          element={
            <PermissionsGate
              permissionKey={PERMISSIONS.media_processing}
              showPermissionDeniedMessage
            >
              <MediaProcessingId />
            </PermissionsGate>
          }
        />
        <Route path={`${ROUTER_PATHS.persona}/:id`} element={<Persona />} />
        <Route
          path={ROUTER_PATHS.basket}
          element={
            <PermissionsGate
              permissionKey={PERMISSIONS.report_basket}
              showPermissionDeniedMessage
            >
              <Basket />
            </PermissionsGate>
          }
        />
        <Route path={ROUTER_PATHS.person_library} element={<PersonLibrary />} />
        <Route path={ROUTER_PATHS.about_us} element={<AboutUs />} />
        <Route path={ROUTER_PATHS.terms_of_use} element={<TermsOfUse />} />
        <Route path="*" element={<Main />} />
      </Route>
    </Routes>
  )
}
