import SearchIcon from '@mui/icons-material/Search'
import { debounce, Popover } from '@mui/material'
import type { ChangeEvent, Dispatch, MouseEvent, SetStateAction } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { apiCreateBug, apiSetSpeakerData } from '../../../../api'
import { UnknownSpeakerData } from '../../../../constants'
import { BugTypes } from '../../../../constants/enums'
import { useHoveredNotification } from '../../../../hooks/useHoveredNotification'
import { useLanguage } from '../../../../hooks/useLanguage'
import {
  dictionariesSelector,
  mediaDataSelector,
} from '../../../../redux/selectors'
import { updatePersonaInMediaListTranscription } from '../../../../redux/slices/mediaListSlice'
import { updateSpeakerInTranscriptionData } from '../../../../redux/slices/transcriptionsDataSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import type { MediaData, Transcription } from '../../../../redux/types/media'
import { filterObjectsByStringField, getSliceByNumber } from '../../../../utils'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import { IpIcon } from '../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpProgressBar } from '../../../ipDesignSystemComponents/atoms/IpProgressBar'
import type { IpRadioGroupPropTypesReact } from '../../../ipDesignSystemComponents/atoms/IpRadioGroup'
import { IpRadioGroup } from '../../../ipDesignSystemComponents/atoms/IpRadioGroup'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import type { PersonaInfoIconState } from '../../persona'
import { PersonaInfoIcon } from '../../persona'
import { AddSpeakerForm } from './AddSpeakerForm'

import './index.scss'

const LIST_START_LENGTH = 10
const DEBOUNCE_TIMEOUT = 500

const endAdornment = (
  <IpIcon
    classes="filter-search-icon"
    ariaLabel="Filter search icon"
    color="primary"
    fontSize="20px"
  >
    <SearchIcon />
  </IpIcon>
)

type SelectSpeakerProps = {
  isBug?: boolean
  anchorEl: null | HTMLElement
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
  setWrongSpeakerMode: Dispatch<SetStateAction<boolean>>
  activeTranscription: Transcription
  setPersonaInfoIconState: Dispatch<SetStateAction<PersonaInfoIconState>>
}

export const SelectSpeaker = (props: SelectSpeakerProps) => {
  const {
    isBug,
    anchorEl,
    setAnchorEl,
    setWrongSpeakerMode,
    activeTranscription,
    setPersonaInfoIconState,
  } = props
  const dispatch = useAppDispatch()
  const { suffix } = useLanguage()
  const nameKey: `name${string}` = `name${suffix}`
  const { notification } = useHoveredNotification()
  const { data: mediaData } = useAppSelector(mediaDataSelector)
  const { data: dictionariesData } = useAppSelector(dictionariesSelector)
  const { personas = [] } = dictionariesData
  const currentMediaData: MediaData | undefined =
    mediaData[activeTranscription.media_id]

  const {
    media_id,
    speaker,
    persona_id,
    id: transcription_id,
    [`text${suffix}` as const]: text,
    start,
    end,
  } = activeTranscription

  const open = Boolean(anchorEl)

  const [radioDataList, setRadioDataList] = useState(personas)
  const [searchValue, setSearchValue] = useState('')
  const [isSelectUI, setIsSelectUI] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [visibleList, setVisibleList] = useState(
    getSliceByNumber(personas, LIST_START_LENGTH)
  )

  const hasMore = radioDataList.length > visibleList.length

  useEffect(() => {
    const newPersonas = filterObjectsByStringField(
      searchValue,
      personas,
      nameKey
    )
    setRadioDataList(newPersonas)
    setVisibleList(getSliceByNumber(newPersonas, LIST_START_LENGTH))
  }, [personas, nameKey])

  const getMoreItems = () => {
    setVisibleList(
      getSliceByNumber(radioDataList, visibleList.length + LIST_START_LENGTH)
    )
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsSelectUI(true)
    setSearchValue('')
    setRadioDataList(personas)
    setVisibleList(getSliceByNumber(personas, LIST_START_LENGTH))
    setIsUpdateLoading(false)
    setWrongSpeakerMode(false)
  }

  const onSpeakerSelect = async (value: string) => {
    try {
      if (isBug) {
        if (currentMediaData) {
          await apiCreateBug({
            transcription_id,
            media_id,
            air_date: currentMediaData.air_date,
            weekday: currentMediaData.weekday,
            media_type_id: currentMediaData.media_type_id,
            source_id: currentMediaData.source_id,
            author_id: currentMediaData.author_id ?? undefined,
            program_id: currentMediaData.program_id ?? '',
            speaker,
            bug_type_id: BugTypes.persona,
            correct_persona_id: value,
            persona_id,
            text: text ?? '',
            start,
            end,
          })
        }
      } else {
        const res = await apiSetSpeakerData({
          media_id,
          speaker_id: speaker,
          persona_id: value,
          transcription_id,
        })

        /** 
         todo: in case we need update speakers in mediaData
         
          if (res.media) {
            dispatch(updateSpeakersInMediaData(res.media as MediaData))
          }
        */
        if (res.transcriptions) {
          dispatch(
            updateSpeakerInTranscriptionData(
              res.transcriptions as Transcription[]
            )
          )

          dispatch(
            updatePersonaInMediaListTranscription({
              id: media_id,
              persona_id: value,
            })
          )
        }
      }
    } catch (e: any) {
      notification(
        `Errors: ${e.message}; ${
          e?.response?.data?.error
        }; ${e?.response?.data?.message?.join(',')};`,
        'error',
        true
      )
    }

    handleClose()
  }

  const onRadioChangeHandler: IpRadioGroupPropTypesReact['onChange'] = async ({
    target: { value },
  }) => {
    setIsUpdateLoading(true)
    await onSpeakerSelect(value)
  }

  const onPersonaIconClick = (
    event: MouseEvent<HTMLElement>,
    personaId: string
  ) => {
    setPersonaInfoIconState({
      anchor: event.currentTarget,
      id: personaId ?? '',
    })
    event.stopPropagation()
  }

  const handleUpdateFiltersDebounced = useCallback(
    debounce((newValue: string) => {
      setIsUpdateLoading(true)
      const newList = filterObjectsByStringField(newValue, personas, nameKey)
      setRadioDataList(newList)
      setVisibleList(getSliceByNumber(newList, LIST_START_LENGTH))
      setIsUpdateLoading(false)
    }, DEBOUNCE_TIMEOUT),
    [personas, nameKey]
  )

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    handleUpdateFiltersDebounced(e.target.value)
  }

  const isUnknownPersonaVisible = !searchValue && isBug

  const radioButtons: IpRadioGroupPropTypesReact['radioButtons'] = (
    isUnknownPersonaVisible ? [UnknownSpeakerData, ...visibleList] : visibleList
  ).map((item, i) => ({
    value: item.id,
    label: (
      <>
        {item[isUnknownPersonaVisible && i === 0 ? 'name' : nameKey]}
        {item.id !== UnknownSpeakerData.id && (
          <PersonaInfoIcon onClick={(e) => onPersonaIconClick(e, item.id)} />
        )}
      </>
    ),
  }))

  const selectUI = (
    <>
      <IpTextField
        value={searchValue}
        variant="standard"
        onChange={onChangeHandler}
        endAdornment={endAdornment}
      />
      <div id="speaker-list">
        <InfiniteScroll
          dataLength={visibleList.length + 1}
          next={getMoreItems}
          loader="Loading..."
          hasMore={hasMore}
          scrollableTarget="speaker-list"
        >
          <IpRadioGroup
            radioButtons={radioButtons}
            ariaLabel="author"
            onChange={onRadioChangeHandler}
            value={persona_id}
          />
        </InfiniteScroll>
      </div>
      {!radioDataList.length && (
        <>
          <span className="speaker-nothing">
            There is no such speaker in the list
          </span>
          <IpButton
            className="speaker-add"
            size="small"
            variant="contained"
            onClick={() => setIsSelectUI(false)}
          >
            Add
          </IpButton>
        </>
      )}
    </>
  )

  const addFormUI = (
    <AddSpeakerForm
      onSubmit={onSpeakerSelect}
      onError={handleClose}
      setIsUpdateLoading={setIsUpdateLoading}
    />
  )

  return (
    <Popover
      className="speaker-select-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onBackdropClick={handleClose}
    >
      {isUpdateLoading ? <IpProgressBar /> : isSelectUI ? selectUI : addFormUI}
    </Popover>
  )
}
