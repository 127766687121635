import React, { forwardRef } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import type { Theme } from '@mui/material'
import { Checkbox, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import colors from '@infopulse-design-system/shared/theme/colors'
import type { IpCheckboxPropTypes } from '@infopulse-design-system/shared/types/IpCheckbox.types'
import type { IpColorTypes } from '@infopulse-design-system/shared/types/IpColor.types'
import {
  formatClassName,
  generateClasses,
  getColor,
} from '@infopulse-design-system/shared/utils/ui.utils'
import styles from '@infopulse-design-system/shared/theme/components/IpCheckbox'
import '@infopulse-design-system/shared/theme/components/IpCheckbox/styles.scss'

export type IpCheckboxPropTypesReact = IpCheckboxPropTypes<
  ReactNode,
  ChangeEvent<HTMLInputElement>
>

const IpFormControlLabelStyled = styled(FormControlLabel)(
  ({ color, size }: { color: string; size?: string }) => {
    return {
      fontSize: size || styles.checkbox_size,
      color,
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color,
      },
    }
  }
)

const IpCheckboxStyled = styled(Checkbox)(
  ({ color, theme }: { color: IpColorTypes; theme?: Theme }) => {
    return {
      color: theme?.palette[color].main,
    }
  }
)

/**
 * `IpCheckbox`es are interactive elements that enable users to choose one or more items
 * from a given set. They provide a convenient way to enable or disable specific options.
 */
export const IpCheckbox = forwardRef(function IpCheckbox(
  props: IpCheckboxPropTypesReact,
  ref
) {
  const {
    checked,
    checkedIcon,
    classes,
    color = 'primary',
    disabled,
    icon,
    label,
    labelColor,
    labelPlacement = 'right',
    onChange,
    required,
    size,
    value,
  } = props

  const customClasses = formatClassName(
    generateClasses('checkbox', 'react'),
    classes
  )

  const labelCustomColor = disabled
    ? colors.grey[900]
    : labelColor
    ? getColor(colors, labelColor)
    : colors[color].main

  return (
    <IpFormControlLabelStyled
      className={customClasses}
      size={size}
      color={labelCustomColor}
      disabled={disabled}
      checked={checked}
      value={value}
      control={
        <IpCheckboxStyled
          checkedIcon={checkedIcon}
          icon={icon}
          color={color}
          onChange={onChange}
          required={required}
        />
      }
      label={label}
      labelPlacement={labelPlacement === 'left' ? 'start' : 'end'}
      ref={ref}
    />
  )
})
