import { useEffect, useState } from 'react'

export const useResizeObserverTopBottom = (
  initialNode: Element | null
): { top: number; bottom: number } => {
  const [bottom, setBottom] = useState<number>(
    initialNode?.getBoundingClientRect()?.bottom ?? 0
  )
  const [top, setTop] = useState<number>(
    initialNode?.getBoundingClientRect()?.top ?? 0
  )

  useEffect(() => {
    if (!initialNode) return

    const resizeObserver = new ResizeObserver(([item]) => {
      const { top: t, bottom: b } = item.target.getBoundingClientRect()
      if (t !== top) {
        setTop(t)
      }
      if (b !== bottom) {
        setBottom(b)
      }
    })

    resizeObserver.observe(initialNode)

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.unobserve(initialNode)
    }
  }, [initialNode])

  return { top, bottom }
}
