import { useEffect } from 'react'
import { personaDataSelector, profileSelector } from '../redux/selectors'
import { resetPersonaDataError } from '../redux/slices/personaDataSlice'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { fetchPersonaData } from '../redux/thunk/personaDataThunk'
import type { PersonaItem } from '../redux/types/personas'
import { getPersonInfoByLang } from '../utils'
import { useHoveredNotification } from './useHoveredNotification'
import { useLanguage } from './useLanguage'

export const usePersonaData = (id: string) => {
  const dispatch = useAppDispatch()
  const { notification } = useHoveredNotification()
  const { suffix } = useLanguage()
  const { name } = useAppSelector(profileSelector).data
  const {
    data,
    isLoading: isPersonaDataLoading,
    error: personaDataError,
  } = useAppSelector(personaDataSelector)
  const personaData: PersonaItem | undefined = data[id]

  useEffect(() => {
    if (
      name &&
      id &&
      !personaData &&
      !isPersonaDataLoading &&
      !personaDataError
    ) {
      const fetchData = async () => {
        const res: any = await dispatch(fetchPersonaData(id))
        if (res.error) {
          notification(
            `Error: ${res.payload}. Error message:  ${res?.error?.message}`,
            'error',
            true
          )
        }
      }
      fetchData()
    }
  }, [name, id, personaData, isPersonaDataLoading, personaDataError])

  const resetError = () => {
    dispatch(resetPersonaDataError())
  }

  return {
    resetError,
    isPersonaData: !!personaData,
    isPersonaDataLoading,
    personaData,
    personaDataError,
    categories_id: personaData?.categories_id,
    citizenship_id: personaData?.citizenship_id,
    sanctions_list_id: personaData?.sanctions_list_id,
    ...getPersonInfoByLang(personaData, suffix),
  }
}
