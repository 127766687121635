import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import type { ExportTranscriptionsParams } from '../../../../api'
import { apiExportMediaData } from '../../../../api'
import { EXPORT_FILE_EXT } from '../../../../constants'
import { EXPORT_FILE_FORMATS, PERMISSIONS } from '../../../../constants/enums'
import { useExportFile } from '../../../../hooks/useExportFile'
import useFilters from '../../../../hooks/useFilters'
import { mediaListSelector, profileSelector } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux/store'
import { getExportFileName } from '../../../../utils'
import { IpButton } from '../../../ipDesignSystemComponents/atoms/IpButton'
import { IpText } from '../../../ipDesignSystemComponents/atoms/IpText'
import { IpTextField } from '../../../ipDesignSystemComponents/atoms/IpTextField'
import { ExportToExcel, ExportToWord } from '../../icons'
import PermissionsGate from '../../PermissionsGate'
import { getDuration, getSize } from './helpers'

export const MediaExportMenu = () => {
  const { data: results } = useAppSelector(mediaListSelector)
  const profile = useAppSelector(profileSelector)
  const { filters } = useFilters()
  const { queryType } = profile.data
  const { handleExport } = useExportFile()
  const [isExcelLoading, setIsExcelLoading] = useState(false)
  const [isDocxLoading, setIsDocxLoading] = useState(false)
  const [state, setState] = useState<
    ExportTranscriptionsParams['fileType'] | null
  >(null)
  const [value, setValue] = useState(100)
  const resultsContText = results.totalMedias ?? 0

  const close = () => {
    setState(null)
    setValue(100)
  }

  const exportHandler = async (
    fileType: ExportTranscriptionsParams['fileType']
  ) => {
    if (!queryType) return
    const fileName = getExportFileName(fileType)
    await handleExport({
      api: apiExportMediaData,
      params: {
        ...filters,
        export_count: value,
        queryType,
        fileType,
      },
      fileName,
      fileExt: EXPORT_FILE_EXT[fileType],
    })
  }

  const onExportExClick = async () => {
    setIsExcelLoading(true)
    await exportHandler(EXPORT_FILE_FORMATS.EXCEL)
    setIsExcelLoading(false)
  }

  const onExportDocxClick = async () => {
    setIsDocxLoading(true)
    await exportHandler(EXPORT_FILE_FORMATS.DOCX)
    setIsDocxLoading(false)
  }

  const load = async () => {
    close()
    if (state === EXPORT_FILE_FORMATS.EXCEL) {
      await onExportExClick()
    } else if (state === EXPORT_FILE_FORMATS.DOCX) {
      await onExportDocxClick()
    }
  }

  return (
    <span className="ml6">
      <PermissionsGate
        permissionKey={PERMISSIONS.export_reports_and_transcriptions}
      >
        <>
          <ExportToExcel
            placement="top"
            loading={isExcelLoading}
            onClick={() => {
              setState(EXPORT_FILE_FORMATS.EXCEL)
            }}
          />
          <ExportToWord
            placement="top"
            loading={isDocxLoading}
            onClick={() => {
              setState(EXPORT_FILE_FORMATS.DOCX)
            }}
          />
        </>
      </PermissionsGate>
      <Dialog
        open={!!state}
        onClose={close}
        sx={{ '.MuiDialog-paper': { borderRadius: '12px' } }}
      >
        <DialogTitle>Amount of medias</DialogTitle>
        <DialogContent>
          <IpText>
            Average waiting time:{' '}
            {getDuration(
              value,
              state as ExportTranscriptionsParams['fileType']
            )}
          </IpText>
          <IpText>
            Average {state} file size ~{' '}
            {getSize(value, state as ExportTranscriptionsParams['fileType'])}Mb
          </IpText>
          <IpText>Max: {resultsContText} item(s)</IpText>
          <IpTextField
            autoFocus
            fullWidth
            inputProps={{
              step: 10,
              min: 0,
              type: 'number',
            }}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(Number(event.target.value))
            }}
          />
        </DialogContent>
        <DialogActions>
          <IpButton variant="outlined" onClick={close}>
            Cancel
          </IpButton>
          <IpButton variant="contained" onClick={load}>
            Load file
          </IpButton>
        </DialogActions>
      </Dialog>
    </span>
  )
}
