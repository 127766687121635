import React, { forwardRef } from 'react'
import type { RefObject, ChangeEvent, ReactNode } from 'react'
import Switch from '@mui/material/Switch'
import type { IpSwitchPropTypes } from '@infopulse-design-system/shared/types/IpSwitch.types'
import {
  formatClassName,
  generateClasses,
} from '@infopulse-design-system/shared/utils/ui.utils'
import '@infopulse-design-system/shared/theme/components/IpSwitch/styles.scss'
import type { InputBaseComponentProps } from '@mui/material'
import { FormControlLabel } from '@mui/material'

export const IpSwitchTestId = 'IpSwitchTestId'

export type IpSwitchPropTypesReact = IpSwitchPropTypes<
  ReactNode,
  InputBaseComponentProps,
  RefObject<ReactNode>,
  ChangeEvent<HTMLInputElement>
>

/**
 * Switches offer a convenient way to toggle the state of individual settings, allowing users to easily
 * turn them on or off. Switches are especially well-suited for adjusting settings on mobile devices,
 * providing a user-friendly and intuitive interaction. To ensure clarity, it's important to provide a
 * clear and descriptive inline label that clearly indicates both the option controlled by the switch
 * and its current state. This enables users to quickly understand and interact with the switch in a
 * seamless manner.
 */
export const IpSwitch = forwardRef(function IpSwitch(
  props: IpSwitchPropTypesReact,
  ref
) {
  const defaultSwitch = (
    <span className="MuiSwitch-thumb css-jsexje-MuiSwitch-thumb" />
  )
  const {
    checked,
    checkedIcon = defaultSwitch,
    classes,
    color,
    disabled,
    icon = defaultSwitch,
    id,
    inputProps,
    inputRef,
    label,
    onChange,
    required,
    size = 'medium',
    value,
  } = props

  const customClasses = formatClassName(
    generateClasses('switch', 'react'),
    classes
  )

  return (
    <FormControlLabel
      data-testid={IpSwitchTestId}
      className={customClasses}
      control={
        <Switch
          checked={checked}
          checkedIcon={checkedIcon}
          color={color}
          disabled={disabled}
          icon={icon}
          id={id}
          inputProps={inputProps}
          inputRef={inputRef}
          onChange={onChange}
          required={required}
          size={size}
          value={value}
        />
      }
      label={label}
      ref={ref}
    />
  )
})
