import LanguageIcon from '@mui/icons-material/Language'
import React from 'react'
import { IpIcon } from '../../../../ipDesignSystemComponents/atoms/IpIcon'
import { IpLink } from '../../../../ipDesignSystemComponents/atoms/IpLink'
import type { IpTooltipPropTypesReact } from '../../../../ipDesignSystemComponents/atoms/IpTooltip'
import { IpTooltip } from '../../../../ipDesignSystemComponents/atoms/IpTooltip'

type Props = {
  placement: IpTooltipPropTypesReact['placement']
  currentUrl?: string
}

export const LinkToUrl = ({ placement, currentUrl }: Props) => {
  return (
    <IpTooltip
      arrow
      title="Original media link"
      placement={placement}
      content={
        currentUrl ? (
          <IpLink href={currentUrl} target="_blank">
            <IpIcon color="primary">
              <LanguageIcon />
            </IpIcon>
          </IpLink>
        ) : (
          <IpIcon>
            <LanguageIcon stroke="#ADB5BD" />
          </IpIcon>
        )
      }
    />
  )
}
