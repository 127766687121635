import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpButton'
import tableStyles from '@infopulse-design-system/shared/theme/components/IpTable'

const isTouchScreen =
  '@media only screen and (hover: none) and (pointer: coarse)'

export default (theme: Theme) => {
  return {
    MuiButton: {
      variants: [
        {
          props: { size: 'extraSmall' },
          style: {
            fontSize: styles.button_extra_small_font_size,
            minWidth: styles.button_extra_small_min_width,
            minHeight: styles.button_extra_small_min_height,
            padding: 0,
          },
        },
        {
          props: { size: 'small' },
          style: {
            minWidth: styles.button_small_min_width,
            minHeight: styles.button_small_min_height,
            fontSize: styles.button_small_font_size,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            minWidth: styles.button_medium_min_width,
            minHeight: styles.button_medium_min_height,
            fontSize: styles.button_medium_font_size,
          },
        },
        {
          props: { size: 'large' },
          style: {
            minWidth: styles.button_large_min_width,
            minHeight: styles.button_large_min_height,
            fontSize: styles.button_large_font_size,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: styles.button_border_radius,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            '.hover-animation': {
              '&:before': {
                width: '100%',
                [isTouchScreen]: {
                  width: 0,
                },
              },
            },
          },
          '.hover-animation': {
            position: 'absolute',
            height: '100%',
            width: '100%',
            borderRadius: 'inherit',
            overflow: 'hidden',
            zIndex: 1,
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: 0,
              height: '100%',
              transition: 'width 0.3s',
              borderRadius: 'inherit',
            },
          },
          '.button-content, .MuiButton-startIcon, .MuiButton-endIcon': {
            position: 'relative',
            zIndex: 2,
          },
          '&.MuiButton': {
            'a&-text': {
              textDecoration: 'underline',
            },
            '&-text': {
              padding: 0,
              minWidth: 0,
              minHeight: 0,
            },
            '&-containedPrimary': {
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
              '.hover-animation:before': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
            '&-containedSecondary': {
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.secondary,
              },
              '.hover-animation:before': {
                backgroundColor: theme.palette.secondary.dark,
              },
            },
            '&-containedSuccess, &-containedError': {
              color: theme.palette.text.secondary,
            },
            '&-outlined': {
              '.hover-animation': {
                height: 'calc(100% + 2px)',
                left: '-1px',
              },
              '&:hover .hover-animation': {
                width: 'calc(100% + 2px)',
              },
            },
            '&-outlinedPrimary, &-outlinedSecondary': {
              backgroundColor: theme.palette.background.default,
              borderWidth: 1,
              borderStyle: 'solid',
              transition: 'border-color 0.3s',
            },
            '&-outlinedPrimary': {
              borderColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.text.secondary,
                borderColor: theme.palette.primary.dark,
                [isTouchScreen]: {
                  color: theme.palette.primary.main,
                },
              },
              '.hover-animation:before': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
            '&-outlinedSecondary': {
              borderColor: theme.palette.secondary.main,
              '&:hover': {
                color: theme.palette.text.secondary,
                borderColor: theme.palette.secondary.dark,
                [isTouchScreen]: {
                  color: theme.palette.secondary.main,
                },
              },
              '.hover-animation:before': {
                backgroundColor: theme.palette.secondary.dark,
              },
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey['500'],
            color: theme.palette.grey['900'],
            '&.MuiButton-outlined': {
              backgroundColor: theme.palette.background.default,
            },
          },
          '& .MuiTouchRipple-root': {
            zIndex: 1,
          },
          '&.IpTableFilter-open-btn': {
            minWidth: tableStyles.table_filter_open_btn_width,
            margin: tableStyles.table_filter_open_btn_margin,
            '&.active:after': {
              content: "''",
              width: tableStyles.table_filter_dot_size,
              height: tableStyles.table_filter_dot_size,
              backgroundColor: theme.palette.error.main,
              borderRadius: '50%',
              position: 'absolute',
              top: tableStyles.table_filter_dot_position_top,
              right: tableStyles.table_filter_dot_position_right,
              zIndex: 2,
            },
          },
          '&.IpTableFilter-reset-btn': {
            margin: tableStyles.table_filter_reset_btn_margin,
          },
        },
      },
    },
  }
}
