import type { QueryType } from '../redux/slices/profileSlice'
import { api } from './axiosInstance'

export const apiGetUserData = async () => {
  try {
    const response = await api.get('user')
    return response.data
  } catch (error) {
    console.error('Error fetching dictionaries:', error)
    throw error
  }
}

export const apiSetUserQueryType = async (params: { queryType: QueryType }) => {
  try {
    const response = await api.post('user/querytype', params)
    return response.data
  } catch (error) {
    console.error('Error during setting user query type:', error)
    throw error
  }
}

export const apiGetClientId = async () => {
  try {
    const response = await api.get('auth/api-client-id')
    return response.data
  } catch (error) {
    console.error('Error during get token', error)
    throw error
  }
}
