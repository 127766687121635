import React from 'react'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { dictionariesSelector } from '../../../../../redux/selectors'
import { useAppSelector } from '../../../../../redux/store'
import type { DictionaryContent } from '../../../../../redux/types/dictionaries'
import { DICTIONARY } from '../../../../../redux/types/dictionaries'
import { CheckBox } from '../CheckBox'
import { FilterSearchModel } from './FilterSearchModel'

import './index.scss'

const variant = DICTIONARY.signs_of_crimes

export const FilterSingsOfCrimes = () => {
  const { data } = useAppSelector(dictionariesSelector)
  const { suffix } = useLanguage()
  const checkboxes = data[variant]
  const isModelsVariants = data.models.length > 1
  const nameKey = `name${suffix}`

  return (
    <div className="filter-soc">
      <div className="filter-checkbox">
        {checkboxes.map((item) => {
          return (
            <CheckBox
              key={item.id}
              id={item.id}
              name={item[nameKey as keyof DictionaryContent] ?? ''}
              type={variant}
              labelColor="text.primary"
            />
          )
        })}
      </div>
      {isModelsVariants && <FilterSearchModel />}
    </div>
  )
}
