import type { Theme } from '@mui/material'
import styles from '@infopulse-design-system/shared/theme/components/IpProgressBar'

export default (theme: Theme) => {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          '&.MuiCircularProgress-colorPrimary': {
            color: theme.palette.primary.dark,
          },
          '&.MuiCircularProgress-colorSecondary': {
            color: theme.palette.secondary.dark,
          },
          '&.MuiCircularProgress-colorError': {
            color: theme.palette.error.dark,
          },
          '&.MuiCircularProgress-colorInfo': {
            color: theme.palette.info.dark,
          },
          '&.MuiCircularProgress-colorSuccess': {
            color: theme.palette.success.dark,
          },
          '&.MuiCircularProgress-colorWarning': {
            color: theme.palette.warning.dark,
          },
          '& ~ .IpProgressBar-label-root': {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.text.primary,
            font: styles.progress_bar_label_font,
          },
        },
      },
    },
  }
}
